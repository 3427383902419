import { BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext, useMemo } from 'react';
import { BlipProgressBar } from '../../../../components/BlipProgressBar';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import {
  CompiledDailyActiveUsersOverspentProgressContent,
  DailyActiveUsersProgressBarAligned,
  DailyActiveUsersProgressTotalAligned,
} from '../styles';
import { useDauConsumptionContext } from '../../../../contexts/Billing/DauConsumptionContext';

const CompiledDailyActiveUserProgressContentComponent: React.FC = () => {
  const { content, currentMonth } = useContext(DailyActiveUsersContext);
  const { compiledDailyActiveUserDataState } = useDauConsumptionContext();
  const compiledContainsDataOnSelectedMonth = compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth;
  const planUsageRate = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsageRate;
  const planUsage = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsage;
  const quantity = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.metric.quantity;
  const exceededPlan = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.exceededPlan;

  const NO_PERCENTAGE_LABEL = '0%';

  const spentPercentage = useMemo(() => {
    if (compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth) {
      return content.formatString(
        content.compiledDailyActiveUserInformation.spentPercentage,
        compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsageRate,
      );
    } else {
      return NO_PERCENTAGE_LABEL;
    }
  }, [compiledDailyActiveUserDataState]);

  return (
    <CompiledDailyActiveUsersOverspentProgressContent
      className="bg-color-surface-2"
      containsOverspent={compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth}
      currentMonth={currentMonth}
      exceededPlan={exceededPlan > 0}
    >
      <DailyActiveUsersProgressBarAligned exceededPlan={exceededPlan > 0}>
        <BdsTypo data-planUsageRate={planUsageRate} tag="span" variant="fs-16" bold="bold" margin={false}>
          {spentPercentage}
        </BdsTypo>
        <BdsTooltip
          position="top-center"
          tooltip-text={content.compiledDailyActiveUserInformation.progressContentHoverIcon}
        >
          <BdsIcon name="info"></BdsIcon>
        </BdsTooltip>
        <BlipProgressBar
          completed={planUsageRate >= 0 && compiledContainsDataOnSelectedMonth ? planUsageRate : 0}
          color={currentMonth ? 'information' : 'default'}
        ></BlipProgressBar>
      </DailyActiveUsersProgressBarAligned>

      <DailyActiveUsersProgressTotalAligned currentMonth={currentMonth}>
        <BdsTypo
          className={currentMonth ? 'color-content-default' : 'color-primary'}
          data-planUsage={planUsage}
          variant="fs-32"
          tag="span"
          bold="extra-bold"
          margin={false}
        >
          {planUsage >= 0 && compiledContainsDataOnSelectedMonth ? planUsage.toLocaleString(content.locale) : '--'}
        </BdsTypo>
        <BdsTypo className={'color-content-disable'} variant="fs-32" tag="span" bold="extra-bold">
          /
        </BdsTypo>
        <BdsTypo
          className={'color-content-disable'}
          data-quantity={quantity}
          variant="fs-24"
          tag="span"
          bold="extra-bold"
          margin={false}
        >
          {quantity >= 0 && compiledContainsDataOnSelectedMonth ? quantity.toLocaleString(content.locale) : '--'}
        </BdsTypo>
      </DailyActiveUsersProgressTotalAligned>
    </CompiledDailyActiveUsersOverspentProgressContent>
  );
};

export default CompiledDailyActiveUserProgressContentComponent;
