import React from 'react';
import PropTypes from 'prop-types';
import { InfoPaperComponent } from './InfoPaperComponent';

const InfoPaperContainer = ({
  icon = '',
  title,
  justifyContent = '',
  classNameTitle = '',
  text,
  tooltipIcon = '',
  tooltipText = '',
  bdsPaper = true,
  infoPaperType = 'number',
  tooltipTrackEvent,
}) => {
  return (
    <InfoPaperComponent
      icon={icon}
      title={title}
      justifyContent={justifyContent}
      classNameTitle={classNameTitle}
      text={text}
      tooltipIcon={tooltipIcon}
      tooltipText={tooltipText}
      bdsPaper={bdsPaper}
      infoPaperType={infoPaperType}
      tooltipTrackEvent={tooltipTrackEvent}
    ></InfoPaperComponent>
  );
};

InfoPaperContainer.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  tooltipIcon: PropTypes.string,
  tooltipText: PropTypes.string,
  bdsPaper: PropTypes.bool,
  infoPaperType: PropTypes.string,
  tooltipTrackEvent: PropTypes.func,
};

export { InfoPaperContainer };
