export const formatNumber = (n) => {
  if (n >= 1_000_000_000) {
    return '' + Math.trunc(n / 100_000_000) / 10 + 'G';
  }
  if (n >= 1_000_000) {
    return '' + Math.trunc(n / 100_000) / 10 + 'M';
  }
  return n;
};

export const roundNextHundredth = (num) => {
  const tenth = num % 100;
  num = num - tenth + Math.ceil(tenth / 100) * 100;
  return num;
};

export const MAX_32_BIT_INTEGER = 2147483647;
