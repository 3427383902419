export const CommonReducer = (state, action) => {
  switch (action.type) {
    case 'setLanguage':
      return { ...state, language: action.language.toLowerCase() };
    case 'setConfig':
      return { ...state, config: action.config };
    case 'setLoading':
      return { ...state, loading: action.loading };
    case 'setLoggedUser':
      return { ...state, loggedUser: action.loggedUser };
    case 'setHeaderContent':
      return { ...state, headerContent: action.headerContent };
    default:
      throw new Error(`CommonReducer: invalid action called ${action.type}`);
  }
};

