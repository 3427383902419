import { Order } from 'models/revenue/Order';

export const sortByProperty = (array: Array<any>, property: string, order: Order) => {
  order = order || 'asc';
  return array.sort((a, b) => {
    if (a[property] < b[property]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[property] > b[property]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const sortByOrder = (array: Array<any>, order: Order) => {
  order = order || 'asc';
  return array.sort((a, b) => {
    if (a < b) {
      return order === 'asc' ? -1 : 1;
    }
    if (a > b) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

export const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};
