import { BlipApi } from './BlipApi';
import { applicationDomain } from 'shared/Domains';

export const getConfiguration = async ({ owner, caller }) => {
  let uri = `lime://${owner}/configuration`;
  const to = applicationDomain.msging;
  if (caller) {
    uri += `/caller?owner=${caller}`;
  }

  const {response} = await BlipApi.sendCommand({
    to,
    method: 'get',
    uri,
  });
  return response;
};

export const setConfiguration = async ({ owner, caller, configuration, pp = null }) => {
  let uri = `lime://${caller}/configuration`;
  const to = applicationDomain.msging;
  if (owner) {
    uri += `?caller=${owner}`;
  }

  const { response } = await BlipApi.sendCommand({
    to,
    method: 'set',
    uri,
    type: 'application/json',
    resource: configuration,
    from: owner,
    pp,
  });
  return response;
};
