import React from 'react';
import PropTypes from 'prop-types';
import { BdsTypo, BdsButton, BdsModal, BdsModalAction } from 'blip-ds/dist/blip-ds-react';
import NumberFormat from 'react-number-format';
import { ContentModal } from './styles';

const ModalAgentBillingMessage = ({ open, content, subscription,planName, quantityAgents, onClick, close }) => {
  
  const startDate = new Date();
  const { additionalItemValue, quantity } = subscription.find((s) => s.metricId === 'Agent');
  
  return (
    <BdsModal open={open} onBdsModalChanged={() => close()}>
      <ContentModal>
        <BdsTypo tag="h2" variant="fs-20" bold="extra-bold" className="cheetos">
          {content.modalBilling.title}
        </BdsTypo>
        <BdsTypo tag="h2" variant="fs-20" bold="extra-bold" className="disabled-text">
          {content.modalBilling.subTitle}
        </BdsTypo>
        <BdsTypo tag="p" variant="fs-14" bold="regular">
          {content.formatString(content.modalBilling.body, {
            month: content.modalBilling.months[startDate.getMonth()],
            additionalItemValue: (
              <NumberFormat
                value={additionalItemValue}
                displayType={'text'}
                thousandSeparator={content.thousandSeparator}
                decimalSeparator={content.decimalSeparator}
                prefix={'R$ '}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          })}
        </BdsTypo>
        <div className="flex justify-between mt3  ">
          <div className="flex-wrap ">
            <BdsTypo tag="h4" variant="fs-14" bold="regular" className="cloud">
              {content.modalBilling.plan}
            </BdsTypo>
            <BdsTypo tag="h2" variant="fs-20" bold="extra-bold" className="rooftop">
              {planName}
            </BdsTypo>
          </div>
          <div className="flex-wrap ">
            <BdsTypo tag="h4" variant="fs-14" bold="regular" className="cloud">
              {content.modalBilling.limitAgentPlan}
            </BdsTypo>
            <BdsTypo tag="h2" variant="fs-20" bold="extra-bold" className="rooftop">
              {`${quantity}/${content.modalBilling.month}`}
            </BdsTypo>
          </div>
          <div className="flex-wrap ">
            <BdsTypo tag="h4" variant="fs-14" bold="regular" className="cloud ">
              {content.modalBilling.agentActiveMonth}
            </BdsTypo>
            <BdsTypo tag="h2" variant="fs-20" bold="extra-bold" className="rooftop">
              {quantityAgents}
            </BdsTypo>
          </div>
        </div>
        <div className="mt4">
          <BdsTypo variant="fs-14" bold="bold" className="mt3 rooftop">
            {content.modalBilling.confirm}
          </BdsTypo>
        </div>
      </ContentModal>
      <BdsModalAction>
        <BdsButton className="mr4" variant="secondary" onClick={() => close(false)}>
          {content.cancel}
        </BdsButton>
        <BdsButton bds-loading-variant="primary" variant="primary" onClick={() => onClick()}>
          {content.continue}
        </BdsButton>
      </BdsModalAction>
    </BdsModal>
  );
};

ModalAgentBillingMessage.propTypes = {
  content: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  quantityAgents: PropTypes.number,
  planName: PropTypes.string,
  close: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

ModalAgentBillingMessage.defaultProps = {
  additionalItemValue: 0,
  planName: 'Standard',
};

export { ModalAgentBillingMessage };
