import { BdsAvatar, BdsCheckbox, BdsGrid, BdsIcon, BdsSwitch, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import { Card } from '../styles';

function ListBotCopilot({bots, translate, onChangeStatus, onChangeSelectBot }) {
  return (
    <BdsGrid className="mt1" direction="column">
      {bots.map(({ id, name, status, imageUri, selected, hasPermission }) => (
        <Card key={id} selected={selected} className="mt3 column flex justify-between items-center">
          <div className="flex column items-center">
            <BdsCheckbox
              disabled={!hasPermission}
              name={id}
              checked={selected}
              className="mh3"
              onBdsChange={onChangeSelectBot}
            />
            {imageUri ? (
              <BdsAvatar thumbnail={imageUri} />
            ) : (
              <BdsIcon name="blip-chat" type="logo" size="xxx-large" className="round-avatar" />
            )}
            <div className="ml3">
              <div className="mb2">
                <BdsTypo bold="bold" variant="fs-10" className="label">
                  {translate.title}
                </BdsTypo>
              </div>
              <BdsTypo bold="extra-bold" variant="fs-16" margin={false} className={!hasPermission && 'label'}>
                {name}
              </BdsTypo>
            </div>
          </div>
          <div>
            {hasPermission ? (
              <BdsSwitch name={id} onBdsChange={onChangeStatus} checked={status} />
            ) : (
              <BdsTooltip tooltip-text={translate.hasNotPermission}>
                <BdsIcon name="lock" className="label" />
              </BdsTooltip>
            )}
          </div>
        </Card>
      ))}
    </BdsGrid>
  );
}

export default ListBotCopilot;
