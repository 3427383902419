import React from 'react';
import PropTypes from 'prop-types';
import { ChartJS } from '../index';
import { CategoryScale, LinearScale, PointElement, LineElement, Filler, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Title, Tooltip, Legend);

const LineChartComponent = ({ data, options, plugins, redraw }) => {
  return <Line options={options} data={data} plugins={plugins} redraw={redraw} />;
};

LineChartComponent.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.object,
  redraw: PropTypes.bool,
};

export { LineChartComponent };
