import React, { FC, useContext, useEffect } from 'react';
import miuConsumptionContext from '../../../../../contexts/MiuConsumptionContext';
import { billingRoutes } from '../../../../../routes/BillingRoutes';
import MiuConsumptionDailyUsage from '../../../MiuConsumption/components/MiuConsumptionDailyUsage';
import { MiuConsumptionContainer } from '../../styles';

const DailyUsageComponent: FC = () => {
  const { content, dateToSearch, tenant, setHeaderContent } = useContext(miuConsumptionContext);
  useEffect(() => {
    setHeaderContent({
      redirect: billingRoutes.mauConsumption,
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: '/' });
  }, [dateToSearch]);

  return (
    <MiuConsumptionContainer>
      <MiuConsumptionDailyUsage tableBodyHeight="1000px" registersToTake={-1}></MiuConsumptionDailyUsage>
    </MiuConsumptionContainer>
  );
};

export default DailyUsageComponent;
