import React from 'react';
import { BillingConsumptionContextProvider } from '../contexts/Billing/BillingConsumptionContext';
import { BillingMetricsContextProvider, useBillingMetricsContext } from '../contexts/Billing/BillingMetricsContext';
import Revenue from '../views/Revenue';
import DailyActiveUsers from '../views/DailyActiveUsers';
import OldSessionConsumption from '../views/SessionConsumption';
import OldAgentConsumption from '../views/AgentConsumption';

import MiuConsumption from '../views/Billing/MiuConsumption';
import DauConsumption from '../views/Billing/DailyActiveUsers';
import SessionConsumption from '../views/Billing/SessionConsumption';
import AgentConsumption from '../views/Billing/AgentConsumption';
import BillingConsumption from '../views/Billing';
import { useTenant } from '../contexts/TenantContext';
import { useCommon } from '../contexts/CommonContext';

import { BillingMetrics } from '../shared/BillingMetrics';

import { PrivateRoute } from '../components/PrivateRoute';

import {
  DATA_CONSUMPTION,
  DAILY_ACTIVE_USERS_CONSUMPTION,
  AGENT_CONSUMPTION,
  SESSION_CONSUMPTION,
  TENANT_CONSUMPTION,
} from '../shared/FeatureToggleKeys';

import {
  REPORT_OVERVIEW_CONSUMPTION_OPEN,
  REPORT_DAU_CONSUMPTION_OPEN,
  REPORT_AGENT_CONSUMPTION_OPEN,
  REPORT_SESSION_CONSUMPTION_OPEN,
  REPORT_REVENUE_CONSUMPTION_OPEN,
  REPORT_DAU_CONSUMPTION_PER_CHATBOT_OPEN,
  REPORT_DAU_CONSUMPTION_PER_DATE_OPEN,
  REPORT_AGENT_CONSUMPTION_LIST_OPEN,
  REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_OPEN,
  REPORT_SESSION_CONSUMPTION_PER_DATE_OPEN,
  REPORT_REVENUE_CONSUMPTION_PER_CHATBOT_OPEN,
} from '../shared/SegmentEvents';
import { DauConsumptionContextProvider, useDauConsumptionContext } from '../contexts/Billing/DauConsumptionContext';
import {
  AgentConsumptionContextProvider,
  useAgentConsumptionContext,
} from '../contexts/Billing/AgentConsumptionContext';
import {
  SessionConsumptionContextProvider,
  useSessionConsumptionContext,
} from '../contexts/Billing/SessionConsumptionContext';
import { MiuConsumptionContextProvider, useMiuConsumptionContext } from '../contexts/Billing/MiuConsumptionContext';
import { BillingPlanUsageContextProvider } from '../contexts/Billing/BillingPlanUsageContext';
import { accessPermission } from '../services/PermissionService';

export const billingRoutes = {
  dataConsumption: '/data-consumption',
  agentConsumption: '/agent-consumption',
  dauConsumption: '/dau-consumption',
  sessionConsumption: '/session-consumption',
  mauConsumption: '/mau-consumption',

  revenue: '/revenue',
  dailyActiveUsers: '/daily-active-users',
  oldSessionConsumption: '/old-session-consumption',
  oldAgentConsumption: '/old-agent-consumption',
};

export const billingSubRoutes = {
  agentConsumptionMonthly: billingRoutes.agentConsumption + '/monthly',
  dauConsumptionMonthly: billingRoutes.dauConsumption + '/monthly',
  dauConsumptionDaily: billingRoutes.dauConsumption + '/daily',
  sessionConsumptionMonthly: billingRoutes.sessionConsumption + '/monthly',
  sessionConsumptionDaily: billingRoutes.sessionConsumption + '/daily',
  mauConsumptionChatbots: billingRoutes.mauConsumption + '/chatbots',
  mauConsumptionDaily: billingRoutes.mauConsumption + '/daily',

  revenueChatbots: billingRoutes.revenue + '/chatbots',
  dailyActiveUsersMonthly: billingRoutes.dailyActiveUsers + '/monthly',
  dailyActiveUsersDaily: billingRoutes.dailyActiveUsers + '/daily',
  oldSessionConsumptionMonthly: billingRoutes.oldSessionConsumption + '/monthly',
  oldSessionConsumptionDaily: billingRoutes.oldSessionConsumption + '/daily',
  oldAgentConsumptionMonthly: billingRoutes.oldAgentConsumption + '/monthly',
};

export const billingRoutesTracks = [
  {
    path: billingRoutes.dataConsumption,
    segmentEvent: REPORT_OVERVIEW_CONSUMPTION_OPEN,
  },
  {
    path: billingRoutes.dauConsumption,
    segmentEvent: REPORT_DAU_CONSUMPTION_OPEN,
  },
  {
    path: billingRoutes.agentConsumption,
    segmentEvent: REPORT_AGENT_CONSUMPTION_OPEN,
  },
  {
    path: billingRoutes.sessionConsumption,
    segmentEvent: REPORT_SESSION_CONSUMPTION_OPEN,
  },
  {
    path: billingRoutes.mauConsumption,
    segmentEvent: REPORT_REVENUE_CONSUMPTION_OPEN,
  },
];

export const billingSubRoutesTracks = [
  {
    path: billingSubRoutes.agentConsumptionMonthly,
    segmentEvent: REPORT_AGENT_CONSUMPTION_LIST_OPEN,
  },
  {
    path: billingSubRoutes.dauConsumptionMonthly,
    segmentEvent: REPORT_DAU_CONSUMPTION_PER_CHATBOT_OPEN,
  },
  {
    path: billingSubRoutes.dauConsumptionDaily,
    segmentEvent: REPORT_DAU_CONSUMPTION_PER_DATE_OPEN,
  },
  {
    path: billingSubRoutes.sessionConsumptionMonthly,
    segmentEvent: REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_OPEN,
  },
  {
    path: billingSubRoutes.sessionConsumptionDaily,
    segmentEvent: REPORT_SESSION_CONSUMPTION_PER_DATE_OPEN,
  },
  {
    path: billingSubRoutes.mauConsumptionChatbots,
    segmentEvent: REPORT_REVENUE_CONSUMPTION_PER_CHATBOT_OPEN,
  },
];

export const billingRoutesMetrics = [
  {
    path: billingRoutes.dataConsumption,
    metrics: Object.values(BillingMetrics).map<BillingMetrics>((metric) => metric as BillingMetrics),
    featureToggle: DATA_CONSUMPTION,
  },
  {
    path: billingRoutes.dauConsumption,
    metrics: [BillingMetrics.dailyActiveUser],
    featureToggle: DAILY_ACTIVE_USERS_CONSUMPTION,
  },
  {
    path: billingRoutes.agentConsumption,
    metrics: [BillingMetrics.agent],
    featureToggle: AGENT_CONSUMPTION,
  },
  {
    path: billingRoutes.sessionConsumption,
    metrics: [BillingMetrics.session],
    featureToggle: SESSION_CONSUMPTION,
  },
  {
    path: billingRoutes.mauConsumption,
    metrics: [BillingMetrics.monthlyActiveUser, BillingMetrics.monthlyEngagedUser, BillingMetrics.exchangedMessage],
    featureToggle: TENANT_CONSUMPTION,
  },
];

export const asideMenuOptions = [
  {
    group: 'asideMenu',
    option: 'dataConsumption',
    icon: 'monitoring',
    featureToggle: DATA_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: REPORT_OVERVIEW_CONSUMPTION_OPEN,
    metrics: Object.values(BillingMetrics).map<BillingMetrics>((metric) => metric as BillingMetrics),
    path: billingRoutes.dataConsumption,
  },
  {
    group: 'asideMenu',
    option: 'dauConsumption',
    icon: 'user-active',
    featureToggle: DAILY_ACTIVE_USERS_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: REPORT_DAU_CONSUMPTION_OPEN,
    metrics: [BillingMetrics.dailyActiveUser],
    path: billingRoutes.dauConsumption,
  },
  {
    group: 'asideMenu',
    option: 'agentConsumption',
    icon: 'agent',
    featureToggle: AGENT_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: REPORT_AGENT_CONSUMPTION_OPEN,
    metrics: [BillingMetrics.agent],
    path: billingRoutes.agentConsumption,
  },
  {
    group: 'asideMenu',
    option: 'sessionConsumption',
    icon: 'message-talk',
    featureToggle: SESSION_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: REPORT_SESSION_CONSUMPTION_OPEN,
    metrics: [BillingMetrics.session],
    path: billingRoutes.sessionConsumption,
  },
  {
    group: 'asideMenu',
    option: 'mauConsumption',
    icon: 'user-engaged',
    featureToggle: TENANT_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: REPORT_REVENUE_CONSUMPTION_OPEN,
    metrics: [BillingMetrics.monthlyActiveUser, BillingMetrics.monthlyEngagedUser, BillingMetrics.exchangedMessage],
    path: billingRoutes.mauConsumption,
  },
];

const BillingRoutes = () => {
  const { tenant } = useTenant();
  const { common } = useCommon();
  return (
    <BillingConsumptionContextProvider tenant={tenant} common={common}>
      <BillingMetricsContextProvider tenant={tenant}>
        <BillingMetricConfiguration />
      </BillingMetricsContextProvider>
    </BillingConsumptionContextProvider>
  );
};

const BillingMetricConfiguration = () => {
  const { tenant } = useTenant();
  return (
    <DauConsumptionContextProvider tenant={tenant}>
      <AgentConsumptionContextProvider tenant={tenant}>
        <SessionConsumptionContextProvider tenant={tenant}>
          <MiuConsumptionContextProvider tenant={tenant}>
            <BillingPlanUsageConfiguration />
          </MiuConsumptionContextProvider>
        </SessionConsumptionContextProvider>
      </AgentConsumptionContextProvider>
    </DauConsumptionContextProvider>
  );
};

const BillingPlanUsageConfiguration = () => {
  const { dauStatus } = useDauConsumptionContext();
  const { agentStatus } = useAgentConsumptionContext();
  const { sessionStatus } = useSessionConsumptionContext();
  const { miuStatus } = useMiuConsumptionContext();

  return (
    <BillingPlanUsageContextProvider planStatusArray={[dauStatus, agentStatus, sessionStatus, miuStatus]}>
      <Routes />
    </BillingPlanUsageContextProvider>
  );
};

const Routes = () => {
  const { hasEnabledMetric, isMetricAllowed } = useBillingMetricsContext();
  return (
    <>
      <PrivateRoute
        path={billingRoutes.revenue}
        component={Revenue}
        hasPermission={() =>
          hasEnabledMetric([
            BillingMetrics.monthlyActiveUser,
            BillingMetrics.monthlyEngagedUser,
            BillingMetrics.exchangedMessage,
          ])
        }
      />
      <PrivateRoute
        path={billingRoutes.dailyActiveUsers}
        component={DailyActiveUsers}
        hasPermission={() => isMetricAllowed(BillingMetrics.dailyActiveUser)}
      />
      <PrivateRoute
        path={billingRoutes.oldSessionConsumption}
        component={OldSessionConsumption}
        hasPermission={() => isMetricAllowed(BillingMetrics.session)}
      />
      <PrivateRoute
        path={billingRoutes.oldAgentConsumption}
        component={OldAgentConsumption}
        hasPermission={() => isMetricAllowed(BillingMetrics.agent)}
      />
      <PrivateRoute
        path={billingRoutes.mauConsumption}
        component={MiuConsumption}
        hasPermission={() =>
          hasEnabledMetric([
            BillingMetrics.monthlyActiveUser,
            BillingMetrics.monthlyEngagedUser,
            BillingMetrics.exchangedMessage,
          ])
        }
      />
      <PrivateRoute
        path={billingRoutes.dauConsumption}
        component={DauConsumption}
        hasPermission={() => isMetricAllowed(BillingMetrics.dailyActiveUser)}
      />
      <PrivateRoute
        path={billingRoutes.sessionConsumption}
        component={SessionConsumption}
        hasPermission={() => isMetricAllowed(BillingMetrics.session)}
      />
      <PrivateRoute
        path={billingRoutes.agentConsumption}
        component={AgentConsumption}
        hasPermission={() => isMetricAllowed(BillingMetrics.agent)}
      />

      <PrivateRoute
        path={billingRoutes.dataConsumption}
        component={BillingConsumption}
        hasPermission={() =>
          hasEnabledMetric(Object.values(BillingMetrics).map<BillingMetrics>((metric) => metric as BillingMetrics))
        }
      />
    </>
  );
};

export { BillingRoutes };
