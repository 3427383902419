interface Mask {
  [country: string]: { code: string; masks: string[] };
}

const masks: Mask = {
  BR: {
    code: '55',
    masks: ['+__ __ _____ ____', '+__ __ ____ _____'],
  },
};

export const formatPhoneNumber = (value?: string, countryCode = 'BR'): string => {
  if (!value) return '-';

  const countryMask = masks[countryCode.toLocaleUpperCase()];

  if (!countryMask) return value;

  switch (countryCode) {
    case 'BR':
      if (!value.startsWith('+')) {
        value = `+${countryMask.code}${value}`;
      }
      const phone = value.replace(/\D/g, '');

      const [mask, oldMask] = countryMask.masks;

      const pattern = phone.length <= 12 ? oldMask : mask;
      let i = 0;

      return pattern.replace(/_/g, () => phone[i++] || '');
    case 'US':
      /// implement mask according to country
      return value;
    default:
      return value;
  }
};
