export class ConsolidatedSessionConsumption {
  allConversations: {
    marketing: number;
    utility: number;
    authentication: number;
    service: number;
    total: number;
    oldWhatsappPricing?: {
      sessionByCompany?: number;
      sessionByUser?: number;
    };
  };
  billedConversations: {
    marketing: number;
    utility: number;
    authentication: number;
    service: number;
    total: number;
    oldWhatsappPricing?: {
      sessionByCompany?: number;
      sessionByUser?: number;
    };
  };
  freeOfChargeConversations: {
    freeTier: number;
    freeEntryPoint: number;
    total: number;
  };

  constructor(consolidatedSessionConsumption: Partial<ConsolidatedSessionConsumption | null>) {
    this.billedConversations = {
      marketing: 0,
      utility: 0,
      authentication: 0,
      service: 0,
      total: 0,
      oldWhatsappPricing: {
        sessionByCompany: 0,
        sessionByUser: 0,
      },
    };

    this.freeOfChargeConversations = {
      freeTier: 0,
      freeEntryPoint: 0,
      total: 0,
    };

    this.allConversations = {
      ...this.billedConversations,
      ...this.freeOfChargeConversations,
    };

    Object.assign(this, consolidatedSessionConsumption);
  }
}
