import styled from 'styled-components';

export const PaperHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  width: 100%;
  & > bds-typo:first-of-type {
    margin-top: 1rem;
  }  
`;
export const PaperHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
