export const TENANT_CONSUMPTION = 'tenant-consumption';
export const DAILY_ACTIVE_USERS_CONSUMPTION = 'billing-daily-active-users-consumption';
export const DAILY_ACTIVE_USERS_PLAN_INFORMATIONS = 'billing-daily-active-users-plan-informations';
export const SESSION_CONSUMPTION_BY_BOT_INFORMATIONS = 'billing-session-consumption-by-bot';
export const SESSION_CONSUMPTION = 'billing-session-consumption';
export const SESSION_NEW_PRICING = 'billing-new-session-pricing';
export const AGENT_CONSUMPTION = 'billing-agent-consumption';
export const UNLIMITED_AGENT_CONSUMPTION = 'billing-unlimited-agent-consumption';
export const DATA_CONSUMPTION = 'billing-data-consumption';
export const AI_SETTINGS_COPILOT = 'tenant-ai-settings-copilot';
export const CALLS_SETTINGS = 'tenant-calls-settings';
export const TRACKING_LOG = 'billing-tracking-log';
export const ENABLE_MTLS = 'enable-tenant-mtls-certificates';
export const USE_SUBSCRIPTION_REFERENCE_DATE = 'billing-use-subscription-reference-date';
