import styled from 'styled-components';
import blipColors from 'blip-tokens/build/json/colors.json';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { Container } from 'components/Global/components';
import { FadeIn } from 'components/Global/animations';

export const DailyActiveUserContainer = styled(Container)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  gap: 32px;
  padding-bottom: 80px;
  padding-top: 32px;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const DailyActiveUserPaper = styled(BdsPaper)`
  padding: 1.5rem;
  margin-top: 2rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const DailyActiveUserRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  & > bds-typo {
    &::part(bds-typo__text) {
      white-space: nowrap;
      color: ${blipColors['color-neutral-dark-desk']};
    }
  }
  & bds-select {
    width: 250px;
  }
`;

export const DailyActiveUserSumUsagePaperRow = styled(DailyActiveUserRow)`
  display: inline-block;
`;

export const DailyActiveUserUsageStatementContent = styled.div`
  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  & > bds-paper > table > thead {
    background-color: ${blipColors['color-new-color']};
    border-top: ${blipColors['color-new-color']};
    border-bottom: 0.5px solid #d2dfe6;
    & > tr > th {
      background-color: ${blipColors['color-new-color']};
    }
  }

  & > bds-paper > table > tbody > tr > td:first-of-type > span:first-of-type {
    font-weight: bold;
  }
`;

export const DailyActiveUserUsageByBotContent = styled(DailyActiveUserUsageStatementContent)`
  & > bds-paper > table > tbody > tr > td:first-of-type > span:first-of-type {
    font-weight: bold;
  }
`;

export const DailyActiveUserUsageByTemplateContent = styled(DailyActiveUserUsageStatementContent)`
  & > bds-paper > table > tbody > tr > td:nth-child(2) > span:first-of-type {
    font-weight: bold;
  }
`;

export const MonthlyDailyActiveUserUsageTabs = styled.div`
  display: inherit;
`;

// Start Compiled Component Styles
export const CompiledDailyActiveUsersContainer = styled(Container)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  gap: 32px;
  padding-bottom: 80px;
  padding-top: 32px;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const CompiledDailyActiveUsersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 24px;

  & > bds-typo {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
    }
  }
`;

export const CompiledDailyActiveUsersLessWidthRow = styled(CompiledDailyActiveUsersRow)`
  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 65%;
  }

  margin-bottom: -0.5rem;
  margin-top: 20px;
`;

export const CompiledDailyActiveUsersPlainInfotmationRow = styled.div`
  margin: 1.5rem 0;
  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
    }
  }
`;

export const CompiledDailyActiveUsersTotalConsumedPaperContent = styled.div`
  background: ${blipColors['color-neutral-light-whisper']};
  padding: 1rem;
  border-radius: 8px;
  height: 104px;
  width: 225px;
  
  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
      text-align: left;
    }
  }

  & > bds-typo:last-of-type {
    display:block;
    margin-top:8px;
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
      text-align: left;
    }
  }
`;

export const CompiledDailyActiveUsersOverspentProgressContent = styled(
  CompiledDailyActiveUsersTotalConsumedPaperContent,
)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 104px;

  & > div > div > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      color: ${blipColors['color-primary-main']};
    }
  }
`;

export const CompiledDailyActiveUsersOverspentMessagesPaperContent = styled(
  CompiledDailyActiveUsersTotalConsumedPaperContent,
)`
  & > bds-typo:last-of-type {
    &::part(bds-typo__text) {
      color: ${blipColors['color-primary-oranges-carrot']};
      text-align: center;
    }
  }
`;

export const CompiledDailyActiveUsersPaper = styled(BdsPaper)`
  padding: 1.5rem;
  margin-top: 2rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;

  & > div {
    margin-left: 0.5rem;
  }
`;

export const CompiledDailyActiveUsersWithLineRow = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  flex-grow: 0;
  padding-bottom: 16px;
  border-bottom: 1px solid ${blipColors['color-neutral-medium-wave']};
  margin-bottom: 24px;

  :not(:first-child) {
    margin-top: 48px;
  }

  & > bds-typo {
    margin-right: 8px;
    color: ${blipColors['color-neutral-dark-city']};
  }

  & > bds-typo:last-of-type {
    &::part(bds-typo__text) {
      margin-bottom: 1rem;
    }
  }

  & > bds-tooltip:hover {
    color: ${blipColors['color-neutral-dark-rooftop']};
  }
  & > bds-tooltip:active {
    transition: all 0.1s ease-in;
    bds-icon {
      transform: scale(1.1);
    }
  }

  .tooltip__tip {
    display: inline-flex;
    text-align: center;
  }
`;

export const DailyActiveUserConsumptionConsolidatedHeaderRow = styled.div`

.subtitle, .title{
  display: block;
  margin: 4px 0;
}

.title{
  color: ${blipColors['color-neutral-dark-city']};
}

.partialUsage{
  height: 30px;
  border-radius: 8px;
  background: ${blipColors['color-extend-browns-sand']};
  padding: 6px 8px;
  color: ${blipColors['color-primary-oranges-carrot']};
  margin-left: 20px;    
  & bds-typo:first-of-type {
    color: ${blipColors['color-primary-oranges-carrot']};
  }    
}
`;
export const DailyActiveUserConsumptionConsolidatedHeaderPartial = styled.div`
  display: flex;
  width: 100%;
`;

export const CompiledDailyOverspentAlertRow = styled(CompiledDailyActiveUsersRow)`
  margin-top: 20px;
  & bds-typo.highlighted {
    &::part(bds-typo__text) {
      color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
    }
  }
`;

export const DailyActiveUsersProgressBarAligned = styled.div`
  width: 75%;
  color: ${blipColors['color-neutral-dark-desk']};

  & > bds-typo:first-of-type {
    position: relative;
    top: -5px;
  }
  & bds-tooltip {
    display: inline-block;
    margin-left: 10px;
  }

  .bp-progressbar-container{
    background-color: ${blipColors['color-neutral-medium-wave']};
  }
`;
export const DailyActiveUsersProgressTotalAligned = styled.div`
  width: 25%;
  margin-top: 35px;
  text-align: center;

  & > bds-typo {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
    }
  }
  & > bds-typo.highlighted {
    &::part(bds-typo__text) {
      color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
    }
  }

  & > bds-typo.total-dau{
    margin: 0 8px;
  }
`;

// End Compiled Component Styles
