import { TenantMember, TenantSubscriptionPlan } from 'interfaces';
import FeatureToggleService from 'services/FeatureToggleService';
import { BillingMetrics, UNLIMITED_PLAN_METRIC } from 'shared/BillingMetrics';
import { UNLIMITED_AGENT_CONSUMPTION } from 'shared/FeatureToggleKeys';

// Mocked values that will probably come from backend
const roles = {
  admin: {
    'tenant-summary': ['read', 'write'],
    'tenant-members': ['read', 'write'],
    'tenant-workspace': ['read', 'write'],
    'tenant-dashboard': ['read', 'write'],
    'tenant-billing': ['read'],
  },
  member: {
    'tenant-summary': ['read'],
    'tenant-members': [],
    'tenant-workspace': ['read', 'write'],
    'tenant-dashboard': [],
    'tenant-billing': [],
  },
  guest: {
    'tenant-summary': ['read'],
    'tenant-members': [],
    'tenant-workspace': ['read'],
    'tenant-dashboard': [],
    'tenant-billing': [],
  },
};
// END Mocked values that will probably come from backend

export const accessPermission = {
  summary: 'tenant-summary',
  members: 'tenant-members',
  workspace: 'tenant-workspace',
  dashboard: 'tenant-dashboard',
  billing: 'tenant-billing',
};

export const canUserRead = (permissionId, role) => {
  if (!roles[role][permissionId]) {
    return false;
  }
  return roles[role][permissionId].indexOf('read') !== -1;
};

export const canUserWrite = (permissionId, role) => {
  if (!roles[role][permissionId]) {
    return false;
  }
  return roles[role][permissionId].indexOf('write') !== -1;
};

export const hasPermissions = async (
  subscriptionMetrics: Array<TenantSubscriptionPlan>,
  loggedUser: TenantMember,
  accessPermission: string,
  metricIds: Array<BillingMetrics>,
  featureToggleId?: any,
): Promise<boolean> => {
  if (!subscriptionMetrics) {
    return false;
  }

  if (!loggedUser) {
    return false;
  }

  const isFeatureToggleEnabled = featureToggleId
    ? await FeatureToggleService.isFeatureToggleEnabled(featureToggleId)
    : false;

  const enablePage = await hasEnabledMetric(subscriptionMetrics, metricIds);

  const isAdminUser = canUserRead(accessPermission, loggedUser.roleId);

  return isAdminUser && (isFeatureToggleEnabled || enablePage);
};

export const hasEnabledMetric = async (
  subscriptionMetrics: Array<TenantSubscriptionPlan>,
  metricIds: Array<BillingMetrics>,
) => {
  const featureToggleId = await FeatureToggleService.isFeatureToggleEnabled(UNLIMITED_AGENT_CONSUMPTION);
  return subscriptionMetrics.some((metric) =>
    metricIds.some((metricId) =>
      metricId === BillingMetrics.agent && metric.quantity === UNLIMITED_PLAN_METRIC
        ? metric.metricId === metricId && featureToggleId
        : metric.metricId === metricId,
    ),
  );
};
