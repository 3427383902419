export const localization = {
  pt: {
    name: 'Nome',
    email: 'Email',
    role: 'Papel',
    invite: 'Convidar',
    edit: 'Editar',
    members: 'membro(s)',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    chooseRole: 'Escolha o papel',
    delete: 'Excluir',
    deleteMessage: 'Tem certeza que deseja excluir esse(s) membro(s)?',
    deleteMembersSuccess: 'Membro(s) excluído(s)',
    deleteMembersError: 'Falha ao excluir membro(s)',
    guest: 'Pode visualizar',
    member: 'Pode editar',
    admin: 'Admin',
    setRoleSuccess: 'Membro(s) atualizado(s) com sucesso',
    setRoleError: 'Falha ao atualizar membro(s)',
    loadMembersError: 'Falha ao carregar membros',
    adminDescription: 'Edita todos os dados do contrato, gerencia membros, cria e edita chatbots.',
    memberDescription: 'Cria e edita chatbots, mas não gerencia os membros do contrato.',
    guestDescription: 'Apenas visualiza informações do contrato.',
    pendingInvitation: 'Pendente',
  },
  en: {
    name: 'Name',
    email: 'Email',
    role: 'Role',
    invite: 'Invite',
    edit: 'Edit',
    members: 'member(s)',
    cancel: 'Cancel',
    apply: 'Apply',
    chooseRole: 'Choose the role',
    delete: 'Delete',
    deleteMessage: 'Are you sure you want to delete these members?',
    deleteMembersSuccess: 'Member(s) deleted(s)',
    deleteMembersError: 'Failed to delete member(s)',
    guest: 'Viewer',
    member: 'Editor',
    admin: 'Admin',
    setRoleSuccess: 'Member(s) successfully updated',
    setRoleError: 'Failed to update member(s)',
    loadMembersError: 'Failed to load members',
    adminDescription: 'Manage roles and contract settings; edit specifications; creates and edits chatbots.',
    memberDescription: 'Create and edit chatbots; cannot manage roles and contract settings.',
    guestDescription: 'Only view info of contract.',
    pendingInvitation: 'Pending',
  },
  es: {
    name: 'Nombre',
    email: 'Correo Electrónico',
    role: 'Papel',
    invite: 'Invitar',
    edit: 'Editar',
    members: 'miembro(s)',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    chooseRole: 'Elija el papel',
    delete: 'Eliminar',
    deleteMessage: '¿Está seguro de que desea eliminar estos miembros?',
    deleteMembersSuccess: 'Miembro(s) excluido(s)',
    deleteMembersError: 'Error al eliminar miembro(s)',
    guest: 'Puede ver',
    member: 'Puede editar',
    admin: 'Admin',
    setRoleSuccess: 'Miembro(s) actualizado(s) correctamente',
    setRoleError: 'Error al actualizar miembro(s)',
    loadMembersError: 'No se pudieron cargar los miembros',
    adminDescription: 'Edite todos los datos del contrato, administre miembros, cree y edite chatbots.',
    memberDescription: 'Crea y edita chatbots, pero no gestiona miembros contratados.',
    guestDescription: 'Ver solo la información del contrato.',
    pendingInvitation: 'Pendiente',
  },
};
