import { BillingMetricConsumption } from '../metrics/BillingMetricConsumption';

export class MonthlyMiuConsumption {
  botName!: string;
  botIdentity!: string;
  metricConsumption!: BillingMetricConsumption[];

  constructor(monthlyMiuConsumption: Partial<MonthlyMiuConsumption>) {
    Object.assign(this, monthlyMiuConsumption);
  }
}
