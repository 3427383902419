import { BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext, useMemo } from 'react';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import {
  CompiledDailyActiveUsersWithLineRow,
  DailyActiveUserConsumptionConsolidatedHeaderPartial,
  DailyActiveUserConsumptionConsolidatedHeaderRow,
} from '../styles';
import { DateDropdown } from '../../../components/DateDropdown';
import { DAILY_ACTIVE_USERS_PERIOD_SELECT } from '../../../shared/SegmentEvents';
import { createTrack } from '../../../api/SegmentApi';
import { getFirstDayOfSpecifiedMonth, getLastDayOfSpecifiedMonth, toUTCDateString } from '../../../utils/date';

const CompiledDailyActiveUserTitleComponent: React.FC = () => {
  const {
    currentMonth,
    content,
    tenant,
    loggedUser,
    setDateToSearch,
    dateToSearch,
    periodOptions,
    compiledDailyActiveUserDataState,
  } = useContext(DailyActiveUsersContext);

  const dateContent = useMemo(() => {
    return content.formatString(content.compiledDailyActiveUserInformation.about,
      toUTCDateString(getFirstDayOfSpecifiedMonth(dateToSearch), content.locale, true),
      toUTCDateString(
        currentMonth ? new Date() : getLastDayOfSpecifiedMonth(dateToSearch),
        content.locale,
      ),
    );
  }, [compiledDailyActiveUserDataState]);

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, DAILY_ACTIVE_USERS_PERIOD_SELECT, {
      dauDate: dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      dauUser: loggedUser.email,
    });
  };

  return (
    <DailyActiveUserConsumptionConsolidatedHeaderRow>
      <CompiledDailyActiveUsersWithLineRow>
        <div>
          <DailyActiveUserConsumptionConsolidatedHeaderPartial>
            <BdsTypo className="title" tag="h3" variant="fs-24" bold="bold" margin={false}>
              {content.formatString(
                content.compiledDailyActiveUserInformation.title,
                content.compiledDailyActiveUserInformation.month[dateToSearch.getMonth()],
              )}
            </BdsTypo>
            {currentMonth && (
              <BdsPaper className="partialUsage" elevation="static">
                <BdsTypo variant="fs-12" bold="semi-bold">
                  {content.compiledDailyActiveUserInformation.partialUsage}
                </BdsTypo>
              </BdsPaper>
            )}
          </DailyActiveUserConsumptionConsolidatedHeaderPartial>
          <BdsTypo className="subtitle" variant="fs-14">
            {dateContent}
          </BdsTypo>
        </div>
        <DateDropdown
          value={dateToSearch.toISOString()}
          bdsChangeEvent={periodOptionChangeEvent}
          options={periodOptions}
          label={content.monthPlaceholder}
        ></DateDropdown>
      </CompiledDailyActiveUsersWithLineRow>
    </DailyActiveUserConsumptionConsolidatedHeaderRow>
  );
};

export default CompiledDailyActiveUserTitleComponent;
