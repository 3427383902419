import { BlipProgressBarComponent } from './BlipProgressBarComponent';
import PropTypes from 'prop-types';

const BlipProgressBarContainer = ({ completed, color }) => {
  return <BlipProgressBarComponent completed={completed} color={color}></BlipProgressBarComponent>;
};

BlipProgressBarContainer.propTypes = {
  completed: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export { BlipProgressBarContainer };
