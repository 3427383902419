import { BlipApi } from './BlipApi';
import { ChatbotConsumption } from '../models/revenue/ChatbotConsumption';
import { ContractRules } from '../models/revenue/ContractRules';
import { MRRContractRules } from 'models/revenue/MRRContractRules';
import { MRRFinancialRules } from 'models/revenue/MRRFinancialRules';
import { ReferenceValue } from 'models/revenue/ReferenceValue';
import { MRRRevenue } from 'models/revenue/MRRRevenue';
import { LocalizedStrings } from 'react-localization';
import { MonthlyConsumption } from 'models/revenue/MonthlyConsumption';
import { MRRBlipMetrics } from 'models/revenue/MRRBlipMetrics';

export default class RevenueApi {

  private readonly RESOURCE_NOT_FOUND_CODE = 67;

  constructor(
    private contentLocalizer: LocalizedStrings<any>
  ) { }

  public async getMonthlyConsumption(tenantId: string, month: string, miu: string): Promise<MonthlyConsumption|null> {
    try {
      const { response } = await BlipApi.sendCommand({
        to: 'postmaster@portal.blip.ai',
        method: 'get',
        uri: `/tenants/${tenantId}/consumption?month=${month}`,
      });

      return new MRRRevenue(response)
        .toMonthlyConsumption(miu);
    } catch (e) {
      const error = JSON.parse(<string>e);
      const { reason: { code } } = JSON.parse(error.message);
      if(code === this.RESOURCE_NOT_FOUND_CODE){
        return null;
      }
      throw e;
    }
  }

  public async getChatbotsConsumption(
    tenantId: string,
    take: number,
    month: string,
    miu: string
  ) : Promise<ChatbotConsumption[]> {
    const postMessage = await BlipApi.sendCommand({
      to: 'postmaster@portal.blip.ai',
      method: 'get',
      uri: `/tenants/${tenantId}/application-consumption?month=${month}&$take=${take}`,
    });
    const blipMetrics = postMessage.response.items.map(item=> new MRRBlipMetrics(item));
    return blipMetrics.map(blipMetric=> blipMetric.toChatbotConsumption(miu));
  }

  public async getContractRules(tenantId: string, month: string): Promise<ContractRules> {
    let contractRules = await this.getContractGeneralRules(tenantId);
    contractRules = await this.fillContractFinancialRules(contractRules, month);
    return contractRules;
  }

  private async getContractGeneralRules(tenantId: string): Promise<ContractRules> {
    const { response } = await BlipApi.sendCommand({
      to: 'postmaster@portal.blip.ai',
      method: 'get',
      uri: `/tenants/${tenantId}/contract-rules`,
    });

    return (new MRRContractRules(response))
      .toContractRules(tenantId);
  }

  private async fillContractFinancialRules(contractRules: ContractRules, month: string): Promise<ContractRules> {
    contractRules.referenceValues = await this.getContractReferenceValues(contractRules.tenantId, month);

    const baseReferenceValue = contractRules.referenceValues
      .find(referenceValue => referenceValue.stepRule === 1);
    contractRules.planBase = baseReferenceValue?.fixPrice ?? 0;

    return contractRules;
  }

  private async getContractReferenceValues(tenantId: string, month: string): Promise<ReferenceValue[]> {
    const { response : { items } } = await BlipApi.sendCommand({
      to: 'postmaster@portal.blip.ai',
      method: 'get',
      uri: `/tenants/${tenantId}/financial-rules?month=${month}`,
    });

    return items
      .map(item => new MRRFinancialRules(item))
      .map((financialRules: MRRFinancialRules) => financialRules.toReferenceValue(this.contentLocalizer));
  }
}
