import { FC, useState } from 'react';

import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { useTenant } from '../../contexts/TenantContext';
import { useCommon } from '../../contexts/CommonContext';
import { createTrack } from '../../api/SegmentApi';
import { showAlert, redirectToApplicationList, getTenantUrl, startLoading, stopLoading } from '../../api/CommonApi';
import { TenantApi, removeMember, getApplicationsWithUniqueAdmin } from '../../api/TenantApi';

import { BdsTypo } from 'blip-ds/dist/blip-ds-react';

import { LeaveContractLink } from './styles';

import { localization } from './localization';

import { LEAVE_CONTRACT_SUCCESS, LEAVE_CONTRACT_CLICK } from '../../shared/SegmentEvents';

const LeaveContract: FC = () => {
  const content = useContentLocalizer(localization);
  const ONE_TENANT = 1;
  const LEAVE_CONTRACT_PARAM = '?leaveContract=true';
  const [userTenants, setUserTenants] = useState([]);
  const {
    common: { loggedUser },
  } = useCommon();
  const {
    tenant: { info: tenant },
  } = useTenant();

  const getUserTenants = async () => {
    const userTenants = await TenantApi.mine();
    setUserTenants(userTenants.response.items);
  };

  const redirectToFirstUserTenant = async () => {
    const tenantToRedirect = userTenants.find((item) => item.id !== tenant.id);

    if (!tenantToRedirect) {
      console.error('Error trying to redirect to tenant!');
    }

    const { url } = await getTenantUrl(tenantToRedirect.id);

    window.parent.location.href = url + LEAVE_CONTRACT_PARAM;
  };

  const redirectToLastUsedTenant = async () => {
    if (loggedUser.extras?.lastUsedTenants) {
      const lastUsedTenants = JSON.parse(loggedUser.extras.lastUsedTenants).reverse().slice(1);

      const lastUsedTenant = lastUsedTenants.find((item) => item?.length > 0);

      if (lastUsedTenant) {
        redirectToPortal(lastUsedTenant);
        return;
      }
    }

    redirectToFirstUserTenant();
  };

  const showLeaveContractAlert = async (content) => {
    return showAlert({
      ...content,
      variant: 'delete',
      icon: 'trash',
    });
  };

  const redirectToPortal = async (tenantId = undefined) => {
    const { url } = await getTenantUrl(tenantId);

    if (tenantId) {
      window.parent.location.href = url + `/application${LEAVE_CONTRACT_PARAM}`;
    } else {
      window.parent.location.href = url + `/application/tenant/create${LEAVE_CONTRACT_PARAM}`;
    }
  };

  const trackLeaveContractSuccess = () => {
    createTrack(tenant, LEAVE_CONTRACT_SUCCESS, {
      user: loggedUser.email,
    });
  };

  const leaveUniqueContract = async () => {
    const confirm = await showLeaveContractAlert(content.leaveUniqueContractConfirmAlert);

    if (confirm.response) {
      await removeMember(loggedUser);
      redirectToPortal();
      trackLeaveContractSuccess();
    }
  };

  const showPendingBotsWarning = async () => {
    const confirm = await showLeaveContractAlert(content.leaveContractChangePermissionsAlert);

    confirm.response && redirectToApplicationList();
  };

  const leaveContract = async () => {
    const confirm = await showLeaveContractAlert(content.leaveContractConfirmAlert);

    if (confirm.response) {
      await removeMember(loggedUser);
      redirectToLastUsedTenant();
      trackLeaveContractSuccess();
    }
  };

  const handleLeaveContract = async () => {
    try {
      startLoading();
      const {
        response: { items: pendingBots },
      } = await getApplicationsWithUniqueAdmin(tenant.id, loggedUser.identity);

      createTrack(tenant, LEAVE_CONTRACT_CLICK, {
        user: loggedUser.email,
      });

      getUserTenants();
      stopLoading();
      if (pendingBots?.length) {
        await showPendingBotsWarning();
      } else if (userTenants.length === ONE_TENANT) {
        await leaveUniqueContract();
      } else {
        await leaveContract();
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  return (
    <LeaveContractLink>
      {loggedUser.identity !== tenant.ownerIdentity && (
        <BdsTypo tag="span" bold="bold" variant="fs-12" onClick={handleLeaveContract}>
          {content.leaveContract}
        </BdsTypo>
      )}
    </LeaveContractLink>
  );
};

export default LeaveContract;
