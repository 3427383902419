export class MonthlyAgentConsumption {
  agentEmail!: string;
  agentName!: string;
  agentPhotoUri!: string;
  extraCharge!: boolean;
  totalTickets!: number;
  firstTicketDate!: Date;
  lastTicketDate!: Date;

  constructor(consolidatedAgentConsumption: Partial<MonthlyAgentConsumption>) {
    Object.assign(this, consolidatedAgentConsumption);

    this.firstTicketDate = this.firstTicketDate instanceof Date ? this.firstTicketDate : new Date(this.firstTicketDate);

    this.lastTicketDate = this.lastTicketDate instanceof Date ? this.lastTicketDate : new Date(this.lastTicketDate);
  }
}
