export class DailySessionConsumption {
  dateReference!: string;
  sessionByUser!: number;
  sessionByCompany!: number;
  sessionTotal!: number;

  constructor(dailySessionConsumption: Partial<DailySessionConsumption>) {
    this.dateReference = '';
    this.sessionByUser = 0;
    this.sessionByCompany = 0;
    this.sessionTotal = 0;
    Object.assign(this, dailySessionConsumption);
  }
}
