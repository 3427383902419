import React, { FC, useContext, useEffect } from 'react';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import AgentConsumptionContext from '../../../../contexts/AgentConsumptionContext';
import { REPORT_PERIOD_SELECT } from '../../../../shared/SegmentEvents';
import { capitalizeFirstLetter } from '../../../../utils/strings';
import { ConsolidatedHeaderComponent } from '../../components/Metrics/ConsolidatedHeader/ConsolidatedHeaderComponent';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import AgentConsumptionProgressContent from '../components/AgentConsumptionProgressContent';
import {
  AgentConsumptionPaper,
  AgentConsumptionConsolidatedRow,
  AgentConsumptionConsolidatedContent,
  AgentConsumptionProgressContentAligned,
  AgentConsumptionConsolidatedContentAligned,
  AgentConsumptionWarningContentAligned,
} from '../styles';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { useAgentConsumptionContext } from '../../../../contexts/Billing/AgentConsumptionContext';
import { BlipLoadingComponent } from '../../../../components/BlipLoading/BlipLoadingComponent';

const AgentConsumptionConsolidatedInformationsComponent: FC = () => {
  const {
    currentMonth,
    content,
    tenant,
    dateToSearch,
    periodOptions,
    trackingProps,
    setDateToSearch,
    loggedUser,
    getPlanStatus,
    isMetricAllowed,
  } = useContext(AgentConsumptionContext);

  const { compiledAgentConsumptionDataState, isAgentLoading, consolidatedAgentApiRequisition } =
    useAgentConsumptionContext();
  const consolidatedContainsDataOnSelectedMonth =
    compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth;
  const total = compiledAgentConsumptionDataState.consolidatedAgentConsumption.total;
  const quantity = compiledAgentConsumptionDataState.consolidatedAgentConsumption.metric.quantity;
  const exceededPlan = compiledAgentConsumptionDataState.consolidatedAgentConsumption.exceededPlan;

  useEffect(() => {
    consolidatedAgentApiRequisition(dateToSearch, isMetricAllowed);
  }, [dateToSearch]);

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  return (
    <>
      <PageHeaderComponent
        title={content.title}
        dateToSearch={dateToSearch}
        periodOptions={periodOptions}
        periodOptionChangeEvent={periodOptionChangeEvent}
      />
      <AgentConsumptionPaper>
        <div>
          <ConsolidatedHeaderComponent
            infoHeader={content.formatString(
              content.consolidated.plan,
              capitalizeFirstLetter(tenant.info.plan),
              quantity >= 0 && consolidatedContainsDataOnSelectedMonth ? quantity.toLocaleString(content.locale) : '--',
            )}
            dateToSearch={dateToSearch}
            currentMonth={currentMonth}
            middleInfoContent={content.consolidated.middleInfo}
          />
          {isAgentLoading ? (
            <BlipLoadingComponent />
          ) : (
            <>
              <AgentConsumptionConsolidatedRow>
                <AgentConsumptionProgressContentAligned>
                  <AgentConsumptionProgressContent />
                </AgentConsumptionProgressContentAligned>
                <AgentConsumptionConsolidatedContentAligned>
                  {exceededPlan > 0 && (
                    <AgentConsumptionConsolidatedContent currentMonth={currentMonth} className="bg-color-surface-2">
                      <BdsTypo margin={true} bold="bold" variant="fs-16">
                        {content.consolidated.exceededAgent}
                      </BdsTypo>
                      <BdsTypo
                        className={currentMonth ? 'color-content-default' : 'color-primary'}
                        data-exceededPlan={exceededPlan}
                        variant="fs-32"
                        bold="extra-bold"
                        margin={false}
                      >
                        {consolidatedContainsDataOnSelectedMonth ? exceededPlan.toLocaleString(content.locale) : '--'}
                      </BdsTypo>
                    </AgentConsumptionConsolidatedContent>
                  )}
                  <AgentConsumptionConsolidatedContent currentMonth={currentMonth} className="bg-color-surface-2">
                    <BdsTypo margin={true} bold="bold" variant="fs-16">
                      {content.consolidated.totalAgent}
                    </BdsTypo>
                    <BdsTypo
                      className={currentMonth ? 'color-content-default' : 'color-primary'}
                      data-total={total}
                      variant="fs-32"
                      bold="extra-bold"
                      margin={false}
                    >
                      {consolidatedContainsDataOnSelectedMonth ? total.toLocaleString(content.locale) : '--'}
                    </BdsTypo>
                  </AgentConsumptionConsolidatedContent>
                </AgentConsumptionConsolidatedContentAligned>
              </AgentConsumptionConsolidatedRow>
              {exceededPlan > 0 && (
                <AgentConsumptionWarningContentAligned currentMonth={currentMonth}>
                  <BdsTypo
                    bold="bold"
                    variant="fs-14"
                    className={currentMonth ? 'color-content-default' : 'color-primary'}
                  >
                    {content.formatString(
                      content.consolidated.warningExceededAgentTitle,
                      quantity >= 0 && consolidatedContainsDataOnSelectedMonth
                        ? quantity.toLocaleString(content.locale)
                        : '--',
                    )}
                  </BdsTypo>
                  <BdsTypo variant="fs-14">{content.consolidated.warningExceededAgentSubtitle}</BdsTypo>
                </AgentConsumptionWarningContentAligned>
              )}
            </>
          )}
        </div>
      </AgentConsumptionPaper>
    </>
  );
};

export default AgentConsumptionConsolidatedInformationsComponent;
