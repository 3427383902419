import React, { useMemo } from 'react';
import { EmptyChartIllustration, EmptyState } from '../styles';
import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';

interface ListEmptyProps {
  translate: Record<string, string>;
  status: boolean;
  hasFilters: boolean;
  onChange: () => void;
}

const ListEmpty: React.FC<ListEmptyProps> = ({ translate, status, hasFilters, onChange }) => {
  const {
    titleEnabled,
    subTitleEnabled,
    buttonDisabled,
    titleDisabled,
    subTitleDisabled,
    buttonEnabled,
    titleSearch,
    subTitleSearch,
  } = translate;
  const listEmptyText = useMemo(() => {
    if (hasFilters) {
      if (status) {
        return { title: titleEnabled, subTitle: subTitleEnabled, button: buttonDisabled };
      }
      return { title: titleDisabled, subTitle: subTitleDisabled, button: buttonEnabled };
    }
    return { title: titleSearch, subTitle: subTitleSearch };
  }, [hasFilters, status]);

  const filterIllustration = useMemo(
    () => <EmptyChartIllustration key="filter-illustration" name="robot" type="empty-states" />,
    [],
  );
  const emptyIllustration = useMemo(
    () => <EmptyChartIllustration key="empty-illustration" name="box" type="empty-states" />,
    [],
  );

  const illustration = useMemo(() => (hasFilters ? filterIllustration : emptyIllustration), [hasFilters]);

  return (
    <EmptyState>
      <BdsGrid direction="column" align-items="center" gap="1">
        {illustration}
        <BdsTypo className="flex mb1 mt2" variant="fs-20" bold="bold" margin={false}>
          {listEmptyText.title}
        </BdsTypo>
        <BdsTypo className="flex mb2 label align-center break-spaces" variant="fs-16" margin={false}>
          {listEmptyText.subTitle}
        </BdsTypo>
        <BdsButton className={!hasFilters ? 'hidden' : ''} onClick={() => onChange()}>
          {listEmptyText.button}
        </BdsButton>
      </BdsGrid>
    </EmptyState>
  );
};

export default ListEmpty;
