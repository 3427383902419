import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useCommon } from 'contexts/CommonContext';
import { unauthorizedAccess } from 'api/CommonApi';

export const PrivateRoute = ({ component: Component, hasPermission, ...rest }) => {
  const { common: { loggedUser } } = useCommon();

  return <Route {...rest} render={(props) => {
    if (hasPermission(loggedUser)) {
      return <Component {...props} />;
    }

    unauthorizedAccess();
  }} />;
};

PrivateRoute.propTypes = {
  hasPermission: PropTypes.func,
  component: PropTypes.any,
};
