import { ContractRules } from './ContractRules';

export class MRRContractRules {
  public idContract!: number;
  public metricBaseMIU!: string;
  public mainRuleName!: string;
  public contractType!: string;
  public validityContractRuleStart!: Date;

  constructor(contractRules: Partial<MRRContractRules>) 
  { 
    Object.assign(this, contractRules);
  }

  public toContractRules(tenantId: string): ContractRules {
    return new ContractRules(
      tenantId,
      this.idContract,
      `${this.metricBaseMIU.toUpperCase()}s`,
      this.mainRuleName,
      0,
      this.validityContractRuleStart,
      []
    );
  }
}