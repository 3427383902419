import React from 'react';
import { BdsTypo, BdsChip } from 'blip-ds/dist/blip-ds-react';
import { BlipDropdownButton } from '../../BlipDropdownButton';
import { Divider } from '../styles';
import { localization } from '../localization';
import { useContentLocalizer } from 'hooks/useContentLocalizer';

export const getBots = (item) => {
  const content = useContentLocalizer(localization);
  const { applications } = item;
  return (
    <div className="flex items-center">
      <div className="w-60 ma1  truncate">
        <BdsTypo variant="fs-12" bold="bold" tag="span">
          {applications && applications.join(', ')}
        </BdsTypo>
      </div>
      {applications.length > 1 && (
        <BlipDropdownButton
          key="bots"
          buttonTestId="bots-button"
          buttonClass="bp-btn--text-only bp-fs-4"
          dropdownClass="nowrap justify-center "
          arrowSide="center-right"
          label={
            <BdsChip className="truncate pa2 items-center" variant="primary">
              <BdsTypo bold="bold" variant="fs-12">
                ver {applications.length}
              </BdsTypo>
            </BdsChip>
          }
          cancelLabel={content.cancel}
        >
          <>
            <div className="flex justify-center item-center">
              <BdsTypo bold="bold">Bots</BdsTypo>
            </div>
            <Divider />
            {applications.map((bot, index) => (
              <BdsTypo className="mt-2" bold="bold" variant="fs-12" tag="p" key={bot}>
                {bot} 
                { index !== applications.length -1 && ','} 
              </BdsTypo>
            ))}
          </>
        </BlipDropdownButton>
      )}
    </div>
  );
};
