import styled from 'styled-components';
import { BdsGrid, BdsIllustration, BdsPaper, BdsTable } from 'blip-ds/dist/blip-ds-react';
import colors from '_colors.module.scss';

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  .label {
    color: ${colors.ContentGhost};
  }
  .round-avatar {
    border-radius: 50%;
    background: ${colors.System};
    padding: 8px;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
`;

export const Card = styled(BdsPaper)`
  background: ${({ selected, hasPermission }) => {
    if (hasPermission) {
      return '#f6f6f6';
    }
    return selected ? colors.Surface3 : colors.Surface1;
  }};
  width: 100%;
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  .icon {
    font-size: 4rem;
    color: ${colors.ContentGhost};
  }
  .message {
    color: ${colors.ContentGhost};
    margin-top: 1rem;
  }
`;

export const EmptyChartIllustration = styled(BdsIllustration)`
  width: 125px;
`;

export const Table = styled(BdsTable)`
  .bds-table {
    table-layout: fixed;
    border-radius: 0.5rem;
    overflow: hidden;

    bds-table-header {
      background-color: ${colors.Surface1};
      border-bottom: 1px solid ${colors.Surface2};

      bds-table-row {
        bds-table-th {
          vertical-align: middle;

          &:first-of-type {
            padding-left: 0 !important;
          }

          &.checkbox-th {
            width: 56px;
          }

          &.action-th {
            width: 140px;
          }
        }
      }
    }

    bds-table-body {
      --table-body-hover-color: transparent;

      background-color: ${colors.Surface1};

      bds-table-row {
        &:hover {
          --table-body-hover-color: ${colors.ContentDefault};
        }

        bds-table-cell {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: var(--table-body-hover-color);
            opacity: 0.08;
          }

          bds-typo {
            z-index: 1;
          }
        }

        &.selected {
          background-color: ${colors.Surface3};
        }
      }
    }

    bds-table-row {
      border-bottom: unset;

      &.sc-bds-table-row-h {
        height: auto;

        bds-table-cell {
          text-align: center;
          vertical-align: middle;

          &.sc-bds-table-cell-h {
            padding: 1rem 0.5rem;

            &:first-child {
              padding: 0 0.25rem;

              & + bds-table-cell {
                padding-left: 0;
              }

              .sc-bds-table-cell {
                justify-content: center;
              }
            }
          }

          .cell.sc-bds-table-cell {
            min-height: unset;
            margin: 0;
          }
        }

        bds-table-th {
          &.sc-bds-table-th-h {
            padding: 1rem 0.5rem;

            &:first-child {
              padding-left: 1rem;
            }

            &:last-child {
              padding-right: 1rem;
            }

            .th_cell.sc-bds-table-th {
              height: auto;
            }
          }
        }
      }
    }
  }
`;

export const LoadingStateWrapper = styled(BdsGrid)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.Surface2};
    opacity: 0.5;
  }
`;
