export const localization = {
  pt: {
    header: {
      titleRedirect: 'Blip Calls: Permissões de bots',
      title: 'Blip Calls',
      subTitle: 'Gerencie os bots terão permissão para usar o Blip Calls.',
    },
    summaryBots: {
      enabled: 'Bots habilitados',
      disabled: 'Bots desabilitados',
    },
    searchInput: {
      text: 'Pesquisar bot ou telefone',
    },
    detailsBots: {
      title: 'Nome do bot',
      enable: 'Habilitar',
      disable: 'Desabilitar',
      enabled: 'habilitado',
      disabled: 'desabilitado',
      hasNotPermission: 'Você não tem acesso a esse contato inteligente. Solicite acesso ao (à) administrador(a).',
    },
    enabledCalls: {
      success: 'Bot {0} com sucesso',
      iconVisibility: 'Visibilidade alterada com sucesso',
      enabled: 'habilitado',
      disabled: 'desabilitado',
      error: 'Falha durante a ação. Tente novamente.',
    },
    selectionBots: {
      selectAll: 'Selecionar todos',
      enable: 'Habilitar Selecionados',
      disable: 'Desabilitar Selecionados',
      selected: '{0} Selecionados:',
    },
    page: {
      result: 'Exibindo {0}-{1} de {2} resultados',
    },
    list: {
      title: 'Nome do Bot',
      phone: 'Telefone',
      iconVisibility: 'Exibir ícone de ligação no WhatsApp',
      action: 'Ação',
    },
    listEmpty: {
      titleEnabled: 'Nenhum bot habilitado',
      subTitleEnabled: 'Que tal checar os bots desabilitados para ativar o Blip\nCalls?',
      buttonDisabled: 'Mostrar bots desabilitados',
      titleDisabled: 'Nenhum bot desabilitado',
      subTitleDisabled: 'Que tal checar em quais bots o Blip Calls está\nhabilitado?',
      buttonEnabled: 'Mostrar bots habilitados',
      titleSearch: 'Nenhum resultado encontrado',
      subTitleSearch: 'Não encontramos nenhum resultado para sua\npesquisa. Que tal refazer a busca?',
    },
    filter: {
      filterByLabel: 'Filtrar por:',
      paginationLabel: 'Resultados',
      allBots: 'Todos os bots',
      onlyEnabled: 'Somente habilitados',
      onlyDisabled: 'Somente desabilitados',
    },
  },
  en: {
    header: {
      titleRedirect: 'Blip Calls: Bot permissions',
      title: 'Blip Calls',
      subTitle: 'Manage the bots that will have permission to use Blip Calls.',
    },
    summaryBots: {
      enabled: 'Enabled bots',
      disabled: 'Disabled bots',
    },
    searchInput: {
      text: 'Search bot or phone',
    },
    detailsBots: {
      title: 'Name of the bot',
      enable: 'Enable',
      disable: 'Disable',
      enabled: 'enabled',
      disabled: 'disabled',
      hasNotPermission: 'You do not have access to this smart contact. Request access to the administrator.',
    },
    enabledCalls: {
      success: 'Bot {0} successfully',
      iconVisibility: 'Access changed successfully',
      enabled: 'enabled',
      disabled: 'disabled',
      error: 'Failure while trying to perform this action. Please try again.',
    },
    selectionBots: {
      selectAll: 'Select all',
      enable: 'Enable Selected',
      disable: 'Disable Selected',
      selected: '{0} Selected:',
    },
    list: {
      title: 'Bot name',
      phone: 'Phone number',
      iconVisibility: 'Display phone call icon on WhatsApp',
      action: 'Action',
    },
    listEmpty: {
      titleEnabled: 'No bots enabled',
      subTitleEnabled: 'How about checking on which bots Blip Calls is\ndisabled to enable it?',
      buttonDisabled: 'Show disabled bots',
      titleDisabled: 'No bots disabled',
      subTitleDisabled: 'How about checking which bots Blip Calls is enabled\non?',
      buttonEnabled: 'Show enabled bots',
      titleSearch: 'No results found',
      subTitleSearch: "We couldn't find any results for your search. How\nabout redoing it?",
    },
    filter: {
      filterByLabel: 'Filter by:',
      paginationLabel: 'Results',
      allBots: 'All bots',
      onlyEnabled: 'Only enabled',
      onlyDisabled: 'Only disabled',
    },
  },
  es: {
    header: {
      titleRedirect: 'Blip Calls: Permisos de bots',
      title: 'Blip Calls',
      subTitle: 'Administre los bots que tendrán permiso para usar Blip Calls.',
    },
    summaryBots: {
      enabled: 'Bots habilitados',
      disabled: 'Bots deshabilitados',
    },
    searchInput: {
      text: 'Buscar bot o teléfono',
    },
    detailsBots: {
      title: 'Nombre del bot',
      enable: 'Habilitar',
      disable: 'Deshabilitar',
      enabled: 'habilitado',
      disabled: 'deshabilitado',
      hasNotPermission: 'No tienes acceso a este contacto inteligente. Solicita acceso al administrador.',
    },
    enabledCalls: {
      success: 'Bot {0} con éxito',
      iconVisibility: 'Visibilidad modificada con éxito',
      enabled: 'activado',
      disabled: 'desactivado',
      error: 'Error durante la acción. Inténtalo de nuevo.',
    },
    selectionBots: {
      selectAll: 'Seleccionar todo',
      enable: 'Habilitar seleccionados',
      disable: 'Deshabilitar seleccionados',
      selected: '{0} Seleccionados:',
    },
    list: {
      title: 'Nombre del bot',
      phone: 'Teléfono',
      iconVisibility: 'Mostrar el icono de llamada en WhatsApp',
      action: 'Acción',
    },
    listEmpty: {
      titleEnabled: 'Ningún bot activado',
      subTitleEnabled: '¿Qué tal comprobar en qué bots está desactivado Blip\nCalls para activarlo?',
      buttonDisabled: 'Mostrar bots desactivados',
      titleDisabled: 'Ningún bot desactivado',
      subTitleDisabled: '¿Qué tal comprobar en qué bots está activado Blip\nCalls?',
      buttonEnabled: 'Mostrar bots activados',
      titleSearch: 'Ningún resultado encontrado',
      subTitleSearch: 'No encontramos ningún resultado para tu búsqueda.\n¿Qué tal rehacerla?',
    },
    filter: {
      filterByLabel: 'Filtrar por:',
      paginationLabel: 'Resultados',
      allBots: 'Todos los bots',
      onlyEnabled: 'Solo habilitados',
      onlyDisabled: 'Solo deshabilitados',
    },
  },
};
