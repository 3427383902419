import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext, useMemo } from 'react';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { CompiledDailyOverspentAlertRow } from '../styles';

const CompiledDailyActiveUserOverspentWarningComponent: React.FC = () => {
  const { content, compiledDailyActiveUserDataState, currentMonth } = useContext(DailyActiveUsersContext);

  const overspentDauWarningDetails = useMemo(() => {
    if (compiledDailyActiveUserDataState.showPlanInformations) {
      return content.formatString(
        content.compiledDailyActiveUserInformation.overspentWarningFeeDetailsWithValues,
        compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.plan.overspentDailyActiveUserValue.toLocaleString(
          content.locale,
          {
            style: 'currency',
            currency: compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.plan.currencyCode,
          },
        ),
      );
    } else {
      return content.compiledDailyActiveUserInformation.overspentWarningFeeDetails;
    }
  }, [compiledDailyActiveUserDataState]);

  return (
    <>
      {compiledDailyActiveUserDataState.compiledContainsOverspent && (
        <CompiledDailyOverspentAlertRow currentMonth={currentMonth}>
          <div>
            <BdsTypo className="highlighted" tag="p" variant="fs-14" bold="bold">
              {content.compiledDailyActiveUserInformation.overspentWarningMessage}
              <br />
            </BdsTypo>
            <BdsTypo tag="p" variant="fs-14">
              {overspentDauWarningDetails}
            </BdsTypo>
          </div>
        </CompiledDailyOverspentAlertRow>
      )}
    </>
  );
};

export default CompiledDailyActiveUserOverspentWarningComponent;
