import React, { useContext } from 'react';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { ConsolidatedHeaderComponent } from '../../components/Metrics/ConsolidatedHeader/ConsolidatedHeaderComponent';
import { capitalizeFirstLetter } from '../../../../utils/strings';
import { useDauConsumptionContext } from '../../../../contexts/Billing/DauConsumptionContext';

const CompiledDailyActiveUserTitleComponent: React.FC = () => {
  const { dateToSearch, currentMonth, content, tenant } = useContext(DailyActiveUsersContext);
  const { compiledDailyActiveUserDataState } = useDauConsumptionContext();
  const quantity = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.metric.quantity;
  const compiledContainsDataOnSelectedMonth = compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth;

  return (
    <ConsolidatedHeaderComponent
      infoHeader={content.formatString(
        content.compiledDailyActiveUserInformation.plan,
        capitalizeFirstLetter(tenant.info.plan),
        quantity >= 0 && compiledContainsDataOnSelectedMonth ? quantity.toLocaleString(content.locale) : '--',
      )}
      dateToSearch={dateToSearch}
      currentMonth={currentMonth}
      middleInfoContent={content.compiledDailyActiveUserInformation.middleInfo}
    />
  );
};

export default CompiledDailyActiveUserTitleComponent;
