import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { localization } from './localization';
import { TenantMembersComponent } from './TenantMembersComponent';
import { startLoading, stopLoading, showToast, showTenantModal } from '../../api/CommonApi';
import { useTenant } from '../../contexts/TenantContext';
import { removeMember, setMemberRole, getAllTenantMembers } from '../../api/TenantApi';
import { createTrack } from 'api/SegmentApi';

import {
  ORGANIZATION_MEMBERS,
  ORGANIZATION_MEMBERS_SEARCH,
  ORGANIZATION_MEMBERS_SORT,
} from '../../shared/SegmentEvents';

const TenantMembersContainer = ({ members, canEdit = false }) => {
  const content = useContentLocalizer(localization);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedRole, setSelectedRole] = useState(undefined); // {value: value, label: Label}
  const { tenant, setTenantMembers } = useTenant();

  useEffect(() => {
    setFilteredMembers(members);
    setSelectedMembers([]);
  }, [members]);

  const filterMembers = (key) => {
    const tempFilteredMembers = members.filter(
      (member) => member.fullName.toLowerCase().includes(key.toLowerCase()) || member.email.includes(key.toLowerCase()),
    );
    setFilteredMembers(tempFilteredMembers);
    createTrack(tenant.info, ORGANIZATION_MEMBERS_SEARCH, {
      status: 'success',
      qtdMembers: tempFilteredMembers.length,
      text: key,
    });
  };

  const sortMembers = async (sort) => {
    const tempFilteredMembers = filteredMembers.sort((a, b) => {
      if (a[sort.property] < b[sort.property]) {
        return sort.order === 'asc' ? -1 : 1;
      }
      if (a[sort.property] > b[sort.property]) {
        return sort.order === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setFilteredMembers([...tempFilteredMembers]);
    createTrack(tenant.info, ORGANIZATION_MEMBERS_SORT, {
      status: 'success',
      qtdMembers: tempFilteredMembers.length,
      sortType: sort.order,
      sortProperty: sort.property,
    });
  };

  const trackOrganizationMember = (status, type, role, keepTrack = true) =>
    createTrack(tenant.info, ORGANIZATION_MEMBERS, {
      status,
      qtdMembers: selectedMembers.length,
      type,
      role,
      keepTrack,
    });

  const setRole = async () => {
    try {
      await Promise.all(
        selectedMembers.map(async (member) => {
          await setMemberRole(member, selectedRole.value);
        }),
      );
      trackOrganizationMember('success', 'edit', selectedRole.value);
      showToast({
        type: 'success',
        message: content.setRoleSuccess,
      });
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.setRoleError,
      });
      trackOrganizationMember('failed', 'edit', selectedRole.value);
    } finally {
      const updatedMembers = await getAllTenantMembers(tenant.info.id);
      setTenantMembers(updatedMembers);
      setSelectedMembers([]);
      stopLoading();
    }
  };

  const deleteSelectedMembers = async () => {
    startLoading();
    try {
      await Promise.all(
        selectedMembers.map(async (member) => {
          await removeMember(member);
        }),
      );
      showToast({
        type: 'success',
        message: content.deleteMembersSuccess,
      });
      trackOrganizationMember('success', 'delete');
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.deleteMembersError,
      });
      trackOrganizationMember('failed', 'delete');
    } finally {
      const updatedMembers = await getAllTenantMembers(tenant.info.id);
      setTenantMembers(updatedMembers);
      setSelectedMembers([]);
      stopLoading();
    }
  };

  const inviteMembers = async () => {
    const modal = await showTenantModal('InviteModal');
    if (modal.response === true) {
      startLoading();
      try {
        const updatedMembers = await getAllTenantMembers(tenant.info.id);
        setTenantMembers(updatedMembers);
      } catch (e) {
        showToast({
          type: 'danger',
          message: content.loadMembersError,
        });
      } finally {
        stopLoading();
      }
    }
  };

  const tableModel = [
    { label: content.name, key: 'fullName' },
    { label: content.email, key: 'email' },
    { label: content.role, key: 'roleId' },
  ];

  return (
    <TenantMembersComponent
      members={filteredMembers.map((member) =>
        member.userStatus === 'PendingUser'
          ? { ...member, fullName: `${member.fullName} (${content.pendingInvitation})` }
          : member,
      )}
      selectedMembers={selectedMembers}
      content={content}
      tableModel={tableModel}
      filter={filterMembers}
      sort={sortMembers}
      selectedRole={selectedRole}
      setRole={setRole}
      setSelectedMembers={setSelectedMembers}
      setSelectedMembersRole={setRole}
      deleteSelectedMembers={deleteSelectedMembers}
      canEdit={canEdit}
      setSelectedRole={setSelectedRole}
      inviteMembers={inviteMembers}
    />
  );
};

TenantMembersContainer.propTypes = {
  members: PropTypes.arrayOf(Object).isRequired,
  canEdit: PropTypes.bool,
};

export { TenantMembersContainer };
