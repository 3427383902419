import React, { FC, useContext } from 'react';
import { BdsButton, BdsAlert, BdsAlertHeader, BdsAlertBody, BdsAlertActions } from 'blip-ds/dist/blip-ds-react';
import '../../styles.scss';
import MtlsContext from '../../../../contexts/MtlsContext';

const RemoveCertificate: FC = () => {
  const { removeCertificateAlert, closeRemoveCertificateAlert } = useContext(MtlsContext);

  const handleClick = () => {
    closeRemoveCertificateAlert();
    showToast();
  };

  const showToast = async () => {
    const newtoast = document.createElement('bds-toast');
    document.body.appendChild(newtoast);

    const icon = 'like';
    const variant = 'success';
    const toastTitle = '';
    const actionType = 'icon';
    const toastText = 'Certificado removido com sucesso';
    const buttonAction = 'close';

    await newtoast.create({
      variant,
      actionType,
      toastTitle,
      buttonAction,
      toastText,
      icon,
    });
  };

  return (
    <BdsAlert open={removeCertificateAlert}>
      <BdsAlertHeader variant="warning" icon="warning">
        Atenção!
      </BdsAlertHeader>
      <BdsAlertBody>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. At corporis eligendi cumque ratione nulla a quos
        error!
      </BdsAlertBody>
      <BdsAlertActions>
        <BdsButton variant="secondary" onClick={closeRemoveCertificateAlert}>
          Cancelar
        </BdsButton>
        <BdsButton variant="secondary" onClick={handleClick}>
          Desativar
        </BdsButton>
      </BdsAlertActions>
    </BdsAlert>
  );
};

export default RemoveCertificate;
