import React, { createContext, Reducer, useReducer, useState } from 'react';
import BillingSessionConsumptionServiceApi from '../../api/Billing/BillingSessionConsumptionApi';
import { SummarySessionConsumption } from '../../models/billing/sessionConsumption/SummarySessionConsumption';
import { getMonthTwoDigits } from '../../utils/date';
import { RequestStatus } from '../../shared/Requests';
import {
  BillingMetrics,
  getMetricStatus,
  MetricStatus,
  METRIC_NOT_REGISTERED,
  UNLIMITED_PLAN_METRIC,
} from '../../shared/BillingMetrics';

interface CompiledSessionConsumptionDataState {
  summarySessionConsumption: SummarySessionConsumption;
  consolidatedContainsDataOnSelectedMonth: boolean;
}

interface SessionConsumptionContextData {
  compiledSessionConsumptionDataState: CompiledSessionConsumptionDataState;
  consolidatedSessionApiRequisition: (
    dateToSearch: Date,
    isMetricAllowed: (value: BillingMetrics) => boolean,
  ) => Promise<void>;
  isSessionLoading: boolean;
  sessionStatus: MetricStatus;
}

interface props {
  tenant: any;
}

export const SessionConsumptionContext = createContext<SessionConsumptionContextData>(
  {} as SessionConsumptionContextData,
);

export const SessionConsumptionContextProvider: React.FC<props> = ({ children, tenant }) => {
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [sessionStatus, setSessionStatus] = useState(MetricStatus.error);
  const billingSessionConsumptionApi = new BillingSessionConsumptionServiceApi();
  const summarySessionConsumption = new SummarySessionConsumption(null);
  const [compiledSessionConsumptionDataState, setCompiledSessionConsumptionDataState] = useReducer<
    Reducer<CompiledSessionConsumptionDataState, Partial<CompiledSessionConsumptionDataState>>
  >(
    (compiledSessionConsumptionDataState, newCompiledSessionConsumptionDataState) => ({
      ...compiledSessionConsumptionDataState,
      ...newCompiledSessionConsumptionDataState,
    }),
    {
      summarySessionConsumption,
      consolidatedContainsDataOnSelectedMonth: false,
    },
  );

  const consolidatedSessionApiRequisition = async (
    dateToSearch: Date,
    isMetricAllowed: (value: BillingMetrics) => boolean,
  ): Promise<void> => {
    try {
      setIsSessionLoading(true);

      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;

      const consolidatedSession = isMetricAllowed(BillingMetrics.session)
        ? await billingSessionConsumptionApi.getSummarySessionConsumption(tenant.info.id, monthString)
        : summarySessionConsumption;

      setCompiledSessionConsumptionDataState({
        summarySessionConsumption: consolidatedSession,
        consolidatedContainsDataOnSelectedMonth: billingSessionConsumptionApi.getStatus() === RequestStatus.SUCCESS,
      });

      if (billingSessionConsumptionApi.getStatus() === RequestStatus.SUCCESS) {
        setSessionStatus(getMetricStatus(UNLIMITED_PLAN_METRIC));
      } else {
        setSessionStatus(getMetricStatus(METRIC_NOT_REGISTERED));
      }
    } finally {
      setIsSessionLoading(false);
    }
  };

  return (
    <SessionConsumptionContext.Provider
      value={{
        compiledSessionConsumptionDataState,
        consolidatedSessionApiRequisition,
        isSessionLoading,
        sessionStatus,
      }}
    >
      {children}
    </SessionConsumptionContext.Provider>
  );
};

export function useSessionConsumptionContext(): SessionConsumptionContextData {
  const context = React.useContext(SessionConsumptionContext);

  if (!context) {
    throw new Error('use sessionConsumptionContext context must be used within an SessionConsumptionContextProvider');
  }

  return context;
}
