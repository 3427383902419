import styled from 'styled-components';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import blipColors from 'blip-tokens/build/json/colors.json';
import { Container } from 'components/Global/components';
import { FadeIn } from 'components/Global/animations';

export const AgentConsumptionContainer = styled(Container)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  gap: 32px;
  padding: 2rem 0rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const AgentConsumptionPaper = styled(BdsPaper)`
  padding: 1.5rem;
  margin-top: 2rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;

  & > div {
    margin-left: 0.5rem;
  }

  & .activeAgents{
    width: 40%;
  }

  & .bp-table thead th:first-of-type, .bp-table-chart table thead th:first-of-type{
    width: 40%;
  }

  & .activeAgents-thumbnail {
    float:left;
  }

  & .activeAgents-content {
    float:left;
    margin-left: 8px;
    width: 50%;
    font-weight: bold;
    white-space: nowrap;
    color: ${blipColors['color-neutral-dark-desk']};
  }  

  & .activeAgents-child {
    float:left;
    margin-left: 8px;
    width: 50%;
    white-space: nowrap;
    color: ${blipColors['color-neutral-medium-elephant']};
  }  
`;

export const AgentConsumptionConsolidatedRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  & > bds-typo {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
    }
  }
`;

export const AgentConsumptionConsolidatedHeaderRow = styled.div`
  gap: 8px;
  display: flex;
  width: 100%;
  color: ${blipColors['color-neutral-dark-desk']};

  & > div {
    width: 100%;
  }

  .subtitle, .title{
    display: block;
    margin: 4px 0;
  }
  
  .title{
    color: ${blipColors['color-neutral-dark-city']};
  }
  
  .partialUsage{
    height: 30px;
    border-radius: 8px;
    background: ${blipColors['color-extend-browns-sand']};
    padding: 6px 8px;
    color: ${blipColors['color-primary-oranges-carrot']};
    margin-left: 20px;    
    & bds-typo:first-of-type {
      color: ${blipColors['color-primary-oranges-carrot']};
    }    
  }
`;

export const AgentConsumptionConsolidatedHeaderPartial = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
`;

export const AgentConsumptionConsolidatedPeriodRow = styled.div`
  margin-bottom: 1rem;
  flex-grow: 0;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid ${blipColors['color-neutral-medium-wave']};
`;

export const AgentConsumptionConsolidatedFilterText = styled.div`
  color: ${blipColors['color-neutral-dark-desk']};
  margin: 20px 0;
`;

export const AgentConsumptionConsolidatedContent = styled.div`
  background: ${blipColors['color-neutral-light-whisper']};
  padding: 1rem;
  border-radius: 8px;
  margin-left: 20px;
  white-space: nowrap;
  height: 104px;
  width: 225px;

  & > bds-typo:first-of-type {
    color: ${blipColors['color-neutral-dark-desk']};
    margin-bottom: 8px;
    display: inline-block;
  }
  & > bds-typo:last-child {
    color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
  }
`;
export const AgentConsumptionProgressPaperContent = styled.div`
  background: ${blipColors['color-neutral-light-whisper']};
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  display: flex;

  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-rooftop']};
      text-align: center;
    }
  }

  & > bds-typo:last-of-type {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-rooftop']};
      text-align: center;
    }
  }
`;

export const AgentConsumptionProgressContent = styled(AgentConsumptionProgressPaperContent)`
  & bds-typo.highlighted {
    &::part(bds-typo__text) {
      color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
    }
  }
`;

export const AgentConsumptionConsolidatedContentAligned = styled.div`
  display: flex;
`;

export const AgentConsumptionProgressContentAligned = styled.div`
  display: flex;
  width: 100%;
  height: 104px;
`;

export const AgentConsumptionProgressBarAligned = styled.div`
  width: 75%;

  color: ${blipColors['color-neutral-dark-desk']};
  & > bds-typo:first-of-type {
    position: relative;
    top: -5px;
  }
  & bds-tooltip {
    display: inline-block;
    margin-left: 10px;
  }

  .bp-progressbar-container{
    background-color: ${blipColors['color-neutral-medium-wave']};
  }
`;

export const AgentConsumptionProgressTotalAligned = styled.div`
  width: 25%;
  margin-top: 35px;
  text-align: center;

  & > bds-typo {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
    }
  }
  & > bds-typo.highlighted {
    &::part(bds-typo__text) {
      color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
    }
  }

  & > bds-typo.total-agent{
    margin: 0 8px;
  }
  
`;

export const AgentConsumptionWarningContentAligned = styled.div`
  margin-top: 20px;
  & > bds-typo.highlighted {
    &::part(bds-typo__text) {
      color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
    }
  }
`;


export const AgentConsumptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: ${blipColors['color-neutral-dark-desk']};

  & bds-paper {
    margin-left: 20px;
    height: 30px;
    border-radius: 8px;
    background: ${blipColors['color-extend-browns-sand']};
    padding: 6px 8px;
    color: ${blipColors['color-primary-oranges-carrot']};
    & bds-typo:first-of-type {
      color: ${blipColors['color-primary-oranges-carrot']};
    }
  }  
`;

export const AgentConsumptionHeaderPartial = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.currentMonth == true ? 'space-between' : 'flex-end'};
`;

