import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext } from 'react';
import { useDauConsumptionContext } from '../../../../contexts/Billing/DauConsumptionContext';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersTotalConsumedPaperContent } from '../styles';

const CompiledDailyActiveUserTotalContentComponent: React.FC = () => {
  const { content, currentMonth } = useContext(DailyActiveUsersContext);
  const { compiledDailyActiveUserDataState } = useDauConsumptionContext();
  const total = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.total;
  const compiledContainsDataOnSelectedMonth = compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth;

  return (
    <CompiledDailyActiveUsersTotalConsumedPaperContent className="bg-color-surface-2">
      <BdsTypo tag="h4" variant="fs-16" bold="bold" margin={true}>
        {content.compiledDailyActiveUserInformation.totalUsed}
      </BdsTypo>
      <BdsTypo
        tag="h4"
        variant="fs-32"
        bold="bold"
        margin={true}
        data-total={total}
        className={currentMonth ? 'color-content-default' : 'color-primary'}
      >
        {total >= 0 && compiledContainsDataOnSelectedMonth ? total.toLocaleString(content.locale) : '--'}
      </BdsTypo>
    </CompiledDailyActiveUsersTotalConsumedPaperContent>
  );
};

export default CompiledDailyActiveUserTotalContentComponent;
