import styled from 'styled-components';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import colors from '../../../../../_colors.module.scss';

export const ConsolidatedPaperContainer = styled(BdsPaper)`
  padding: 28px 32px;
  margin-top: 24px;
`;

export const ConsolidatedPaperHeader = styled.div`
  margin-bottom: 24px;
`;

export const ConsolidatedPaperBody = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 370px;
  height: auto;
`;

export const LeftChartArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: space-between;
  min-width: 178px;
  width: 20%;
  & > bds-typo > bds-tooltip {
    margin-left: 15px;
    position: relative;
    top: 3px;
  }
`;

export const LabelChartAligment = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;

  &:before {
    content: ' ';
    border-radius: 5px;
    width: 8px;
    height: 8px;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colors.Primary)};
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 1rem;
  }
`;

export const MiddleBorder = styled.div`
  border: 1px solid ${colors.Surface3};
`;

export const RightChartArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 178px;
  width: 40%;
`;
export const WarningLabel = styled.div`
  & > bds-typo > span:first-of-type {
    font-weight: 700;
  }
`;
