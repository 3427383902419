export class DailyActiveUserUsage {
  dateReference!: Date;
  total!: number;

  constructor(dailyActiveUsersMonthlyUsage: Partial<DailyActiveUserUsage>) {
    Object.assign(this, dailyActiveUsersMonthlyUsage);

    this.dateReference =
    this.dateReference instanceof Date ? this.dateReference : new Date(this.dateReference);
  }
}
