export class ConsolidatedAgentConsumption {
  metric!: any;
  total!: number;
  planUsageRate!: number;
  exceededPlan!: number;
  planUsage!: number;
  startDateReference!: Date;
  endDateReference!: Date;

  constructor(consolidatedAgentConsumption: Partial<ConsolidatedAgentConsumption>) {
    Object.assign(this, consolidatedAgentConsumption);

    this.startDateReference =
      this.startDateReference instanceof Date ? this.startDateReference : new Date(this.startDateReference);

    this.endDateReference =
      this.endDateReference instanceof Date ? this.endDateReference : new Date(this.endDateReference);
  }
}
