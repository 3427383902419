import { BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext, useMemo } from 'react';
import { BlipProgressBar } from '../../../components/BlipProgressBar';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import {
  CompiledDailyActiveUsersOverspentProgressContent,
  DailyActiveUsersProgressBarAligned,
  DailyActiveUsersProgressTotalAligned,
} from '../styles';
import blipColors from 'blip-tokens/build/json/colors.json';

const CompiledDailyActiveUserProgressContentComponent: React.FC = () => {
  const { content, compiledDailyActiveUserDataState, currentMonth } = useContext(DailyActiveUsersContext);

  const NO_PERCENTAGE_LABEL = '0%';

  const spentPercentage = useMemo(() => {
    if (compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth) {
      return content.formatString(
        content.compiledDailyActiveUserInformation.spentPercentage,
        compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsageRate,
      );
    } else {
      return NO_PERCENTAGE_LABEL;
    }
  }, [compiledDailyActiveUserDataState]);

  const planUsage = (toLocaleString: boolean) => {
    if (compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth) {
      return toLocaleString
        ? compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsage.toLocaleString()
        : compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsage;
    } else {
      return '--';
    }
  };

  const quantity = (toLocaleString: boolean) => {
    if (compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth) {
      return toLocaleString
        ? compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.metric.quantity.toLocaleString(
          content.locale,
        )
        : compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.metric.quantity;
    } else {
      return '--';
    }
  };

  const planUsageRate = compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth
    ? compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsageRate
    : 0;

  return (
    <CompiledDailyActiveUsersOverspentProgressContent
      containsOverspent={compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth}
      currentMonth={currentMonth}
    >
      <DailyActiveUsersProgressBarAligned>
        <BdsTypo data-planUsageRate={planUsageRate} tag="span" variant="fs-16" bold="bold" margin={false}>
          {spentPercentage}
        </BdsTypo>
        <BdsTooltip
          position="top-center"
          tooltip-text={content.compiledDailyActiveUserInformation.progressContentHoverIcon}
        >
          <BdsIcon name="info"></BdsIcon>
        </BdsTooltip>
        <BlipProgressBar
          completed={planUsageRate}
          color={currentMonth ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']}
        ></BlipProgressBar>
      </DailyActiveUsersProgressBarAligned>

      <DailyActiveUsersProgressTotalAligned currentMonth={currentMonth}>
        <BdsTypo className="highlighted" data-planUsage={planUsage(false)} variant="fs-32" tag="span" bold="extra-bold" margin={false}>
          {planUsage(true)}
        </BdsTypo>
        <BdsTypo className="total-dau" variant="fs-32" tag="span" bold="extra-bold">
          /
        </BdsTypo>
        <BdsTypo data-quantity={quantity(false)} variant="fs-24" tag="span" bold="extra-bold" margin={false}>
          {quantity(true)}
        </BdsTypo>
      </DailyActiveUsersProgressTotalAligned>
    </CompiledDailyActiveUsersOverspentProgressContent>
  );
};

export default CompiledDailyActiveUserProgressContentComponent;
