import { IframeMessageProxy } from 'iframe-message-proxy';

export const getCurrentUser = async () => {
  const postMessage = await IframeMessageProxy.sendMessage({ action: 'getUserContext', content: { command: 'getCurrentUser' }});
  return postMessage.response;
};

export const getAuthToken = async () => {
  const postMessage = await IframeMessageProxy.sendMessage({ action: 'getToken' });
  return postMessage.response;
};

export const UserApi = {
  getCurrentUser,
};
