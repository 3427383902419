import { FC, useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router';
import { BdsSelectOption } from 'blip-ds/dist/blip-ds-react';

import ContractConsumption from './components/ContractConsumption';
import ChatbotsConsumption from './components/ChatbotsConsumption';
import RevenueContext from '../../contexts/RevenueContext';
import {
  generateMonthListBetweenDates,
  getMonthTwoDigits,
  getDayBeforeToday,
  getFirstDayOfMonth,
} from '../../utils/date';
import { useCommon } from '../../contexts/CommonContext';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { localization } from './localization';
import { createTrack } from '../../api/SegmentApi';
import { useTenant } from '../../contexts/TenantContext';
import { MONTH_REVENUE_SELECT } from '../../shared/SegmentEvents';
import { ContractRules } from '../../models/Revenue/ContractRules';
import RevenueApi from '../../api/RevenueApi';
import { sortByProperty } from '../../utils/array';
import { ChatbotConsumption } from '../../models/Revenue/ChatbotConsumption';
import { billingRoutes, billingSubRoutes } from '../../routes/BillingRoutes';

const RevenueComponent: FC = () => {
  const [startDate, setStartDate] = useState<Date>(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState<Date>(getDayBeforeToday());
  const [contractRules, setContractRules] = useState<ContractRules>({} as ContractRules);
  const [chatbotsConsumption, setChatbotsConsumption] = useState<ChatbotConsumption[]>([]);
  const [chatbotTableModel, setChatbotTableModel] = useState([]);
  const [dateToSearch, setDateToSearch] = useState(getFirstDayOfMonth());

  const {
    common: { config, loggedUser },
  } = useCommon();
  const { tenant } = useTenant();
  const content = useContentLocalizer(localization);
  const initialDate = new Date(config.REVENUE_START_DATE);
  const revenueApi = new RevenueApi(content);

  useEffect(() => {
    const getData = async () => {
      const monthString = `${startDate.getFullYear()}-${getMonthTwoDigits(startDate)}`;
      const contractRules = await revenueApi.getContractRules(tenant.info.id, monthString);

      setDateToSearch(startDate);
      setContractRules(contractRules);
    };
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    setChatbotTableModel([
      { label: 'ID - Chatbot', key: 'name' },
      { label: contractRules.miu, key: 'consumption' },
      { label: 'MSGs', key: 'messages' },
    ]);
  }, [contractRules]);

  const generateMonthlyConsumptionOptions = useMemo(() => {
    const monthList = generateMonthListBetweenDates(initialDate, new Date()).sort(
      (first, second) => second.getTime() - first.getTime(),
    );
    return monthList.map((monthDate) => {
      const month = monthDate.getMonth();
      const year = monthDate.getFullYear();
      return (
        <BdsSelectOption value={monthDate.toISOString()} key={`${month}-${year}`}>
          {content.monthlyConsumption.month[month]} {year}
        </BdsSelectOption>
      );
    });
  }, []);

  const updateMonthlyConsumption = (selectedOption) => {
    const startDate = new Date(selectedOption.detail.value);
    const lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    let endDate = getDayBeforeToday();
    endDate = lastDayOfMonth > endDate ? endDate : lastDayOfMonth;
    setStartDate(startDate);
    setEndDate(endDate);
    createTrack(tenant.info, MONTH_REVENUE_SELECT, {
      month: endDate.getMonth() + 1,
      year: endDate.getFullYear(),
      user: loggedUser.email,
    });
  };

  const sortChatbots = async (sort) => {
    const sortedChatbotsConsumption = sortByProperty(chatbotsConsumption, sort.property, sort.order);
    setChatbotsConsumption([...sortedChatbotsConsumption]);
  };

  const currentMonth = useMemo(() => {
    const month = dateToSearch.getMonth() + 1 === new Date().getMonth() + 1;

    const year = dateToSearch.getFullYear() === new Date().getFullYear();

    return month && year;
  }, [dateToSearch]);

  const isFirstDayOfMonth = (): boolean => endDate < startDate;

  const contextProps = {
    currentMonth,
    startDate,
    endDate,
    contractRules,
    setContractRules,
    chatbotsConsumption,
    setChatbotsConsumption,
    chatbotTableModel,
    tenant,
    content,
    revenueApi,
    generateMonthlyConsumptionOptions,
    updateMonthlyConsumption,
    sortChatbots,
    isFirstDayOfMonth,
  };

  return (
    <RevenueContext.Provider value={contextProps}>
      <Switch>
        <Route exact path={billingRoutes.revenue} component={ContractConsumption} />
        <Route exact path={billingSubRoutes.revenueChatbots} component={ChatbotsConsumption} />
      </Switch>
    </RevenueContext.Provider>
  );
};

export default RevenueComponent;
