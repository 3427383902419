import React from 'react';
import PropTypes from 'prop-types';
import { ConsolidatedHeaderComponent } from './ConsolidatedHeaderComponent';

const ConsolidatedHeaderContainer = ({
  infoHeader,
  dateToSearch,
  currentMonth,
  middleInfoContent,
  endInfoContent,
  showConsolidatedBorder = true,
  filter1,
  filter2,
}) => {
  return (
    <ConsolidatedHeaderComponent
      infoHeader={infoHeader}
      dateToSearch={dateToSearch}
      currentMonth={currentMonth}
      middleInfoContent={middleInfoContent}
      endInfoContent={endInfoContent}
      showConsolidatedBorder={showConsolidatedBorder}
      filter1={filter1}
      filter2={filter2}
    ></ConsolidatedHeaderComponent>
  );
};

ConsolidatedHeaderContainer.propTypes = {
  infoHeader: PropTypes.string.isRequired,
  dateToSearch: PropTypes.instanceOf(Date).isRequired,
  currentMonth: PropTypes.bool.isRequired,
  showConsolidatedBorder: PropTypes.bool,
  middleInfoContent: PropTypes.string,
  endInfoContent: PropTypes.string,
};

export { ConsolidatedHeaderContainer };
