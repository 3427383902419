export const capitalizeFirstLetter = (str) => {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
};

export const getOrdinal = (str, locale = 'en-US', gender = 'm') => {
  if (locale === 'en-US') {
    var arr = ['th', 'st', 'nd', 'rd'];
    var v = str % 100;
    return str + (arr[(v - 20) % 10] || arr[v] || arr[0]);
  } else if (locale === 'es-ES') {
    return str + (gender === 'm' ? 'o' : 'a');
  } else {
    return str + (gender === 'm' ? 'º' : 'ª');
  }
};
