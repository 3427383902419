import React, { FC, useContext, useEffect, useState } from 'react';
import { BdsBanner, BdsPaper, BdsSelect } from 'blip-ds/dist/blip-ds-react';
import { BlipTable } from '../../../../components/BlipTable';
import SessionConsumptionContext from '../../../../contexts/SessionConsumptionContext';
import { PaperHeaderComponent } from '../../components/Metrics/PaperHeader/PaperHeaderComponent';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { getMonthTwoDigits } from '../../../../utils/date';
import { sortByProperty } from '../../../../utils/array';
import '../../../../typings/blip-ds.d';
import { Link } from 'react-router-dom';
import {
  SessionConsumptionRow,
  SessionConsumptionPaper,
  SessionConsumptionUsageStatementContent,
  SessionConsumptionHeaderFilter,
} from '../styles';
import {
  action,
  origin,
  REPORT_PERIOD_SELECT,
  REPORT_SESSION_CONSUMPTION_CONVERSATION_SELECT,
  REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_OPEN,
  REPORT_SESSION_CONSUMPTION_WABAID_SELECT,
  REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_BUSINESSINITIATED_TAB_CLICK,
  REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_USERINITIATED_TAB_CLICK,
  REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_MOREINFO_CLICK,
} from '../../../../shared/SegmentEvents';

export interface SessionConsumptionMonthlyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const SessionConsumptionMonthlyUsageComponent: FC<SessionConsumptionMonthlyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    dateToSearch,
    content,
    tenant,
    loggedUser,
    trackingProps,
    getPlanStatus,
    billingSessionConsumptionApi,
    oldSessionConsumptionMonthlyUsage,
    setOldSessionConsumptionMonthlyUsage,
    sortOldSessionConsumptionMonthlyUsage,
    periodOptions,
    setDateToSearch,
    conversationTypeFilter,
    conversationWabaFilter,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    setConversationTypeFilter,
    setConversationWabaFilter,
    isWabaloading,
    WHATSAPP_BP_PRICING_CHANGES_URL,
    WHATSAPP_BP_PRICING_CHANGES_START_DATE,
    showSessionNewPricing,
    setShowSessionNewPricing,
  } = useContext(SessionConsumptionContext);
  const [isLoading, setLoading] = useState(false);
  const [businessInitiatedMonthlyUsage, setBusinessInitiatedMonthlyUsage] = useState([] as any);
  const [userInitiatedMonthlyUsage, setUserInitiatedMonthlyUsage] = useState([] as any);
  const isDetailedView = registersToTake === -1;

  const tableMonthlyUsageModel = [
    { label: content.chatbotSessionConsumption.columnPhoneNumber, key: 'phoneNumber' },
    { label: content.chatbotSessionConsumption.columnUserSession, key: 'sessionByUser' },
    { label: content.chatbotSessionConsumption.columnEnterpriseSession, key: 'sessionByCompany' },
    { label: content.chatbotSessionConsumption.columnTotal, key: 'sessionTotal' },
  ];

  const businessInitiatedTable = [
    { label: content.sessionConsumptionByPhone.columnPhoneNumber, key: 'phoneNumber' },
    { label: content.sessionConsumptionByPhone.businessInitiated.columnUtility, key: 'utility' },
    { label: content.sessionConsumptionByPhone.businessInitiated.columnAuthentication, key: 'authentication' },
    { label: content.sessionConsumptionByPhone.businessInitiated.columnMarketing, key: 'marketing' },
    { label: content.sessionConsumptionByPhone.columnTotal, key: 'total' },
  ];

  const userInitiatedTable = [
    { label: content.sessionConsumptionByPhone.columnPhoneNumber, key: 'phoneNumber' },
    { label: content.sessionConsumptionByPhone.userInitiated.service, key: 'service' },
    { label: content.sessionConsumptionByPhone.userInitiated.free, key: 'free' },
    { label: content.sessionConsumptionByPhone.columnTotal, key: 'total' },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
        const showSessionNewPricing = dateToSearch >= WHATSAPP_BP_PRICING_CHANGES_START_DATE;
        setShowSessionNewPricing(showSessionNewPricing);
        if (showSessionNewPricing) {
          const sessionConsumptionMonthlyUsage = await billingSessionConsumptionApi.getMonthlySessionConsumption(
            tenant.info.id,
            monthString,
            registersToTake,
            conversationWabaFilter,
          );
          const businessInitiatedMonthlyUsage = sessionConsumptionMonthlyUsage.map((obj) => ({
            phoneNumber: obj.phoneNumber,
            utility: obj.businessInitiated.utility,
            authentication: obj.businessInitiated.authentication,
            marketing: obj.businessInitiated.marketing,
            total: obj.businessInitiated.total,
          }));

          const userInitiatedMonthlyUsage = sessionConsumptionMonthlyUsage.map((obj) => ({
            phoneNumber: obj.phoneNumber,
            service: obj.userInitiated.service,
            free: obj.userInitiated.free,
            total: obj.userInitiated.total,
          }));

          setBusinessInitiatedMonthlyUsage(businessInitiatedMonthlyUsage);
          setUserInitiatedMonthlyUsage(userInitiatedMonthlyUsage);
        } else {
          const oldSessionConsumptionMonthlyUsage = await billingSessionConsumptionApi.getOldMonthlySessionConsumption(
            tenant.info.id,
            monthString,
            registersToTake,
            conversationTypeFilter,
            conversationWabaFilter,
          );
          setOldSessionConsumptionMonthlyUsage(oldSessionConsumptionMonthlyUsage);
        }
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  const sortBusinessInitiatedMonthlyUsage = (sort) => {
    const sortedBusinessInitiatedMonthlyUsage = sortByProperty(
      businessInitiatedMonthlyUsage,
      sort.property,
      sort.order,
    );
    setBusinessInitiatedMonthlyUsage([...sortedBusinessInitiatedMonthlyUsage]);
  };

  const sortUserInitiatedMonthlyUsage = (sort) => {
    const sortedUserInitiatedMonthlyUsage = sortByProperty(userInitiatedMonthlyUsage, sort.property, sort.order);
    setUserInitiatedMonthlyUsage([...sortedUserInitiatedMonthlyUsage]);
  };

  const trackMonthlyButton = () => {
    trackEvent(REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_OPEN, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.SESSION,
      action: action.CLICK,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToRetrieveData,
      planStatus: planStatus,
      origin: origin.SESSIONPERPHONENUMBER,
    });
  };

  const wabaOptionChangeEvent = (selectedOption) => {
    const conversationWabaValue = selectedOption.detail.value;
    setConversationWabaFilter(conversationWabaValue);
    trackEvent(REPORT_SESSION_CONSUMPTION_WABAID_SELECT, tenant, loggedUser, {
      ...trackingProps,
      whatsappConversationWaba: conversationWabaValue,
      origin: origin.SESSIONPERPHONENUMBER,
    });
  };

  const typeOptionChangeEvent = (selectedOption) => {
    const conversationTypeValue = selectedOption.detail.value;
    setConversationTypeFilter(conversationTypeValue);
    trackEvent(REPORT_SESSION_CONSUMPTION_CONVERSATION_SELECT, tenant, loggedUser, {
      ...trackingProps,
      whatsappConversationType: conversationTypeValue,
      origin: origin.SESSIONPERPHONENUMBER,
    });
  };

  return (
    <>
      {isDetailedView && (
        <PageHeaderComponent
          title={content.chatbotSessionConsumption.title}
          dateToSearch={dateToSearch}
          periodOptions={periodOptions}
          periodOptionChangeEvent={periodOptionChangeEvent}
        />
      )}
      <SessionConsumptionPaper>
        <SessionConsumptionRow isDetailedView={isDetailedView}>
          <PaperHeaderComponent
            title={content.chatbotSessionConsumption.title}
            subtitle={content.chatbotSessionConsumption.about}
            path={'/session-consumption/monthly'}
            buttonCaption={content.chatbotSessionConsumption.buttonAllChatbots}
            trackButton={trackMonthlyButton}
            isDetailedView={isDetailedView}
            idTest={'show-all-dash-session-monthly-consumption'}
          />
          {isDetailedView && (
            <SessionConsumptionHeaderFilter>
              {conversationWabaFilterOptions.length > 0 && (
                <BdsSelect
                  disabled={conversationWabaFilterOptions.length === 1}
                  value={conversationWabaFilter}
                  label={content.conversationWabaLabel}
                  onBdsChange={wabaOptionChangeEvent}
                >
                  {conversationWabaFilterOptions}
                </BdsSelect>
              )}
              {!showSessionNewPricing && (
                <BdsSelect
                  value={conversationTypeFilter}
                  label={content.conversationTypeLabel}
                  onBdsChange={typeOptionChangeEvent}
                >
                  {conversationTypeFilterOptions}
                </BdsSelect>
              )}
            </SessionConsumptionHeaderFilter>
          )}
        </SessionConsumptionRow>
        <SessionConsumptionUsageStatementContent>
          {showSessionNewPricing ? (
            <bds-tab-group
              align="left"
              onClick={(e) => {
                try {
                  const isActiveFirstTab = e.target.firstChild.getAttribute('open') === '';
                  if (isActiveFirstTab) {
                    trackEvent(REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_USERINITIATED_TAB_CLICK, tenant, loggedUser, {
                      ...trackingProps,
                      action: action.CLICK,
                      origin: origin.SESSIONPERPHONENUMBER,
                    });
                  } else {
                    trackEvent(
                      REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_BUSINESSINITIATED_TAB_CLICK,
                      tenant,
                      loggedUser,
                      {
                        ...trackingProps,
                        action: action.CLICK,
                        origin: origin.SESSIONPERPHONENUMBER,
                      },
                    );
                  }
                } catch (error) {}
              }}
            >
              <bds-tab-item label={content.sessionConsumptionByPhone.businessInitiated.tab}>
                <BdsBanner variant="system" context="inside">
                  {content.sessionConsumptionByPhone.businessInitiated.bannerText}
                </BdsBanner>
                <BlipTable
                  idKey="businessInitiatedMonthlySessionConsumption"
                  model={businessInitiatedTable}
                  data={businessInitiatedMonthlyUsage}
                  selectedItems={[]}
                  onSortSet={sortBusinessInitiatedMonthlyUsage}
                  canSelect={false}
                  bodyHeight={tableBodyHeight}
                  loading={isWabaloading || isLoading}
                ></BlipTable>
              </bds-tab-item>
              <bds-tab-item label={content.sessionConsumptionByPhone.userInitiated.tab}>
                <BdsBanner variant="system" context="inside">
                  {content.sessionConsumptionByPhone.userInitiated.bannerText}
                </BdsBanner>
                <BlipTable
                  idKey="userInitiatedMonthlySessionConsumption"
                  model={userInitiatedTable}
                  data={userInitiatedMonthlyUsage}
                  selectedItems={[]}
                  onSortSet={sortUserInitiatedMonthlyUsage}
                  canSelect={false}
                  bodyHeight={tableBodyHeight}
                  loading={isWabaloading || isLoading}
                ></BlipTable>
              </bds-tab-item>
            </bds-tab-group>
          ) : (
            <BdsPaper>
              <BlipTable
                idKey="sessionConsumptions"
                model={tableMonthlyUsageModel}
                data={oldSessionConsumptionMonthlyUsage}
                selectedItems={[]}
                onSortSet={sortOldSessionConsumptionMonthlyUsage}
                canSelect={false}
                bodyHeight={tableBodyHeight}
                loading={isWabaloading || isLoading}
              ></BlipTable>
            </BdsPaper>
          )}
        </SessionConsumptionUsageStatementContent>
      </SessionConsumptionPaper>
    </>
  );
};

export default SessionConsumptionMonthlyUsageComponent;
