import styled from 'styled-components';
import blipColors from 'blip-tokens/build/json/colors.json';

export const Sidebar = styled.div`
  grid-column: 1 / 2;
`;

export const SidebarPaperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
`;

export const SidebarPaperRow = styled.div`
  margin: 24px 0px 8px 0px;

  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      margin-bottom: 8px;
    }
  }

  & > bds-typo:last-child {
    &::part(bds-typo__text) {
      margin-top: 8px;
    }
  }
`;

export const SidebarPaperRowInline = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      margin-top: 8px;
    }
  }

  &:hover {
    bds-icon {
      cursor: pointer;
      transition: all ease-in .2s;
      &:hover {
        transform: scale(1.05);
      }
    }
    bds-tooltip {
      bds-icon:active {
        transform: scale(1.1);
      }
    }

  }
`;

export const SidebarTenantUrl = styled.div`
  display: flex;
  gap: 8px;
  color: ${blipColors['color-primary-main']};
  align-items: flex-end;

  &:hover {
    color: ${blipColors['color-primary-dark']};

    bds-tooltip {
      color: ${blipColors['color-primary-dark']};
    }
  }
`;

export const SidebarHorizontal = styled.div`
  grid-column: 1 / 3;
`;

export const SidebarPaperContentHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;

`;

export const SidebarHorizontalText = styled.div`
  padding-left: 32px;
  flex-grow: 1;
`;

export const SidebarPaperBottomTextHorizontal = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-start;
  gap: 7vmax;
  @media screen and (max-width: 720px){
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  align-items: flex-end;
`;

export const SidebarPaperHorizontalRow = styled.div`
  display: flex;
  flex-direction: column;
  & > bds-typo:first-of-type {
    padding-bottom: 8px;
  }

  &:hover {
    bds-icon {
      cursor: pointer;
    }
  }
`;

export const SidebarPaperHorizontalRowInline = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  &:hover {
    bds-icon {
      cursor: pointer;
    }
  }
`;
