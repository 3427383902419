import styled from 'styled-components';

export const ConsolidatedHeader = styled.div`
  bds-chip-tag, bds-select {
    margin-left: 0.5rem;
  }
`;

export const ConsolidatedBorder = styled.div`
  margin: 30px 0 30px 0;
  border-bottom: 1px solid;
`;

export const ConsolidatedEndInfo = styled.div`
  margin-bottom: 20px;
`;
