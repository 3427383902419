import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTenant } from '../../contexts/TenantContext';
import { useCommon } from '../../contexts/CommonContext';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import BlipCard from '../../components/BlipCard';
import Sidebar from '../../components/Sidebar';
import { BdsTypo, BdsTooltip, BdsIcon } from 'blip-ds/dist/blip-ds-react';
import { createTrack } from '../../api/SegmentApi';
import { localization } from './localization';
import { useHistory } from 'react-router-dom';
import FeatureToggleService from '../../services/FeatureToggleService';
import { DATA_CONSUMPTION } from '../../shared/FeatureToggleKeys';
import { ContactHomeContainer, ContactHomeContent, ContactHomeContentRow, ContactHomeOptionContainer } from './styles';
import { generalSettingsOptions, featureManagementOptions } from '../../routes';
import { canUserRead } from '../../services/PermissionService';
import './styles.scss';
import { RouteOptions } from '../../models/shared/RouteOptions';

const ContactHome: React.FC = () => {
  const {
    common: { loggedUser },
  } = useCommon();
  const history = useHistory();
  const content = useContentLocalizer(localization);
  const {
    tenant: { info: tenant, privateRoutes, subscription },
  } = useTenant();

  const [featureManagementMenuOptions, setFeatureManagementMenuOptions] = useState<ReactElement[]>([]);
  const [generalSettingsMenuOptions, setGeneralSettingsMenuOptions] = useState<ReactElement[]>([]);
  const [isUsageDataOptionsVisible, setUsageDataOptionsVisible] = useState(false);
  const [isConsumptionReportVisible, setConsumptionReportVisible] = useState(false);

  const onOptionClick = (trackingKey: string, path: string): void => {
    createTrack(tenant, trackingKey, {
      user: loggedUser.email,
      plan: tenant.plan,
      origin: 'contract-panel',
      action: 'click',
    });

    history.push(path);
  };

  const generateMenuOptions = (availableOptions: RouteOptions[], useFeatureToggle = false): ReactElement[] | Promise<ReactElement[]> => {
    const buildMenuOption = (item: RouteOptions, idx: number): ReactElement => (
      <BlipCard
        key={idx}
        icon={item.icon}
        title={content[item.group][item.option].title}
        description={content[item.group][item.option].description}
        tagNewFeature={content[item.group][item.option]?.new}
        onClick={() => onOptionClick(item.segmentEvent, item.path)}
      ></BlipCard>
    );

    const additionalCheckFilter = (options: RouteOptions[]): RouteOptions[] =>
      options.filter(item =>
        item.additionalCheck && item.accessPermission ? item.additionalCheck(item.accessPermission, loggedUser.roleId) : true
      );  

    if (useFeatureToggle) {
      return Promise.all(
        availableOptions.map(async (item) => {
          let visible = item.featureToggle ? await FeatureToggleService.isFeatureToggleEnabled(item.featureToggle as string) : true;
          if(item.metrics) {
            visible = visible && subscription?.some((s) => s.metricId === item.metrics);
          }
          return {
            item,
            visible,
          };
        })
      ).then(result =>
        additionalCheckFilter(result
          .filter(
            data => data.visible
          )
          .map(data => data.item)
        )
          .map(
            (item, idx) => buildMenuOption(item, idx)
          )
      );
    } else {
      return additionalCheckFilter(availableOptions).map(buildMenuOption);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const isConsumptionReportEnabled = await FeatureToggleService.isFeatureToggleEnabled(DATA_CONSUMPTION);
      setConsumptionReportVisible(isConsumptionReportEnabled);
    };
    getData();
  }, []);

  useEffect(() => {
    (async () => {
      const menuOptions = await generateMenuOptions(featureManagementOptions, true);

      setFeatureManagementMenuOptions(menuOptions);
    })();
  }, [featureManagementOptions]);

  useEffect(() => {
    (async () => {
      const menuOptions = await generateMenuOptions(generalSettingsOptions, true);

      setGeneralSettingsMenuOptions(menuOptions);
    })();
  }, [generalSettingsOptions]);  

  const getUsageDataMenuOptions = useMemo(() => {
    let availableUsageDataOptions = privateRoutes.filter((item) => item.group === 'usageData');
    const usageDataOptionsVisible = availableUsageDataOptions.length > 1;
    availableUsageDataOptions = availableUsageDataOptions.filter((item) =>
      isConsumptionReportVisible ? item.option === 'dataConsumption' : item.option !== 'dataConsumption',
    );
    setUsageDataOptionsVisible(usageDataOptionsVisible);
    return generateMenuOptions(availableUsageDataOptions);
  }, [privateRoutes, isConsumptionReportVisible]);

  return (
    <ContactHomeContainer>
      <Sidebar horizontal={!(generalSettingsMenuOptions.length > 0) && !isUsageDataOptionsVisible} />
      <ContactHomeContent>
        {generalSettingsMenuOptions.length > 0 && (
          <ContactHomeContentRow className="bb-color-surface">
            <BdsTypo variant="fs-24" tag="span" bold="semi-bold" data-test="general-settings-title">
              {content.generalSettings.title}
            </BdsTypo>
            <BdsTooltip
              position="top-center"
              tooltip-text={content.generalSettings.info}
              data-test="general-settings-tooltip"
            >
              <BdsIcon name="info" theme="solid"></BdsIcon>
            </BdsTooltip>
          </ContactHomeContentRow>
        )}
        <ContactHomeOptionContainer>{generalSettingsMenuOptions}</ContactHomeOptionContainer>
        {featureManagementMenuOptions.length > 0 && (
          <>
            <ContactHomeContentRow className="bb-color-surface">
              <BdsTypo variant="fs-24" tag="span" bold="semi-bold" data-test="ai-settings-title">
                {content.featureManagement.title}
              </BdsTypo>
              <BdsTooltip position="top-center" tooltip-text={content.featureManagement.info} data-test="ai-settings-tooltip">
                <BdsIcon name="info" theme="solid"></BdsIcon>
              </BdsTooltip>
            </ContactHomeContentRow>
            <ContactHomeOptionContainer>{featureManagementMenuOptions}</ContactHomeOptionContainer>
          </>
        )}
        {isUsageDataOptionsVisible && (
          <>
            <ContactHomeContentRow className="bb-color-surface">
              <BdsTypo variant="fs-24" tag="span" bold="semi-bold" data-test="usage-data-title">
                {content.usageData.title}
              </BdsTypo>
              <BdsTooltip position="top-center" tooltip-text={content.usageData.info} data-test="usage-data-tooltip">
                <BdsIcon name="info" theme="solid"></BdsIcon>
              </BdsTooltip>
            </ContactHomeContentRow>
            <ContactHomeOptionContainer>{getUsageDataMenuOptions}</ContactHomeOptionContainer>
          </>
        )}
      </ContactHomeContent>
    </ContactHomeContainer>
  );
};

export default ContactHome;
