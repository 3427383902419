import React from 'react';
import PropTypes from 'prop-types';
import { DoughnutComponent } from './DoughnutChartComponent';

const DoughnutChartContainer = ({ data, options, plugins, redraw }) => {
  return <DoughnutComponent data={data} options={options} plugins={plugins} redraw={redraw}></DoughnutComponent>;
};

DoughnutChartContainer.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.array,
  redraw: PropTypes.bool,
};

export { DoughnutChartContainer };
