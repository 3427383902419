import { BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext } from 'react';
import { BlipProgressBar } from '../../../components/BlipProgressBar';
import AgentConsumptionContext from '../../../contexts/AgentConsumptionContext';
import {
  AgentConsumptionProgressBarAligned,
  AgentConsumptionProgressContent,
  AgentConsumptionProgressTotalAligned,
} from '../styles';
import blipColors from 'blip-tokens/build/json/colors.json';

const AgentConsumptionProgressComponent: React.FC = () => {
  const { content, currentMonth, compiledAgentConsumptionDataState } = useContext(AgentConsumptionContext);

  const planUsage = (toLocaleString: boolean) => {
    if (compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth) {
      return toLocaleString
        ? compiledAgentConsumptionDataState.consolidatedAgentConsumption.planUsage.toLocaleString(content.locale)
        : compiledAgentConsumptionDataState.consolidatedAgentConsumption.planUsage;
    } else {
      return '--';
    }
  };

  const quantity = (toLocaleString: boolean) => {
    if (compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth) {
      return toLocaleString
        ? compiledAgentConsumptionDataState.consolidatedAgentConsumption.metric.quantity.toLocaleString(content.locale)
        : compiledAgentConsumptionDataState.consolidatedAgentConsumption.metric.quantity;
    } else {
      return '--';
    }
  };

  return (
    <AgentConsumptionProgressContent currentMonth={currentMonth} containsOverspent={true}>
      <AgentConsumptionProgressBarAligned>
        <BdsTypo
          data-planUsageRate={compiledAgentConsumptionDataState.consolidatedAgentConsumption.planUsageRate}
          tag="span"
          variant="fs-16"
          bold="bold"
          margin={false}
        >
          {content.consolidated.progressContentTitle}
        </BdsTypo>
        <BdsTooltip position="top-center" tooltip-text={content.consolidated.progressContentHoverIcon}>
          <BdsIcon name="info"></BdsIcon>
        </BdsTooltip>
        <BlipProgressBar
          completed={compiledAgentConsumptionDataState.consolidatedAgentConsumption.planUsageRate}
          color={currentMonth ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']}
        ></BlipProgressBar>
      </AgentConsumptionProgressBarAligned>
      <AgentConsumptionProgressTotalAligned currentMonth={currentMonth}>
        <BdsTypo
          data-planUsage={planUsage(false)}
          className="highlighted"
          variant="fs-32"
          tag="span"
          bold="extra-bold"
          margin={false}
        >
          {planUsage(true)}
        </BdsTypo>
        <BdsTypo className="total-agent" variant="fs-32" tag="span" bold="extra-bold" margin={true}>
          /
        </BdsTypo>
        <BdsTypo data-quantity={quantity(false)} variant="fs-24" tag="span" bold="extra-bold" margin={false}>
          {quantity(true)}
        </BdsTypo>
      </AgentConsumptionProgressTotalAligned>
    </AgentConsumptionProgressContent>
  );
};

export default AgentConsumptionProgressComponent;
