import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import React, { FC, useContext, useEffect, useState } from 'react';
import { BlipTable } from '../../../../components/BlipTable';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { getMonthTwoDigits } from '../../../../utils/date';
import { DailyActiveUserPaper, DailyActiveUserUsageStatementContent } from '../styles';
import { PaperHeaderComponent } from '../../components/Metrics/PaperHeader/PaperHeaderComponent';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { trackEvent } from '../../../../api/Billing/Tracking';
import {
  action,
  origin,
  REPORT_DAU_CONSUMPTION_PER_CHATBOT_OPEN,
  REPORT_PERIOD_SELECT,
} from '../../../../shared/SegmentEvents';

export interface DailyActiveUserMonthlyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const DailyActiveUserMonthlyUsageComponent: FC<DailyActiveUserMonthlyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    dateToSearch,
    content,
    tenant,
    loggedUser,
    sortDailyActiveUserMonthlyUsage,
    setDailyActiveUserMonthlyUsage,
    dailyActiveUserMonthlyUsage,
    billingDailyActiveUserApi,
    periodOptions,
    setDateToSearch,
    trackingProps,
    getPlanStatus,
  } = useContext(DailyActiveUsersContext);
  const isDetailedView = registersToTake === -1;
  const [isLoading, setLoading] = useState(false);

  const tableMonthlyUsageModel = [
    {
      label: content.chatbotDailyActiveUserConsuption.columnChatbot,
      key: 'botName',
      hasChildContent: true,
      childContentkey: 'botId',
    },
    { label: content.chatbotDailyActiveUserConsuption.columnDailyActiveUsers, key: 'total' },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
        const dailyActiveUserMonthlyUsage = await billingDailyActiveUserApi.getDailyActiveUserMonthlyUsage(
          tenant.info.id,
          monthString,
          registersToTake,
        );

        setDailyActiveUserMonthlyUsage(dailyActiveUserMonthlyUsage);
      } finally {
        setLoading(false);
      }
    };

    getData();

  }, [dateToSearch]);

  const trackChatbotButton = () => {
    trackEvent(REPORT_DAU_CONSUMPTION_PER_CHATBOT_OPEN, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.DAU,
      action: action.CLICK,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.DAUPERCHATBOT,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  return (
    <>
      {isDetailedView && (
        <PageHeaderComponent
          title={content.chatbotDailyActiveUserConsuption.title}
          dateToSearch={dateToSearch}
          periodOptions={periodOptions}
          periodOptionChangeEvent={periodOptionChangeEvent}
        />
      )}
      <DailyActiveUserPaper>
        <PaperHeaderComponent
          title={content.chatbotDailyActiveUserConsuption.title}
          subtitle={content.chatbotDailyActiveUserConsuption.about}
          path={'/dau-consumption/monthly'}
          buttonCaption={content.chatbotDailyActiveUserConsuption.buttonAllChatbots}
          trackButton={trackChatbotButton}
          isDetailedView={isDetailedView}
          idTest={'show-all-dash-dau-monthly-consumption'}
        />
        <DailyActiveUserUsageStatementContent>
          <BdsPaper>
            <BlipTable
              idKey="dailyActiveUsers"
              model={tableMonthlyUsageModel}
              data={dailyActiveUserMonthlyUsage}
              selectedItems={[]}
              onSortSet={sortDailyActiveUserMonthlyUsage}
              canSelect={false}
              bodyHeight={tableBodyHeight}
              loading={isLoading}
            ></BlipTable>
          </BdsPaper>
        </DailyActiveUserUsageStatementContent>
      </DailyActiveUserPaper>
    </>
  );
};

export default DailyActiveUserMonthlyUsageComponent;
