import React from 'react';
import { BdsTypo, BdsIcon, BdsTooltip } from 'blip-ds/dist/blip-ds-react';
import { localization } from './localization';
import { Divider } from '../styles';
import { SummaryPaper, SummaryBody, SummaryContent } from './styles';
import { useContentLocalizer } from 'hooks/useContentLocalizer';

export interface SummaryAgents {
  quantityAgentsTriggered: number;
  quantityAgents: number;
  agentsSubscriptionNumber: number;
  quantityAgentsEnabled: number;
}

const Summary: React.FC<SummaryAgents> = ({
  quantityAgentsTriggered,
  quantityAgents,
  agentsSubscriptionNumber,
  quantityAgentsEnabled,
}) => {
  const content = useContentLocalizer(localization);
  return (
    <SummaryPaper>
      <div>
        <BdsTypo variant="fs-24" bold="bold" tag="h3" margin={false}>
          {content.title}
        </BdsTypo>
        <Divider className="mt3"/>
        <div>
          <BdsTypo variant="fs-20" bold="bold" tag="h4" margin={false}>
            {agentsSubscriptionNumber > 0 &&
              content.formatString(content.subTitle, { quantityPlan: agentsSubscriptionNumber })}
          </BdsTypo>
          <div className="mt1">
            <BdsTypo variant="fs-14">{content.agentDescriptionTriggered}</BdsTypo>
            <BdsTypo variant="fs-14">{content.agentExtra}</BdsTypo>
          </div>
        </div>
        <SummaryBody>
          <SummaryContent>
            <div className="flex mb2">
              <BdsTypo bold="bold" variant="fs-14">
                {content.agentsTotal}
              </BdsTypo>
            </div>
            <BdsTypo variant="fs-32" tag="h2" bold="extra-bold" margin={false}>
              {quantityAgents}
            </BdsTypo>
          </SummaryContent>
          <SummaryContent>
            <div className="flex">
              <BdsTypo bold="bold" variant="fs-14">
                {content.agentsEnabled}
              </BdsTypo>
              <BdsTooltip position="top-center" tooltip-text={content.agentDescriptionEnabled}>
                <BdsIcon name="info" className="ml2" theme="outline" aria-label={content.agentsEnabled} />
              </BdsTooltip>
            </div>
            <BdsTypo variant="fs-32" tag="h2" bold="extra-bold" margin={false}>
              {quantityAgentsEnabled}
            </BdsTypo>
          </SummaryContent>
          <SummaryContent>
            <div className="flex">
              <BdsTypo bold="bold" variant="fs-14">
                {content.agentsTriggered}
              </BdsTypo>
              <BdsTooltip position="top-center" tooltip-text={content.agentDescriptionSummaryTriggered}>
                <BdsIcon name="info" className="ml2" theme="outline" aria-label={content.agentsTriggered} />
              </BdsTooltip>
            </div>
            <BdsTypo variant="fs-32" tag="h2" bold="extra-bold" margin={false}>
              {quantityAgentsTriggered}
            </BdsTypo>
          </SummaryContent>
        </SummaryBody>
      </div>
    </SummaryPaper>
  );
};

export default Summary;
