import React from 'react';
import PropTypes from 'prop-types';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { localization } from './localization';
import { DateDropdownComponent } from './DateDropdownComponent';

const DateDropdownContainer = ({ value, bdsChangeEvent, options, label = '' }) => {
  const content = useContentLocalizer(localization);

  const placeholderContent = label != '' ? label : content.monthPlaceholder;

  return (
    <DateDropdownComponent
      label={placeholderContent}
      bdsChangeEvent={bdsChangeEvent}
      value={value}
      options={options}
    ></DateDropdownComponent>
  );
};

DateDropdownContainer.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  bdsChangeEvent: PropTypes.any,
  options: PropTypes.array,
};

export { DateDropdownContainer };
