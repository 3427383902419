export const localization = {
  pt: {
    locale: 'pt-BR',
    partialUsage: 'Consumo parcial',
    period: 'De {startDate} a {endDate}',
    middleInfo:
      '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
  },
  en: {
    locale: 'en-US',
    partialUsage: 'Partial usage',
    period: 'From {startDate} to {endDate}',
    middleInfo: '*Monthly statements will become available within 5 business days of month end.',
  },
  es: {
    locale: 'es-ES',
    partialUsage: 'Consumo parcial',
    period: 'Del {startDate} al {endDate}',
    middleInfo:
      '*Los estados de cuenta mensuales estarán disponibles dentro de los 5 días hábiles posteriores al final del mes.',
  },
};
