import { formatPhoneNumberIntl } from 'react-phone-number-input';

export class MonthlySessionConsumption {
  phoneNumber!: string;
  sessionByUser!: number;
  sessionByCompany!: number;

  constructor(monthlySessionConsumption: Partial<MonthlySessionConsumption>) {
    this.phoneNumber = '';
    this.sessionByUser = 0;
    this.sessionByCompany = 0;
    Object.assign(this, monthlySessionConsumption);

    if (!this.phoneNumber.startsWith('+')) {
      this.phoneNumber = '+' + this.phoneNumber;
    }

    this.phoneNumber = formatPhoneNumberIntl(this.phoneNumber);
  }
}
