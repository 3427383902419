import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { FadeIn } from 'components/Global/animations';
import styled from 'styled-components';
import blipColors from 'blip-tokens/build/json/colors.json';
import { Container } from 'components/Global/components';

export const SessionConsumptionContainer = styled(Container)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  gap: 32px;
  padding: 2rem 0rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const SessionConsumptionPaper = styled(BdsPaper)`
  padding: 1.5rem;
  margin-top: 2rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const SessionConsumptionConsolidatedContent = styled.div`
  background: ${blipColors['color-neutral-light-whisper']};
  padding: 1rem;
  border-radius: 8px;
  position: block;
  width: 31%;

  & > bds-typo:first-of-type {
    color: ${blipColors['color-neutral-dark-rooftop']};
    margin-bottom: 8px;
  }
  & > bds-typo:last-child {
    color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
  }  
`;

export const SessionConsumptionConsolidatedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > bds-typo {
    &::part(bds-typo__text) {
      color: ${blipColors['color-neutral-dark-desk']};
    }
  }
`;

export const SessionConsumptionConsolidatedPeriodRow = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-grow: 0;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid ${blipColors['color-neutral-medium-wave']};
`;

export const SessionConsumptionConsolidatedFilterText = styled.div`
  margin: 20px 0;
`;

export const SessionConsumptionConsolidatedHeaderRow = styled.div`
  color: ${blipColors['color-neutral-dark-city']};
  display: flex;

  .title, .subtitle{
    display: block;
    margin: 4px 0;
  }  

  & bds-select {
    width: 350px;
    margin-left: 1.5rem;
  }

  & > div{
    width: 100%;
  }

  .partialUsage{
    height: 30px;
    border-radius: 8px;
    background: ${blipColors['color-extend-browns-sand']};
    padding: 6px 8px;
    color: ${blipColors['color-primary-oranges-carrot']};
    margin-left: 20px;    
    & bds-typo:first-of-type {
      color: ${blipColors['color-primary-oranges-carrot']};
    }    
  }
`;

export const SessionConsumptionConsolidatedHeaderPartial = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
`;

export const SessionConsumptionHeaderFilter = styled.div`
  display: flex;
  & bds-select{
    margin-left: 0.5rem;
  }
`;


export const SessionConsumptionHeaderPartial = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.currentMonth == true ? 'space-between' : 'flex-end'};
`;

export const SessionConsumptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: ${blipColors['color-neutral-dark-city']};

  & bds-paper {
    margin-left: 20px;
    height: 30px;
    border-radius: 8px;
    background: ${blipColors['color-extend-browns-sand']};
    padding: 6px 8px;
    color: ${blipColors['color-primary-oranges-carrot']};
    & bds-typo:first-of-type {
      color: ${blipColors['color-primary-oranges-carrot']};
    }
  }  
`;

export const SessionConsumptionSumUsagePaperRow = styled(SessionConsumptionRow)`
  display: inline-block;
`;

export const SessionConsumptionUsageStatementContent = styled.div`
  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  & > bds-paper > table > thead {
    background-color: ${blipColors['color-new-color']};
    border-top: ${blipColors['color-new-color']};
    border-bottom: 0.5px solid #d2dfe6;
    & > tr > th {
      background-color: ${blipColors['color-new-color']};
    }
  }

  & > bds-paper > table > tbody > tr > td:first-of-type > span:first-of-type {
    font-weight: bold;
  }
`;
