import React, { FC, useContext, useEffect, useState } from 'react';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { BlipTable } from '../../../../components/BlipTable';
import { getMonthTwoDigits, toUTCDateString } from '../../../../utils/date';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { PaperHeaderComponent } from '../../components/Metrics/PaperHeader/PaperHeaderComponent';
import { AgentConsumptionPaper } from '../styles';
import AgentConsumptionContext from '../../../../contexts/AgentConsumptionContext';
import {
  action,
  origin,
  REPORT_AGENT_CONSUMPTION_LIST_OPEN,
  REPORT_PERIOD_SELECT,
} from '../../../../shared/SegmentEvents';

export interface AgentConsumptionMonthlyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const AgentConsumptionMonthlyUsageComponent: FC<AgentConsumptionMonthlyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    dateToSearch,
    periodOptions,
    content,
    tenant,
    loggedUser,
    setHeaderContent,
    trackingProps,
    getPlanStatus,
    sortAgentConsumptionMonthlyUsage,
    setDateToSearch,
    billingAgentConsumptionApi,
    monthlyAgentConsumptionDataState,
    setMonthlyAgentConsumptionDataState,
  } = useContext(AgentConsumptionContext);
  const isDetailedView = registersToTake === -1;
  const [isLoading, setLoading] = useState(false);
  const tableMonthlyUsageModel = [
    {
      label: content.monthly.columnAgentId,
      key: 'agentName',
      hasChildContent: true,
      childContentkey: 'agentEmail',
      hasThumbnail: true,
      thumbnailKey: 'agentPhotoUri',
      className: 'activeAgents',
    },
    { label: content.monthly.columnExtraCharge, key: 'extraCharge' },
    { label: content.monthly.columnNumTickets, key: 'totalTickets' },
    { label: content.monthly.columnFirstRepliedTicket, key: 'firstTicketDate' },
    { label: content.monthly.columnLastRepliedTicket, key: 'lastTicketDate' },
  ];

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: null });
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;

        const agentConsumptionMonthlyUsage = await billingAgentConsumptionApi.getMonthlyAgentConsumption(
          tenant.info.id,
          monthString,
          registersToTake,
        );

        const agentConsumptionMonthlyUsageParse = agentConsumptionMonthlyUsage.map((obj) => ({
          ...obj,
          extraCharge: obj.extraCharge ? content.monthly.extraCharge[0] : content.monthly.extraCharge[1],
          firstTicketDate: toUTCDateString(new Date(obj.firstTicketDate), content.locale),
          lastTicketDate: toUTCDateString(new Date(obj.lastTicketDate), content.locale),
        }));
        setMonthlyAgentConsumptionDataState(agentConsumptionMonthlyUsageParse);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [dateToSearch]);

  const trackMonthlyButton = () => {
    trackEvent(REPORT_AGENT_CONSUMPTION_LIST_OPEN, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.AGENTCONSUMPTION,
      action: action.CLICK,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.AGENTCONSUMPTIONLIST,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  return (
    <>
      {isDetailedView && (
        <PageHeaderComponent
          title={content.monthly.title}
          dateToSearch={dateToSearch}
          periodOptions={periodOptions}
          periodOptionChangeEvent={periodOptionChangeEvent}
        />
      )}
      <AgentConsumptionPaper>
        <PaperHeaderComponent
          title={content.monthly.title}
          subtitle={content.monthly.subtitle}
          path={'/agent-consumption/monthly'}
          buttonCaption={content.monthly.buttonShowAll}
          trackButton={trackMonthlyButton}
          isDetailedView={isDetailedView}
          idTest={'show-all-dash-agent-monthly-consumption'}
        />
        <BdsPaper>
          <BlipTable
            idKey="agentConsumptions"
            model={tableMonthlyUsageModel}
            data={monthlyAgentConsumptionDataState}
            selectedItems={[]}
            onSortSet={sortAgentConsumptionMonthlyUsage}
            canSelect={false}
            bodyHeight={tableBodyHeight}
            rowsPerPage={30}
            loading={isLoading}
          ></BlipTable>
        </BdsPaper>
      </AgentConsumptionPaper>
    </>
  );
};

export default AgentConsumptionMonthlyUsageComponent;
