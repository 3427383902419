import styled from 'styled-components';

export const BlipTableFooter = styled.div`
  background-color: #f3f6fa;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .button {
    margin: 4px;
  }
`;
