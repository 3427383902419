import React from 'react';
import PropTypes from 'prop-types';
import { ChartJS } from '../index';
import { ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutComponent = ({ data, options, plugins, redraw }) => {
  return <Doughnut options={options} data={data} plugins={plugins} redraw={redraw} />;
};

DoughnutComponent.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.array,
  redraw: PropTypes.bool,
};

export { DoughnutComponent };
