import React, { createContext, Reducer, useReducer, useState } from 'react';
import BillingDailyActiveUsersServiceApi from '../../api/Billing/BillingDailyActiveUsersApi';
import { CompiledDailyActiveUserConsumption } from '../../models/billing/dailyActiveUsers/CompiledDailyActiveUserConsumption';
import { getMonthTwoDigits } from '../../utils/date';
import { RequestStatus } from '../../shared/Requests';
import {
  BillingMetrics,
  getMetricStatus,
  MetricStatus,
  METRIC_NOT_REGISTERED,
  UNLIMITED_PLAN_METRIC,
} from '../../shared/BillingMetrics';

interface CompiledDailyActiveUserDataState {
  compiledDailyActiveUserConsumption: CompiledDailyActiveUserConsumption;
  compiledContainsDataOnSelectedMonth: boolean;
}

interface DauConsumptionContextData {
  compiledDailyActiveUserDataState: CompiledDailyActiveUserDataState;
  consolidatedDauApiRequisition: (
    dateToSearch: Date,
    isMetricAllowed: (value: BillingMetrics) => boolean,
  ) => Promise<void>;
  isDauLoading: boolean;
  dauStatus: MetricStatus;
}

interface props {
  tenant: any;
}

export const DauConsumptionContext = createContext<DauConsumptionContextData>({} as DauConsumptionContextData);

export const DauConsumptionContextProvider: React.FC<props> = ({ children, tenant }) => {
  const [isDauLoading, setIsDauLoading] = useState(false);
  const [dauStatus, setDauStatus] = useState(MetricStatus.error);
  const billingDailyActiveUserApi = new BillingDailyActiveUsersServiceApi();
  const compiledDailyActiveUserConsumption = new CompiledDailyActiveUserConsumption(null);
  const [compiledDailyActiveUserDataState, setCompiledDailyActiveUserDataState] = useReducer<
    Reducer<CompiledDailyActiveUserDataState, Partial<CompiledDailyActiveUserDataState>>
  >(
    (compiledDailyActiveUserDataState, newCompiledDailyActiveUserDataState) => ({
      ...compiledDailyActiveUserDataState,
      ...newCompiledDailyActiveUserDataState,
    }),
    {
      compiledDailyActiveUserConsumption,
      compiledContainsDataOnSelectedMonth: false,
    },
  );

  const consolidatedDauApiRequisition = async (
    dateToSearch: Date,
    isMetricAllowed: (value: BillingMetrics) => boolean,
  ): Promise<void> => {
    try {
      setIsDauLoading(true);
      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const compiledDauConsumption = isMetricAllowed(BillingMetrics.dailyActiveUser)
        ? await billingDailyActiveUserApi.getCompiledDailyActiveUserConsumption(tenant.info.id, monthString)
        : compiledDailyActiveUserConsumption;
      setCompiledDailyActiveUserDataState({
        compiledDailyActiveUserConsumption: compiledDauConsumption,
        compiledContainsDataOnSelectedMonth: billingDailyActiveUserApi.getStatus() === RequestStatus.SUCCESS,
      });
      if (billingDailyActiveUserApi.getStatus() === RequestStatus.SUCCESS) {
        setDauStatus(
          getMetricStatus(
            compiledDauConsumption?.metric.quantity === UNLIMITED_PLAN_METRIC
              ? UNLIMITED_PLAN_METRIC
              : compiledDauConsumption.planUsageRate,
          ),
        );
      } else {
        setDauStatus(getMetricStatus(METRIC_NOT_REGISTERED));
      }
    } finally {
      setIsDauLoading(false);
    }
  };

  return (
    <DauConsumptionContext.Provider
      value={{
        compiledDailyActiveUserDataState,
        consolidatedDauApiRequisition,
        isDauLoading,
        dauStatus,
      }}
    >
      {children}
    </DauConsumptionContext.Provider>
  );
};

export function useDauConsumptionContext(): DauConsumptionContextData {
  const context = React.useContext(DauConsumptionContext);

  if (!context) {
    throw new Error('use dauconsumptioncontext context must be used within an DauConsumptionContextProvider');
  }

  return context;
}
