import { MonthlyAgentConsumption } from '../../models/billing/agentConsumption/MonthlyAgentConsumption';
import { ConsolidatedAgentConsumption } from '../../models/billing/agentConsumption/ConsolidatedAgentConsumption';
import { BlipApi } from '../BlipApi';
import { METHOD, RequestStatus } from 'shared/Requests';
import { useSubscriptionReferenceDate } from './BillingApi';

export default class BillingAgentConsumptionApi {
  private readonly BILLING_COMMAND_DESTINATION = 'postmaster@billing.blip.ai';
  private code = '';
  private status = '';
  private useSubscriptionReferenceDate: boolean | undefined = undefined;

  public async getUseSubscriptionReferenceDate(): Promise<void> {
    if (this.useSubscriptionReferenceDate === undefined) {
      this.useSubscriptionReferenceDate = await useSubscriptionReferenceDate();
    }    
  }

  public getStatus() {
    return this.status;
  }

  public getCode() {
    return this.code;
  }

  public setSuccess() {
    this.status = RequestStatus.SUCCESS;
  }

  public setErrorLog(e) {
    const error = JSON.parse(<string>e);
    const {
      status,
      reason: { code },
    } = JSON.parse(error.message);
    this.code = code;
    this.status = status;
    console.log(e);
  }

  public async getConsolidatedAgentConsumption(tenantId: string, month: string): Promise<ConsolidatedAgentConsumption> {
    await this.getUseSubscriptionReferenceDate();
    const uri = `/tenants/${tenantId}/agents/summary?month=${month}&useSubscriptionReferenceDate=${this.useSubscriptionReferenceDate}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const { response } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return new ConsolidatedAgentConsumption(response);
    } catch (e) {
      this.setErrorLog(e);
      return new ConsolidatedAgentConsumption(null);
    }
  }
  public async getMonthlyAgentConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
  ): Promise<MonthlyAgentConsumption[]> {
    await this.getUseSubscriptionReferenceDate();
    let uri = `/tenants/${tenantId}/agents/monthly/by-agent?month=${month}&useSubscriptionReferenceDate=${this.useSubscriptionReferenceDate}`;
    uri = uri + `&$take=${registersToTake}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new MonthlyAgentConsumption(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<MonthlyAgentConsumption>();
    }
  }
}
