import { BdsButton, BdsPaper, BdsSelect, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { FC, useContext, useEffect } from 'react';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { BlipTable } from '../../../components/BlipTable';
import SessionConsumptionContext from '../../../contexts/SessionConsumptionContext';
import {
  SessionConsumptionRow,
  SessionConsumptionPaper,
  SessionConsumptionSumUsagePaperRow,
  SessionConsumptionUsageStatementContent,
  SessionConsumptionHeaderPartial,
  SessionConsumptionHeaderFilter,
} from '../styles';
import { DateDropdown } from '../../../components/DateDropdown';
import { Link } from 'react-router-dom';
import { getMonthTwoDigits } from '../../../utils/date';
import { createTrack } from '../../../api/SegmentApi';
import {
  SESSION_CONSUMPTION_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK,
  SESSION_CONSUMPTION_PERIOD_SELECT_MONTHLY_OPENED,
  SESSION_CONSUMPTION_TYPE_SELECT_MONTHLY_OPENED,
  SESSION_CONSUMPTION_WABA_SELECT_MONTHLY_OPENED,
} from '../../../shared/SegmentEvents';

export interface SessionConsumptionMonthlyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const SessionConsumptionMonthlyUsageComponent: FC<SessionConsumptionMonthlyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    currentMonth,
    dateToSearch,
    content,
    tenant,
    loggedUser,
    sortSessionConsumptionMonthlyUsage,
    billingSessionConsumptionApi,
    sessionConsumptionMonthlyUsage,
    setSessionConsumptionMonthlyUsage,
    periodOptions,
    setDateToSearch,
    conversationTypeFilter,
    conversationWabaFilter,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    setConversationTypeFilter,
    setConversationWabaFilter,
  } = useContext(SessionConsumptionContext);

  const tableMonthlyUsageModel = [
    { label: content.chatbotSessionConsumption.columnPhoneNumber, key: 'phoneNumber' },
    { label: content.chatbotSessionConsumption.columnUserSession, key: 'sessionByUser' },
    { label: content.chatbotSessionConsumption.columnEnterpriseSession, key: 'sessionByCompany' },
  ];

  useEffect(() => {
    const getData = async () => {
      startLoading();

      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const sessionConsumptionMonthlyUsage = await billingSessionConsumptionApi.getMonthlySessionConsumption(
        tenant.info.id,
        monthString,
        registersToTake,
        conversationTypeFilter,
        conversationWabaFilter,
      );
      setSessionConsumptionMonthlyUsage(sessionConsumptionMonthlyUsage);

      stopLoading();
    };

    getData();
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  const trackSessionConsumptionMonthlyBalanceStatementDetail = async () => {
    createTrack(tenant.info, SESSION_CONSUMPTION_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, SESSION_CONSUMPTION_PERIOD_SELECT_MONTHLY_OPENED, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const wabaOptionChangeEvent = async (selectedOption) => {
    const conversationWabaValue = selectedOption.detail.value;
    setConversationWabaFilter(conversationWabaValue);
    createTrack(tenant.info, SESSION_CONSUMPTION_WABA_SELECT_MONTHLY_OPENED, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaValue,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const typeOptionChangeEvent = async (selectedOption) => {
    const conversationTypeValue = selectedOption.detail.value;
    setConversationTypeFilter(conversationTypeValue);
    createTrack(tenant.info, SESSION_CONSUMPTION_TYPE_SELECT_MONTHLY_OPENED, {
      sessionType: conversationTypeValue,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  return (
    <SessionConsumptionPaper>
      <SessionConsumptionRow>
        <BdsTypo className="title" no-wrap="true" tag="h3" variant="fs-24" bold="bold" margin={false}>
          {content.chatbotSessionConsumption.title}
          <BdsTypo className="subtitle" variant="fs-14">{content.chatbotSessionConsumption.about}</BdsTypo>
        </BdsTypo>
        {registersToTake > 0 ? (
          <Link to={{ pathname: '/old-session-consumption/monthly', state: { SessionConsumptionssDaily: true } }}>
            <BdsButton
              variant="ghost"
              arrow={true}
              size="short"
              onClick={trackSessionConsumptionMonthlyBalanceStatementDetail}
            >
              {content.chatbotSessionConsumption.buttonAllChatbots}
            </BdsButton>
          </Link>
        ) : (
          <SessionConsumptionHeaderPartial currentMonth={currentMonth}>
            {currentMonth && (
              <BdsPaper className="partialUsage" elevation="static">
                <BdsTypo variant="fs-12" bold="semi-bold">
                  {content.consolidated.partialUsage}
                </BdsTypo>
              </BdsPaper>
            )}
            <SessionConsumptionHeaderFilter>
              {conversationWabaFilterOptions.length > 0 && (
                <BdsSelect
                  disabled={conversationWabaFilterOptions.length === 1}
                  value={conversationWabaFilter}
                  label={content.conversationWabaLabel}
                  onBdsChange={wabaOptionChangeEvent}
                >
                  {conversationWabaFilterOptions}
                </BdsSelect>
              )}
              <BdsSelect
                value={conversationTypeFilter}
                label={content.conversationTypeLabel}
                onBdsChange={typeOptionChangeEvent}
              >
                {conversationTypeFilterOptions}
              </BdsSelect>
              <DateDropdown
                value={dateToSearch.toISOString()}
                bdsChangeEvent={periodOptionChangeEvent}
                options={periodOptions}
                label={content.monthPlaceholder}
              ></DateDropdown>
            </SessionConsumptionHeaderFilter>
          </SessionConsumptionHeaderPartial>
        )}
      </SessionConsumptionRow>
      <SessionConsumptionSumUsagePaperRow></SessionConsumptionSumUsagePaperRow>
      <SessionConsumptionUsageStatementContent>
        <BdsPaper>
          <BlipTable
            idKey="sessionConsumptions"
            model={tableMonthlyUsageModel}
            data={sessionConsumptionMonthlyUsage}
            selectedItems={[]}
            onSortSet={sortSessionConsumptionMonthlyUsage}
            canSelect={false}
            bodyHeight={tableBodyHeight}
          ></BlipTable>
        </BdsPaper>
      </SessionConsumptionUsageStatementContent>
    </SessionConsumptionPaper>
  );
};

export default SessionConsumptionMonthlyUsageComponent;
