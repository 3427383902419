export enum RequestStatus  {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum METHOD {
  GET = 'get',
  SET = 'set',
  MERGE = 'merge',
}