import CopilotPanel from './CopilotPanel';
import CopilotAd from './CopilotAd';
import {
  AI_SETTINGS_COPILOT,
} from '../../shared/FeatureToggleKeys';
import FeatureToggleService from '../../services/FeatureToggleService';
import { useEffect, useState } from 'react';
import { ApplicationApi } from 'api/ApplicationApi';
import { getConfiguration } from 'api/DeskConfigurationApi';
import { useCommon } from '../../contexts/CommonContext';
import { useTenant } from 'contexts/TenantContext';
import { applicationDomain } from 'shared/Domains';

function Copilot() {
  const {
    common: {
      config,
    },
  } = useCommon();

  const {
    tenant: { info },
  } = useTenant();
  
  const [isCopilotEnabled, setIsCopilotEnabled] = useState(false);
  const [configBots, setConfigBots] = useState([]);
  const { MSGING_DOMAIN } = config;

  useEffect(async () => {
    const deskConfig = await getBots();
    setConfigBots(deskConfig);

    const verifyIsCopilotEnabled = async () => {
      const isCopilotEnabled = await FeatureToggleService.isFeatureToggleEnabled(AI_SETTINGS_COPILOT);
      setIsCopilotEnabled(isCopilotEnabled || deskConfig.some(bot => bot.status)); 
    };
    
    verifyIsCopilotEnabled();
  }, []);


  const getBots = async () => {
    try {
      const { response: owners } = await ApplicationApi.sendApplicationRequest('getApplicationsByTenant', info.id);
      const configBots = await getFlagConfiguration(owners);
      return configBots;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getFlagConfiguration = async (owners = []) => {
    const updatedOwners = await owners.map(async (ownerIdentity) => {
      if (!ownerIdentity.hasPermission) {
        return { ...ownerIdentity, status: false, selected: false };
      }
      try {
        const { items } = await getConfiguration({
          owner: `${ownerIdentity.id}@${MSGING_DOMAIN}`,
          caller: applicationDomain.desk,
        });
        const copilotConfig = items.find((item) => item.name === 'TenantBotBlipCopilotEnabled');
        const status = copilotConfig ? copilotConfig.value === 'True' : false;
        return { ...ownerIdentity, status };
      } catch (error) {
        console.error(error);
        return { ...ownerIdentity, status: false, selected: false };
      }
    });
    return await Promise.all(updatedOwners);
  };

  return (
    <div>
      {isCopilotEnabled ? <CopilotPanel deskBots={configBots} /> : <CopilotAd copilotEnableClick={() => setIsCopilotEnabled(true)} />}
    </div>
  );
}

export default Copilot;
