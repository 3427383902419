import React from 'react';

import { PageTemplate } from './styles';

interface PageTemplateComponentProps {
  children: React.ReactNode;
}

const PageTemplateComponent: React.FC<PageTemplateComponentProps> = ({
  children,
}) => {
  return <>
    <PageTemplate>
      <div className="bp-card bp-card--left-arrow">
        {children}
      </div>
    </PageTemplate>
  </>;
};

export { PageTemplateComponent };
