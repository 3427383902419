import React, { useEffect } from 'react';
import { AgentComponent } from './AgentComponent';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { localization } from './localization';
import { useTenant } from 'contexts/TenantContext';
import { useCommon } from 'contexts/CommonContext';
import { startLoading, stopLoading } from 'api/CommonApi';

const AgentContainer = () => {
  const content = useContentLocalizer(localization);
  const { tenant } = useTenant();
  const {
    setHeaderContent,
    common: { loggedUser },
  } = useCommon();

  useEffect(() => {
    setHeaderContent({ redirect: '/', text: `${content.formatString(content.members)} ${tenant.info.name}` });

    return () => setHeaderContent({ redirect: null });
  }, [tenant.name]);

  useEffect(() => {
    startLoading();
    stopLoading();
  }, []);
  return (
    <AgentComponent
      loggedUser={loggedUser}
    />
  );
};

export { AgentContainer };
