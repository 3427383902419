import { formatPhoneNumberIntl } from 'react-phone-number-input';

export class OldMonthlySessionConsumption {
  phoneNumber!: string;
  sessionByUser!: number;
  sessionByCompany!: number;

  constructor(OldMonthlySessionConsumption: Partial<OldMonthlySessionConsumption>) {
    this.phoneNumber = '';
    this.sessionByUser = 0;
    this.sessionByCompany = 0;
    Object.assign(this, OldMonthlySessionConsumption);

    if (!this.phoneNumber.startsWith('+')) {
      this.phoneNumber = '+' + this.phoneNumber;
    }

    this.phoneNumber = formatPhoneNumberIntl(this.phoneNumber);
  }
}
