import { BdsButton, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { FC, useContext, useEffect } from 'react';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { BlipTable } from '../../../components/BlipTable';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { useCommon } from '../../../contexts/CommonContext';
import { getMonthTwoDigits } from '../../../utils/date';
import {
  DailyActiveUserRow,
  DailyActiveUserPaper,
  DailyActiveUserSumUsagePaperRow,
  DailyActiveUserUsageStatementContent,
  DailyActiveUserConsumptionConsolidatedHeaderPartial,
  DailyActiveUserConsumptionConsolidatedHeaderRow,
} from '../styles';
import { DateDropdown } from '../../../components/DateDropdown';
import { Link } from 'react-router-dom';
import {
  DAILY_ACTIVE_USERS_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK,
  DAILY_ACTIVE_USERS_PERIOD_SELECT_MONTHLY_OPENED,
} from '../../../shared/SegmentEvents';
import { createTrack } from '../../../api/SegmentApi';

export interface DailyActiveUserMonthlyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const DailyActiveUserMonthlyUsageComponent: FC<DailyActiveUserMonthlyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    currentMonth,
    dateToSearch,
    content,
    tenant,
    loggedUser,
    sortDailyActiveUserMonthlyUsage,
    setDailyActiveUserMonthlyUsage,
    dailyActiveUserMonthlyUsage,
    billingDailyActiveUserApi,
    periodOptions,
    setDateToSearch,
  } = useContext(DailyActiveUsersContext);
  const { setHeaderContent } = useCommon();

  const tableMonthlyUsageModel = [
    {
      label: content.chatbotDailyActiveUserConsuption.columnChatbot,
      key: 'botName',
      hasChildContent: true,
      childContentkey: 'botId',
    },
    { label: content.chatbotDailyActiveUserConsuption.columnDailyActiveUsers, key: 'total' },
  ];

  useEffect(() => {
    const getData = async () => {
      startLoading();

      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const dailyActiveUserMonthlyUsage = await billingDailyActiveUserApi.getDailyActiveUserMonthlyUsage(
        tenant.info.id,
        monthString,
        registersToTake,
      );

      setDailyActiveUserMonthlyUsage(dailyActiveUserMonthlyUsage);

      stopLoading();
    };

    getData();

    return () => setHeaderContent({ redirect: null });
  }, [dateToSearch]);

  const trackDauMonthlyBalanceStatementDetail = async () => {
    createTrack(tenant.info, DAILY_ACTIVE_USERS_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK, {
      dauDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      dauUser: loggedUser.email,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, DAILY_ACTIVE_USERS_PERIOD_SELECT_MONTHLY_OPENED, {
      dauDate: dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      dauUser: loggedUser.email,
    });
  };

  return (
    <DailyActiveUserPaper>
      <DailyActiveUserRow>
        <BdsTypo className='title' tag="h3" variant="fs-24" bold="bold" margin={false}>
          {content.chatbotDailyActiveUserConsuption.title}
          <BdsTypo variant="fs-14">{content.chatbotDailyActiveUserConsuption.about}</BdsTypo>
        </BdsTypo>
        {registersToTake > 0 ? (
          <Link to={{ pathname: '/daily-active-users/monthly', state: { DailyActiveUserssDaily: true } }}>
            <BdsButton variant="ghost" arrow={true} size="short" onClick={trackDauMonthlyBalanceStatementDetail}>
              {content.chatbotDailyActiveUserConsuption.buttonAllChatbots}
            </BdsButton>
          </Link>
        ) : (
          <>
            <DailyActiveUserConsumptionConsolidatedHeaderPartial>
              <DailyActiveUserConsumptionConsolidatedHeaderRow>
                {currentMonth && (
                  <BdsPaper className='partialUsage' elevation="static">
                    <BdsTypo variant="fs-12" bold="semi-bold">
                      {content.compiledDailyActiveUserInformation.partialUsage}
                    </BdsTypo>
                  </BdsPaper>
                )}
              </DailyActiveUserConsumptionConsolidatedHeaderRow>
            </DailyActiveUserConsumptionConsolidatedHeaderPartial>
            <DateDropdown
              value={dateToSearch.toISOString()}
              bdsChangeEvent={periodOptionChangeEvent}
              options={periodOptions}
              label={content.monthPlaceholder}
            ></DateDropdown>
          </>
        )}
      </DailyActiveUserRow>
      <DailyActiveUserSumUsagePaperRow></DailyActiveUserSumUsagePaperRow>
      <DailyActiveUserUsageStatementContent>
        <BdsPaper>
          <BlipTable
            idKey="dailyActiveUsers"
            model={tableMonthlyUsageModel}
            data={dailyActiveUserMonthlyUsage}
            selectedItems={[]}
            onSortSet={sortDailyActiveUserMonthlyUsage}
            canSelect={false}
            bodyHeight={tableBodyHeight}
          ></BlipTable>
        </BdsPaper>
      </DailyActiveUserUsageStatementContent>
    </DailyActiveUserPaper>
  );
};

export default DailyActiveUserMonthlyUsageComponent;
