export const localization = {
  pt: {
    name: 'Nome',
    email: 'Email',
    accept: 'Aceitar',
    delete: 'Excluir',
    emptyMessage: 'Não há solicitações pendentes',
    acceptMemberSuccess: 'Membro aprovado com sucesso!',
    acceptMemberError: 'Falha ao aprovar membro',
    rejectMemberSuccess: 'Membro rejeitado com sucesso!',
    rejectMemberError: 'Falha ao excluir membro',
    acceptMembersSuccess: 'Membros aprovados com sucesso!',
    acceptMembersError: 'Falha ao aprovar membro(s)',
    rejectMembersSuccess: 'Membros rejeitados com sucesso!',
    rejectMembersError: 'Falha ao excluir membro(s)',
    cancel: 'Cancelar',
    members: 'membro(s)',
    member: 'membro',
    deleteConfirmation: 'Tem certeza que deseja excluir',
    alert: {
      rejectUser: {
        title: 'Quer mesmo rejeitar o pedido?',
        body: 'Você perderá acesso a este e-mail e não poderá resgatá-lo no futuro, caso mude de ideia. Essa ação não pode ser desfeita, ok?',
        buttons: {          
          cancel: 'Voltar',
          confirm: 'Rejeitar',
        },
      },
      rejectMultipleUsers: {
        title: 'Quer mesmo rejeitar os pedidos?',
        body: 'Você perderá acesso a estes e-mails e não poderá resgatá-los no futuro, caso mude de ideia. Essa ação não pode ser desfeita, ok?',
        buttons: {          
          cancel: 'Voltar',
          confirm: 'Rejeitar',
        },
      },
    },
  },
  en: {
    name: 'Name',
    email: 'Email',
    accept: 'Accept',
    delete: 'Delete',
    emptyMessage: 'No pending requests',
    acceptMemberSuccess: 'Member successfully approved!',
    acceptMemberError: 'Failed to approve member',
    rejectMemberSuccess: 'Member successfully rejected!',
    rejectMemberError: 'Failed to delete member',
    acceptMembersSuccess: 'Members successfully approved!',
    acceptMembersError: 'Failed to approve member(s)',
    rejectMembersSuccess: 'Members successfully rejected!',
    rejectMembersError: 'Failed to delete member(s)',
    cancel: 'Cancel',
    members: 'member(s)',
    member: 'member',
    deleteConfirmation: 'Are you sure you want to delete',
    alert: {
      rejectUser: {
        title: 'Do you really want to reject this request?',
        body: 'By doing it, you lose your access to this e-mail and will not be able to redeem it, if you change your mind. This action cannot be undone, ok?',
        buttons: {
          cancel: 'Go back',
          confirm: 'Reject',
        },
      },
      rejectMultipleUsers: {
        title: 'Do you really want to reject these requests?',
        body: 'By doing it, you lose your access to these e-mails and will not be able to redeem them, if you change your mind. This action cannot be undone, ok?',
        buttons: {          
          cancel: 'Go back',
          confirm: 'Reject',
        },
      },
    },
  },
  es: {
    name: 'Nombre',
    email: 'Correo Electrónico',
    accept: 'Aceptar',
    delete: 'Eliminar',
    emptyMessage: 'No hay solicitudes pendientes',
    acceptMemberSuccess: '¡Miembro aprobado con éxito!',
    acceptMemberError: 'No se pudo aprobar al miembro',
    rejectMemberSuccess: '¡Miembro rechazado correctamente!',
    rejectMemberError: 'No se pudo borrar el miembro',
    acceptMembersSuccess: '¡Miembros aprobados con éxito!',
    acceptMembersError: 'Error al aprobar miembro(s)',
    rejectMembersSuccess: '¡Miembros rechazados con éxito!',
    rejectMembersError: 'Error al eliminar miembro(s)',
    cancel: 'Cancelar',
    members: 'miembro(s)',
    member: 'miembro',
    deleteConfirmation: 'Está seguro que quiere borrarlo',
    alert: {
      rejectUser: {
        title: '¿De verdad quiere rechazar la solicitud?',
        body: 'Perderá el acceso a este correo electrónico y no podrá canjearlo en el futuro si cambia de opinión. Esta acción no se puede deshacer, ¿de acuerdo?',
        buttons: {          
          cancel: 'Volver',
          confirm: 'Rechazar',
        },
      },
      rejectMultipleUsers: {
        title: '¿Realmente quiere rechazar solicitudes?',
        body: 'Perderá el acceso a estos correos electrónicos y no podrá canjearlos en el futuro si cambia de opinión. Esta acción no se puede deshacer, ¿de acuerdo?',
        buttons: {          
          cancel: 'Volver',
          confirm: 'Rechazar',
        },
      },
    },
  },
};
