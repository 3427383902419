import React from 'react';
import { BdsButtonIcon } from 'blip-ds/dist/blip-ds-react';
import { localization } from '../localization';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { useTenant } from 'contexts/TenantContext';
import { removeAgentContract, setEnableAgent } from 'api/AgentApi';
import { showToast, showAlert } from 'api/CommonApi';
import { createTrack } from 'api/SegmentApi';
import { ORGANIZATION_AGENTS_DELETE, ORGANIZATION_AGENTS_TRIGGERED_DISABLED } from 'shared/SegmentEvents';

export const remove = (item) => {
  const { userIdentity, creationDate, isEnabled, tenantId } = item;
  const {
    tenant: { agents, info },
    setTenantAgents,
  } = useTenant();

  const content = useContentLocalizer(localization);

  const confirmRemoveAgent = async () => {
    const { triggered } = agents.find((a) => a.userIdentity == userIdentity);
    if (triggered) {
      setAgentdisabled();
    } else {
      removeAgent(userIdentity);
    }
  };

  const removeAgent = async () => {
    const confirmDelete = await showAlert({
      ...content.deleteAgents,
      variant: 'delete',
      icon: 'trash',
    });

    if (!confirmDelete?.response) {
      return createTrack(info, ORGANIZATION_AGENTS_DELETE, {
        status: 'success',
        agent: userIdentity,
        buttonClick: 'cancel',
      });
    }

    try {
      await removeAgentContract(info.id, userIdentity);
      showToast({
        type: 'success',
        message: content.deleteAgentsSuccess,
      });
      createTrack(info, ORGANIZATION_AGENTS_DELETE, {
        status: 'success',
        agent: userIdentity,
        buttonClick: 'confirm',
      });
      setTenantAgents(agents.filter((agent) => agent.userIdentity !== userIdentity));
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.deleteAgentsError,
      });
      createTrack(info, ORGANIZATION_AGENTS_DELETE, {
        status: 'failed',
        agent: userIdentity,
        buttonClick: 'confirm',
      });
    }
  };

  const setAgentdisabled = async () => {
    const confirmDisabled = await showAlert({
      ...content.disabledTriggeredAgents,
      variant: 'warning',
      icon: 'warning',
    });
    if (!confirmDisabled.response) {
      return createTrack(info, ORGANIZATION_AGENTS_TRIGGERED_DISABLED, {
        buttonClick: 'cancel',
        status: 'success',
        agent: item.email,
        isEnabled: item.isEnabled,
      });
    }
    if (!isEnabled) {
      return;
    }
    try {
      item.isEnabled = !isEnabled;
      await setEnableAgent(tenantId, item);
      item.applications = '';
      const updateAgents = agents.map((attendant) => {
        return userIdentity === attendant.userIdentity ? item : attendant;
      });
      await setTenantAgents(updateAgents);
      showToast({
        type: 'success',
        message: content.disableAgentSuccess,
      });
      createTrack(info, ORGANIZATION_AGENTS_TRIGGERED_DISABLED, {
        buttonClick: 'confirm',
        status: 'success',
        agent: item.email,
        isEnabled: item.isEnabled,
      });
    } catch (error) {
      showToast({
        type: 'danger',
        message: content.diableAgentError,
      });
      createTrack(info, ORGANIZATION_AGENTS_TRIGGERED_DISABLED, {
        buttonClick: 'confirm',
        status: 'failed',
        agent: item.email,
        isEnabled: item.isEnabled,
      });
    }
  };

  return (
    <>
      {creationDate && (
            <BdsButtonIcon
              variant="secondary"
              icon="trash"
              size='small'
              key={userIdentity}
              name={userIdentity}
              className="button-option-delete pointer"
              onClick={(e) => {
                confirmRemoveAgent(e.target.name);
              }}
            >
              {content.delete}
            </BdsButtonIcon>
      )}
    </>
  );
};
