export const localization = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo: Conversas via WhatsApp',
    monthPlaceholder: 'Período',
    conversationTypeLabel: 'Conversas',
    conversationWabaLabel: 'WABA',
    title: 'Conversas via WhatsApp',
    plan: 'Plano {0}: Conversas',
    middleInfo: '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
    endInfo:
      'Os valores deste relatório são recebidos direto do WhatsApp e podem ser diferentes dos mostrados na fatura. Use o relatório apenas como estimativa do seu consumo.',
    consolidated: {
      tooltip: {
        allConversations:
          'O número de conversas por mensagens no WhatsApp entre empresa e usuário (pessoa física ou jurídica com quem a empresa está trocando mensagens). Uma conversa inclui todas as mensagens entregues em um período de 24 horas. Uma conversa começa quando a primeira mensagem da empresa é entregue e termina 24 horas depois. A primeira mensagem pode ser iniciada pela empresa (marketing, utilidade ou autenticação) ou pode ser uma resposta da empresa em até 24 horas do recebimento de uma mensagem de usuário (serviço).',
        billedConversations:
          'O número de conversas por mensagens de WhatsApp, entre empresas e usuários, que são cobradas. Usuário é a pessoa física ou jurídica com quem a empresa está trocando mensagens. Uma conversa inclui todas as mensagens trocadas dentro de um período de 24 horas, começando quando a primeira mensagem enviada pela empresa é entregue.',
        freeOfChargeConversations:
          'O número de conversas gratuitas, realizadas por mensagens no WhatsApp, entre empresa e usuários (pessoa física ou jurídica com quem a empresa está trocando mensagens). Uma conversa começa quando a primeira mensagem da empresa é entregue e termina 24 horas depois. A exceção são as conversas com ponto de entrada gratuito, que têm um período de 72 horas.',
      },
      sessionsByUser: 'Conversas iniciadas pelo usuário',
      sessionsByCompany: 'Conversas iniciadas pela empresa',
      sessionsTotal: 'Total de conversas',
      conversationTypeCategory: {
        newPricing: {
          subTitle: 'Dados depois da alteração de categorias',
          marketing: 'Marketing',
          utility: 'Utilidade',
          authentication: 'Autenticação',
          service: 'Serviço',
          all: {
            title: 'Todas as conversas',
          },
          regular: {
            title: 'Conversas cobradas',
          },
          free: {
            tooltip: {
              freeTier: 'As primeiras conversas gratuitas de cada mês por WABA (WhatsApp Business Account)',
              freeEntryPoint:
                'Conversas iniciadas em pontos específicos, como anúncios direcionados ao WhatsApp e CTAs via Facebook. Nesses casos, apenas a primeira conversa iniciada pelo usuário é gratuita',
            },
            title: 'Conversas gratuitas',
            freeTier: 'Free Tier',
            freeEntryPoint: 'Free entry points',
          },
        },
        oldPricing: {
          title: 'Dados antes da alteração de categorias',
          sessionsByUser: 'Conversas iniciadas pelo usuário',
          sessionsByCompany: 'Conversas iniciadas pela empresa',
        },
      },
    },
    defaultConversationTypeValue: 'All',
    conversationType: [
      { value: 'All', label: 'Todas as conversas' },
      { value: 'FreeTier', label: 'Conversas grátis' },
      { value: 'Regular', label: 'Conversas cobradas' },
    ],
    chatbotSessionConsumption: {
      title: 'Números de telefone',
      about: 'Acompanhe o consumo de conversas.',
      columnPhoneNumber: 'Telefone',
      columnUserSession: 'Conversa iniciada pelo usuário',
      columnEnterpriseSession: 'Conversa iniciada pela empresa',
      columnTotal: 'Total',
      buttonAllChatbots: 'Mostrar detalhes',
    },

    sessionDailyConsumption: {
      title: 'Contagem diária de conversas',
      about: 'Acompanhe o consumo diário de conversas.',
      columnDateReference: 'Data',
      columnUserSession: 'Conversa iniciada pelo usuário',
      columnBusinessSession: 'Conversa iniciada pela empresa',
      columnTotal: 'Total',
      buttonAllChatbots: 'Mostrar detalhes',
    },

    sessionConsumptionByPhone: {
      title: 'Números de telefone',
      about: 'Acompanhe o consumo de conversas.',
      columnPhoneNumber: 'Telefone',
      businessInitiated: {
        bannerText:
          'Importante! Conversas iniciadas pela empresa agora são classificadas pela Meta em 3 tipos: Utilidade, Autenticação ou Marketing.',
        bannerLinkText: 'Mais Informações',
        tab: 'Conversas iniciadas pela Empresa',
        columnUtility: 'Utilidade',
        columnAuthentication: 'Autenticação',
        columnMarketing: 'Marketing',
      },
      userInitiated: {
        bannerText:
          'Importante! As conversas iniciadas pelo usuário são classificadas pela Meta como conversas de Serviço.',
        bannerLinkText: 'Mais Informações',
        tab: 'Conversas iniciadas pelo Usuário',
        service: 'Serviços',
        free: 'Gratuitas',
      },
      columnTotal: 'Total',
    },

    sessionConsumptionByDate: {
      title: 'Números de telefone',
      about: 'Acompanhe o consumo de conversas.',
      columnDateReference: 'Data',
      businessInitiated: {
        bannerText:
          'Importante! Conversas iniciadas pela empresa agora são classificadas pela Meta em 3 tipos: Utilidade, Autenticação ou Marketing.',
        bannerLinkText: 'Mais Informações',
        tab: 'Conversas iniciadas pela Empresa',
        columnUtility: 'Utilidade',
        columnAuthentication: 'Autenticação',
        columnMarketing: 'Marketing',
      },
      userInitiated: {
        bannerText:
          'Importante! As conversas iniciadas pelo usuário são classificadas pela Meta como conversas de Serviço.',
        bannerLinkText: 'Mais Informações',
        tab: 'Conversas iniciadas pelo Usuário',
        service: 'Serviços',
        free: 'Gratuitas',
      },
      columnTotal: 'Total',
    },
  },
  en: {
    locale: 'en-US',
    header: 'Usage report: Conversations via WhatsApp',
    monthPlaceholder: 'Period',
    conversationTypeLabel: 'Conversation',
    conversationWabaLabel: 'WABA',
    title: 'Conversations via WhatsApp',
    plan: '{0}: Conversation',
    middleInfo: '*Monthly statements will become available within 5 business days of month end.',
    endInfo:
      'The numbers in this report come directly from WhatsApp and may differ from the numbers shown on the invoice. It is best to use the report only as an estimate of your consumption.',
    consolidated: {
      tooltip: {
        allConversations:
          "The number of WhatsApp conversations between company and users (defined as an individual or legal entity with whom a company is exchanging messages). A conversation includes all messages delivered within a 24-hour period. A conversation starts when the first message from a company is delivered, and ends 24 hours later. The first message can be a company-initiated conversation (marketing, utility, or authentication) or a reply from the company within the 24-hour period of receiving a user's message (service).",
        billedConversations:
          'Charged WhatsApp conversations between company and users. A conversation includes all messages exchanged within a 24-hour period, starting when the first message sent by the company is delivered.',
        freeOfChargeConversations:
          'The number of free of charge conversations between company and users (defined as an individual or legal entity with whom the company is exchanging messages) via WhatsApp messages. A conversation starts when the first message from the company is delivered and ends 24 hours later. The exception is the free entry point conversation (with 72-hour window).',
      },
      sessionsByUser: 'User-initiated conversations',
      sessionsByCompany: 'Company-initiated conversations',
      sessionsTotal: 'Total of conversations',
      conversationTypeCategory: {
        newPricing: {
          subTitle: 'Data after category changes',
          marketing: 'Marketing',
          utility: 'Utility',
          authentication: 'Authentication',
          service: 'Service',
          all: {
            title: 'See all conversations',
          },
          regular: {
            title: 'Billed conversations',
          },
          free: {
            tooltip: {
              freeTier: 'The first free conversations each month per WABA (WhatsApp Business Account)',
              freeEntryPoint:
                'Conversations initiated at specific points, such as WhatsApp ads and Facebook CTAs. In such cases, only the first user-initiated conversation is free',
            },
            title: 'Free-of-charge conversations',
            freeTier: 'Free Tier',
            freeEntryPoint: 'Free entry points',
          },
        },
        oldPricing: {
          title: 'Data before category changes',
          sessionsByUser: 'User-initiated ',
          sessionsByCompany: 'Company-initiated ',
          total: 'Total of conversations',
        },
      },
    },
    defaultConversationTypeValue: 'All',
    conversationType: [
      { value: 'All', label: 'See all conversations' },
      { value: 'FreeTier', label: 'Free-of-charge conversations' },
      { value: 'Regular', label: 'Billed conversations' },
    ],
    chatbotSessionConsumption: {
      title: 'Phone number',
      about: 'Track conversation consumption.',
      columnPhoneNumber: 'Phone number',
      columnUserSession: 'User-initiated conversation',
      columnEnterpriseSession: 'Company-initiated conversation',
      columnTotal: 'Total',
      buttonAllChatbots: 'Show details',
    },
    sessionDailyConsumption: {
      title: 'Daily conversation count',
      about: 'Track daily conversation consumption.',
      columnDateReference: 'Date',
      columnUserSession: 'User-initiated conversation',
      columnBusinessSession: 'Company-initiated conversation',
      columnTotal: 'Total',
      buttonAllChatbots: 'Show details',
    },
    sessionConsumptionByPhone: {
      title: 'Phone number',
      about: 'Track conversation consumption.',
      columnPhoneNumber: 'Phone number',
      businessInitiated: {
        bannerText:
          'Important! Business-initiated conversations fall into 3 types, according to Meta: Utility, Authentication, and Marketing.',
        bannerLinkText: 'More Information',
        tab: 'Business-initiated conversations',
        columnUtility: 'Utility',
        columnAuthentication: 'Authentication',
        columnMarketing: 'Marketing',
      },
      userInitiated: {
        bannerText:
          "Important! User-initiated conversations are classified by Meta as Service.",
        bannerLinkText: 'More Information',
        tab: 'User-initiated conversations',
        service: 'Service',
        free: 'Free',
      },
      columnTotal: 'Total',
    },

    sessionConsumptionByDate: {
      title: 'Daily conversation count',
      about: 'Track daily conversation consumption.',
      columnDateReference: 'Date',
      businessInitiated: {
        bannerText:
          'Important! Business-initiated conversations fall into 3 types, according to Meta: Utility, Authentication, and Marketing.',
        bannerLinkText: 'More Information',
        tab: 'Business-initiated conversations',
        columnUtility: 'Utility',
        columnAuthentication: 'Authentication',
        columnMarketing: 'Marketing',
      },
      userInitiated: {
        bannerText:
          "Important! User-initiated conversations are classified by Meta as Service.",
        bannerLinkText: 'More Information',
        tab: 'User-initiated conversations',
        service: 'Service',
        free: 'Free',
      },
      columnTotal: 'Total',
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo: Conversaciones vía WhatsApp',
    monthPlaceholder: 'Período',
    conversationTypeLabel: 'Conversaciones',
    conversationWabaLabel: 'WABA',
    title: 'Conversaciones vía WhatsApp',
    plan: 'Plano {0}: Conversaciones',
    middleInfo:
      '*El cierre de un mes estará disponible dentro de los 5 días hábiles a partir del último día de ese mes.',
    endInfo:
      'Los importes de este informe se reciben directamente de WhatsApp y pueden diferir de los que se muestran en la factura. Utilice este informe solo como una estimación de su consumo.',
    consolidated: {
      tooltip: {
        allConversations:
          'La cantidad de conversaciones de WhatsApp entre la empresa y los usuarios (definidos como una persona física o jurídica con la que una empresa intercambia mensajes). Una conversación incluye todos los mensajes entregados en un período de 24 horas. Una conversación comienza cuando se entrega el primer mensaje de una empresa y finaliza 24 horas después. El primer mensaje puede ser una conversación iniciada por la empresa (marketing, utilidad o autenticación) o una respuesta de la empresa dentro del período de 24 horas de recibir el mensaje de un usuario (servicio).',
        billedConversations:
          'Conversaciones via WhatsApp, entre empresa y usuarios, que se cobran. Una conversación incluye todos los mensajes intercambiados en un período de 24 horas, a partir de la entrega del primer mensaje enviado por la empresa.',
        freeOfChargeConversations:
          'La cantidad de conversaciones gratuitas entre la empresa y los usuarios (definidos como una persona física o jurídica con la que la empresa está intercambiando mensajes) a través de mensajes de WhatsApp. Una conversación comienza cuando se entrega el primer mensaje de la empresa y finaliza 24 horas después. La excepción es la conversación de punto de entrada libre (con intervalo de 72 horas).',
      },
      sessionsByUser: 'Conversaciones iniciadas por el usuario',
      sessionsByCompany: 'Conversaciones iniciadas por la empresa',
      sessionsTotal: 'Total de conversaciones',
      conversationTypeCategory: {
        newPricing: {
          subTitle: 'Datos después de cambiar de categoría',
          marketing: 'Marketing',
          utility: 'Utilidad',
          authentication: 'Autenticación',
          service: 'Servicio',
          all: {
            title: 'Todas las conversaciones',
          },
          regular: {
            title: 'Conversaciones cobradas',
          },
          free: {
            tooltip: {
              freeTier: 'Las primeras conversaciones gratuitas cada mes por WABA (WhatsApp Business Account)',
              freeEntryPoint:
                'Conversaciones iniciadas en puntos específicos, como anuncios de WhatsApp y CTA de Facebook. En estos casos, solo la primera conversación iniciada por el usuario es gratuita',
            },
            title: 'Conversaciones gratis',
            freeTier: 'Free Tier',
            freeEntryPoint: 'Free entry points',
          },
        },
        oldPricing: {
          title: 'Datos antes de cambiar de categoría',
          sessionsByUser: 'Iniciadas por el usuario',
          sessionsByCompany: 'Iniciadas por la empresa',
          total: 'Total de conversaciones',
        },
      },
    },
    defaultConversationTypeValue: 'All',
    conversationType: [
      { value: 'All', label: 'Todas las conversaciones' },
      { value: 'FreeTier', label: 'Conversaciones gratis' },
      { value: 'Regular', label: 'Conversaciones cobradas' },
    ],
    chatbotSessionConsumption: {
      title: 'Número de teléfono',
      about: 'Seguimiento del consumo de conversaciones.',
      columnPhoneNumber: 'Número de teléfono',
      columnUserSession: 'Conversación iniciada por el usuario',
      columnEnterpriseSession: 'Conversación iniciada por la empresa',
      columnTotal: 'Total',
      buttonAllChatbots: 'Mostrar detalles',
    },
    sessionDailyConsumption: {
      title: 'Recuento de conversaciones diarias',
      about: 'Rastree el consumo diario de conversaciones.',
      columnDateReference: 'Fecha',
      columnUserSession: 'Conversación iniciada por el usuario',
      columnBusinessSession: 'Conversación iniciada por la empresa',
      columnTotal: 'Total',
      buttonAllChatbots: 'Mostrar detalles',
    },
    sessionConsumptionByPhone: {
      title: 'Número de teléfono',
      about: 'Seguimiento del consumo de conversaciones.',
      columnPhoneNumber: 'Número de teléfono',
      businessInitiated: {
        bannerText:
          '¡Importante! Las conversaciones iniciadas por negocios se dividen en 3 grupos, según Meta: Utilidad, Autenticación y Marketing.',
        bannerLinkText: 'Mas informaciones',
        tab: 'Conversaciones iniciadas por Negocios',
        columnUtility: 'Utilidad',
        columnAuthentication: 'Autenticación',
        columnMarketing: 'Marketing',
      },
      userInitiated: {
        bannerText:
          '¡Importante! Las conversaciones iniciadas por el usuario se clasifican por Meta como conversaciones de Servicio.',
        bannerLinkText: 'Mas informaciones',
        tab: 'Conversaciones iniciadas por el Usuario',
        service: 'Servicio',
        free: 'Gratuitas',
      },
      columnTotal: 'Total',
    },

    sessionConsumptionByDate: {
      title: 'Daily conversation count',
      about: 'Track daily conversation consumption.',
      columnDateReference: 'Date',
      businessInitiated: {
        bannerText:
          '¡Importante! Las conversaciones iniciadas por negocios se dividen en 3 grupos, según Meta: Utilidad, Autenticación y Marketing.',
        bannerLinkText: 'Mas informaciones',
        tab: 'Conversaciones iniciadas por Negocios',
        columnUtility: 'Utilidad',
        columnAuthentication: 'Autenticación',
        columnMarketing: 'Marketing',
      },
      userInitiated: {
        bannerText:
          '¡Importante! Las conversaciones iniciadas por el usuario se clasifican por Meta como conversaciones de Servicio.',
        bannerLinkText: 'Mas informaciones',
        tab: 'Conversaciones iniciadas por el Usuario',
        service: 'Servicio',
        free: 'Gratuitas',
      },
      columnTotal: 'Total',
    },
  },
};
