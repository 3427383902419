import React, { useState } from 'react';
import { BdsSwitch } from 'blip-ds/dist/blip-ds-react';
import { localization } from '../localization';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { useTenant } from 'contexts/TenantContext';
import { setEnableAgent } from 'api/AgentApi';
import { showToast, showAlert } from 'api/CommonApi';
import { createTrack } from 'api/SegmentApi';
import { ORGANIZATION_AGENTS_ENABLED } from 'shared/SegmentEvents';
import { verifyAgentBillingAdditional, agentTriggered } from './utils';
import { ModalAgentBillingMessage } from '../Modal';

export const actionAgentEnabled = (agent) => {
  const {
    tenant: { agents, info, subscription },
    setTenantAgents,
  } = useTenant();
  const plan = info.plan;
  const content = useContentLocalizer(localization);
  const [showModalBilling, setShowModalBilling] = useState(false);
  const { userIdentity, creationDate, isEnabled } = agent;

  const toggleEnabledAgent = async () => {
    agent.isEnabled = !agent.isEnabled;
    const updateAgents = agents.map((attendant) => {
      return userIdentity === attendant.userIdentity ? agent : attendant;
    });
    await setTenantAgents(updateAgents);
  };

  const confirmAgentEnable = async (e) => {
    const { checked } = e.target;
    if (agent.isEnabled === checked) {
      return;
    }
    await toggleEnabledAgent();
    if (agent.isEnabled && verifyAgentBillingAdditional(agents, subscription) && !agent.triggered) {
      setShowModalBilling(true);
    } else {
      setAgentEnable();
    }
  };

  const setAgentEnable = async () => {
    if (creationDate === undefined) {
      return;
    }
    try {
      await setEnableAgent(agent.tenantId, agent);
      const successMessage = agent.isEnabled ? content.enableAgentSuccess : content.disableAgentSuccess;
      showToast({
        type: 'success',
        message: successMessage,
      });
      createTrack(info, ORGANIZATION_AGENTS_ENABLED, {
        status: 'success',
        agent: agent.email,
        isEnabled: agent.isEnabled,
      });
    } catch (e) {
      await toggleEnabledAgent();
      const errorMessage = agent.isEnabled ? content.enableAgentError : content.diableAgentError;
      showToast({
        type: 'danger',
        message: errorMessage,
      });
      createTrack(info, ORGANIZATION_AGENTS_ENABLED, {
        status: 'failed',
        agent: agent.email,
        isEnabled: agent.isEnabled,
      });
    } finally {
      setShowModalBilling(false);
    }
  };

  const closeModalBilling = async () => {
    setShowModalBilling(false);
    toggleEnabledAgent();
  };
  
  return (
    <>
      {creationDate && (
        <div className="flex items-center pointer trucante" onClick={(e) => confirmAgentEnable(e)}>
          <BdsSwitch key={userIdentity} name={userIdentity} checked={isEnabled} refer={isEnabled} />
          <p className={`ml2 truncate ${isEnabled ? ' bold' : 'disabled bold'} `}>
            {isEnabled ? content.enabled : content.disabled}
          </p>
        </div>
      )}
      <ModalAgentBillingMessage
        open={showModalBilling}
        content={content}
        quantityAgents={agentTriggered(agents)}
        subscription={subscription}
        planName={plan}
        onClick={setAgentEnable}
        close={closeModalBilling}
      />
    </>
  );
};
