import styled from 'styled-components';

export const BlipLoadingContainer = styled.div`
  display: flex;
  align-content: space-around;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  align-items: center;
  width: 100%;
  height: 100%;
`;
