import axios from 'axios';

interface Configurations {
  PORTAL_DOMAIN: string;
  LD_CLIENT_SDK_KEY: string;
  LD_PROXY_BASE_URL: string;
  LD_PROXY_EVENTS_URL: string;
  LD_PROXY_CLIENT_STREAM: string;
  LD_STREAM_RECONNECT_DELAY: number;
  REVENUE_START_DATE: string;
  EVENT_RECEIVER_REDIRECT: boolean;
  BLIP_DOMAIN: string;
  MSGING_DOMAIN: string;
  TENANT_AGENTS_PAGE_SIZE: number;
  WHATSAPP_BP_PRICING_CHANGES_URL: string;
  WHATSAPP_BP_PRICING_CHANGES_START_DATE: string;
  WHATSAPP_BP_PRICING_CHANGES_CONSOLIDATED_START_DATE: string;
}
export const getAppSettings = async (): Promise<Configurations> => {
  const { data } = await axios.get('/appSettings.json');
  return data;
};
