import React, { createContext } from 'react';
import { BillingMetrics } from '../../shared/BillingMetrics';

interface BillingMetricsContextData {
  isMetricAllowed: (value: BillingMetrics) => boolean;
  hasEnabledMetric: (value: Array<BillingMetrics>) => boolean;
}

interface props {
  tenant: any;
}

export const BillingMetricsContext = createContext<BillingMetricsContextData>({} as BillingMetricsContextData);

export const BillingMetricsContextProvider: React.FC<props> = ({ children, tenant }) => {
  let privateRouteMetrics: any[] = [];
  tenant.privateRoutes.map((privateRoute) => {
    if (privateRoute.option !== 'dataConsumption') {
      privateRouteMetrics = [...privateRouteMetrics, ...privateRoute.metrics];
    }
  });

  const hasEnabledMetric = (metricIds: Array<BillingMetrics>): boolean =>
    privateRouteMetrics.some((privateRouteMetric) => metricIds.some((metricId) => privateRouteMetric === metricId));

  const isMetricAllowed = (metricId: BillingMetrics): boolean => {
    return privateRouteMetrics.some((allowedMetric) => allowedMetric === metricId);
  };

  return (
    <BillingMetricsContext.Provider
      value={{
        isMetricAllowed,
        hasEnabledMetric,
      }}
    >
      {children}
    </BillingMetricsContext.Provider>
  );
};

export function useBillingMetricsContext(): BillingMetricsContextData {
  const context = React.useContext(BillingMetricsContext);

  if (!context) {
    throw new Error('use billingmetricscontext context must be used within an BillingMetricsContextProvider');
  }

  return context;
}
