import { IframeMessageProxy } from 'iframe-message-proxy';

export const startLoading = () => IframeMessageProxy.sendMessage({ action: 'startLoading' });

export const stopLoading = () => IframeMessageProxy.sendMessage({ action: 'stopLoading' });

export const setHeight = (height) => IframeMessageProxy.sendMessage({ action: 'heightChange', content: height });

export const stateChange = (pathName) =>
  IframeMessageProxy.sendMessage({ action: 'stateChangeSuccess', content: { toUrl: pathName } });

export const redirectToState = (stateName, params) =>
  IframeMessageProxy.sendMessage({
    action: 'stateChangeSuccess',
    content: { to: stateName, type: 'stateChangeSuccess', toParams: params },
  });

export const unauthorizedAccess = () => IframeMessageProxy.sendMessage({ action: 'unauthorizedAccess' });

export const showTenantModal = (template) =>
  IframeMessageProxy.sendMessage({ action: 'Tenant:showModal', content: { template } });

/**
 *
 * @param {Object - { type: 'success' | 'danger' | 'warning' | 'info', message: string}} toast
 */
export const showToast = (toast) => IframeMessageProxy.sendMessage({ action: 'toast', content: toast });

export const showConfirmModal = ({ title, body, confirm, cancel }) =>
  IframeMessageProxy.sendMessage({ action: 'showModal', content: { title, body, confirm, cancel } });

export const showUploadErrorToast = (statusCode) =>
  IframeMessageProxy.sendMessage({ action: 'showUploadErrorToast', content: statusCode });

export const showAlert = (options) => IframeMessageProxy.sendMessage({ action: 'showAlert', content: options });

export const redirectToApplicationList = () => {
  return IframeMessageProxy.sendMessage({
    action: 'redirectProcessor',
    content: {
      action: 'redirectToApplicationList',
    },
  });
};

export const redirectToSubscriptionNotificationPayment = (origin) => {
  return IframeMessageProxy.sendMessage({
    action: 'redirectProcessor',
    content: {
      action: 'redirectToSubscriptionNotificationPayment',
      params: origin,
    },
  });
};

export const getTenantUrl = (tenantId) =>
  IframeMessageProxy.sendMessage({ action: 'getTenantUrl', content: { tenantId } });

export const RESOURE_NOT_FOUND_ERROR_CODE = 67;

export const CommonApi = {
  startLoading,
  stopLoading,
  setHeight,
  stateChange,
  showTenantModal,
  showToast,
  showUploadErrorToast,
};
