export const localization = {
  pt: {
    asideMenu: {
      dataConsumption: {
        title: 'Visão geral: Relatório de consumo',
        subtitle: 'Acompanhe o consumo do seu plano',
      },
      dauConsumption: {
        title: 'Consumo de conversas únicas',
        subtitle: 'Veja os dados de consumo de conversas únicas (antes usuários ativos diários)',
      },
      agentConsumption: {
        title: 'Consumo de atendentes',
        subtitle: 'Veja os dados de consumo de atendentes acionados',
      },
      sessionConsumption: {
        title: 'Consumo de conversas via WhatsApp',
        subtitle: 'Veja os dados de consumo de conversas via WhatsApp',
      },
      mauMsgConsumption: {
        title: 'Consumo de MAUs e Mensagens',
        subtitle: 'Visualize os dados de consumo de usuários ativos mensais e mensagens',
      },
      meuMsgConsumption: {
        title: 'Consumo de MEUs e Mensagens',
        subtitle: 'Visualize os dados de consumo de usuários engajados mensais e mensagens',
      },
      mauConsumption: {
        title: 'Consumo de MAUs',
        subtitle: 'Visualize os dados de consumo de usuários ativos mensais',
      },
      meuConsumption: {
        title: 'Consumo de MEUs',
        subtitle: 'Visualize os dados de consumo de usuários engajados mensais',
      },
      msgConsumption: {
        title: 'Consumo de Mensagens',
        subtitle: 'Visualize os dados de consumo de mensagens',
      },
    },
  },
  en: {
    asideMenu: {
      dataConsumption: {
        title: 'Overview: Usage report',
        subtitle: 'Track your plan\'s usage information',
      },
      dauConsumption: {
        title: 'Usage per single conversations',
        subtitle: 'See consumption data based on single conversations (formerly daily active users)',
      },
      agentConsumption: {
        title: 'Usage per agents',
        subtitle: 'Check your usage details per activated agents',
      },
      sessionConsumption: {
        title: 'Usage per conversation via WhatsApp',
        subtitle: 'Check your usage details per WhatsApp conversation',
      },
      mauMsgConsumption: {
        title: 'Usage per MAUs and Messages',
        subtitle: 'Check your usage details per monthly active users and messages',
      },
      meuMsgConsumption: {
        title: 'Usage per MEUs and Messages',
        subtitle: 'Check your usage details per monthly active users and messages',
      },
      mauConsumption: {
        title: 'Usage per MAUs',
        subtitle: 'Check your usage details per monthly active users',
      },
      meuConsumption: {
        title: 'Usage per MEUs',
        subtitle: 'Check your usage details per monthly active users',
      },
      msgConsumption: {
        title: 'Usage per Messages',
        subtitle: 'Check your usage details per messages',
      },
    },
  },
  es: {
    asideMenu: {
      dataConsumption: {
        title: 'Visión general: Reporte de consumo',
        subtitle: 'Sigue el consumo de tu plan',
      },
      dauConsumption: {
        title: 'Consumo de conversaciones únicas',
        subtitle: 'Ver datos de consumo basados en conversaciones únicas (anteriormente usuarios activos diarios)',
      },
      agentConsumption: {
        title: 'Consumo de asistentes',
        subtitle: 'Ver los datos de consumo de asistentes activados',
      },
      sessionConsumption: {
        title: 'Consumo de conversaciones vía WhatsApp',
        subtitle: 'Ver los datos de consumo de conversaciones vía WhatsApp',
      },
      mauMsgConsumption: {
        title: 'Consumo de MAUs y Mensajes',
        subtitle: 'Ver los datos de consumo de usuarios activos mensuales y mensajes',
      },
      meuMsgConsumption: {
        title: 'Consumo de MEUs y Mensajes',
        subtitle: 'Ver los datos de consumo de usuarios engajados mensuales y mensajes',
      },
      mauConsumption: {
        title: 'Consumo de MAUs',
        subtitle: 'Ver los datos de consumo de usuarios activos mensuales',
      },      
      meuConsumption: {
        title: 'Consumo de MEUs',
        subtitle: 'Ver los datos de consumo de usuarios engajados mensuales',
      },
      msgConsumption: {
        title: 'Consumo de Mensajes',
        subtitle: 'Visualize os dados de consumo de Mensajes',
      },
    },
  },
};