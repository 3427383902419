import React, { useContext, useEffect } from 'react';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersPaper, CompiledDailyActiveUsersRow } from '../styles';
import CompiledDailyActiveUserTitle from './CompiledDailyActiveUserTitle';
import CompiledDailyActiveUserProgressContent from './CompiledDailyActiveUserProgressContent';
import CompiledDailyActiveUserOverspentContent from './CompiledDailyActiveUserOverspentContent';
import CompiledDailyActiveUserTotalContent from './CompiledDailyActiveUserTotalContent';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { origin, REPORT_PERIOD_SELECT } from '../../../../shared/SegmentEvents';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { useDauConsumptionContext } from '../../../../contexts/Billing/DauConsumptionContext';
import { BlipLoadingComponent } from '../../../../components/BlipLoading/BlipLoadingComponent';

const CompiledDailyActiveUsersComponent: React.FC = () => {
  const {
    content,
    dateToSearch,
    tenant,
    trackingProps,
    loggedUser,
    getPlanStatus,
    periodOptions,
    setDateToSearch,
    isMetricAllowed,
  } = useContext(DailyActiveUsersContext);
  const { compiledDailyActiveUserDataState, isDauLoading, consolidatedDauApiRequisition } = useDauConsumptionContext();
  const exceededPlan = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.exceededPlan;

  useEffect(() => {
    consolidatedDauApiRequisition(dateToSearch, isMetricAllowed);
  }, [dateToSearch]);


  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.DAU,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  return (
    <>
      <PageHeaderComponent
        title={content.title}
        dateToSearch={dateToSearch}
        periodOptions={periodOptions}
        periodOptionChangeEvent={periodOptionChangeEvent}
      />
      <CompiledDailyActiveUsersPaper>
        <div>
          <CompiledDailyActiveUserTitle></CompiledDailyActiveUserTitle>
          <CompiledDailyActiveUsersRow>
            {isDauLoading ? (
              <BlipLoadingComponent />
            ) : (
              <>
                <CompiledDailyActiveUserProgressContent></CompiledDailyActiveUserProgressContent>
                {exceededPlan > 0 && (
                  <CompiledDailyActiveUserOverspentContent></CompiledDailyActiveUserOverspentContent>
                )}
                <CompiledDailyActiveUserTotalContent></CompiledDailyActiveUserTotalContent>
              </>
            )}
          </CompiledDailyActiveUsersRow>
        </div>
      </CompiledDailyActiveUsersPaper>
    </>
  );
};

export default CompiledDailyActiveUsersComponent;
