export class MonthlyAgentConsumption {
  agentEmail!: string;
  agentName!: string;
  agentPhotoUri!: string;
  extraCharge!: boolean;
  totalTickets!: number;
  firstTicketDate!: string;
  lastTicketDate!: string;

  constructor(monthlyAgentConsumption: Partial<MonthlyAgentConsumption>) {
    this.agentEmail = '';
    this.agentName = '';
    this.agentPhotoUri = '';
    this.extraCharge = false;
    this.totalTickets = 0;
    this.firstTicketDate = '';
    this.lastTicketDate = '';
    Object.assign(this, monthlyAgentConsumption);
  }
}
