import { IframeMessageProxy } from 'iframe-message-proxy';
import { BlipApi } from './BlipApi';
import axios from 'axios';
import { getFileBinary } from 'utils/fileUtil';
import { RESOURE_NOT_FOUND_ERROR_CODE } from './CommonApi';
import { Tenant, TenantMember } from 'interfaces';
import { hasPermissions } from 'services/PermissionService';

const TENANT_USERS_PAGE_SIZE = 100;

export const getTenant = async (): Promise<Tenant> => {
  const postMessage = await IframeMessageProxy.sendMessage({
    action: 'getUserContext',
    content: { command: 'getCurrentTenant' },
  });
  return postMessage.response;
};

export const getTenantMember = async ({ tenantId, userIdentity }) => {
  const postMessage = await BlipApi.sendCommand({
    to: 'postmaster@portal.blip.ai',
    method: 'get',
    uri: `/tenants/${tenantId}/users/${encodeURIComponent(userIdentity)}`,
  });
  return postMessage.response;
};

export const getTenantMembers = async (tenantId: string, page = 1) => {
  const postMessage = await BlipApi.sendCommand({
    to: 'postmaster@portal.blip.ai',
    method: 'get',
    uri: `/tenants/${tenantId}/users?$skip=${(page - 1) * TENANT_USERS_PAGE_SIZE}&$take=${TENANT_USERS_PAGE_SIZE}`,
  });

  let members = postMessage.response.items;

  members = Promise.all(
    members.map(async (member) => {
      try {
        const userData = await BlipApi.sendCommand({
          to: 'postmaster@blip.ai',
          method: 'get',
          uri: `lime://blip.ai/accounts/${encodeURIComponent(member.userIdentity.split('@')[0])}`,
        });
        member = { ...member, ...userData.response };
        return member;
      } catch (e) {
        member = {
          ...member,
          fullName: member.fullName ? member.fullName : decodeURIComponent(member.userIdentity.split('@')[0]),
          email: decodeURIComponent(member.userIdentity.split('@')[0]),
        };
        return member;
      }
    }),
  );

  return members;
};

export const getAllTenantMembers = async (tenantId) => {
  let page = 1;
  let members: TenantMember[] = [];
  try {
    do {
      const pageMembers = await getTenantMembers(tenantId, page);
      members = [...members, ...pageMembers];
      page++;
    } while (members.length % TENANT_USERS_PAGE_SIZE === 0);

    return members;
  } catch (e) {
    const parsedError = JSON.parse(JSON.parse(<string>e).message);
    if (parsedError.reason.code === RESOURE_NOT_FOUND_ERROR_CODE) {
      return members;
    }

    throw e;
  }
};

export const setTenant = async (tenant) => {
  await BlipApi.sendCommand({
    to: 'postmaster@portal.blip.ai',
    method: 'set',
    uri: `/tenants/${tenant.id}`,
    type: 'application/vnd.iris.portal.tenant+json',
    resource: tenant,
  });
  await IframeMessageProxy.sendMessage({ action: 'getUserContext', content: { command: 'setCurrentTenant', tenant } });
};

export const uploadProfilePic = async (image) => {
  const uploadUrlResponse = await BlipApi.sendCommand({
    method: 'get',
    to: 'postmaster@media.blip.ai',
    uri: '/upload-media-uri',
  });
  const data = new FormData();
  data.append('image', new Blob([image]));

  try {
    const file = await getFileBinary(image);
    const postResponse = await axios.post(uploadUrlResponse.response, file, {
      headers: {
        'Content-Type': image.type,
      },
    });

    return postResponse.data.mediaUri;
  } catch (e) {
    throw e;
  }
};

export const acceptMember = (member) => {
  return BlipApi.sendCommand({
    method: 'set',
    to: 'postmaster@portal.blip.ai',
    uri: `/tenants/${member.tenantId}/users/${encodeURIComponent(member.userIdentity)}/user-status`,
    type: 'application/vnd.iris.portal.tenant-user-status',
    resource: 'accepted',
  });
};

export const rejectMember = (member) => {
  return BlipApi.sendCommand({
    method: 'set',
    to: 'postmaster@portal.blip.ai',
    uri: `/tenants/${member.tenantId}/users/${encodeURIComponent(member.userIdentity)}/user-status`,
    type: 'application/vnd.iris.portal.tenant-user-status',
    resource: 'rejected',
  });
};

export const removeMember = (member) => {
  return BlipApi.sendCommand({
    method: 'delete',
    to: 'postmaster@portal.blip.ai',
    uri: `/tenants/${member.tenantId}/users/${encodeURIComponent(member.userIdentity)}`,
  });
};

export const setMemberRole = (member, role) => {
  return BlipApi.sendCommand({
    method: 'set',
    to: 'postmaster@portal.blip.ai',
    uri: `/tenants/${member.tenantId}/users/${encodeURIComponent(member.userIdentity)}/role`,
    type: 'application/vnd.iris.portal.role+json',
    resource: {
      id: role,
    },
  });
};

export const mine = () => {
  return BlipApi.sendCommand({
    method: 'get',
    to: 'postmaster@portal.blip.ai',
    uri: '/tenants-mine',
  });
};

export const getApplicationsWithUniqueAdmin = (tenantId, userIdentity) => {
  return BlipApi.sendCommand({
    method: 'get',
    to: 'postmaster@portal.blip.ai',
    uri: `/tenants/${tenantId}/unique-admin-applications/${encodeURIComponent(userIdentity)}`,
  });
};

export const getTenantPlan = (tenantId: string) => {
  return IframeMessageProxy.sendMessage({
    action: 'getTenantPlan',
    content: {
      tenantId,
    },
  });
};

export const getWallet = (tenantId: string) => {
  return BlipApi.sendCommand({
    method: 'get',
    to: 'postmaster@portal.blip.ai',
    uri: `/tenants/${tenantId}/subscription/wallet`,
  });
};

export const getPrivateRoutes = async (subscription, loggedUser, routes) => {
  const isRouteAllowed = await Promise.all(
    routes.map((item) =>
      hasPermissions(subscription, loggedUser, item.accessPermission, item.metrics, item.featureToggle),
    ),
  );
  return routes.filter((item, index) => isRouteAllowed[index]);
};

export const TenantApi = {
  getTenant,
  getAllTenantMembers,
  uploadProfilePic,
  acceptMember,
  rejectMember,
  removeMember,
  setMemberRole,
  mine,
  getApplicationsWithUniqueAdmin,
  getTenantPlan,
  getPrivateRoutes,
};
