import React, { useContext, useEffect } from 'react';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { DailyActiveUserContainer } from '../styles';
import CompiledDailyActiveUsers from './CompiledDailyActiveUsers';
import DailyActiveUserMonthlyUsage from './DailyActiveUserMonthlyUsage';
import DailyActiveUserUsage from './DailyActiveUserUsage';

const DailyActiveUsersSummaryComponent: React.FC = () => {
  const { tenant, content, setHeaderContent } = useContext(DailyActiveUsersContext);

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: null });
  }, []);

  return (
    <DailyActiveUserContainer>
      <CompiledDailyActiveUsers></CompiledDailyActiveUsers>
      <DailyActiveUserMonthlyUsage tableBodyHeight="1000px" registersToTake={5}></DailyActiveUserMonthlyUsage>
      <DailyActiveUserUsage tableBodyHeight="1000px" registersToTake={5}></DailyActiveUserUsage>
    </DailyActiveUserContainer>
  );
};

export default DailyActiveUsersSummaryComponent;
