import React, { useContext } from 'react';
import { FC, useMemo } from 'react';
import { BdsBanner } from 'blip-ds/dist/blip-ds-react';
import { capitalizeFirstLetter } from '../../../../../utils/strings';
import { useContentLocalizer } from '../../../../../hooks/useContentLocalizer';
import { localization } from './localizations';
import { BlipLoadingComponent } from '../../../../../components/BlipLoading/BlipLoadingComponent';
import DataConsumptionContext from '../../../../../contexts/Billing/DataConsumptionContext';
import { blipBannerStatus } from '../../../../../shared/BillingMetrics';
import { ConsolidatedHeaderComponent } from '../../../components/Metrics/ConsolidatedHeader/ConsolidatedHeaderComponent';
import '../../../../../typings/blip-ds.d';
import {
  DataConsumptionConsolidatedBlipIcon,
  DataConsumptionConsolidatedBanner,
  DataConsumptionConsolidatedPaper,
} from './styles';

const ConsolidatedDataConsumptionComponent: FC = () => {
  const { tenant, dateToSearch, currentMonth, planStatus, planStatusLoading } = useContext(DataConsumptionContext);
  const content = useContentLocalizer(localization);
  const arrStatus: any[] = [];
  content.consolidated.planStatus.map((item, index) => {
    const arr = { ...item, ...blipBannerStatus[index] };
    arrStatus.push(arr);
  });

  const planStatusMemo = useMemo(() => {
    return arrStatus.map(
      (props, index) =>
        planStatus.current === props.status && (
          <bds-grid key={index} direction="row" justify-content="flex-start">
            <DataConsumptionConsolidatedBlipIcon>
              <img src={props.blipImg} alt={props.label} />
            </DataConsumptionConsolidatedBlipIcon>
            <DataConsumptionConsolidatedBanner>
              <BdsBanner variant={props.variant} context="inside">
                {props.label}
              </BdsBanner>
            </DataConsumptionConsolidatedBanner>
          </bds-grid>
        ),
    );
  }, [planStatus.current]);

  return (
    <DataConsumptionConsolidatedPaper>
      <bds-grid direction="column" justify-content="flex-start">
        <ConsolidatedHeaderComponent
          infoHeader={content.formatString(content.consolidated.plan, capitalizeFirstLetter(tenant.info.plan))}
          dateToSearch={dateToSearch}
          currentMonth={currentMonth}
          showConsolidatedBorder={false}
        />
        {planStatusLoading ? <BlipLoadingComponent /> : <>{planStatusMemo}</>}
      </bds-grid>
    </DataConsumptionConsolidatedPaper>
  );
};

export default ConsolidatedDataConsumptionComponent;
