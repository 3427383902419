export class DailyActiveUserUsage {
  dateReference!: string;
  total!: number;

  constructor(dailyActiveUsersMonthlyUsage: Partial<DailyActiveUserUsage>) {
    this.dateReference = '';
    this.total = 0;
    Object.assign(this, dailyActiveUsersMonthlyUsage);    
  }
}
