export const localization = {
  pt: {
    title: 'Lista de Atendentes',
    overview: 'Resumo',
    members: 'Atendentes do contrato',
  },
  en: {
    title: 'Agents List',
    overview: 'Overview',
    members: 'Agents of',
  },
  es: {
    title: 'Lista de asistentes',
    overview: 'Resumen',
    members: 'Asistentes del contrato',
  },
};
