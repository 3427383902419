import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uploadProfilePic } from 'api/TenantApi';
import { showUploadErrorToast } from 'api/CommonApi';
import { AvatarComponent } from './AvatarComponent';

export const AvatarContainer = ({
  img,
  canEdit,
  changePicLabel,
  onUpdateImage,
}) => {
  const GENERIC_ERROR_STATUS = 400;
  const PAYLOAD_TOO_LARGE = 413;
  const INVALID_MIME_TYPE = 415;
  const MAX_FILE_SIZE = 20;

  const [isLoading, setIsLoading] = useState(false);

  const updateImage = async (image) => {
    try {
      setIsLoading(true);
      if (image) {
        if (!['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(image.type)) {
          return showUploadErrorToast(INVALID_MIME_TYPE);
        }
  
        if (image.size / (1024 * 1024) > MAX_FILE_SIZE) {
          return showUploadErrorToast(PAYLOAD_TOO_LARGE);
        }
  
        const photoUri = await uploadProfilePic(image);
        onUpdateImage(photoUri);
      }
    } catch (err) {
      console.log(err);
      showUploadErrorToast(GENERIC_ERROR_STATUS);
    } finally {
      setIsLoading(false);
    }
  };

  return <AvatarComponent
    updateImage={updateImage}
    img={img}
    canEdit={canEdit}
    isLoading={isLoading}
    changePicLabel={changePicLabel} />;
};

AvatarContainer.propTypes = {
  img: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  changePicLabel: PropTypes.string.isRequired,
  onUpdateImage: PropTypes.func.isRequired,
};
