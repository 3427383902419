export const localization = {
  pt: {
    contractName: 'Nome do contrato',
    id: 'ID',
    creationDate: 'Data de criação',
    chatbots: 'Chatbots',
    members: 'Membros',
    changePic: 'Alterar foto',
    leaveContract: 'Deixar contrato',
    nameUpdated: 'Nome atualizado',
    nameUpdateError: 'Falha ao atualizar o nome',
    nameTooShortError: 'O nome precisa ter no mínimo 2 caracteres',
    copy: 'Copiar',
  },
  en: {
    contractName: 'Contract name',
    id: 'ID',
    creationDate: 'Creation date',
    chatbots: 'Chatbots',
    members: 'Members',
    changePic: 'Change photo',
    leaveContract: 'Leave contract',
    nameUpdated: 'Name updated',
    nameUpdateError: 'Failed to update name',    
    nameTooShortError: 'The name has to be at least 2 characters long',
    copy: 'Copy',
  },
  es: {
    contractName: 'Nombre del contrato',
    id: 'ID',
    creationDate: 'Fecha de creación',
    chatbots: 'Chatbots',
    members: 'Miembros',
    changePic: 'Cambiar foto',
    leaveContract: 'Dejar contrato',
    nameUpdated: 'Nombre actualizado',
    nameUpdateError: 'No se pudo actualizar el nombre',
    nameTooShortError: 'El nombre debe tener al menos 2 caracteres',
    copy: 'Copiar',
  },
};
