export class ConsolidatedSessionConsumption {
  sessionByUser!: number;
  sessionByCompany!: number;
  sessionTotal!: number;
  startDateReference!: string;
  endDateReference!: string;

  constructor(consolidatedSessionConsumption: Partial<ConsolidatedSessionConsumption | null>) {
    this.sessionByUser = 0;
    this.sessionByCompany = 0;
    this.sessionTotal = 0;
    this.startDateReference = '';
    this.endDateReference = '';
    Object.assign(this, consolidatedSessionConsumption);
  }
}
