import React from 'react';
import PropTypes from 'prop-types';

const BlipDropdownButtonComponent = ({
  buttonClass,
  dropdownClass,
  label,
  children,
  arrowSide,
  showDropdown,
  toggleDropdown,
  footer,
  buttonTestId,
}) => {
  return (
    <div className="BlipDropdownButton relative mr2">
      <button data-testid={buttonTestId} className={`bp-btn ${buttonClass}`} onClick={() => toggleDropdown()}>
        {label}
      </button>
      {showDropdown && (
        <>
          <div className="overlay z-1" onClick={() => toggleDropdown()}></div>
          <div className={`bp-card bp-card--${arrowSide}-arrow pa0 z-2 absolute ${dropdownClass}`}>
            {children && <div className="pa3">{children}</div>}
            {footer.length > 0 && (
              <>
                {children && <div className="bp-divider-h" />}
                <div className="pa2 flex flex-row-reverse" onClick={() => toggleDropdown()}>
                  {footer}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

BlipDropdownButtonComponent.propTypes = {
  buttonClass: PropTypes.string,
  dropdownClass: PropTypes.string,
  label: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  arrowSide: PropTypes.string.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  footer: PropTypes.arrayOf(PropTypes.node).isRequired,
  buttonTestId: PropTypes.string,
};

export { BlipDropdownButtonComponent };
