export const localization = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'Conversas via WhatsApp',
      description:
        'Os valores deste relatório são recebidos direto do WhatsApp e podem ser diferentes dos mostrados na fatura. Use esse relatório apenas como estimativa do seu consumo.',
      showMoreButton: 'Mostrar mais',
      tooltipText:
        'São trocas de mensagem via WhatsApp entre empresas e usuários. Uma conversa inclui todas as mensagens trocadas dentro de um período de 24 horas, começando quando a primeira mensagem enviada pela empresa é entregue.',
    },
    leftChart: {
      title: 'Consumo de conversas',
      label: 'Total conversas',
      customLabels: {
        freeTier: 'Free tier',
        freeEntryPoint: 'Free entry point',
        charged: 'Cobradas',
      },
    },
    leftInfoPaper: {
      totalFreeTier: {
        description: 'Uso do free tier',
        tooltipText: 'As primeiras conversas gratuitas de cada mês por WABA (WhatsApp Business Account)',
      },
      totalFreeEntryPoint: {
        description: 'Free entry point',
        tooltipText:
          'Conversas iniciadas em pontos específicos, como anúncios direcionados ao WhatsApp e CTAs via Facebook. Nesses casos, apenas a primeira conversa iniciada pelo usuário é gratuita',
      },
      totalRegular: {
        description: 'Conversas cobradas',
        tooltipText:
          'São aquelas conversas realizadas por mensagens de WhatsApp, entre empresa e usuários, que são cobradas. Uma conversa inclui todas as mensagens trocadas dentro de um período de 24 horas, começando quando a primeira mensagem enviada pela empresa é entregue.',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Origem das conversas',
      customLabels: {
        businessInitiated: 'Conversas iniciadas pela empresa',
        userInitiated: 'Conversas iniciadas pelo usuário',
        total: 'Total de conversas',
      },
    },
    rightInfoPaper: {
      total: {
        description: 'Total de conversas',
      },
      totalBusinessInitiated: {
        description: 'Total de conversas iniciadas pela empresa',
      },
      totalUserInitiated: {
        description: 'Total de conversas iniciadas pelo usuário',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'Conversations via WhatsApp',
      description:
        'The numbers in this report come directly from WhatsApp and may differ from the numbers shown on the invoice. It is best to use the report only as an estimate of your consumption.',
      showMoreButton: 'Show more',
      tooltipText:
        'WhatsApp messages exchanged between companies and users. Conversations include all messages exchanged within a 24-hour span, starting when the first message sent by the company is delivered.',
    },
    leftChart: {
      title: 'Conversation usage',
      label: 'Conversation total',
      customLabels: {
        freeTier: 'Free tier',
        freeEntryPoint: 'Free entry point',
        charged: 'Charged',
      },
    },
    leftInfoPaper: {
      totalFreeTier: {
        description: 'Free tier usage',
        tooltipText: 'The first free conversations each month per WABA (WhatsApp Business Account)',
      },
      totalFreeEntryPoint: {
        description: 'Free entry point',
        tooltipText:
          'Conversations initiated at specific points, such as WhatsApp ads and Facebook CTAs. In such cases, only the first conversation initiated by the user is free',
      },
      totalRegular: {
        description: 'Charged conversations',
        tooltipText:
          'Charged WhatsApp conversations between company and users. A conversation includes all messages exchanged within a 24-hour span, starting when the first message, sent by the company is delivered.',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Origin',
      customLabels: {
        businessInitiated: 'Conversation started by the company',
        userInitiated: 'Conversation started by the user',
        total: 'Conversation total',
      },
    },
    rightInfoPaper: {
      total: {
        description: 'Conversation total',
      },
      totalBusinessInitiated: {
        description: 'Total of conversation started by the company',
      },
      totalUserInitiated: {
        description: 'Total of conversation started by the user',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'Conversaciones vía WhatsApp',
      description:
        'Los importes de este informe se reciben directamente de WhatsApp y pueden diferir de los que se muestran en la factura. Utilice este informe solo como una estimación de su consumo.',
      showMoreButton: 'Mostrar más',
      tooltipText:
        'Intercambio de mensajes de WhatsApp entre empresas y usuarios. Las conversaciones incluyen todos los mensajes intercambiados dentro de un período de 24 horas, a partir de la entrega del primer mensaje enviado por la empresa.',
    },
    leftChart: {
      title: 'Consumo de conversas',
      label: 'Conversaciones totales',
      customLabels: {
        freeTier: 'Free tier',
        freeEntryPoint: 'Free entry point',
        charged: 'Cargadas',
      },
    },
    leftInfoPaper: {
      totalFreeTier: {
        description: 'Uso del free tier',
        tooltipText: 'Las primeras conversaciones gratuitas cada mes por WABA (WhatsApp Business Account)',
      },
      totalFreeEntryPoint: {
        description: 'Free entry point',
        tooltipText:
          'Conversaciones iniciadas en puntos específicos, como anuncios de WhatsApp y CTA de Facebook. En estos casos, solo la primera conversación iniciada por el usuario es gratuita',
      },
      totalRegular: {
        description: 'Conversaciones cargadas',
        tooltipText:
          'Son conversaciones de Whatsapp, entre empresa y usuarios, que se cobran. Una conversación incluye todos los mensajes intercambiados en un período de 24 horas, a partir de la entrega del primer mensaje enviado por la empresa.',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Origen de las conversaciones',
      customLabels: {
        businessInitiated: 'Conversaciones iniciadas por la empresa',
        userInitiated: 'Conversaciones iniciadas por el usuario',
        total: 'Conversaciones totales',
      },
    },
    rightInfoPaper: {
      total: {
        description: 'Conversaciones totales',
      },
      totalBusinessInitiated: {
        description: 'Conversaciones iniciadas por la empresa',
      },
      totalUserInitiated: {
        description: 'Conversaciones iniciadas por el usuario',
      },
    },
  },
};
