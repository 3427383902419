export const TenantReducer = (state, action) => {
  switch (action.type) {
    case 'setTenant':
      return { ...state, ...action.tenant };
    case 'setTenantName':
      return { ...state, info: { ...state.info, name: action.name } };
    case 'setTenantPhotoUri':
      return { ...state, info: { ...state.info, photoUri: action.photoUri } };
    case 'setTenantMembers':
      return { ...state, members: action.members };
    case 'setTenantAgents':
      return { ...state, agents: action.agents };
    default:
      throw new Error(`TenantReducer: invalid action called ${action.type}`);
  }
};
