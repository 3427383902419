export const localization = {
  pt: {
    locale: 'pt-BR',
    monthPlaceholder: 'Período',
  },
  en: {
    locale: 'en-US',
    monthPlaceholder: 'Period',
  },
  es: {
    locale: 'es-ES',
    monthPlaceholder: 'Período',
  },
};
