import { LocalizedStrings } from 'react-localization';
import { MAX_32_BIT_INTEGER } from '../../utils/numbers';
import { ReferenceValue } from './ReferenceValue';

export class MRRFinancialRules {
  public idContract!: number;
  public stepRule!: number;
  public fixPrice!: number;
  public coreMetric!: string;
  public rangeStartMetric!: number;
  public rangeEndMetric!: number;
  public pricePerUser!: number;
  public validityStart!: Date;
  public validityEnd!: Date;

  constructor(financialRule: Partial<MRRFinancialRules>){
    Object.assign(this, financialRule);
  }

  public toReferenceValue(contentLocalizer: LocalizedStrings<any>): ReferenceValue {
    const locale = contentLocalizer.locale;
    const currency = contentLocalizer.currency;

    const rangeStartMetric = this.rangeStartMetric.toLocaleString(locale);
    const rangeEndMetric = this.rangeEndMetric.toLocaleString(locale);
    const coreMetric = this.coreMetric.toUpperCase();
    const pricePerUnitString = `${this.pricePerUser.toLocaleString(locale, { style: 'currency', currency })}`;
    const fixPriceString = `${this.fixPrice.toLocaleString(locale, { style: 'currency', currency })}`;
    const perCoreMetricString = contentLocalizer.formatString(contentLocalizer.contractRules.referenceTable.additional,coreMetric);

    return new ReferenceValue(
      coreMetric,
      this.stepRule,
      this.fixPrice,
      this.pricePerUser,
      this.rangeEndMetric ===  MAX_32_BIT_INTEGER
        ? `${contentLocalizer.contractRules.referenceTable.start} ${rangeStartMetric}`
        : `${rangeStartMetric} ${contentLocalizer.contractRules.referenceTable.middle} ${rangeEndMetric}`,
      this.pricePerUser > 0
        ? this.fixPrice > 0
          ? `${pricePerUnitString} ${perCoreMetricString} + ${fixPriceString}`
          : `${pricePerUnitString} ${perCoreMetricString}`
        : `${fixPriceString}`
    );
  }
}