import React from 'react';
import PropTypes from 'prop-types';
import { PageHeaderComponent } from './PageHeaderComponent';

const PageHeaderContainer = ({ title, dateToSearch, periodOptions, periodOptionChangeEvent }) => {
  return (
    <PageHeaderComponent
      title={title}
      dateToSearch={dateToSearch}
      periodOptions={periodOptions}
      periodOptionChangeEvent={periodOptionChangeEvent}
    ></PageHeaderComponent>
  );
};

PageHeaderContainer.propTypes = {
  title: PropTypes.string.isRequired,
  dateToSearch: PropTypes.any.isRequired,
  periodOptions: PropTypes.array.isRequired,
  periodOptionChangeEvent: PropTypes.func.isRequired,
};

export { PageHeaderContainer };
