export const localization = {
  pt: {
    registerToast: {
      title: 'Seu interesse foi registrado!',
      message: 'Um dos nossos especialistas entrará em contato para apresentar a solução.',
    },
    description: {
      text: 'Desbloqueie tarefas repetitivas e operacionais de atendentes e vendedores, entregue experiências assertivas e personalizadas para seus clientes. Com o Blip Copilot, você ganha um assistente de IA em tempo integral, empoderando seus atendentes e aumentando a capacidade produtiva e escala dos atendimentos. Reduza em até {0} do seu TMA e melhore outros indicadores, como o tempo de primeira resposta e CSAT agora mesmo!',
      parameter: '20%',
      imgAlt: 'Imagem de um computador com um chatbot',
    },
    title: {
      text: '{0} O ajudante de IA ideal para empoderar seus atendentes e vendedores direto no Blip Desk',
      parameter: 'Blip Copilot:',
    },
    popup: 'Atendimentos mais ágeis e agentes empoderados',
    button: 'Quero conhecer o Copilot!',
    features: {
      suggestion:{
        title: 'Sugestão de resposta',
        description: 'Respostas {0} gerando menor fricção em cenários complexos.',
        parameter: 'completas, corretas e ágeis,',
      },
      transcription: {
        title: 'Transcrições de áudios',
        description: 'Transcrição de áudios dentro da conversa, escalando a {0} dos agentes humanos.',
        parameter: 'capacidade de interação',
      },
      summary: {
        title: 'Resumo inteligente',
        description: 'Conversas {0} através do resumo do histórico de conversas.',
        parameter: 'empoderadas por dados',
      },
    },
    modal:{
      title: 'A contratação do Blip Copilot ainda não foi realizada.',
      description:'Ao habilitar esta funcionalidade, custos adicionais por agente e por sessão poderão ser aplicados à fatura do seu contrato. Ao continuar, você declara que está ciente e concorda com essas possíveis cobranças.',
      moreDescription:'Em caso de dúvidas sobre a contratação do Blip Copilot, entre em contato com o time comercial. ',
      more: 'Saiba mais.',
      cancel: 'Cancelar',
      continue: 'Continuar',
    },
  },
  en: {
    registerToast: {
      title: 'Your interest has been registered!',
      message: 'One of our specialists will contact you to present the solution.',
    },
    description: {
      text: 'With Blip Desk AI assistant, you increase your team\'s productivity and empower your attendants. Reducing up to {0} of your TMA and improving other indicators such as first response time and CSAT.',
      parameter: '20%',
      imgAlt: 'Image of a computer with a chatbot',
    },
    title: {
      text: '{0} efficiency for your team, quality for your customers',
      parameter: 'Blip Copilot:',
    },
    popup: 'Faster support and empowered agents',
    button: 'I want to know Copilot!',
    features: {
      suggestion:{
        title: 'Response suggestion',
        description: '{0} responses, generating less friction in complex scenarios.',
        parameter: 'Complete, correct, and agile',
      },
      transcription: {
        title: 'Audio transcriptions',
        description: 'Transcription of audios within the conversation, scaling {0} of human agents.',
        parameter: 'the interaction capacity',
      },
      summary: {
        title: 'Intelligent summary',
        description: 'Conversations {0} through the conversation history summary.',
        parameter: 'empowered by data',
      },
    },
    modal:{
      title: 'The acquisition of Blip Copilot has not yet been completed.',
      description:'By enabling this feature, additional costs per agent and per session may be applied to your contract\'s invoice. By continuing, you acknowledge and agree to these potential charges.',
      moreDescription:'If you have any questions about the acquisition of Blip Copilot, please contact the sales team. ',
      more: 'Learn more.',
      cancel: 'Cancel',
      continue: 'Continue',
    },
  },
  es: {
    registerToast: {
      title: '¡Se ha registrado su interés!',
      message: 'Uno de nuestros especialistas se pondrá en contacto con usted para presentarle la solución.',
    },
    description: {
      text: 'Con el asistente de IA de Blip Desk, aumenta la productividad de su equipo y empodera a sus agentes. Reduciendo hasta un {0} de su TMA y mejorando otros indicadores como el tiempo de primera respuesta y CSAT.',
      parameter: '20%',
      imgAlt: 'Imagen de una computadora con un chatbot',
    },
    title: {
      text: '{0} eficiencia para su equipo, calidad para sus clientes',
      parameter: 'Blip Copilot:',
    },
    popup: 'Soporte más rápido y agentes empoderados',
    button: '¡Quiero conocer a Copilot!',
    features: {
      suggestion:{
        title: 'Sugerencia de respuesta',
        description: 'Respuestas {0}, generando menos fricción en escenarios complejos.',
        parameter: 'completas, correctas y ágiles',
      },
      transcription: {
        title: 'Transcripciones de audio',
        description: 'Transcripción de audios dentro de la conversación, escalando {0} de los agentes humanos.',
        parameter: 'la capacidad de interacción',
      },
      summary: {
        title: 'Resumen inteligente',
        description: 'Conversaciones {0} a través del resumen del historial de conversaciones.',
        parameter: 'empoderadas por datos',
      },
    },
    modal:{
      title: 'La adquisición de Blip Copilot aún no ha sido completada.',
      description:'Al habilitar esta función, se podrán aplicar costos adicionales por agente y por sesión a la factura de tu contrato. Al continuar, reconoces y aceptas estos posibles cargos.',
      moreDescription:'Si tienes alguna duda sobre la adquisición de Blip Copilot, contacta al equipo de ventas. ',
      more: 'Saber más.',
      cancel: 'Cancelar',
      continue: 'Continuar',
    },
  },
};
