import { BdsButton, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { FC, useContext, useEffect } from 'react';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { BlipTable } from '../../../components/BlipTable';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { useCommon } from '../../../contexts/CommonContext';
import { getMonthTwoDigits, toUTCDateString } from '../../../utils/date';
import {
  DailyActiveUserRow,
  DailyActiveUserPaper,
  DailyActiveUserUsageStatementContent,
  DailyActiveUserConsumptionConsolidatedHeaderPartial,
  DailyActiveUserConsumptionConsolidatedHeaderRow,
} from '../styles';
import { DateDropdown } from '../../../components/DateDropdown';
import { Link } from 'react-router-dom';
import {
  DAILY_ACTIVE_USERS_DAILY_BALANCE_STATEMENT_DETAIL_CLICK,
  DAILY_ACTIVE_USERS_PERIOD_SELECT_DAILY_OPENED,
} from '../../../shared/SegmentEvents';
import { createTrack } from '../../../api/SegmentApi';

export interface DailyActiveUserUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const dailyActiveUserUsageComponent: FC<DailyActiveUserUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    currentMonth,
    dateToSearch,
    content,
    tenant,
    loggedUser,
    sortDailyActiveUserUsage,
    setDailyActiveUserUsage,
    dailyActiveUserUsage,
    billingDailyActiveUserApi,
    periodOptions,
    setDateToSearch,
  } = useContext(DailyActiveUsersContext);
  const { setHeaderContent } = useCommon();

  const tableDailyUsageModel = [
    { label: content.dailyUsage.byDaily.date, key: 'dateReference' },
    { label: content.dailyUsage.byDaily.dailyActiveUsers, key: 'total' },
  ];

  useEffect(() => {
    const getData = async () => {
      startLoading();

      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const dailyActiveUserUsage = await billingDailyActiveUserApi.getDailyActiveUserUsage(
        tenant.info.id,
        monthString,
        registersToTake,
      );

      const dailyActiveUserUsageParse = dailyActiveUserUsage.map((obj) => ({
        ...obj,
        dateReference: toUTCDateString(obj.dateReference, content.locale),
      }));

      setDailyActiveUserUsage(dailyActiveUserUsageParse);

      stopLoading();
    };

    getData();

    return () => setHeaderContent({ redirect: null });
  }, [dateToSearch]);

  const trackDauDailyBalanceStatementDetail = async () => {
    createTrack(tenant.info, DAILY_ACTIVE_USERS_DAILY_BALANCE_STATEMENT_DETAIL_CLICK, {
      dauDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      dauUser: loggedUser.email,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, DAILY_ACTIVE_USERS_PERIOD_SELECT_DAILY_OPENED, {
      dauDate: dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      dauUser: loggedUser.email,
    });
  };

  return (
    <DailyActiveUserPaper>
      <DailyActiveUserRow>
        <BdsTypo className="title" tag="h3" variant="fs-24" bold="bold" margin={false}>
          {content.dailyUsage.title}
          <BdsTypo variant="fs-14">{content.dailyUsage.tableInformation}</BdsTypo>
        </BdsTypo>
        {registersToTake > 0 ? (
          <Link to={{ pathname: '/daily-active-users/daily', state: { DailyActiveUserDaily: true } }}>
            <BdsButton variant="ghost" arrow={true} size="short" onClick={trackDauDailyBalanceStatementDetail}>
              {content.dailyUsage.buttonTitle}
            </BdsButton>
          </Link>
        ) : (
          <>
            <DailyActiveUserConsumptionConsolidatedHeaderPartial>
              <DailyActiveUserConsumptionConsolidatedHeaderRow>
                {currentMonth && (
                  <BdsPaper className="partialUsage" elevation="static">
                    <BdsTypo variant="fs-12" bold="semi-bold">
                      {content.compiledDailyActiveUserInformation.partialUsage}
                    </BdsTypo>
                  </BdsPaper>
                )}
              </DailyActiveUserConsumptionConsolidatedHeaderRow>
            </DailyActiveUserConsumptionConsolidatedHeaderPartial>
            <DateDropdown
              value={dateToSearch.toISOString()}
              bdsChangeEvent={periodOptionChangeEvent}
              options={periodOptions}
              label={content.monthPlaceholder}
            ></DateDropdown>
          </>
        )}
      </DailyActiveUserRow>
      <DailyActiveUserUsageStatementContent>
        <BdsPaper>
          <BlipTable
            idKey="date"
            model={tableDailyUsageModel}
            data={dailyActiveUserUsage}
            selectedItems={[]}
            onSortSet={sortDailyActiveUserUsage}
            canSelect={false}
            bodyHeight={tableBodyHeight}
          ></BlipTable>
        </BdsPaper>
      </DailyActiveUserUsageStatementContent>
    </DailyActiveUserPaper>
  );
};

export default dailyActiveUserUsageComponent;
