import React from 'react';
import PropTypes from 'prop-types';
import { BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { InfoPaperHeader, InfoPaperBody, InfoPaperContainer, InfoPaperTitle } from './styles';

const InfoPaperComponent = ({
  icon = '',
  title,
  justifyContent = '',
  classNameTitle = '',
  text,
  tooltipIcon = '',
  tooltipText = '',
  bdsPaper = true,
  infoPaperType = 'number',
  tooltipTrackEvent,
}) => {
  const showIcon = (icon && title.length <= 7 && infoPaperType === 'number') || infoPaperType === 'weekly';

  return (
    <InfoPaperContainer bdsPaper={bdsPaper}>
      <InfoPaperHeader justifyContent={justifyContent} showIcon={showIcon}>
        {showIcon && <BdsIcon name={icon} size="medium"></BdsIcon>}
        <InfoPaperTitle className={classNameTitle}>
          <BdsTypo
            tag="span"
            variant={infoPaperType === 'weekly' ? 'fs-14' : 'fs-18'}
            bold={infoPaperType === 'weekly' ? 'bold' : 'extra-bold'}
            lineHeight="double"
          >
            {title}
          </BdsTypo>
        </InfoPaperTitle>
        {tooltipIcon && tooltipText && (
          <BdsTooltip position="top-center" tooltip-text={tooltipText} onMouseOver={tooltipTrackEvent}>
            <BdsIcon name={tooltipIcon} size="x-small"></BdsIcon>
          </BdsTooltip>
        )}
      </InfoPaperHeader>
      <InfoPaperBody>
        <BdsTypo variant="fs-12" bold="semi-bold" lineHeight="plus">
          {text}
        </BdsTypo>
      </InfoPaperBody>
    </InfoPaperContainer>
  );
};

InfoPaperComponent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  tooltipIcon: PropTypes.string,
  tooltipText: PropTypes.string,
  bdsPaper: PropTypes.bool,
  infoPaperType: PropTypes.string,
  tooltipTrackEvent: PropTypes.func,
};

export { InfoPaperComponent };
