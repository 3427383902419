export const localization = {
  pt: {
    title: 'Resumo dos atendentes',
    subTitle:'Previsto no Plano: {quantityPlan} Atendentes acionados no mês',
    agentDescriptionTriggered:'São considerados acionados todos os atendentes que receberam no mínimo um ticket de atendimento no mês atual', 
    agentDescriptionEnabled:'Atendentes que podem receber tickets de atendimento', 
    agentExtra:'Para cada atendente acionado além do limite do plano será cobrado um valor extra',
    agentsTotal:'Total atendentes',
    agentsEnabled:'Atendentes Acionáveis',
    agentsTriggered: 'Atendentes Acionados',
    agentDescriptionSummaryTriggered:'Atendentes que realizaram um ou mais atendimentos neste período',
  },
  en: {
    title: 'Summary of Agents',
    subTitle:'Expected in the Plan: {quantityPlan} Agents activated in the month',
    agentDescriptionTriggered:'All agents who received at least one service ticket in the current month are considered triggered',
    agentDescriptionEnabled:'Agents who can receive service tickets', 
    agentExtra:'For each agent triggered beyond the plan limit, an extra amount will be charged',
    agentsTotal:'Total agents',
    agentsEnabled:'Actionable Agents',
    agentsTriggered: 'Triggered Agents',
    agentDescriptionSummaryTriggered:'Agents who performed one or more triggered in the current month',
  },
  es: {
    title: 'Resumen de asistentes',
    subTitle:'Esperado en el Plan: {quantityPlan} Asistentes activados en el mes',
    agentDescriptionTriggered:'Todos los asistentes que recibieron al menos un ticket de servicio en el mes en curso se consideran activados',
    agentDescriptionEnabled:'Asistentes que pueden recibir tickets de servicio', 
    agentExtra:'Por cada operador activado más allá del límite del plan, se cobrará una cantidad adicional',
    agentsTotal:'Asistentes totales. ',
    agentsEnabled:'Asistentes Accionables',
    agentsTriggered: 'Asistentes Activados',
    agentDescriptionSummaryTriggered:'Asistentes que realizaron una o más llamadas este mes',
  },
};
