import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BlipCheckBox } from 'components/BlipCheckBox';
import { BdsIcon } from 'blip-ds/dist/blip-ds-react';
import { BlipTableTd } from './BlipTableTd';
import { BlipLoading } from '../BlipLoading';
import { BlipTableFooter } from './BlipTableFooter';
import useTable from './hooks/useTable';

const Sorter = ({ property, currentSort, setSort }) => {
  return (
    <div
      className={`ordinator items-center ${!property.notSortable && 'pointer'}`}
      onClick={() =>
        !property.notSortable &&
        setSort({
          property: property.key,
          order: currentSort.property === property.key && currentSort.order === 'asc' ? 'desc' : 'asc',
        })
      }
    >
      <p>{property.label}</p>
      {currentSort.property !== property.key && !property.notSortable && (
        <BdsIcon name="arrow-up" className="sortArrow"></BdsIcon>
      )}
      {currentSort.property === property.key && currentSort.order === 'asc' && <BdsIcon name="arrow-up"></BdsIcon>}
      {currentSort.property === property.key && currentSort.order === 'desc' && <BdsIcon name="arrow-down"></BdsIcon>}
    </div>
  );
};

Sorter.propTypes = {
  property: PropTypes.any.isRequired,
  currentSort: PropTypes.object,
  setSort: PropTypes.func.isRequired,
};

const BlipTableComponent = ({
  model,
  data,
  selectedItems,
  idKey,
  toggleSelect,
  toggleSelectAll,
  canSelect,
  currentSort,
  setSort,
  content,
  actions,
  emptyMessage,
  isAllSelected,
  bodyHeight,
  loading = false,
  rowsPerPage,
}) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage ? rowsPerPage : data.length);
  return (
    <>
      <table id={idKey} className="bp-table w-100 tl bp-table--scroll-y ">
        <thead className="bp-c-desk">
          <tr className="w-100">
            {canSelect && data.length > 0 && (
              <th className="w4">
                <BlipCheckBox toggleCheck={toggleSelectAll} checked={isAllSelected()} />
              </th>
            )}
            {model.map((property) => (
              <th key={property.key} className={property.columnWidth ? property.columnWidth : ''}>
                <div className="flex items-center">
                  <Sorter property={property} currentSort={currentSort} setSort={setSort} />
                </div>
              </th>
            ))}
            {canSelect && (
              <th className="w5">
                <div
                  className={`selectedItems flex justify-around items-center ${
                    selectedItems.length > 0 ? '' : 'hidden'
                  }`}
                >
                  <p className="nowrap">
                    {selectedItems.length} {content.selected}
                  </p>
                  {actions}
                </div>
              </th>
            )}
          </tr>
        </thead>
        <tbody
          style={{
            maxHeight: bodyHeight,
          }}
        >
          {data.length > 0 && loading === false ? (
            slice.map((item, index) => {
              const isSelected = selectedItems.some((selectedItem) => item[idKey] === selectedItem[idKey]);
              return (
                <tr key={`${item[idKey]}-${index}`} className={`${isSelected ? 'selected' : ''}`}>
                  {canSelect && (
                    <td className="w4">
                      <BlipCheckBox toggleCheck={() => toggleSelect(item)} checked={isSelected} />
                    </td>
                  )}

                  {model.map((property) => (
                    <BlipTableTd
                      key={property.key + item[idKey]}
                      elementKey={property.key + item[idKey]}
                      property={property}
                      item={item}
                      content={content}
                      columnWidth={property.columnWidth}
                      className={property.className}
                      classNameChild={property.classNameChild}
                      spanClass={property.spanClass}
                    />
                  ))}
                  {canSelect && <td className="w5">{item['actions']}</td>}
                </tr>
              );
            })
          ) : (
            <tr className="w-100 bp-bg-offwhite tc">
              <td className="bp-bg-offwhite " colSpan={model.length + 1}>
                {loading ? <BlipLoading /> : <p className="empty-message pa5">{emptyMessage}</p>}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data.length > rowsPerPage && <BlipTableFooter range={range} slice={slice} setPage={setPage} page={page} />}
    </>
  );
};

BlipTableComponent.propTypes = {
  model: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  idKey: PropTypes.string.isRequired,
  toggleSelect: PropTypes.func.isRequired,
  toggleSelectAll: PropTypes.func.isRequired,
  canSelect: PropTypes.bool,
  currentSort: PropTypes.object,
  setSort: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(PropTypes.node),
  emptyMessage: PropTypes.string.isRequired,
  isAllSelected: PropTypes.func.isRequired,
  bodyHeight: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  rowsPerPage: PropTypes.number,
};

BlipTableComponent.defaultProps = {
  actions: [],
};

export { BlipTableComponent };
