import React from 'react';
import PropTypes from 'prop-types';

const BlipProgressBarComponent = ({ completed, color }) => {
  return <bds-progress-bar color={color} percent={completed}></bds-progress-bar>;
};

BlipProgressBarComponent.propTypes = {
  completed: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export { BlipProgressBarComponent };
