import React, { useEffect, useState } from 'react';
import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import CreateCertificate from './Components/CreateCertificate';
import ListCertificates from './Components/ListCertificates';
import MtlsContext from '../../contexts/MtlsContext';
import MtlsCertificateApi from '../../api/MtlsCertificateApi';
import { useTenant } from '../../contexts/TenantContext';
import { MtlsCertificate } from '../../models/MtlsCertificate';
import { localization } from './localization';
import { useCommon } from '../../contexts/CommonContext';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import './styles.scss';

const Mtls: React.FC = () => {
  const { tenant } = useTenant();
  const tenantId = tenant.info.id;
  const { setHeaderContent } = useCommon();
  const content = useContentLocalizer(localization);
  const mtlsCertificateApi = new MtlsCertificateApi();
  const [mtlsCertificate, setMtlsCertificate] = useState<MtlsCertificate[]>();
  const [submitModal, setSubmitModal] = useState(false);
  const [removeCertificateAlert, setRemoveCertificateAlert] = useState(false);

  const openSubmitModal = () => setSubmitModal(true);
  const closeSubmitModal = () => setSubmitModal(false);

  const openRemoveCertificateAlert = () => setRemoveCertificateAlert(true);
  const closeRemoveCertificateAlert = () => setRemoveCertificateAlert(false);

  useEffect(() => {
    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: '/' });
  }, []);

  const contextProps = {
    submitModal,
    openSubmitModal,
    closeSubmitModal,
    removeCertificateAlert,
    openRemoveCertificateAlert,
    closeRemoveCertificateAlert,
    mtlsCertificateApi,
    tenantId,
    mtlsCertificate,
    setMtlsCertificate,
    content,
  };

  return (
    <MtlsContext.Provider value={contextProps}>
      <BdsGrid containerFluid margin="y-5" direction="column" gap="2">
        <CreateCertificate />
        <ListCertificates />
      </BdsGrid>
    </MtlsContext.Provider>
  );
};

export default Mtls;
