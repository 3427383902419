import { FC, useContext, useEffect } from 'react';
import { useCommon } from '../../../../contexts/CommonContext';
import { useContentLocalizer } from '../../../../hooks/useContentLocalizer';
import { localization } from '../../localization';
import SessionConsumptionDailyUsage from '../SessionConsumptionDailyUsage';
import SessionConsumptionContext from '../../../../contexts/SessionConsumptionContext';
import React from 'react';
import { SessionConsumptionContainer } from '../../styles';

const DailyUsageComponent: FC = () => {
  const { dateToSearch, tenant, conversationTypeFilter, conversationWabaFilter } =
    useContext(SessionConsumptionContext);
  const { setHeaderContent } = useCommon();
  const content = useContentLocalizer(localization);

  useEffect(() => {
    setHeaderContent({
      redirect: '/old-session-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: null });
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  return (
    <SessionConsumptionContainer>
      <SessionConsumptionDailyUsage tableBodyHeight="1000px" registersToTake={-1}></SessionConsumptionDailyUsage>
    </SessionConsumptionContainer>
  );
};

export default DailyUsageComponent;
