import React from 'react';
import { useContext, useEffect } from 'react';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import AgentConsumptionContext from '../../../contexts/AgentConsumptionContext';
import { useCommon } from '../../../contexts/CommonContext';
import { AgentConsumptionContainer } from '../styles';
import AgentConsumptionConsolidatedInformations from './AgentConsumptionConsolidatedInformations';
import AgentConsumptionMonthlyUsage from './AgentConsumptionMonthlyUsage';

const AgentConsumptionSummaryComponent: React.FC = () => {
  const { tenant, content } = useContext(AgentConsumptionContext);
  const { setHeaderContent } = useCommon();

  useEffect(() => {
    startLoading();
    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });

    stopLoading();

    return () => setHeaderContent({ redirect: null });
  }, []);

  return (
    <AgentConsumptionContainer>
      <AgentConsumptionConsolidatedInformations></AgentConsumptionConsolidatedInformations>
      <AgentConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={5}></AgentConsumptionMonthlyUsage>
    </AgentConsumptionContainer>
  );
};

export default AgentConsumptionSummaryComponent;
