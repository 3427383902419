import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  & > bds-tooltip {
    margin-left: 10px;
    margin-top: 6px;
  }
`;

export const Body = styled.div`
  display: flex;
  & > bds-typo:first-of-type {
    margin-top: 1rem;
  }
`;
