export const localization = {
  pt: {
    header: 'Consumo de Conversas Únicas',
    locale: 'pt-BR',
    currency: 'BRL',
    monthPlaceholder: 'Período',
    title: 'Conversas Únicas',
    compiledDailyActiveUserInformation: {
      plan: 'Plano {0}: {1} Conversas Únicas',    
      middleInfo:
        '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
      noContent: 'Os dados de {0} vão estar disponíveis a partir do dia 2.',
      planInformation: 'Plano {0} Conversas Únicas',
      filter: 'Use o filtro para ver o total de Conversas Únicas utilizadas em cada mês.',
      spentPercentage: '{0}% de Conversas Únicas utilizadas incluídos no plano',
      overspentMessages: 'Conversas Únicas excedentes',
      progressContentHoverIcon:
        'Conversas Únicas são contabilizadas uma única vez quando os usuários entram em contato com o chatbot, sendo considerado o período entre as 00:00 e 23:59 do mesmo dia.',
      totalUsed: 'Total Utilizado',
      overspentWarningMessage: 'O limite de Conversas Únicas do plano acabou.',
      overspentWarningFeeDetails:
        'Mas você pode continuar usando seu plano! As Conversas Únicas excedentes estão sendo contabilizados.',
      overspentWarningFeeDetailsWithValues:
        'Mas você pode continuar usando seu plano! Será cobrado {0} para cada Conversa Única excedente.',
      baseValueLabel: 'Valor base do plano',
      totalMonthlyLabel: 'Total de Conversas Únicas por mês',
      totalMonthlyValue: '{0} Conversas Únicas',
      overspentCostLabel: 'Custo por Conversas Únicas excedente',
      overspentCostValue: '{0} por Conversa Única',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    chatbotDailyActiveUserConsuption: {
      title: 'Chatbots',
      about: 'Acompanhe o consumo de mensagens por chatbot',
      columnChatbot: 'ID - Chatbot',
      columnDailyActiveUsers: 'Conversas Únicas',
      buttonAllChatbots: 'Mostrar detalhes',
    },
    dailyUsage: {
      title: 'Contabilização diária de Conversas Únicas',
      buttonTitle: 'Mostrar detalhes',
      tableInformation: 'Abaixo estão detalhadas mais informações do contrato.',
      byDaily: {
        date: 'Data',
        dailyActiveUsers: 'Conversas Únicas',
      },
    },
  },
  en: {
    header: 'Usage report: Single Conversations',
    locale: 'en-US',
    currency: 'BRL',
    monthPlaceholder: 'Period',
    title: 'Single Conversations',
    compiledDailyActiveUserInformation: {
      plan: '{0}: {1} Single Conversations',
      middleInfo: '*The closing invoice of a month will be available within 5 business days from the last day of that month..',
      noContent: 'The data for {0} will be available from the 2nd.',
      planInformation: 'Plan {0} Single Conversations',
      filter: 'Use the filter to view the total number of Single Conversations used each month.',
      spentPercentage: '{0}% of Single Conversations used included in the plan',
      overspentMessages: 'Overflow Single Conversations',
      progressContentHoverIcon:
        'Single Conversations are counted a single time, when users contact the chatbot within a period between 00:00 and 23:59 of the same day.',
      totalUsed: 'Total used',
      overspentWarningMessage: 'The plan\'s Single Conversations limit is over.',
      overspentWarningFeeDetails: 'But you can keep using your plan! The overflow Single Conversations are being booked.',
      overspentWarningFeeDetailsWithValues:
        'But you can keep using your plan! {0} will be charged for each overflow Single Conversations.',
      baseValueLabel: 'Base value of the plan',
      totalMonthlyLabel: 'Total Single Conversations per month',
      totalMonthlyValue: '{0} Single Conversations',
      overspentCostLabel: 'Cost per overflow Single Conversations',
      overspentCostValue: '{0} per Single Conversation',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    chatbotDailyActiveUserConsuption: {
      title: 'Chatbots',
      about: 'Track consumption by Single Conversations via chatbot.',
      columnChatbot: 'ID - Chatbot',
      columnDailyActiveUsers: 'Single Conversations',
      buttonAllChatbots: 'Show details',
    },
    dailyUsage: {
      title: 'Daily accounting of Single Conversations',
      buttonTitle: 'Show details',
      tableInformation: 'Below are detailed more information on the contract.',
      byDaily: {
        date: 'Date',
        dailyActiveUsers: 'Single Conversations',
      },
    },
  },
  es: {
    header: 'Reporte de consumo: Conversaciones Únicas',
    locale: 'es-ES',
    currency: 'BRL',
    monthPlaceholder: 'Período',
    title: 'Conversaciones Únicas',
    compiledDailyActiveUserInformation: {
      plan: 'Plan {0}: {1} Conversaciones Únicas',
      middleInfo:
        '*El cierre de un mes estará disponible dentro de los 5 días hábiles a partir del último día de ese mes.',
      noContent: 'Los datos de {0} estarán disponibles a partir del 2.',
      planInformation: 'Plano {0} DAUs',
      filter: 'Utilice el filtro para ver el número total de Conversaciones Únicas utilizadas cada mes.',
      spentPercentage: '{0}% de las Conversaciones Únicas utilizadas incluidos en el plan',
      overspentMessages: 'Conversaciones Únicas excedentes',
      progressContentHoverIcon:
        'Las conversaciones Únicas, antes denominadas usuarios activos diarios (DAU), son conversaciones diarias contadas desde el envío o la recepción de cualquier tipo de mensaje en Blip entre las 00:00 y las 23:59 del mismo día. Se contabilizarán una sola vez por canal, aunque no haya respuesta de la persona que los recibió. La atención humana (a través de Blip Desk), las notificaciones activas y los mensajes de los chatbots cuentan como conversaciones diarias únicas.',
      totalUsed: 'Total utilizado',
      overspentWarningMessage: 'El límite de Conversaciones Únicas del plan ha terminado.',
      overspentWarningFeeDetails: '¡Pero puedes seguir usando tu plan! Las Conversaciones Únicas se están contabilizando.',
      overspentWarningFeeDetailsWithValues:
        '¡Pero puedes seguir usando tu plan! Se cobrará {0} por cada Conversaciones Únicas excedente.',
      baseValueLabel: 'Valor base del plan',
      totalMonthlyLabel: 'Conversaciones Únicas totales por mes',
      totalMonthlyValue: '{0} Conversaciones Únicas',
      overspentCostLabel: 'Costo por Conversaciones Únicas excedentes',
      overspentCostValue: '{0} por Conversaciones Únicas',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    chatbotDailyActiveUserConsuption: {
      title: 'Chatbots',
      about: 'Seguimiento del consumo de Conversaciones Únicas por chatbot.',
      columnChatbot: 'ID - Chatbot',
      columnDailyActiveUsers: 'Conversaciones Únicas',
      buttonAllChatbots: 'Mostrar detalles',
    },
    dailyUsage: {
      title: 'Contabilidad diaria de Conversaciones Únicas',
      buttonTitle: 'Mostrar detalles',
      tableInformation: 'A continuación se detalla más información sobre el contrato.',
      byDaily: {
        date: 'Data',
        dailyActiveUsers: 'Conversaciones Únicas',
      },
    },
  },
};
