export const localization = {
  pt: {
    locale: 'pt-BR',
    consolidated: {
      plan: 'Plano {0}',
      planStatus: [
        {
          label: 'Não foi possível obter os dados. Tente recarregar a página.',
        },
        {
          label: 'Sem dados para o período escolhido.',
        },
        {
          label: 'Espaço para você acompanhar o consumo do seu plano.',
        },
        {
          label: 'Seu consumo está dentro dos limites do plano contratado.',
        },
        {
          label: 'Seu consumo está quase no limite do plano contratado.',
        },
        {
          label:
            'Seu consumo ultrapassou os limites do plano contratado. Mas tudo bem! Você pode continuar usando o seu plano. O excedente será descrito nas páginas de cada métrica.',
        },
      ],
    },
  },
  en: {
    locale: 'en-US',
    consolidated: {
      plan: '{0}',
      planStatus: [
        {
          label: 'Problems loading the data. Please try refreshing the page.',
        },
        {
          label: 'No data to display for the chosen period.',
        },
        {
          label: 'Space to monitor your plan\'s usage.',
        },
        {
          label: 'Usage is within the limits of your plan.',
        },
        {
          label: 'You have almost reached the limit of your plan.',
        },
        {
          label:
            'Your have exceeded the limits of your plan! You can continue to use it as usal. The exceeding data will appear in the description of each metric.',
        },
      ],
    },
  },
  es: {
    locale: 'es-ES',
    consolidated: {
      plan: '{0}',
      planStatus: [
        {
          label: 'No se pueden obtener los datos. Intenta recargar la página.',
        },
        {
          label: 'No hay datos para el período elegido.',
        },
        {
          label: 'Espacio para el seguimiento del consumo de tu plan.',
        },
        {
          label: 'Tu consumo está dentro de los límites del plan contratado.',
        },
        {
          label: 'Casi has llegado al límite de tu plan.',
        },
        {
          label:
            '¡Tu consumo ha superado los límites de tu plan! Puede continuar usando su plan normalmente. El excedente aparecerá en la descripción de cada métrica.',
        },
      ],
    },
  },
};
