import React, { FC, useContext, useEffect, useMemo } from 'react';
import {
  REPORT_SESSION_CONSUMPTION_CHART_HOVER,
  REPORT_SESSION_CONSUMPTION_OPEN,
  REPORT_SESSION_FREETIER_TOOLTIP_HOVER,
  REPORT_SESSION_FREE_ENTRY_POINT_TOOLTIP_HOVER,
  REPORT_SESSION_BILLED_TOOLTIP_HOVER,
  REPORT_SESSION_TITLE_TOOLTIP_HOVER,
  REPORT_SESSION_WEEKLY_CONSUMPTION_CHART_HOVER,
  action,
} from '../../../../../shared/SegmentEvents';
import { InfoPaper } from '../../../components/ConsumptionData/InfoPaper';
import { ConsolidatedPaper } from '../../../components/ConsumptionData/ConsolidatedPaper';
import { LeftInfoPaperArea, RightInfoPaperArea } from '../../styles';
import DataConsumptionContext from '../../../../../contexts/Billing/DataConsumptionContext';
import DataConsumptionMetricContext from '../../../../../contexts/Billing/DataConsumptionMetricContext';
import { useContentLocalizer } from '../../../../../hooks/useContentLocalizer';
import { localization } from './localizations';
import { roundNextHundredth } from '../../../../../utils/numbers';
import { toUTCDateString } from '../../../../../utils/date';
import { trackEvent } from '../../../../../api/Billing/Tracking';
import { useBillingPlanUsageContext } from '../../../../../contexts/Billing/BillingPlanUsageContext';
import colors from '../../../../../_charts.module.scss';

const SessionConsumptionComponent: FC = () => {
  const ROUND_UP_PERCENT = 1.25;
  const { dateToSearch, isMetricAllowed, tenant, loggedUser, trackingProps } = useContext(DataConsumptionContext);
  const { compiledSessionConsumptionDataState, isSessionLoading, consolidatedSessionApiRequisition } =
    useContext(DataConsumptionMetricContext);
  const { planStatus } = useBillingPlanUsageContext();
  const content = useContentLocalizer(localization);
  const consolidatedContainsDataOnSelectedMonth =
    compiledSessionConsumptionDataState.consolidatedContainsDataOnSelectedMonth;
  const freeTierLimit = compiledSessionConsumptionDataState.summarySessionConsumption.freeTierLimit;
  const total = compiledSessionConsumptionDataState.summarySessionConsumption.total;
  const totalBusinessInitiated =
    compiledSessionConsumptionDataState.summarySessionConsumption.totalBusinessInitiated;
  const totalFreeTier = compiledSessionConsumptionDataState.summarySessionConsumption.totalFreeTier;
  const totalFreeEntryPoint = compiledSessionConsumptionDataState.summarySessionConsumption.totalFreeEntryPoint;
  const totalRegular = compiledSessionConsumptionDataState.summarySessionConsumption.totalRegular;
  const totalUserInitiated = compiledSessionConsumptionDataState.summarySessionConsumption.totalUserInitiated;
  const weekItems = compiledSessionConsumptionDataState.summarySessionConsumption.weekItems;

  const freeTierColor = colors.System;
  const freeEntryPointColor = colors.Info;
  const chargedColor = colors.Brand;

  useEffect(() => {
    consolidatedSessionApiRequisition(dateToSearch, isMetricAllowed);
  }, [dateToSearch]);

  const leftLabels = [content.leftChart.label];
  const leftChartData = {
    chartType: 'BarChart',
    title: content.leftChart.title,
    labels: leftLabels,
    locale: content.locale,
    customLabels: [
      {
        text: content.leftChart.customLabels.freeTier,
        backgroundColor: freeTierColor,
      },
      {
        text: content.leftChart.customLabels.freeEntryPoint,
        backgroundColor: freeEntryPointColor,
      },
      {
        text: content.leftChart.customLabels.charged,
        backgroundColor: chargedColor,
      },
    ],
    datasets: [
      {
        label: content.leftChart.customLabels.freeTier,
        data: leftLabels.map(() => totalFreeTier),
        backgroundColor: freeTierColor,
        stack: 'Stack 0',
      },
      {
        label: content.leftChart.customLabels.freeEntryPoint,
        data: leftLabels.map(() => totalFreeEntryPoint),
        backgroundColor: freeEntryPointColor,
        stack: 'Stack 0',
      },
      {
        label: content.leftChart.customLabels.charged,
        data: leftLabels.map(() => totalRegular),
        backgroundColor: chargedColor,
        stack: 'Stack 0',
      },
    ],
  };

  let leftCount = 0;
  const arrTotal = [totalFreeTier + totalFreeEntryPoint + totalRegular];
  const maxArrTotal = Math.max(...arrTotal) > 0 ? Math.max(...arrTotal) : 1;
  const leftChartOptions = {
    locale: content.locale,
    maintainAspectRatio: false,
    barThickness: 30,
    onHover: function (evt) {
      if (evt.type === 'mousemove' && leftCount < 1) {
        trackEvent(REPORT_SESSION_CONSUMPTION_CHART_HOVER, tenant, loggedUser, trackingProps);
        leftCount++;
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: consolidatedContainsDataOnSelectedMonth
        ? { min: 0, max: roundNextHundredth(maxArrTotal * ROUND_UP_PERCENT) }
        : { min: 0 },
    },
  };

  const weekItemsLabel = useMemo(() => {
    return weekItems.map((item) => {
      return content.formatString(content.rightChart.period, {
        startDate: toUTCDateString(item.startDateReference, content.locale, false),
        endDate: toUTCDateString(item.endDateReference, content.locale, false),
      });
    });
  }, [weekItems]);

  const labels = weekItemsLabel;

  const businessInitiatedColor = colors.Primary;
  const userInitiatedColor = colors.ContentDefault;
  const totalColor = colors.Surface3;

  const rightChartData = {
    chartType: 'LineChart',
    title: content.rightChart.title,
    labels,
    customLabels: [
      {
        text: content.rightChart.customLabels.businessInitiated,
        borderColor: businessInitiatedColor,
        backgroundColor: businessInitiatedColor,
      },
      {
        text: content.rightChart.customLabels.userInitiated,
        borderColor: userInitiatedColor,
        backgroundColor: userInitiatedColor,
      },
      {
        text: content.rightChart.customLabels.total,
        borderColor: totalColor,
        backgroundColor: totalColor,
      },
    ],
    datasets: [
      {
        label: content.rightChart.customLabels.businessInitiated,
        data: weekItems.map((item) => item.totalBusinessInitiated),
        borderColor: businessInitiatedColor,
        backgroundColor: businessInitiatedColor,
      },
      {
        label: content.rightChart.customLabels.userInitiated,
        data: weekItems.map((item) => item.totalUserInitiated),
        borderColor: userInitiatedColor,
        backgroundColor: userInitiatedColor,
      },
      {
        label: content.rightChart.customLabels.total,
        data: weekItems.map((item) => item.total),
        borderColor: totalColor,
        backgroundColor: totalColor,
        fill: true,
      },
    ],
  };

  let rightCount = 0;
  const arrWeekTotal = weekItems.map((item) => item.total);
  const maxArrWeekTotal = Math.max(...arrWeekTotal) > 0 ? Math.max(...arrWeekTotal) : 1;
  const rightChartOptions = {
    locale: content.locale,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    onHover: function (evt) {
      if (evt.type === 'mousemove' && rightCount < 1) {
        trackEvent(REPORT_SESSION_WEEKLY_CONSUMPTION_CHART_HOVER, tenant, loggedUser, trackingProps);
        rightCount++;
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: consolidatedContainsDataOnSelectedMonth
        ? { min: 0, max: roundNextHundredth(maxArrWeekTotal * ROUND_UP_PERCENT) }
        : { min: 0 },
    },
  };

  const leftChart = { leftChartData, leftChartOptions };
  const rightChart = { rightChartData, rightChartOptions };

  let titleTooltipCount = 0;
  const header = {
    title: content.header.title,
    subtitle: content.header.description,
    buttonTitle: content.header.showMoreButton,
    tooltipIcon: 'info',
    tooltipText: content.header.tooltipText,
    pathname: '/session-consumption',
    idTest: 'show-more-report-session-consumption',
    buttonTrackEvent: () =>
      trackEvent(REPORT_SESSION_CONSUMPTION_OPEN, tenant, loggedUser, { ...trackingProps, action: action.CLICK }),
    tooltipTrackEvent: () => {
      if (titleTooltipCount < 1) {
        trackEvent(REPORT_SESSION_TITLE_TOOLTIP_HOVER, tenant, loggedUser, { ...trackingProps, action: action.HOVER });
        titleTooltipCount++;
      }
    },
  };

  let freetierTooltipCount = 0;
  let freeEntryPointTooltipCount = 0;
  let regularTooltipCount = 0;
  const leftInfoPaper = useMemo(() => {
    const showFreeTierLimit =
      freeTierLimit > 0
        ? totalFreeTier.toLocaleString(content.locale) + ' / ' + freeTierLimit.toLocaleString(content.locale)
        : totalFreeTier.toLocaleString(content.locale);
    const freeTierLimitUsage = consolidatedContainsDataOnSelectedMonth ? showFreeTierLimit : '-- / --';
    return (
      <LeftInfoPaperArea>
        <InfoPaper
          justifyContent={'space-between'}
          title={freeTierLimitUsage}
          text={content.leftInfoPaper.totalFreeTier.description}
          tooltipIcon={'info'}
          tooltipText={content.formatString(content.leftInfoPaper.totalFreeTier.tooltipText)}
          tooltipTrackEvent={() => {
            if (freetierTooltipCount < 1) {
              trackEvent(REPORT_SESSION_FREETIER_TOOLTIP_HOVER, tenant, loggedUser, {
                ...trackingProps,
                action: action.HOVER,
              });
              freetierTooltipCount++;
            }
          }}
        ></InfoPaper>
        <InfoPaper
          justifyContent={'space-between'}
          classNameTitle={'space-between-margin'}
          title={consolidatedContainsDataOnSelectedMonth ? totalFreeEntryPoint.toLocaleString(content.locale) : '--'}
          icon={'message-received'}
          text={content.leftInfoPaper.totalFreeEntryPoint.description}
          tooltipIcon={'info'}
          tooltipText={content.formatString(content.leftInfoPaper.totalFreeEntryPoint.tooltipText)}
          tooltipTrackEvent={() => {
            if (freeEntryPointTooltipCount < 1) {
              trackEvent(REPORT_SESSION_FREE_ENTRY_POINT_TOOLTIP_HOVER, tenant, loggedUser, {
                ...trackingProps,
                action: action.HOVER,
              });
              freeEntryPointTooltipCount++;
            }
          }}
        ></InfoPaper>
        <InfoPaper
          justifyContent={'space-between'}
          classNameTitle={'space-between-margin'}
          title={consolidatedContainsDataOnSelectedMonth ? totalRegular.toLocaleString(content.locale) : '--'}
          icon={'message-talk'}
          text={content.leftInfoPaper.totalRegular.description}
          tooltipIcon={'info'}
          tooltipText={content.formatString(content.leftInfoPaper.totalRegular.tooltipText)}
          tooltipTrackEvent={() => {
            if (regularTooltipCount < 1) {
              trackEvent(REPORT_SESSION_BILLED_TOOLTIP_HOVER, tenant, loggedUser, {
                ...trackingProps,
                action: action.HOVER,
              });
              regularTooltipCount++;
            }
          }}
        ></InfoPaper>
      </LeftInfoPaperArea>
    );
  }, [planStatus.current, compiledSessionConsumptionDataState]);

  const rightInfoPapers = useMemo(() => {
    return (
      <RightInfoPaperArea>
        <InfoPaper
          justifyContent={'flex-start'}
          classNameTitle={'flex-start-margin'}
          icon={'message-talk'}
          title={consolidatedContainsDataOnSelectedMonth ? total.toLocaleString(content.locale) : '--'}
          text={content.rightInfoPaper.total.description}
        ></InfoPaper>
        <InfoPaper
          justifyContent={'flex-start'}
          classNameTitle={'flex-start-margin'}
          icon={'company'}
          title={consolidatedContainsDataOnSelectedMonth ? totalBusinessInitiated.toLocaleString(content.locale) : '--'}
          text={content.rightInfoPaper.totalBusinessInitiated.description}
        ></InfoPaper>
        <InfoPaper
          justifyContent={'flex-start'}
          classNameTitle={'flex-start-margin'}
          icon={'user-engaged'}
          title={consolidatedContainsDataOnSelectedMonth ? totalUserInitiated.toLocaleString(content.locale) : '--'}
          text={content.rightInfoPaper.totalUserInitiated.description}
        ></InfoPaper>
      </RightInfoPaperArea>
    );
  }, [compiledSessionConsumptionDataState]);

  return (
    <ConsolidatedPaper
      header={header}
      leftChart={leftChart}
      leftInfoPapers={leftInfoPaper}
      rightChart={rightChart}
      rightInfoPapers={rightInfoPapers}
      isLoading={isSessionLoading}
    ></ConsolidatedPaper>
  );
};

export default SessionConsumptionComponent;
