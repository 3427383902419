import { BdsButton, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import { FC, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { createTrack } from '../../../api/SegmentApi';
import { BlipTable } from '../../../components/BlipTable';
import { DateDropdown } from '../../../components/DateDropdown';
import AgentConsumptionContext from '../../../contexts/AgentConsumptionContext';
import { useCommon } from '../../../contexts/CommonContext';
import {
  AGENT_CONSUMPTION_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK,
  AGENT_CONSUMPTION_PERIOD_SELECT_MONTHLY_OPENED,
} from '../../../shared/SegmentEvents';
import { getMonthTwoDigits, toUTCDateString } from '../../../utils/date';
import { AgentConsumptionHeaderPartial, AgentConsumptionPaper, AgentConsumptionRow } from '../styles';

export interface AgentConsumptionMonthlyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const AgentConsumptionMonthlyUsageComponent: FC<AgentConsumptionMonthlyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    content,
    tenant,
    loggedUser,
    dateToSearch,
    currentMonth,
    periodOptions,
    billingAgentConsumptionApi,
    setDateToSearch,
    monthlyAgentConsumptionDataState,
    setMonthlyAgentConsumptionDataState,
    sortAgentConsumptionMonthlyUsage,
  } = useContext(AgentConsumptionContext);
  const { setHeaderContent } = useCommon();

  const tableMonthlyUsageModel = [
    {
      label: content.monthly.columnAgentId,
      key: 'agentName',
      hasChildContent: true,
      childContentkey: 'agentEmail',
      hasThumbnail: true,
      thumbnailKey: 'agentPhotoUri',
      className: 'activeAgents',
    },
    { label: content.monthly.columnExtraCharge, key: 'extraCharge' },
    { label: content.monthly.columnNumTickets, key: 'totalTickets' },
    { label: content.monthly.columnFirstRepliedTicket, key: 'firstTicketDate' },
    { label: content.monthly.columnLastRepliedTicket, key: 'lastTicketDate' },
  ];

  useEffect(() => {
    const getData = async () => {
      startLoading();
      setHeaderContent({
        redirect: '/',
        text: content.formatString(content.header, tenant.info.name),
      });
      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;

      const agentConsumptionMonthlyUsage = await billingAgentConsumptionApi.getMonthlyAgentConsumption(
        tenant.info.id,
        monthString,
        registersToTake,
      );

      const agentConsumptionMonthlyUsageParse = agentConsumptionMonthlyUsage.map((obj) => ({
        ...obj,
        extraCharge: obj.extraCharge ? content.monthly.extraCharge[0] : content.monthly.extraCharge[1],
        firstTicketDate: toUTCDateString(obj.firstTicketDate, content.locale),
        lastTicketDate: toUTCDateString(obj.lastTicketDate, content.locale),
      }));

      setMonthlyAgentConsumptionDataState(agentConsumptionMonthlyUsageParse);
      stopLoading();
    };

    getData();
  }, [dateToSearch]);

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, AGENT_CONSUMPTION_PERIOD_SELECT_MONTHLY_OPENED, {
      agentConsumptionDate:
        dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      agentConsumptionUser: loggedUser.email,
    });
  };

  const trackAgentConsumptionBalanceStatementMonthlyDetail = async () => {
    createTrack(tenant.info, AGENT_CONSUMPTION_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK, {
      agentConsumptionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      agentConsumptionUser: loggedUser.email,
    });
  };

  return (
    <AgentConsumptionPaper>
      <AgentConsumptionRow>
        <BdsTypo className="title" no-wrap="true" tag="h3" variant="fs-24" bold="bold" margin={false}>
          {content.monthly.title}
          <BdsTypo className="subtitle" variant="fs-14">
            {content.monthly.subtitle}
          </BdsTypo>
        </BdsTypo>
        {registersToTake > 0 ? (
          <Link to={{ pathname: '/old-agent-consumption/monthly' }}>
            <BdsButton
              variant="ghost"
              arrow={true}
              size="short"
              onClick={trackAgentConsumptionBalanceStatementMonthlyDetail}
            >
              {content.monthly.buttonShowAll}
            </BdsButton>
          </Link>
        ) : (
          <AgentConsumptionHeaderPartial currentMonth={currentMonth}>
            {currentMonth && (
              <BdsPaper className="partialUsage" elevation="static">
                <BdsTypo variant="fs-12" bold="semi-bold">
                  {content.consolidated.partialUsage}
                </BdsTypo>
              </BdsPaper>
            )}
            <DateDropdown
              value={dateToSearch.toISOString()}
              bdsChangeEvent={periodOptionChangeEvent}
              options={periodOptions}
              label={content.monthPlaceholder}
            ></DateDropdown>
          </AgentConsumptionHeaderPartial>
        )}
      </AgentConsumptionRow>
      <BdsPaper>
        <BlipTable
          idKey="agentConsumptions"
          model={tableMonthlyUsageModel}
          data={monthlyAgentConsumptionDataState}
          selectedItems={[]}
          onSortSet={sortAgentConsumptionMonthlyUsage}
          canSelect={false}
          bodyHeight={tableBodyHeight}
          rowsPerPage={30}
        ></BlipTable>
      </BdsPaper>
    </AgentConsumptionPaper>
  );
};

export default AgentConsumptionMonthlyUsageComponent;
