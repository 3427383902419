export const localization = {
  pt: {
    header: 'DAUs do contrato {0}',
    locale: 'pt-BR',
    currency: 'BRL',
    monthPlaceholder: 'Mês',
    compiledDailyActiveUserInformation: {
      title: 'Consumo de DAUs (Daily Active Users) em {0}',
      partialUsage: 'Consumo parcial',
      about: 'De {0} a {1}',
      noContent: 'Os dados de {0} vão estar disponíveis a partir do dia 2.',
      planInformation: 'Plano {0} DAUs',
      filter: 'Use o filtro para ver o total de atendentes acionados em cada mês.',
      spentPercentage: '{0}% de DAUs utilizados incluídos no plano',
      overspentMessages: 'DAUs excedentes',
      progressContentHoverIcon: 'Daily active users (DAU) ou usuários ativos diários são contabilizados uma única vez quando os usuários entram em contato com o chatbot, sendo considerado o período entre as 00:00 e 23:59 do mesmo dia.',
      totalUsed: 'Total Utilizado',
      overspentWarningMessage: 'O limite de DAUs do plano acabou.',
      overspentWarningFeeDetails: 'Mas você pode continuar usando seu plano! Os DAUs excedentes estão sendo contabilizados.',
      overspentWarningFeeDetailsWithValues: 'Mas você pode continuar usando seu plano! Será cobrado {0} para cada DAU excedente.',
      baseValueLabel: 'Valor base do plano',
      totalMonthlyLabel: 'Total de DAUs por mês',
      totalMonthlyValue: '{0} DAUs',
      overspentCostLabel: 'Custo por DAUs excedente',
      overspentCostValue: '{0} por DAU',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    chatbotDailyActiveUserConsuption: {
      title: 'Chatbots',
      about: 'Acompanhe o consumo por DAUs por chatbot',
      columnChatbot: 'ID - Chatbot',
      columnDailyActiveUsers: 'DAUs',
      buttonAllChatbots: 'Mostrar detalhes',
    },
    dailyUsage: {
      title: 'Contabilização diária de usuários ativos',
      buttonTitle: 'Mostrar detalhes',
      tableInformation: 'Abaixo estão detalhadas mais informações do contrato.',
      byDaily: {
        date: 'Data',
        dailyActiveUsers: 'DAUs',
      },
    },      
  },
  en: {
    header: 'DAUs from {0} contract',
    locale: 'en-US',
    currency: 'BRL',
    monthPlaceholder: 'Month',
    compiledDailyActiveUserInformation: {
      title: 'Consumption by DAUs (Daily Active Users)',
      partialUsage: 'Partial consumption',
      about: 'From {0} to {1}',
      noContent: 'The data for {0} will be available from the 2nd.',
      planInformation: 'Plan {0} DAUs',
      filter: 'Use the filter to view the total number of activated agents each month.',
      spentPercentage: '{0}% of DAUs used included in the plan',
      overspentMessages: 'Overflow DAUs',
      progressContentHoverIcon: 'Daily active users (DAU) are counted a single time, when users contact the chatbot within a period between 00:00 and 23:59 of the same day.',
      totalUsed: 'Total used',
      overspentWarningMessage: 'The plan\'s DAU limit is over.',
      overspentWarningFeeDetails: 'But you can keep using your plan! The overflow DAUs are being booked.',
      overspentWarningFeeDetailsWithValues: 'But you can keep using your plan! {0} will be charged for each overflow DAU.',      
      baseValueLabel: 'Base value of the plan',
      totalMonthlyLabel: 'Total DAUs per month',
      totalMonthlyValue: '{0} DAUs',
      overspentCostLabel: 'Cost per overflow DAUs',
      overspentCostValue: '{0} per DAU',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    chatbotDailyActiveUserConsuption: {
      title: 'Chatbots',
      about: 'Track consumption by DAUs via chatbot.',
      columnChatbot: 'ID - Chatbot',
      columnDailyActiveUsers: 'DAUs',
      buttonAllChatbots: 'Show details',
    },
    dailyUsage: {
      title: 'Daily accounting of active users',
      buttonTitle: 'Show details',
      tableInformation: 'Below are detailed more information on the contract.',
      byDaily: {
        date: 'Date',
        dailyActiveUsers: 'DAUs',
      },
    },  
  },
  es: {
    header: 'Contrato de DAU de {0}',
    locale: 'es-ES',
    currency: 'BRL',
    monthPlaceholder: 'Mes',
    compiledDailyActiveUserInformation: {
      title: 'Consumo por DAUs (Usuarios Activos Diarios)',
      partialUsage: 'Consumo parcial',
      about: 'Del {0} al {1}',
      noContent: 'Los datos de {0} estarán disponibles a partir del 2.',
      planInformation: 'Plano {0} DAUs',
      filter: 'Utilice el filtro para ver el número total de agentes activados cada mes.',
      spentPercentage: '{0}% de las DAUs utilizadas incluidos en el plan',
      overspentMessages: 'DAU excedentes',
      progressContentHoverIcon: 'Daily active users (DAU) usuarios activos diarios (DAU) se contabilizan una sola vez, cuando los usuarios contactan con el chatbot en un periodo comprendido entre las 00:00 y las 23:59 del mismo día.',
      totalUsed: 'Total utilizado',
      overspentWarningMessage: 'El límite de DAU del plan ha terminado.',
      overspentWarningFeeDetails: '¡Pero puedes seguir usando tu plan! Los DAUs se están contabilizando.',
      overspentWarningFeeDetailsWithValues: '¡Pero puedes seguir usando tu plan! Se cobrará {0} por cada DAU excedente.',      
      baseValueLabel: 'Valor base del plan',
      totalMonthlyLabel: 'DAU totales por mes',
      totalMonthlyValue: '{0} DAUs',
      overspentCostLabel: 'Costo por DAU excedentes',
      overspentCostValue: '{0} por DAU',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    chatbotDailyActiveUserConsuption: {
      title: 'Chatbots',
      about: 'Seguimiento del consumo de DAU por chatbot.',
      columnChatbot: 'ID - Chatbot',
      columnDailyActiveUsers: 'DAUs',
      buttonAllChatbots: 'Mostrar detalles',
    },
    dailyUsage: {
      title: 'Contabilidad diaria de usuarios activos',
      buttonTitle: 'Mostrar detalles',
      tableInformation: 'A continuación se detalla más información sobre el contrato.',
      byDaily: {
        date: 'Data',
        dailyActiveUsers: 'DAUs',
      },
    }, 
  },
};
