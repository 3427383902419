import { ReferenceValue } from './ReferenceValue';

export class ContractRules {
  constructor(
    public tenantId: string,
    public contractId: number,
    public miu: string,
    public mainRuleName: string,
    public planBase: number,
    public contractInitialDate: Date,
    public referenceValues: Array<ReferenceValue>,
  ) {}

}