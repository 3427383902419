import React, { FC, useContext, useEffect } from 'react';
import { useCommon } from '../../../../contexts/CommonContext';
import agentConsumptionContext from '../../../../contexts/AgentConsumptionContext';
import { useContentLocalizer } from '../../../../hooks/useContentLocalizer';
import AgentConsumptionMonthlyUsage from '../../../AgentConsumption/components/AgentConsumptionMonthlyUsage';
import { localization } from '../../localizations';
import { AgentConsumptionContainer } from '../../styles';

const MonthlyUsageComponent: FC = () => {
  const { dateToSearch, tenant } = useContext(agentConsumptionContext);
  const { setHeaderContent } = useCommon();
  const content = useContentLocalizer(localization);

  useEffect(() => {
    setHeaderContent({
      redirect: '/old-agent-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: null });
  }, [dateToSearch]);

  return (
    <AgentConsumptionContainer>
      <AgentConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={-1}></AgentConsumptionMonthlyUsage>
    </AgentConsumptionContainer>
  );
};

export default MonthlyUsageComponent;
