import { ChatbotConsumption } from './ChatbotConsumption';

export class MRRBlipMetrics {
  public month!: string;
  public idContract!: number;
  public botIdentity!: string;
  public MAUs!: number;
  public MEUs!:number;
  public MSGs!:number;
  public MIUs!:number;
  public activeReport!: boolean;

  constructor(chatbotConsumption: Partial<MRRBlipMetrics>)
  {
    Object.assign(this, chatbotConsumption);
  }

  public toChatbotConsumption(miu: string): ChatbotConsumption {
    return new ChatbotConsumption(
      this.botIdentity,
      miu.includes('MAU') ? this.MAUs : this.MEUs,
      this.MSGs
    );
  }
}