import React, { FC, useContext, useEffect, useState } from 'react';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { BlipTable } from '../../../../components/BlipTable';
import { getMonthTwoDigits, toUTCDateString } from '../../../../utils/date';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { PaperHeaderComponent } from '../../components/Metrics/PaperHeader/PaperHeaderComponent';
import { MiuConsumptionPaper } from '../styles';
import MiuConsumptionContext from '../../../../contexts/MiuConsumptionContext';
import {
  action,
  origin,
  REPORT_REVENUE_CONSUMPTION_PER_CHATBOT_OPEN,
  REPORT_PERIOD_SELECT,
} from '../../../../shared/SegmentEvents';
import { billingRoutes, billingSubRoutes } from '../../../../routes/BillingRoutes';
import { BillingMetrics } from '../../../../shared/BillingMetrics';

export interface MiuConsumptionDailyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const MiuConsumptionDailyUsageComponent: FC<MiuConsumptionDailyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    dateToSearch,
    periodOptions,
    content,
    tenant,
    loggedUser,
    setHeaderContent,
    trackingProps,
    getPlanStatus,
    sortMiuConsumptionDailyUsage,
    setDateToSearch,
    billingMiuConsumptionApi,
    dailyMiuConsumptionDataState,
    setDailyMiuConsumptionDataState,
    miuMetricIds,
  } = useContext(MiuConsumptionContext);
  const isDetailedView = registersToTake === -1;
  const [isLoading, setLoading] = useState(false);

  const tableDailyUsageModel = [
    { label: content.daily.columnDateReference, key: 'dateReference' },
    { label: content.daily.columnTotalMiu, key: 'totalMiu' },
    { label: content.daily.columnTotalMsg, key: 'totalMsg' },
  ];

  useEffect(() => {
    setHeaderContent({
      redirect: billingRoutes.dataConsumption,
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: null });
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
        const miuMetricIdsString = miuMetricIds.join(';');

        const miuConsumptionDailyUsage = await billingMiuConsumptionApi.getDailyMiuConsumption(
          tenant.info.id,
          miuMetricIdsString,
          monthString,
          registersToTake,
        );

        const dailyActiveUserMonthlyUsageParse = miuConsumptionDailyUsage.map((obj) => {
          const totalMiu = obj.metricConsumption.find(
            (item) => item.metricId !== BillingMetrics.exchangedMessage,
          )?.total;
          const totalMsg = obj.metricConsumption.find(
            (item) => item.metricId === BillingMetrics.exchangedMessage,
          )?.total;

          return {
            ...obj,
            ...(totalMiu && { totalMiu: totalMiu.toLocaleString(content.locale) }),
            ...(totalMsg && { totalMsg: totalMsg.toLocaleString(content.locale) }),
            dateReference: toUTCDateString(obj.dateReference, content.locale),
          };
        });

        setDailyMiuConsumptionDataState(dailyActiveUserMonthlyUsageParse);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [dateToSearch]);

  const trackDailyButton = () => {
    trackEvent(REPORT_REVENUE_CONSUMPTION_PER_CHATBOT_OPEN, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.MIUCONSUMPTION,
      action: action.CLICK,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.MIUPERCHATBOT,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  return (
    <>
      {isDetailedView && (
        <PageHeaderComponent
          title={content.daily.title}
          dateToSearch={dateToSearch}
          periodOptions={periodOptions}
          periodOptionChangeEvent={periodOptionChangeEvent}
        />
      )}
      <MiuConsumptionPaper>
        <PaperHeaderComponent
          title={content.daily.title}
          subtitle={content.daily.subtitle}
          path={billingSubRoutes.mauConsumptionDaily}
          buttonCaption={content.daily.buttonShowAll}
          trackButton={trackDailyButton}
          isDetailedView={isDetailedView}
          idTest={'show-all-dash-miu-daily-consumption'}
        />
        <BdsPaper>
          <BlipTable
            idKey="miuConsumptions"
            model={tableDailyUsageModel}
            data={dailyMiuConsumptionDataState}
            selectedItems={[]}
            onSortSet={sortMiuConsumptionDailyUsage}
            canSelect={false}
            bodyHeight={tableBodyHeight}
            rowsPerPage={30}
            loading={isLoading}
          ></BlipTable>
        </BdsPaper>
      </MiuConsumptionPaper>
    </>
  );
};

export default MiuConsumptionDailyUsageComponent;
