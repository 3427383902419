import React from 'react';
import PropTypes from 'prop-types';
import { ChartJS } from '../index';
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartComponent = ({ data, options, plugins, redraw }) => {
  return <Bar options={options} data={data} plugins={plugins} redraw={redraw} />;
};

BarChartComponent.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.object,
  redraw: PropTypes.bool,
};

export { BarChartComponent };
