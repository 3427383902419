import React from 'react';
import PropTypes from 'prop-types';
import { AgentContract } from '../../components/AgentContract';
import { canUserWrite } from '../../services/PermissionService';

const AgentComponent = ({ loggedUser }) => {
  return (
    <>
      <AgentContract  canEdit={canUserWrite('tenant-members', loggedUser.roleId)} />
    </>
  );
};

AgentComponent.propTypes = {
  loggedUser: PropTypes.object.isRequired,
};

export { AgentComponent };
