import React, { FC, useContext, useEffect } from 'react';
import { useCommon } from '../../../../contexts/CommonContext';
import sessionConsumptionContext from '../../../../contexts/SessionConsumptionContext';
import { useContentLocalizer } from '../../../../hooks/useContentLocalizer';
import SessionConsumptionMonthlyUsage from '../../../SessionConsumption/components/SessionConsumptionMonthlyUsage';
import { localization } from '../../localization';
import { SessionConsumptionContainer } from '../../styles';

const MonthlyUsageComponent: FC = () => {
  const { dateToSearch, tenant, conversationTypeFilter, conversationWabaFilter } =
    useContext(sessionConsumptionContext);
  const { setHeaderContent } = useCommon();
  const content = useContentLocalizer(localization);

  useEffect(() => {
    setHeaderContent({
      redirect: '/old-session-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: null });
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  return (
    <SessionConsumptionContainer>
      <SessionConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={-1}></SessionConsumptionMonthlyUsage>
    </SessionConsumptionContainer>
  );
};

export default MonthlyUsageComponent;
