import PropTypes from 'prop-types';
import { createTrack } from '../../SegmentApi';
import { formatDate } from '../../../utils/date';
import { MetricStatus } from '../../../shared/BillingMetrics';

interface props {
  date: Date;
  planStatus: MetricStatus;
  action: string;
  origin: string;
}

export const trackEvent = async (trackingKey: string, tenant: any, loggedUser: any, props: props): Promise<void> => {
  createTrack(tenant, trackingKey, {
    ...props,
    plan: tenant.info.plan,
    user: loggedUser.email,
    tenantId: loggedUser.tenantId,
    organizationName: tenant.info.name,
    date: formatDate(props.date, 'en-US', '-', { month: '2-digit', year: 'numeric' }, true),
  });
};

trackEvent.propTypes = {
  tenant: PropTypes.any.isRequired,
  loggedUser: PropTypes.any.isRequired,
  trackingKey: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  planStatus: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
};
