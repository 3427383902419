export const localization = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo do contrato {0}',
  },
  en: {
    locale: 'en-US',
    header: '{0} usage report',
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo del plan de {0}',
  },
};
