import React from 'react';
import PropTypes from 'prop-types';
import { HeaderComponent } from './HeaderComponent';

const HeaderContainer = ({
  title,
  subtitle,
  buttonTitle,
  pathname,
  buttonTrackEvent,
  tooltipIcon,
  tooltipText,
  tooltipTrackEvent,
  idTest,
}) => {
  return (
    <HeaderComponent
      title={title}
      subtitle={subtitle}
      buttonTitle={buttonTitle}
      buttonTrackEvent={buttonTrackEvent}
      pathname={pathname}
      tooltipIcon={tooltipIcon}
      tooltipText={tooltipText}
      tooltipTrackEvent={tooltipTrackEvent}
      idTest={idTest}
    ></HeaderComponent>
  );
};

HeaderContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonTrackEvent: PropTypes.func,
  tooltipTrackEvent: PropTypes.func,
  pathname: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipText: PropTypes.string,
  idTest: PropTypes.string,
};

export { HeaderContainer };
