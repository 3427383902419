export const localization = {
  pt: {
    title: 'Dados de consumo do contrato {tenant}',
    chatbotsConsumptionTitle: 'Dados de consumo dos chatbots de {tenant}',
    thousandSeparator: '.',
    decimalSeparator: ',',
    locale: 'pt-BR',
    currency: 'BRL',
    firstDayOfMonth: 'Os dados de {month} serão apresentados a partir do dia 2. Volte amanhã ou escolha outro mês.',
    monthlyConsumption: {
      title: 'Consumo em {month}',
      partialUsage: 'Consumo parcial',
      period: 'De {startDate} a {endDate}',
      endInfo: '*O fechamento de um mês estará disponível em até 3 dias úteis a partir do último dia do mês referido.',
      selectPlaceholder: 'Período',
      metricTitle: 'Total de {metric}',
      messages: 'MSGs trafegadas',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    chatbotsConsumption: {
      title: 'Chatbots',
      subtitle: 'Confira o consumo de MIUs e MSGs por chatbot.',
      info: 'Confira o consumo de {metric} por chatbot.',
      button: 'Mostrar detalhes',
    },
    contractRules: {
      title: 'Contrato',
      info: 'Abaixo estão detalhadas as regras definidas no contrato.',
      consideredMetric: 'MIUs considerados',
      plan: 'Plano {plan}',
      baseValue: 'Base {value}',
      startDateTitle: 'Início do contrato',
      usageMetricsTitle: 'Métricas de consumo:',
      usageMetricsInfo: 'Conheça as métricas que serão usadas para calcular o consumo na plataforma.',
      advantageTitle: 'O mais vantajoso',
      advantageInfo: 'O cálculo de consumo é baseado na métrica que possuir menor custo no mês vigente.',
      consideredTitle: 'As métricas consideradas são:',
      consideredMIU: 'MIUs: Total de usuários faturados no mês',
      consideredMAU: 'MAUs: Total de usuários ativos no mês',
      consideredMEU: 'MEUs: Total de usuários no mês que enviaram mensagens ao bot',
      consideredMSG: 'MSGs: Total de mensagens trafegadas no mês',
      referenceTable: {
        title: 'Valores de referência',
        info: 'Especificação para cálculo do custo de acordo com a métrica de consumo e a faixa.',
        type: 'Tipo',
        range: 'Faixa',
        calculation: 'Cálculo',
        start: 'A partir de',
        middle: 'a',
        additional: 'por {metric} adicional',
      },
    },
  },
  en: {
    title: '{tenant} contract usage data',
    chatbotsConsumptionTitle: 'Dados de consumo dos chatbots de {tenant}',
    thousandSeparator: ',',
    decimalSeparator: '.',
    locale: 'en-US',
    currency: 'BRL',
    firstDayOfMonth: 'The data for {month} will be displayed from the 2nd. Come back tomorrow or choose another month.',
    monthlyConsumption: {
      title: 'Consumption in {month}',
      partialUsage: 'Partial consumption',
      period: 'From {startDate} to {endDate}',
      endInfo: '*Month-end closing will be available in up to 3 working days from the last day of the referred month.',
      selectPlaceholder: 'Time period',
      metricTitle: '{metric} total',
      messages: 'Exchanged MSGs',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    chatbotsConsumption: {
      title: 'Chatbots',
      subtitle: 'Confira o consumo de MIUs e MSGs por chatbot.',
      info: 'Check the number of {metrics} by each chatbot.',
      button: 'Show details',
    },
    contractRules: {
      title: 'Contract',
      info: 'Check out the detailed rules defined to your contract.',
      consideredMetric: 'Considered MIUs',
      plan: '{plan} plan',
      baseValue: 'Base {value}',
      startDateTitle: 'Contract start date',
      usageMetricsTitle: 'Usage metrics:',
      usageMetricsInfo: 'Get to know the metrics that will be used to calculate the usage on the platform.',
      advantageTitle: 'The most advantageous',
      advantageInfo: 'The usage calculation is based on the metric that has the lowest cost in the current month.',
      consideredTitle: 'The following metrics are considered:',
      consideredMIU: 'MIUs: Monthly invoiced users (According to billing rules)',
      consideredMAU: 'MAUs: Monthly active users',
      consideredMEU: 'MEUs: Monthly engaged users (Users that have sent any messages to your chatbot)',
      consideredMSG: 'MSGs: Monthly exchanged messages',
      referenceTable: {
        title: 'Reference values',
        info: 'Specifications for the billing rules according to the usage metrics by range.',
        type: 'Type',
        range: 'Range',
        calculation: 'Billing rules',
        start: 'Starts from',
        middle: 'to',
        additional: 'per additional {metric}',
      },
    },
  },
  es: {
    title: 'Datos de consumo del contrato {tenant}',
    chatbotsConsumptionTitle: 'Dados de consumo dos chatbots de {tenant}',
    thousandSeparator: '.',
    decimalSeparator: ',',
    locale: 'es-ES',
    currency: 'BRL',
    firstDayOfMonth: 'Los datos de {month} se mostrarán a partir del 2. Vuelve mañana o elige otro mes.',
    monthlyConsumption: {
      title: 'Consumo en {month}',
      partialUsage: 'Consumo parcial',
      period: 'Del {startDate} al {endDate}',
      endInfo: '*El cierre de un mes estará disponible en hasta 3 días hábiles a partir del último día del mes referido.',
      selectPlaceholder: 'Período de tiempo',
      metricTitle: 'Total de {metric}',
      messages: 'MSGs traficadas',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    chatbotsConsumption: {
      title: 'Chatbots',
      subtitle: 'Confira o consumo de MIUs e MSGs por chatbot.',
      info: 'Consulte el consumo de {metric} por chatbot.',
      button: 'Mostrar detalles',
    },
    contractRules: {
      title: 'Contrato',
      info: 'A continuación se detallan las reglas definidas en el contrato.',
      consideredMetric: 'MIUs consideradas',
      plan: 'Plan {plan}',
      baseValue: 'Base {value}',
      startDateTitle: 'Inicio del contrato',
      usageMetricsTitle: 'Métricas de consumo:',
      usageMetricsInfo: 'Conozca las métricas que se utilizarán para calcular el consumo en la plataforma.',
      advantageTitle: 'El mas ventajoso',
      advantageInfo: 'El cálculo del consumo se basa en la métrica que tiene el costo más bajo en el mes actual.',
      consideredTitle: 'Las métricas consideradas son:',
      consideredMIU: 'MIU: Usuarios totales facturados en el mes',
      consideredMAU: 'MAU: Usuarios activos totales en el mes',
      consideredMEU: 'MEU: Usuarios totales en el mes que enviaron mensajes al bot',
      consideredMSG: 'MSG: Total de mensajes traficados en el mes',
      referenceTable: {
        title: 'Valores de referencia',
        info: 'Especificación para el cálculo del costo según la métrica de consumo y el rango.',
        type: 'Tipo',
        range: 'Faja',
        calculation: 'Cálculo',
        start: 'A partir de',
        middle: 'a',
        additional: 'por {metric} adicional',
      },
    },
  },
};

