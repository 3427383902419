import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { TenantPendingMembersComponent } from './TenantPendingMembersComponent';
import { localization } from './localization';
import { acceptMember, rejectMember } from '../../api/TenantApi';
import { startLoading, stopLoading, showToast, showAlert } from '../../api/CommonApi';
import { useTenant } from '../../contexts/TenantContext';
import { useCommon } from '../../contexts/CommonContext';
import { createTrack } from 'api/SegmentApi';

import {
  ORGANIZATION_MEMBERS_INVITES,
  ORGANIZATION_MEMBERS_INVITES_REJECT_CONFIRM,
  ORGANIZATION_MEMBERS_INVITES_REJECT_CANCEL,
  ORGANIZATION_MEMBERS_INVITES_SORT,
} from '../../shared/SegmentEvents';

const TenantPendingMembersContainer = ({ members, canEdit = true }) => {
  const content = useContentLocalizer(localization);
  const [filteredMembers, setFilteredMembers] = useState(members);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { setMemberToAccepted, setMemberToRejected, tenant } = useTenant();
  const {
    common: { loggedUser },
  } = useCommon();
  const ONE_MEMBER = 1;

  useEffect(() => {
    setFilteredMembers(members);
    setSelectedMembers([]);
  }, [members]);

  const trackPendingMemberAnswer = async (status, action, qtdInvites) => {
    createTrack(tenant.info, ORGANIZATION_MEMBERS_INVITES, {
      status,
      action,
      qtdInvites,
    });
  };

  const trackRejectPendingMemberAlert = (status, userEmail, qtdInvites) => {
    let trackEvent;
    switch (status) {
      case 'confirm':
        trackEvent = ORGANIZATION_MEMBERS_INVITES_REJECT_CONFIRM;
        break;
      case 'cancel':
        trackEvent = ORGANIZATION_MEMBERS_INVITES_REJECT_CANCEL;
        break;
      default:
        return;
    }

    createTrack(tenant.info, trackEvent, {
      userEmail,
      qtdInvites,
    });
  };

  const sortMembers = (sort) => {
    const tempFilteredMembers = filteredMembers.sort((a, b) => {
      if (a[sort.property] < b[sort.property]) return sort.order === 'asc' ? -1 : 1;
      if (a[sort.property] > b[sort.property]) return sort.order === 'asc' ? 1 : -1;
      return 0;
    });
    createTrack(tenant.info, ORGANIZATION_MEMBERS_INVITES_SORT, {
      status: 'success',
      qtdInvites: tempFilteredMembers.length,
      sortType: sort.order,
      sortProperty: sort.property,
    });
    setFilteredMembers([...tempFilteredMembers]);
  };

  const approveMember = async (member) => {
    startLoading();
    try {
      await acceptMember(member);
      setMemberToAccepted(member);
      showToast({
        type: 'success',
        message: content.acceptMemberSuccess,
      });
      trackPendingMemberAnswer('success', 'approved', ONE_MEMBER);
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.acceptMemberError,
      });
      trackPendingMemberAnswer('failed', 'approved', ONE_MEMBER);
    } finally {
      stopLoading();
    }
  };

  const deleteMember = async (member) => {
    try {
      const confirmation = await showRejectUserAlert(ONE_MEMBER);

      if (confirmation.response) {
        trackRejectPendingMemberAlert('confirm', loggedUser.email, ONE_MEMBER);
        startLoading();
        await rejectMember(member);
        setMemberToRejected(member);
        showToast({
          type: 'success',
          message: content.rejectMemberSuccess,
        });
        trackPendingMemberAnswer('success', 'refused', ONE_MEMBER);
      } else {
        trackRejectPendingMemberAlert('cancel', loggedUser.email, ONE_MEMBER);
      }
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.rejectMemberError,
      });
      trackPendingMemberAnswer('failed', 'refused', ONE_MEMBER);
    } finally {
      stopLoading();
    }
  };

  const approveSelectedMembers = async () => {
    startLoading();
    try {
      await Promise.all(
        selectedMembers.map(async (member) => {
          await acceptMember(member);
          setMemberToAccepted(member);
        }),
      );
      showToast({
        type: 'success',
        message: selectedMembers.length !== 1 ? content.acceptMembersSuccess : content.acceptMemberSuccess,
      });
      trackPendingMemberAnswer('success', 'approved', selectedMembers.length);
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.acceptMembersError,
      });
      trackPendingMemberAnswer('failed', 'approved', selectedMembers.length);
    } finally {
      setSelectedMembers([]);
      stopLoading();
    }
  };

  const deleteSelectedMembers = async () => {
    try {
      const confirmation = await showRejectUserAlert(selectedMembers.length);
      if (confirmation.response) {
        trackRejectPendingMemberAlert('confirm', loggedUser.email, selectedMembers.length);
        startLoading();
        await Promise.all(
          selectedMembers.map(async (member) => {
            await rejectMember(member);
            setMemberToRejected(member);
          }),
        );
        showToast({
          type: 'success',
          message: selectedMembers.length !== 1 ? content.rejectMembersSuccess : content.rejectMemberSuccess,
        });
        trackPendingMemberAnswer('success', 'refused', selectedMembers.length);
        setSelectedMembers([]);
      } else {
        trackRejectPendingMemberAlert('cancel', loggedUser.email, selectedMembers.length);
      }
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.rejectMembersError,
      });
      trackPendingMemberAnswer('failed', 'refused', selectedMembers.length);
    } finally {
      stopLoading();
    }
  };

  const showRejectUserAlert = async (selectedMembers) => {
    const options = selectedMembers !== ONE_MEMBER ? content.alert.rejectMultipleUsers : content.alert.rejectUser;

    return showAlert({
      ...options,
      variant: 'delete',
      icon: 'trash',
    });
  };

  const tableModel = [
    { label: content.name, key: 'fullName' },
    { label: content.email, key: 'email' },
  ];
  return (
    <TenantPendingMembersComponent
      members={filteredMembers}
      content={content}
      tableModel={tableModel}
      sort={sortMembers}
      canEdit={canEdit}
      approveMember={approveMember}
      deleteMember={deleteMember}
      approveSelectedMembers={approveSelectedMembers}
      deleteSelectedMembers={deleteSelectedMembers}
      selectedMembers={selectedMembers}
      setSelectedMembers={setSelectedMembers}
    />
  );
};

TenantPendingMembersContainer.propTypes = {
  members: PropTypes.arrayOf(Object).isRequired,
  canEdit: PropTypes.bool,
};

export { TenantPendingMembersContainer };
