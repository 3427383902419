import React from 'react';
import PropTypes from 'prop-types';
import { LineChartComponent } from './LineChartComponent';

const LineChartContainer = ({ data, options, plugins, redraw }) => {
  return <LineChartComponent data={data} options={options} plugins={plugins} redraw={redraw}></LineChartComponent>;
};

LineChartContainer.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.object,
  redraw: PropTypes.bool,
};

export { LineChartContainer };
