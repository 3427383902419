import styled from 'styled-components';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { ContainerFullWidth } from 'components/Global/components';
import { FadeIn } from 'components/Global/animations';

export const MiuConsumptionContainer = styled(ContainerFullWidth)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const MiuConsumptionPaper = styled(BdsPaper)`
  margin-bottom: 2rem;
  padding: 1.5rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;

  & .activeMius {
    width: 40%;
  }

  & .activeMius-thumbnail {
    float: left;
  }

  & .activeMius-content {
    float: left;
    margin-left: 8px;
    width: 50%;
    font-weight: bold;
    white-space: nowrap;
  }

  & .activeMius-child {
    float: left;
    margin-left: 8px;
    width: 50%;
    white-space: nowrap;
  }
`;

export const MiuConsumptionConsolidatedRow = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const MiuConsumptionConsolidatedContent = styled.div`
  padding: 1rem;
  border-radius: 8px;
  white-space: nowrap;
  height: 104px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;

  & > bds-typo:first-of-type {
    margin-bottom: 8px;
    display: inline-block;
  }
`;

export const MiuConsumptionConsolidatedContentAligned = styled.div`
  display: flex;
`;

export const MiuConsumptionProgressContentAligned = styled.div`
  display: flex;
  width: 100%;
  height: 104px;
`;

export const MiuConsumptionProgressBarAligned = styled.div`
  width: 100%;
  white-space: nowrap;

  & > bds-typo:first-of-type {
    position: relative;
    top: -5px;
  }
  & bds-tooltip {
    display: inline-block;
    margin-left: 10px;
  }
`;

export const MiuConsumptionProgressTotalAligned = styled.div`
  width: 25%;
  margin-top: 35px;
  text-align: center;

  & > bds-typo.total-miu {
    margin: 0 8px;
  }
`;

export const MiuConsumptionWarningContentAligned = styled.div`
  margin-top: 20px;
`;

export const MiuConsumptionHeaderPartial = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.currentMonth == true ? 'space-between' : 'flex-end')};
`;
