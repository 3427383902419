import React from 'react';
import PropTypes from 'prop-types';
import { BdsLoadingSpinner } from 'blip-ds/dist/blip-ds-react';
import { BlipLoadingContainer } from './styles';

const BlipLoadingComponent = ({ align = 'center', size = 'small', color = 'main' }) => {
  return (
    <BlipLoadingContainer align={align}>
      <BdsLoadingSpinner size={size} color={color} />
    </BlipLoadingContainer>
  );
};

BlipLoadingComponent.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export { BlipLoadingComponent };
