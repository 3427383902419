import React, { useEffect, useState } from 'react';
import { BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTenant } from './../../../../contexts/TenantContext';
import { useCommon } from './../../../../contexts/CommonContext';
import { useBillingConsumptionContext } from '../../../../contexts/Billing/BillingConsumptionContext';
import { useBillingPlanUsageContext } from '../../../../contexts/Billing/BillingPlanUsageContext';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { localization as asideMenuLocalization } from './localization';
import { origin, action } from '../../../../shared/SegmentEvents';
import { billingRoutesTracks, billingSubRoutesTracks } from '../../../../routes/BillingRoutes';
import { useContentLocalizer } from '../../../../hooks/useContentLocalizer';
import { useMiuConsumptionContext } from '../../../../contexts/Billing/MiuConsumptionContext';
import './styles.scss';

export const AsideMenu: React.FC = ({ children }) => {
  const { dateToSearch } = useBillingConsumptionContext();
  const { planStatus } = useBillingPlanUsageContext();
  const { localization } = useMiuConsumptionContext();
  const content = useContentLocalizer(asideMenuLocalization);

  const history = useHistory();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  const [countTrack, setCountTrack] = useState(0);
  const {
    tenant,
    tenant: { privateRoutes },
  } = useTenant();
  const {
    common: { loggedUser },
  } = useCommon();

  const isExternalAccessRoute = history.action === 'POP';

  const trackingProps = {
    date: dateToSearch,
    planStatus: planStatus.current,
    origin: origin.ASIDEMENU,
    action: action.CLICK,
  };

  const routes = [...billingRoutesTracks, ...billingSubRoutesTracks];
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isExternalAccessRoute) {
        const trackingKey = routes.find((metric) => metric.path === currentPath);
        if (trackingKey && countTrack < 1) {
          trackEvent(trackingKey.segmentEvent, tenant, loggedUser, {
            ...trackingProps,
            origin: origin.EXTERNAL,
            action: action.OPEN,
          });
          setCountTrack(1);
        }
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [planStatus.current]);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const goTo = (path: string) => {
    history.push(path);
  };

  const allowedAsideMenuMetrics = privateRoutes.filter((item) => item.group === 'asideMenu');

  return (
    <div className="main-container">
      <div className="main-left-column">
        <ol className="main-side-nav-menuList">
          {allowedAsideMenuMetrics.map((item) => (
            <li
              onClick={() => trackEvent(item.segmentEvent, tenant, loggedUser, trackingProps)}
              key={item.option}
              id={item.option}
              className={item.path === currentPath ? 'active' : ''}
            >
              <div className={'side-menu-item-anchor'} onClick={() => goTo(item.path)}>
                <div className="side-menu-item-icon">
                  <BdsIcon name={item.icon} size="large"></BdsIcon>
                </div>
                <div className="side-menu-item-text">
                  <BdsTypo variant="fs-14" line-height="plus" bold="bold">
                    {item.option === 'mauConsumption'
                      ? content[item.group][localization.asideMenuLocalization].title
                      : content[item.group][item.option].title}
                  </BdsTypo>
                  <BdsTypo variant="fs-12" line-height="plus" tag="span" bold="regular">
                    {item.option === 'mauConsumption'
                      ? content[item.group][localization.asideMenuLocalization].subtitle
                      : content[item.group][item.option].subtitle}
                  </BdsTypo>
                </div>
              </div>
              <div className="side-menu-item-border-bottom"></div>
            </li>
          ))}
        </ol>
      </div>
      <div className="main-base-template-content">{children}</div>
    </div>
  );
};
