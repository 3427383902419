import React, { useContext, useEffect } from 'react';
import MiuConsumptionContext from '../../../../contexts/MiuConsumptionContext';
import { MiuConsumptionContainer } from '../styles';
import MiuConsumptionConsolidatedInformations from './MiuConsumptionConsolidatedInformations';
import MiuConsumptionMonthlyUsage from './MiuConsumptionMonthlyUsage';
import MiuConsumptionDailyUsage from './MiuConsumptionDailyUsage';

const MiuConsumptionSummaryComponent: React.FC = () => {
  const { tenant, content, setHeaderContent } = useContext(MiuConsumptionContext);

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: null });
  }, []);

  return (
    <MiuConsumptionContainer>
      <MiuConsumptionConsolidatedInformations></MiuConsumptionConsolidatedInformations>
      <MiuConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={5}></MiuConsumptionMonthlyUsage>
      <MiuConsumptionDailyUsage tableBodyHeight="1000px" registersToTake={5}></MiuConsumptionDailyUsage>
    </MiuConsumptionContainer>
  );
};

export default MiuConsumptionSummaryComponent;
