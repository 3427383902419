import moment from 'moment';
import { BlipApi } from './BlipApi';
import { RESOURE_NOT_FOUND_ERROR_CODE } from './CommonApi';
import { TenantAgents } from 'interfaces';
import { getAppSettings } from '../utils/appSettings';
import { MonthlyAgentConsumption } from 'models/agentConsumption/MonthlyAgentConsumption';
import BillingAgentConsumptionApi from './BillingAgentConsumptionApi';

const settings = async (item) => {
  const setting = await getAppSettings();
  return setting[item];
};

export const getAllTenantAgents = async (tenantId: string): Promise<TenantAgents[]> => {
  const billingApi = new BillingAgentConsumptionApi();
  const TENANT_AGENTS_PAGE_SIZE = await settings('TENANT_AGENTS_PAGE_SIZE');
  let page = 1;
  let agents: TenantAgents[] = [];
  let triggeredAgents: Array<MonthlyAgentConsumption> | null = [];

  try {
    do {
      const pageMembers = await getTenantAgents(tenantId, page);
      agents = [...agents, ...pageMembers];
      page++;
    } while (agents.length % TENANT_AGENTS_PAGE_SIZE === 0);
  } catch (e) {
    const parsedError = JSON.parse(JSON.parse(<string>e).message);
    if (parsedError.reason.code === RESOURE_NOT_FOUND_ERROR_CODE) {
      agents = [];
    } else {
      throw e;
    }
  }

  try {
    const yearMonth = moment(new Date()).format('YYYY-MM');
    triggeredAgents = await billingApi.getMonthlyAgentConsumption(tenantId, yearMonth, TENANT_AGENTS_PAGE_SIZE);
  } catch (e) {
    const parsedError = JSON.parse(JSON.parse(<string>e).message);
    if (parsedError.reason.code === RESOURE_NOT_FOUND_ERROR_CODE) {
      triggeredAgents = [];
    } else {
      throw e;
    }
  }

  agents = agents.map((agent) => {
    const triggeredAgent = triggeredAgents?.find((ta) => ta.agentEmail == agent.email);
    agent = {
      ...agent,
      creationDate: agent.creationDate ? new Date(agent.creationDate) : undefined,
      fullName: agent.fullName ? agent.fullName : decodeURIComponent(agent.userIdentity.split('@')[0]),
      email: agent.email ? agent.email : decodeURIComponent(agent.userIdentity.split('@')[0]),
      triggered: triggeredAgent !== undefined,
      additional: false,
      firstAttendance: triggeredAgent?.firstTicketDate,
      ticketsCount: triggeredAgent?.totalTickets ?? 0,
    };
    return agent;
  });

  const triggeredAgentsNotRegistred = triggeredAgents?.filter(
    (triggeredAgent) => !agents.find((agent) => triggeredAgent.agentEmail === agent.email),
  );

  const agentsNotFound: Array<TenantAgents> | undefined = triggeredAgentsNotRegistred?.map(
    (triggeredAgent): TenantAgents => {
      return {
        tenantId: '',
        userIdentity: triggeredAgent.agentEmail,
        fullName: triggeredAgent.agentEmail,
        email: triggeredAgent.agentEmail,
        roleId: '',
        userStatus: '',
        enabled: false,
        triggered: true,
        additional: false,
        firstAttendance: triggeredAgent.firstTicketDate,
        ticketsCount: triggeredAgent.totalTickets,
        applications: [],
      };
    },
  );
  
  if(agentsNotFound){ 
    agents = [...agents,... agentsNotFound];
  }
  return agents;
};

export const getTenantAgents = async (tenantId: string, page = 1) => {
  const TENANT_AGENTS_PAGE_SIZE = await settings('TENANT_AGENTS_PAGE_SIZE');
  const postMessage = await BlipApi.sendCommand({
    to: 'postmaster@portal.blip.ai',
    method: 'get',
    uri: `/tenants/${tenantId}/agents?$skip=${(page - 1) * TENANT_AGENTS_PAGE_SIZE}&$take=${TENANT_AGENTS_PAGE_SIZE}`,
  });

  const agents = postMessage.response.items.map((agent) => {
    agent = { ...agent, enabled: agent.isEnabled };
    return agent;
  });

  return agents;
};

export const getTenantAgentBots = async (tenantId: string, userIdentity: string) => {
  try {
    const postMessage = await BlipApi.sendCommand({
      to: 'postmaster@desk.msging.net',
      method: 'get',
      uri: `/tenants/${tenantId}/agents/${encodeURIComponent(userIdentity)}/owners`,
    });
    return postMessage.response.items;
  } catch (error) {
    return [];
  }
};

export const setEnableAgent = async (tenantId, agent) => {
  return await BlipApi.sendCommand({
    to: 'postmaster@portal.blip.ai',
    method: 'set',
    uri: `/tenants/${tenantId}/agents-is-enabled`,
    type: 'application/vnd.iris.portal.tenant-agent-user+json',
    resource: agent,
  });
};

export const setAgentContract = async (tenantId, agent) => {
  const BLIP_DOMAIN = await settings('BLIP_DOMAIN');
  const agents = await agent.map((userIdentity) => {
    return { userIdentity: encodeURIComponent(userIdentity).concat(`@${BLIP_DOMAIN}`) };
  });

  await BlipApi.sendCommand({
    to: 'postmaster@portal.blip.ai',
    method: 'set',
    uri: `/tenants/${tenantId}/agents`,
    type: 'application/vnd.lime.collection+json',
    resource: {
      itemType: 'application/vnd.iris.portal.tenant-agent-user+json',
      items: agents,
    },
  });
};

export const removeAgentContract = async (tenantId, userIdentity) => {
  const BLIP_DOMAIN = await settings('BLIP_DOMAIN');
  return BlipApi.sendCommand({
    method: 'delete',
    to: 'postmaster@portal.blip.ai',
    uri: `/tenants/${tenantId}/agents/${encodeURIComponent(userIdentity).concat(`@${BLIP_DOMAIN}`)}`,
  });
};
