export const localization = {
  pt: {
    locale: 'pt-BR',
    title: 'Visão geral do plano',
  },
  en: {
    locale: 'en-US',
    title: 'Plan Overview',
  },
  es: {
    locale: 'es-ES',
    title: 'Descripción general del plan',
  },
};
