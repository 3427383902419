import { BillingMetricConsumption } from '../metrics/BillingMetricConsumption';

export class DailyMiuConsumption {
  botName!: string;
  botIdentity!: string;
  metricConsumption!: BillingMetricConsumption[];

  constructor(dailyMiuConsumption: Partial<DailyMiuConsumption>) {
    Object.assign(this, dailyMiuConsumption);
  }
}
