import React, { useContext, useState } from 'react';
import {
  BdsButton,
  BdsTypo,
  BdsModalAction,
  BdsGrid,
  BdsInput,
  BdsSelect,
  BdsDatepicker,
  BdsStepper,
  BdsStep,
  BdsPaper,
  BdsUpload,
  BdsList,
  BdsListItem,
} from 'blip-ds/dist/blip-ds-react';
import MtlsContext from '../../../../contexts/MtlsContext';

const UploadCertificate: React.FC = () => {
  return (
    <BdsUpload
      multiple={false}
      //error="There was an error attaching the file, please try again or select another file"
      titleName="Title uploader"
      subtitle="Description uploades, e.g.: only .jpg files at 10mb or less"
    ></BdsUpload>
  );
};

const FormCertificate: React.FC = () => {
  return (
    <BdsGrid direction="column" gap="2" height="100%" padding="none">
      <BdsTypo variant="fs-20" bold="bold" margin={false}>
        Cadastrar novo certificado mTLS
      </BdsTypo>
      <BdsTypo variant="fs-14">
        Insira a sua descrição aqui. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
      </BdsTypo>
      <BdsGrid flexWrap="wrap" padding="none" justifyContent="space-between">
        <BdsGrid xxs="6" padding="l-none" margin="b-2">
          <BdsInput label="Nome" placeholder="Insira o nome do novo certificado"></BdsInput>
        </BdsGrid>
        <BdsGrid xxs="6" padding="none">
          <BdsSelect
            style={{ width: '100%' }}
            label="Bots conectados"
            placeholder="Quais bots utilizaram a certificação?"
          ></BdsSelect>
        </BdsGrid>
        <BdsGrid xxs="6" padding="l-none">
          <BdsInput label="URL" placeholder="Insira o nome do novo certificado"></BdsInput>
        </BdsGrid>
        <BdsGrid xxs="6" padding="none">
          <BdsDatepicker style={{ width: '100%' }}></BdsDatepicker>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
};

const CertificateConfirmation: React.FC = () => {
  return (
    <BdsList>
      <BdsListItem value="01" text="Here you put the text of the item"></BdsListItem>
      <BdsListItem value="02" text="Here you put the text of the item"></BdsListItem>
    </BdsList>
  );
};

const SubmitCertificate: React.FC = () => {
  const [step, setStep] = useState(0);
  const handleNextStep = async () => {
    const stepperComponent = document.querySelector('bds-stepper');
    if (!stepperComponent) return;
    const activeStep = await stepperComponent.getActiveStep();
    stepperComponent.setActiveStep(activeStep + 1);
    stepperComponent.setCompletedStep(activeStep);
    setStep(activeStep + 1);
  };
  const handlePreviousStep = async () => {
    const stepperComponent = document.querySelector('bds-stepper');
    if (!stepperComponent) return;
    const activeStep = await stepperComponent.getActiveStep();
    stepperComponent.setActiveStep(activeStep - 1);
    setStep(activeStep - 1);
  };
  const getStepContentByIndex = (index) => {
    const content = {
      0: <UploadCertificate />,
      1: <FormCertificate />,
      2: <CertificateConfirmation />,
    };
    return content[index];
  };

  const { closeSubmitModal } = useContext(MtlsContext);
  const handleClick = () => {
    closeSubmitModal();
    showSucessToast();
  };

  const showSucessToast = async () => {
    const newtoast = document.createElement('bds-toast');
    document.body.appendChild(newtoast);

    const icon = 'like';
    const variant = 'success';
    const toastTitle = '';
    const actionType = 'icon';
    const toastText = 'Certificado adicionado com sucesso';
    const buttonAction = 'close';

    await newtoast.create({
      variant,
      actionType,
      toastTitle,
      buttonAction,
      toastText,
      icon,
    });
  };

  return (
    <>
      <BdsStepper>
        <BdsStep active={true}>Upload do certificado</BdsStep>
        <BdsStep>Informações do certificado</BdsStep>
        <BdsStep>Conferência</BdsStep>
      </BdsStepper>
      <div style={{ padding: '2rem', margin: '2rem 0', border: '1px solid #ccc', borderRadius: '5px' }}>
        {getStepContentByIndex(step)}
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        {(step == 1 || step == 2) && (
          <BdsButton
            style={{
              marginRight: '8px',
            }}
            onClick={handlePreviousStep}
          >
            Previous step
          </BdsButton>
        )}
        {(step == 0 || step == 1) && <BdsButton onClick={handleNextStep}>Next step</BdsButton>}
        {step == 2 && <BdsButton onClick={handleNextStep}>Finish</BdsButton>}
        {step === 3 && handleClick()}
      </div>
    </>
  );
};

export default SubmitCertificate;
