export const localization = {
  pt: {
    locale: 'pt-BR',
    name: 'Nome',
    url: 'URL',
    expiration: 'Expiração',
    status: 'Status',
    actions: 'Ações',
    title: 'Certificados MTLS',
    subtitle: 'Listagem de certificados MTLS',
    validationStatus: {
      valid: 'Válido',
      invalid: 'Inválido',
      underValidation: 'Em validação',
    },
    errorToast: 'Erro ao carregar certificados MTLS',
  },
  en: {
    locale: 'en-US',
    name: 'Name',
    url: 'URL',
    expiration: 'Expiration',
    status: 'Status',
    actions: 'Actions',
    title: 'MTLS Certificates',
    subtitle: 'MTLS certificates listing',
    validationStatus: {
      valid: 'Valid',
      invalid: 'Invalid',
      underValidation: 'Under validation',
    },
    errorToast: 'Error loading MTLS certificates',
  },
  es: {
    locale: 'es-ES',
    name: 'Nombre',
    url: 'URL',
    expiration: 'Vencimiento',
    status: 'Estado',
    actions: 'Acciones',
    title: 'Certificados MTLS',
    subtitle: 'Listado de certificados MTLS',
    validationStatus: {
      valid: 'Válido',
      invalid: 'Inválido',
      underValidation: 'En validación',
    },
    errorToast: 'Error al cargar certificados MTLS',
  },
};
