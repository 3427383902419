import { BillingMetrics } from 'shared/BillingMetrics';
import { BillingPlanMetric } from '../metrics/BillingPlanMetric';

export class ConsolidatedAgentConsumption extends BillingPlanMetric {
  constructor(consolidatedAgentConsumption: Partial<ConsolidatedAgentConsumption | null>) {
    super(consolidatedAgentConsumption);
    this.metric.metricId = BillingMetrics.agent;
    Object.assign(this, consolidatedAgentConsumption);
  }
}
