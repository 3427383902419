import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext, useMemo } from 'react';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersPlainInfotmationRow } from '../styles';

const CompiledPlanInformationsComponent: React.FC = () => {
  const { content, compiledDailyActiveUserDataState } = useContext(DailyActiveUsersContext);

  const planContent = useMemo(() => {
    if (compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth) {
      return content.formatString(
        content.compiledDailyActiveUserInformation.planInformation,
        compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.metric.quantity.toLocaleString(),
      );
    } else {
      return content.formatString(content.compiledDailyActiveUserInformation.planInformation, '--');
    }
  }, [compiledDailyActiveUserDataState]);

  return (
    <CompiledDailyActiveUsersPlainInfotmationRow>
      <BdsTypo tag="h4" variant="fs-20" bold="bold" margin={false} >
        {planContent}
      </BdsTypo>
      <BdsTypo variant="fs-14">
        {content.compiledDailyActiveUserInformation.filter}
      </BdsTypo>    
    </CompiledDailyActiveUsersPlainInfotmationRow>
  );
};

export default CompiledPlanInformationsComponent;
