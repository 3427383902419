import React, { Reducer, useEffect, useMemo, useReducer, useState } from 'react';
import { useCommon } from '../../contexts/CommonContext';
import { useTenant } from '../../contexts/TenantContext';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { localization } from './localization';
import SessionConsumptionContext from '../../contexts/SessionConsumptionContext';
import { Route } from 'react-router';
import SessionConsumptionSummary from './components/SessionConsumptionSummary';
import { generateMonthListBetweenDates, getDayBeforeToday, getFirstDayOfMonth } from '../../utils/date';
import { startLoading, stopLoading } from '../../api/CommonApi';
import { BdsSelectOption } from 'blip-ds/dist/blip-ds-react';
import { Switch } from 'react-router-dom';
import { ConsolidatedSessionConsumption } from '../../models/sessionConsumption/ConsolidatedSessionConsumption';
import BillingSessionConsumptionApi from '../../api/BillingSessionConsumptionApi';
import { MonthlySessionConsumption } from '../../models/sessionConsumption/MonthlySessionConsumption';
import { WabaConversationConsumption } from '../../models/sessionConsumption/WabaConversationConsumption';
import { sortByProperty } from '../../utils/array';
import { DailySessionConsumption } from '../../models/sessionConsumption/DailySessionConsumption';
import MonthlyUsage from './components/Monthly/MonthlyUsage';
import DailyUsage from './components/Daily/DailyUsage';
import { billingRoutes, billingSubRoutes } from '../../routes/BillingRoutes';

interface CompiledSessionConsumptionDataState {
  consolidatedSessionConsumption: ConsolidatedSessionConsumption;
}

const SessionConsumption: React.FC = () => {
  const {
    setHeaderContent,
    common: { config, loggedUser },
  } = useCommon();
  const { tenant } = useTenant();
  const content = useContentLocalizer(localization);
  const [conversationTypeFilter, setConversationTypeFilter] = useState(content.defaultConversationTypeValue);
  const [conversationWabaFilter, setConversationWabaFilter] = useState('');
  const billingSessionConsumptionApi = new BillingSessionConsumptionApi();
  const [dateToSearch, setDateToSearch] = useState(getFirstDayOfMonth());
  const [conversationWabaFilterOptions, setConversationWabaFilterOptions] = useState<WabaConversationConsumption[]>([]);
  const [sessionConsumptionMonthlyUsage, setSessionConsumptionMonthlyUsage] = useState<MonthlySessionConsumption[]>([]);
  const [sessionConsumptionDailyUsage, setSessionConsumptionDailyUsage] = useState<DailySessionConsumption[]>([]);
  const [compiledSessionConsumptionDataState, setCompiledSessionConsumptionDataState] = useReducer<
    Reducer<CompiledSessionConsumptionDataState, Partial<CompiledSessionConsumptionDataState>>
  >(
    (compiledSessionConsumptionDataState, newCompiledSessionConsumptionDataState) => ({
      ...compiledSessionConsumptionDataState,
      ...newCompiledSessionConsumptionDataState,
    }),
    {
      consolidatedSessionConsumption: {
        startDateReference: getFirstDayOfMonth(),
        endDateReference: getDayBeforeToday(),
        sessionByUser: 0,
        sessionByCompany: 0,
        sessionTotal: 0,
      },
    },
  );

  const initialDate = new Date(config.REVENUE_START_DATE);
  initialDate.setSeconds(0);

  useEffect(() => {
    startLoading();

    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });

    const getData = async () => {
      const wabaConversationConsumption = await billingSessionConsumptionApi.getWabaConversationConsumption(
        tenant.info.id,
      );

      const wabaOptions = [];
      wabaConversationConsumption.map((wabaId, index) => {
        if (index == 0) {
          setConversationWabaFilter(wabaId);
        }

        wabaOptions.push(
          <BdsSelectOption value={wabaId} key={wabaId}>
            {wabaId}
          </BdsSelectOption>,
        );
      });
      setConversationWabaFilterOptions(wabaOptions);
    };
    getData();

    stopLoading();

    return () => setHeaderContent({ redirect: null });
  }, []);

  const periodOptions = useMemo(() => {
    const monthList = generateMonthListBetweenDates(initialDate, new Date()).sort(
      (first, second) => second.getTime() - first.getTime(),
    );

    return monthList.map((monthDate) => {
      const month = monthDate.getMonth();
      const year = monthDate.getFullYear();

      return (
        <BdsSelectOption value={monthDate.toISOString()} key={`${month}-${year}`}>
          {content.consolidated.month[month]} {year}
        </BdsSelectOption>
      );
    });
  }, []);

  const conversationTypeFilterOptions = useMemo(() => {
    return content.conversationType.map((conversationType) => {
      return (
        <BdsSelectOption value={conversationType.value} key={conversationType.value}>
          {conversationType.label}
        </BdsSelectOption>
      );
    });
  }, []);

  const sortSessionConsumptionMonthlyUsage = async (sort) => {
    const sortedChatbotsConsumption = sortByProperty(sessionConsumptionMonthlyUsage, sort.property, sort.order);
    setSessionConsumptionMonthlyUsage([...sortedChatbotsConsumption]);
  };

  const sortSessionConsumptionDailyUsage = async (sort) => {
    const sortedDailySessionUsage = sortByProperty(sessionConsumptionDailyUsage, sort.property, sort.order);
    setSessionConsumptionDailyUsage([...sortedDailySessionUsage]);
  };

  const currentMonth = useMemo(() => {
    const month = dateToSearch.getMonth() + 1 === new Date().getMonth() + 1;
    const year = dateToSearch.getFullYear() === new Date().getFullYear();
    return month && year;
  }, [dateToSearch]);

  const contextProps = {
    currentMonth,
    dateToSearch,
    conversationTypeFilter,
    conversationWabaFilter,
    compiledSessionConsumptionDataState,
    content,
    loggedUser,
    tenant,
    billingSessionConsumptionApi,
    sessionConsumptionMonthlyUsage,
    sessionConsumptionDailyUsage,
    periodOptions,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    sortSessionConsumptionMonthlyUsage,
    setSessionConsumptionMonthlyUsage,
    sortSessionConsumptionDailyUsage,
    setSessionConsumptionDailyUsage,
    setCompiledSessionConsumptionDataState,
    setDateToSearch,
    setConversationWabaFilter,
    setConversationTypeFilter,
  };

  return (
    <SessionConsumptionContext.Provider value={contextProps}>
      <Switch>
        <Route exact path={billingRoutes.oldSessionConsumption} component={SessionConsumptionSummary}></Route>
        <Route exact path={billingSubRoutes.oldSessionConsumptionMonthly} component={MonthlyUsage}></Route>
        <Route exact path={billingSubRoutes.oldSessionConsumptionDaily} component={DailyUsage}></Route>
      </Switch>
    </SessionConsumptionContext.Provider>
  );
};

export default SessionConsumption;
