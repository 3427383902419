import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BlipTable } from 'components/BlipTable';
import { BlipDropdownButton } from 'components/BlipDropdownButton';
import { BlipIcon } from 'components/BlipIcon';

const TenantPendingMembersComponent = ({
  members,
  content,
  tableModel,
  sort,
  canEdit,
  approveMember,
  deleteMember,
  approveSelectedMembers,
  deleteSelectedMembers,
  selectedMembers,
  setSelectedMembers,
}) => {
  const [membersWithActions, setMembersWithActions] = useState([]);

  const getActions = useCallback((accept, refuse) => [
    <button onClick={accept} key="accept" className="bp-btn bp-btn--text-only bp-btn--bot">{content.accept}</button>,
    <button onClick={refuse} key="delete" className="bp-btn bp-btn--text-only bp-btn--delete">{content.delete}</button>,
  ], [content]);

  const getHeaderActions = (accept, refuse) => [
    <BlipDropdownButton
      key="accept"
      buttonTestId="accept-button"
      buttonClass="bp-btn--text-only bp-fs-4"
      dropdownClass="w5"
      arrowSide="right"
      label={<BlipIcon color="desk" name="check-ball" />}
      cancelLabel={content.cancel}
      footer={[
        <button key="accept" onClick={accept} className="bp-btn bp-btn--blip-dark bp-btn--text-only">{content.accept}</button>,
        <button key="cancel" className="bp-btn bp-btn--silver bp-btn--text-only">{content.cancel}</button>,
      ]}
    >
      <p>{content.accept} <span className="bp-c-blip-dark">{selectedMembers.length}</span> {content.members}?</p>
      <p className="pt2 bp-fs-7">{content.deleteMessage}</p>
    </BlipDropdownButton>,
    <button key="delete" className="bp-btn bp-btn--text-only bp-fs-4" onClick={refuse}>
      <BlipIcon color="desk" name="trash" />
    </button>,

  ];

  useEffect(
    () => {
      setMembersWithActions(members.map(
        member => ({ ...member, actions: getActions(() => approveMember(member), () => deleteMember(member)) }))
      );
    }, [members, getActions, approveMember, deleteMember]
  );

  return <div className="TenantPendingMembers flex flex-column items-end">
    <BlipTable
      idKey="userIdentity"
      model={tableModel}
      data={membersWithActions}
      onItemSelect={setSelectedMembers}
      selectedItems={selectedMembers}
      onSortSet={sort}
      canSelect={canEdit}
      actions={getHeaderActions(approveSelectedMembers, deleteSelectedMembers)}
      emptyMessage={content.emptyMessage}
      bodyHeight='400px'
    />
  </div>;
};

TenantPendingMembersComponent.propTypes = {
  members: PropTypes.arrayOf(Object).isRequired,
  content: PropTypes.object.isRequired,
  tableModel: PropTypes.array.isRequired,
  sort: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  approveMember: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
  approveSelectedMembers: PropTypes.func.isRequired,
  deleteSelectedMembers: PropTypes.func.isRequired,
  selectedMembers: PropTypes.arrayOf(Object).isRequired,
  setSelectedMembers: PropTypes.func.isRequired,
};

export { TenantPendingMembersComponent };
