import React, { FC, useContext, useEffect } from 'react';
import miuConsumptionContext from '../../../../../contexts/MiuConsumptionContext';
import { billingRoutes } from '../../../../../routes/BillingRoutes';
import MiuConsumptionMonthlyUsage from '../../../MiuConsumption/components/MiuConsumptionMonthlyUsage';
import { MiuConsumptionContainer } from '../../styles';

const MonthlyUsageComponent: FC = () => {
  const { content, dateToSearch, tenant, setHeaderContent } = useContext(miuConsumptionContext);
  useEffect(() => {
    setHeaderContent({
      redirect: billingRoutes.mauConsumption,
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: '/' });
  }, [dateToSearch]);

  return (
    <MiuConsumptionContainer>
      <MiuConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={-1}></MiuConsumptionMonthlyUsage>
    </MiuConsumptionContainer>
  );
};

export default MonthlyUsageComponent;
