import React, { createContext, useMemo, useState } from 'react';
import { BdsSelectOption } from 'blip-ds/dist/blip-ds-react';
import { generateMonthListBetweenDates, getFirstDayOfMonth } from '../../utils/date';
import { localization } from '../../shared/localization';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';

interface BillingConsumptionContextData {
  dateToSearch: Date;
  setDateToSearch: (value: Date) => void;
  currentMonth: boolean;
  periodOptions: any[];
}

interface props {
  common: any;
  tenant: any;
}

export const BillingConsumptionContext = createContext<BillingConsumptionContextData>(
  {} as BillingConsumptionContextData,
);

export const BillingConsumptionContextProvider: React.FC<props> = ({ children, common, tenant }) => {
  const [dateToSearch, setDateToSearch] = useState(getFirstDayOfMonth());
  const content = useContentLocalizer(localization);

  const initialDate = new Date(common.config.REVENUE_START_DATE);
  initialDate.setSeconds(0);

  const currentMonth = useMemo(() => {
    const month = dateToSearch.getMonth() + 1 === new Date().getMonth() + 1;
    const year = dateToSearch.getFullYear() === new Date().getFullYear();
    return month && year;
  }, [dateToSearch]);

  const periodOptions = useMemo(() => {
    const monthList = generateMonthListBetweenDates(initialDate, new Date()).sort(
      (first, second) => second.getTime() - first.getTime(),
    );

    return monthList.map((monthDate) => {
      const month = monthDate.getMonth();
      const year = monthDate.getFullYear();

      return (
        <BdsSelectOption value={monthDate.toISOString()} key={`${month}-${year}`}>
          {content.months[month]} {year}
        </BdsSelectOption>
      );
    });
  }, []);

  return (
    <BillingConsumptionContext.Provider
      value={{
        dateToSearch,
        setDateToSearch,
        currentMonth,
        periodOptions,
      }}
    >
      {children}
    </BillingConsumptionContext.Provider>
  );
};

export function useBillingConsumptionContext(): BillingConsumptionContextData {
  const context = React.useContext(BillingConsumptionContext);

  if (!context) {
    throw new Error('use billingconsumptioncontext context must be used within an BillingConsumptionContextProvider');
  }

  return context;
}
