import React from 'react';
import { BdsTableBody, BdsTableHeader, BdsTableRow, BdsTableTh } from 'blip-ds/dist/blip-ds-react';
import { Table } from '../styles';
import { BdsCheckboxCustomEvent } from 'blip-ds/loader';
import { WhatsAppApplication } from '../typing/models/whatsapp-application.interface';
import ListItem from '../ListItem';

interface ListBotCallsProps {
  bots: WhatsAppApplication[];
  translate: Record<string, any>;
  onEnableCalling: (application: WhatsAppApplication, enabled: boolean) => Promise<boolean>;
  onEnableIconVisibility: (application: WhatsAppApplication, enabled: boolean) => Promise<boolean>;
  onChangeSelectBot: (event: BdsCheckboxCustomEvent<any>) => void;
}

const ListBotCalls: React.FC<ListBotCallsProps> = ({
  bots,
  translate,
  onEnableCalling,
  onEnableIconVisibility,
  onChangeSelectBot,
}) => {
  return bots?.length ? (
    <Table>
      <BdsTableHeader>
        <BdsTableRow>
          <BdsTableTh className="checkbox-th" />
          <BdsTableTh>{translate.list.title}</BdsTableTh>
          <BdsTableTh>{translate.list.phone}</BdsTableTh>
          <BdsTableTh>{translate.list.iconVisibility}</BdsTableTh>
          <BdsTableTh className="action-th">{translate.list.action}</BdsTableTh>
        </BdsTableRow>
      </BdsTableHeader>
      <BdsTableBody>
        {bots.map((bot, idx) => {
          return (
            <ListItem
              key={`${idx}-${bot.id}`}
              bot={bot}
              translate={translate.detailsBots}
              onEnableCalling={onEnableCalling}
              onEnableIconVisibility={onEnableIconVisibility}
              onChangeSelectBot={onChangeSelectBot}
            />
          );
        })}
      </BdsTableBody>
    </Table>
  ) : (
    <></>
  );
};

ListBotCalls.displayName = 'ListBotCalls';

export default ListBotCalls;
