export const localization = {
  pt: {
    loadOrganizationError: 'Falha ao carregar contrato',
  },
  en: {
    loadOrganizationError: 'Failed to load contract',
  },
  es: {
    loadOrganizationError: 'No se pudo cargar el contrato',
  },
};
