export const localization = {
  pt: {
    title: 'Lista de Atendentes',
    agent: 'Atendente',
    email: 'Email',
    isEnabled: 'É acionável?',
    bot: 'Bot',
    createdAt: 'Data de cadastro',
    triggered: 'Atendente acionado',
    true: 'Sim',
    false: 'Não',
    excess: 'Cobrança adicional',
    checkEnabled: 'Ver somente acionados',
    add: 'Cadastrar atendente',
    addAgentSuccess: 'Para Iniciar a operação você precisa associar estes atendentes a uma fila de atendimento.',
    understood: 'Entendi',
    edit: 'Editar',
    cancel: 'Cancelar',
    apply: 'Confirmar',
    continue: 'Continuar',
    delete:'Excluir Atendente',
    deleteAgents: {
      title:'Excluir Atendente',
      body: 'Deseja excluir o atendente?',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },
    disabledTriggeredAgents: {
      title:'Desabilitar atendente',
      body: 'Atendente foi acionado no período, não sendo possível excluí-lo.<br> Deseja torná-lo não acionável?',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },    
    disabledAgents: {
      title:'Não foi possível Excluir!',
      body: 'Essa ação irá remover o Atendente de seus respectivos Bots',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },
    deleteAgentsSuccess: 'Atendente excluído com sucesso',
    deleteAgentsError: 'Falha ao excluir atendente',
    enableAgentSuccess: 'Atendente habilitado para acionamento',
    enableAgentError: 'Falha ao habilitar atendente para acionamento',
    disableAgentSuccess: 'Atendente desabilitado para acionamento',
    diableAgentError: 'Falha ao desabilitar atendente para acionamento',
    enabled: 'Acionável',
    disabled: 'Não acionável',
    true: 'Sim',
    false: 'Não',
    locale: 'pt-BR',
    searchNotFound:{
      title: 'Atendente não encontrado :(',
      subTitle:'Não há atendentes cadastrados com esse nome.',
    },
    modal: {
      title: 'Cadastrar Atendentes',
    },
    modalBilling: {
      title: 'Atenção',
      subTitle: 'Atendimento passível de cobrança extra.',
      body:
        'Você atingiu o limite de atendentes acionados do seu plano, e caso este atendente faça algum atendimento, será gerada uma cobrança extra de {additionalItemValue} no contrato do mês de {month}.',
      plan: 'Seu Plano atual',
      limitAgentPlan: 'Limite de Atendentes do Plano',
      agentActiveMonth: 'Atendentes Acionados no mês',
      confirm: 'Deseja continuar mesmo assim?',
      month: 'Mês',
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    success: {
      setAgent: 'Atendente criado com sucesso!',
    },
    error: {
      email: 'Formato de endereço de e-mail inválido',
      setAgent: 'Falha ao atualizar Atendente',
    },
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
  en: {
    title: 'Agent List',
    agent: 'Agent',
    email: 'Email',
    isEnabled: 'Is triggerable?',
    bot: 'Bot',
    createdAt: 'Registration date',
    triggered: 'Agent triggered',
    excess: 'Additional billing',
    checkEnabled: 'View only triggered',
    add: 'Add agent',
    addAgentSuccess: 'To start the operation, you need to associate these agents to a service list.',
    understood: 'I understood',
    edit: 'Edit',
    cancel: 'Cancel',
    apply: 'Confirm',
    continue: 'Continue',
    deleteAgentsSuccess: 'Agent successfully deleted!',
    delete:'Delete Agent',
    deleteAgents: {
      title:'Delete Agent',
      body: 'Do you want to delete the Agent?',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    disabledTriggeredAgents: {
      title:'Unable to delete!',
      body: 'Agent was trigger during the period, and it is not possible to delete him.<br> Do you want to make it non-actionable?',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    disabledAgents: {
      title:'Disabled agent',
      body: 'This action will remove the agent from their respective Bots',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    deleteAgentsError: 'Failed to delete agent',
    enableAgentSuccess: 'Agent enabled for activation',
    enableAgentError: 'Failed to enable agent to trigger',
    disableAgentSuccess: 'Agent disabled for activation',
    diableAgentError: 'Failed to disable agent for trigger',
    enabled: 'Actionable',
    disabled: 'No actionable',
    true: 'Yes',
    false: 'No',
    locale: 'en-US',
    searchNotFound:{
      title: 'Agent not found :(',
      subTitle:'There are no Agents registered with that name.',
    },
    modal: {
      title: 'Register Agents',
    },
    modalBilling: {
      title: 'Attention',
      subTitle: 'Service subject to extra charge.',
      body:
        'You have reached the limit of activated agents in your plan, and if this agent does any assistance, an extra charge of {additionalItemValue} will be generated in the {months} contract.',
      plan: 'Your current plan',
      limitAgentPlan: 'Plan Agent Limit',
      agentActiveMonth: 'Agents activated in the month',
      confirm: 'Do you want to continue anyway?',
      month: 'Month',
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    success: {
      setAgent: 'Successfully created agent!',
    },
    error: {
      email: 'Invalid email address format',
      setAgent: 'Failed to update agent',
    },
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  es: {
    title: 'Lista del Asistente',
    agent: 'Asistente',
    email: 'Email',
    isEnabled: 'Es disparable?',
    bot: 'Bot',
    createdAt: 'Fecha de registro',
    triggered: 'Asistente contado',
    excess: 'Facturación adicional',
    checkEnabled: 'Ver solo contados',
    add: 'Agregar asistente',
    addAgentSuccess: 'Para iniciar la operación, debe asociar estos asistentes a una cola de servicio.',
    understood: 'Entendí',
    edit: 'Editar',
    cancel: 'Cancelar',
    apply: 'Confirmar',
    continue: 'Continuar',
    delete:'Eliminar asistente',
    deleteAgents: {
      title:'Eliminar asistente',
      body: 'Quieres eliminar el asistente?',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },
    disabledTriggeredAgents: {
      title:'No se puede eliminar!',
      body: 'Se llamó al asistente durante el período y no es posible eliminarlo.<br>Quieres que no sea procesable?',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },
    disabledAgents: {
      title:'Inhabilitar asistente',
      body: 'Esta acción eliminará al asistente de sus respectivos Bots',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },
    deleteAgentsSuccess: 'Asistente eliminado con éxito!',
    deleteAgentsError: 'No se pudo eliminar al asistente',
    enableAgentSuccess: 'Asistente habilitada para activación',
    enableAgentError: 'No se pudo habilitar la activación del asistente',
    disableAgentSuccess: 'Asistente inhabilitado para activación',
    diableAgentError: 'No se pudo inhabilitar a la asistente para el activación',
    enabled: 'Procesable',
    disabled: 'No procesable',
    true: 'Sí',
    false: 'No',
    locale: 'es-ES',
    searchNotFound:{
      title: 'Asistente no encontrado :(',
      subTitle:'No hay agentes registrados con ese nombre.',
    },
    modal: {
      title: 'Agregar Asistentes',
    },
    modalBilling: {
      title: 'Aviso',
      subTitle: 'Servicio sujeto a cargo extra.',
      body:
        'Ha alcanzado el límite de asistentes activados en su plan, y si este asistente hace alguna asistencia, se generará un cargo extra de {additionalItemValue} en el contrato de {months}.',
      plan: 'Tu plan actual',
      limitAgentPlan: 'Límite de asistente del plan',
      agentActiveMonth: 'Asistentes llamados en el mes',
      confirm: 'Quieres continuar de todos modos?',
      month: 'Mes',
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    success: {
      setAgent: 'Asistente creado con éxito!',
    },
    error: {
      email: 'Formato de dirección de Correo electrónico invalido',
      setAgent: 'Error al actualizar asistente',
    },
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};
