import styled from 'styled-components';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { ContainerFullWidth } from 'components/Global/components';
import { FadeIn } from 'components/Global/animations';

export const DataConsumptionContainer = styled(ContainerFullWidth)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const DataConsumptionConsolidatedContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DataConsumptionConsolidatedPaper = styled(BdsPaper)`
  padding: 1.5rem;
  height: 188px;
`;

export const DataConsumptionConsolidatedBlipIcon = styled.div`
  img {
    height: 58px;
  }
`;

export const DataConsumptionConsolidatedBanner = styled.div`
  width: 100%;
  height: 58px;
  margin-left: 24px;
`;

export const DataConsumptionConsolidatedRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
