import React from 'react';
import PropTypes from 'prop-types';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
export const BlipTableTdComponent = ({ elementKey, property, item, content, columnWidth = '', className = '' , spanClass = ''}) => {
  const getElement = (key, item, action) => {
    if (action) return action(item);
    if (item[key] === undefined) return;

    switch (typeof item[key]) {
      case 'number':
        return item[key].toLocaleString(content.locale);
      case 'object':
        if (item[key] instanceof Date) return item[key].toLocaleDateString(content.locale);
        if (Array.isArray(item[key]) && item[key].every((item) => typeof item === 'string'))
          return item[key].join(', ');
        break;
      case 'boolean':
        return item[key] ? content.true : content.false;
      default:
        return item[key].toLocaleString(content.locale);
    }
  };

  return (
    <td key={elementKey} title={item[property.key]} className={`${className}${columnWidth}`}>
      {property.hasThumbnail && (
        <bds-avatar
          name={item[property.key]}
          thumbnail={item[property.thumbnailKey]}
          size="small"
          upload="false"
          class={className !== '' ? className + '-thumbnail' : ''}
        ></bds-avatar>
      )}
      {
        <span className={ spanClass == '' ? className  : spanClass }>
          {getElement(property.key, item, property.action, property.hasChildContent)}
        </span>
      }
      {property.hasChildContent && (
        <BdsTypo variant="fs-14" className={className !== '' ? className + '-child' : ''}>
          {item[property.childContentkey]}
        </BdsTypo>
      )}
    </td>
  );
};

BlipTableTdComponent.propTypes = {
  elementKey: PropTypes.string,
  property: PropTypes.object,
  item: PropTypes.object,
  content: PropTypes.object,
  columnWidth: PropTypes.string,
  spanClass: PropTypes.string,
};
