import React from 'react';
import PropTypes from 'prop-types';
import { BdsSelect } from 'blip-ds/dist/blip-ds-react';

const DateDropdownComponent = ({ value, bdsChangeEvent, options, label = '' }) => {
  return (
    <BdsSelect label={label} onBdsChange={bdsChangeEvent} value={value}>
      {options}
    </BdsSelect>
  );
};

DateDropdownComponent.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  bdsChangeEvent: PropTypes.any,
  options: PropTypes.array,
};

export { DateDropdownComponent };
