export class OldDailySessionConsumption {
  dateReference!: string;
  sessionByUser!: number;
  sessionByCompany!: number;
  sessionTotal!: number;

  constructor(OldDailySessionConsumption: Partial<OldDailySessionConsumption>) {
    this.dateReference = '';
    this.sessionByUser = 0;
    this.sessionByCompany = 0;
    this.sessionTotal = 0;    
    Object.assign(this, OldDailySessionConsumption);
  }
}