import { BusinessInitiatedSession } from './BusinessInitiatedSession';
import { UserInitiatedSession } from './UserInitiatedSession';

export class DailySessionConsumption {
  dateReference!: string;
  businessInitiated!: BusinessInitiatedSession;
  userInitiated!: UserInitiatedSession;

  constructor(DailySessionConsumption: Partial<DailySessionConsumption>) {
    this.dateReference = '';
    this.businessInitiated = {
      utility: 0,
      authentication: 0,
      marketing: 0,
      total: 0,
    };
    this.userInitiated = {
      service: 0,
      free: 0,
      total: 0,
    };

    Object.assign(this, DailySessionConsumption);
  }
}
