export const localization = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo: Atendentes',
    title: 'Atendentes',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plano {0}: {1} atendentes',
      middleInfo:
        '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',      
      planInformation: 'Plano {0} atendentes',
      exceededAgent: 'Atendentes excedentes',
      totalAgent: 'Total de atendentes',
      progressContentTitle: 'Atendentes acionados incluídos no plano',
      progressContentHoverIcon: 'Atendente acionado é aquele que realizou um ou mais atendimentos neste período.',
      warningExceededAgentTitle: 'O limite de {0} atendentes acionados do plano foi atingido.',
      warningExceededAgentSubtitle:
        'Não se preocupe! Você pode seguir usando o seu plano como de costume.',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    monthly: {
      title: 'Lista de atendentes acionados',
      subtitle:
        'São considerados atendentes consumidos aqueles que receberam pelo menos um ticket no período, realizando um ou mais atendimentos.',
      buttonShowAll: 'Mostrar detalhes',
      columnAgentId: 'Atendente',
      columnExtraCharge: 'Cobrança extra',
      columnNumTickets: 'N. de tickets',
      columnFirstRepliedTicket: 'Data do primeiro ticket atendido',
      columnLastRepliedTicket: 'Data do último ticket atendido',
      extraCharge: ['Sim', 'Não'],
    },
  },
  en: {
    locale: 'en-US',
    header: 'Usage report: Agents',
    title: 'Agents',
    monthPlaceholder: 'Period',
    consolidated: {
      plan: '{0}: {1} agents',      
      middleInfo: '*Monthly statements will become available within 5 business days of month end.',
      planInformation: 'Plan {0} agents',
      exceededAgent: 'Agent limit exceeded',
      totalAgent: 'Total number of agents',
      progressContentTitle: 'Activated agents included in the plan',
      progressContentHoverIcon: 'Activated agent is the one who replied to one or more tickets.',
      warningExceededAgentTitle: 'The limit of {0} activated agents in the plan was reached.',
      warningExceededAgentSubtitle:
        'Don\'t worry! You can go on and use your plan as usual.',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    monthly: {
      title: 'List of activated agents',
      subtitle: 'Counted agents are those who have received at least one ticket in the period, closing one or more of them.',
      buttonShowAll: 'Show details',
      columnAgentId: 'Agent',
      columnExtraCharge: 'Extra charge',
      columnNumTickets: 'No. of tickets',
      columnFirstRepliedTicket: 'Date of the first replied ticket',
      columnLastRepliedTicket: 'Date of the last replied ticket',
      extraCharge: ['Yes', 'No'],
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo: Asistentes',
    title: 'Asistentes',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plan {0}: {1} asistentes',      
      middleInfo:
      '*El cierre de un mes estará disponible dentro de los 5 días hábiles a partir del último día de ese mes.',
      exceededAgent: 'Asistentes excedentes',
      planInformation: 'Plan {0} asistentes',
      totalAgent: 'Total de asistentes',
      progressContentTitle: 'Asistentes activados incluidos en el plan',
      progressContentHoverIcon: 'Asistente activado es aquel que respondió a uno o más tickets.',
      warningExceededAgentTitle: 'Se ha alcanzado el límite del plan de {0} agentes activados.',
      warningExceededAgentSubtitle:
        '¡No te preocupes! Puede usar su plan como de costumbre.',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    monthly: {
      title: 'Lista de asistentes activados',
      subtitle: 'Los asistentes consumidos son aquellos que recibieron al menos un ticket en el período, realizando una o más llamadas.',
      buttonShowAll: 'Mostrar detalles',
      columnAgentId: 'Asistente',
      columnExtraCharge: 'Carga extra',
      columnNumTickets: 'N. de tickets',
      columnFirstRepliedTicket: 'Fecha del primer ticket respondido',
      columnLastRepliedTicket: 'Fecha del último ticket respondido',
      extraCharge: ['Sí', 'No'],
    },
  },
};
