import styled from 'styled-components';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { ContainerFullWidth } from 'components/Global/components';
import { FadeIn } from 'components/Global/animations';

export const DailyActiveUserContainer = styled(ContainerFullWidth)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const DailyActiveUserPaper = styled(BdsPaper)`
  padding: 1.5rem;
  margin-bottom: 2rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const DailyActiveUserUsageStatementContent = styled.div`
  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  & > bds-paper > table > tbody > tr > td:first-of-type > span:first-of-type {
    font-weight: bold;
  }
`;

export const DailyActiveUserUsageByBotContent = styled(DailyActiveUserUsageStatementContent)`
  & > bds-paper > table > tbody > tr > td:first-of-type > span:first-of-type {
    font-weight: bold;
  }
`;

export const DailyActiveUserUsageByTemplateContent = styled(DailyActiveUserUsageStatementContent)`
  & > bds-paper > table > tbody > tr > td:nth-child(2) > span:first-of-type {
    font-weight: bold;
  }
`;

export const MonthlyDailyActiveUserUsageTabs = styled.div`
  display: inherit;
`;

// Start Compiled Component Styles
export const CompiledDailyActiveUsersContainer = styled(ContainerFullWidth)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  gap: 32px;
  padding-bottom: 80px;
  padding-top: 32px;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const CompiledDailyActiveUsersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 24px;
`;

export const CompiledDailyActiveUsersLessWidthRow = styled(CompiledDailyActiveUsersRow)`
  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 65%;
  }

  margin-bottom: -0.5rem;
  margin-top: 20px;
`;

export const CompiledDailyActiveUsersPlainInfotmationRow = styled.div`
  margin: 1.5rem 0;
`;

export const CompiledDailyActiveUsersTotalConsumedPaperContent = styled.div`
  padding: 1rem;
  border-radius: 8px;
  height: 112px;

  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      text-align: left;
    }
  }

  & > bds-typo:last-of-type {
    display: block;
    margin-top: 8px;
    &::part(bds-typo__text) {
      text-align: left;
    }
  }
`;

export const CompiledDailyActiveUsersOverspentProgressContent = styled(
  CompiledDailyActiveUsersTotalConsumedPaperContent,
)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 112px;
  padding-top: 35px;  

  @media (max-width: 1366px) {
    width: ${(props) => (props.exceededPlan  ? '60%' : '100%')};
  }  

`;

export const CompiledDailyActiveUsersPaper = styled(BdsPaper)`
  padding: 1.5rem;
  margin-bottom: 2rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const CompiledDailyActiveUsersWithLineRow = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  flex-grow: 0;
  padding-bottom: 16px;
  margin-bottom: 24px;

  :not(:first-child) {
    margin-top: 48px;
  }

  & > bds-typo:last-of-type {
    &::part(bds-typo__text) {
      margin-bottom: 1rem;
    }
  }

  & > bds-tooltip:active {
    transition: all 0.1s ease-in;
    bds-icon {
      transform: scale(1.1);
    }
  }

  .tooltip__tip {
    display: inline-flex;
    text-align: center;
  }
`;

export const CompiledDailyOverspentAlertRow = styled(CompiledDailyActiveUsersRow)`
  margin-top: 20px;
`;

export const DailyActiveUsersProgressBarAligned = styled.div`
  width: 100%;
  @media (max-width: 1366px) {
    width: ${(props) => (props.exceededPlan  ? '75%' : '100%')};
  }    
  white-space: nowrap;

  & > bds-typo:first-of-type {
    position: relative;
    top: -5px;
  }
  & bds-tooltip {
    display: inline-block;
    margin-left: 10px;
  }

  & bds-progress-bar {
    margin-top: 12px;
  }
`;
export const DailyActiveUsersProgressTotalAligned = styled.div`
  width: 25%;
  margin-top: 35px;
  text-align: center;

  & > bds-typo {
    margin: 0 4px;
  }
`;

// End Compiled Component Styles
