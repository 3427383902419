import React, { FC, useEffect, useContext, useState } from 'react';
import { BdsTypo, BdsPaper, BdsSelect } from 'blip-ds/dist/blip-ds-react';
import * as Styles from '../styles';
import { useCommon } from '../../../contexts/CommonContext';
import { BlipTable } from '../../../components/BlipTable';
import { getMonthTwoDigits } from '../../../utils/date';
import RevenueContext from '../../../contexts/RevenueContext';

const ChatbotsConsumptionComponent: FC<any> = (props) => {
  const {
    currentMonth,
    startDate,
    endDate,
    content,
    tenant,
    setContractRules,
    chatbotsConsumption,
    setChatbotsConsumption,
    chatbotTableModel,
    revenueApi,
    generateMonthlyConsumptionOptions,
    updateMonthlyConsumption,
    sortChatbots,
    isFirstDayOfMonth,
  } = useContext(RevenueContext);
  const [isloading, setLoading] = useState(false);

  const { setHeaderContent } = useCommon();

  useEffect(() => {
    const redirectedFromContractConsumption = props.location.state?.fromContractConsumption ?? false;
    setHeaderContent({
      redirect: redirectedFromContractConsumption ? '/revenue' : '/',
      text: content.formatString(content.chatbotsConsumptionTitle, { tenant: tenant.info.name }),
    });

    const getData = async () => {
      try {
        setLoading(true);
        const monthString = `${startDate.getFullYear()}-${getMonthTwoDigits(startDate)}`;
        // TODO: remove fixed constant "10000" when implemented pagination on screen
        const applicationsQuantity = 10000;
        const apiContractRules = await revenueApi.getContractRules(tenant.info.id, monthString);
        const chatbotsConsumption = await revenueApi.getChatbotsConsumption(
          tenant.info.id,
          applicationsQuantity,
          monthString,
          apiContractRules.miu,
        );

        setContractRules(apiContractRules);
        setChatbotsConsumption(chatbotsConsumption);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [startDate, endDate]);

  return (
    <Styles.RevenueContainer>
      <BdsPaper elevation="static">
        <Styles.MonthlyConsumption>
          <Styles.MonthlyConsumptionInfo>
            <div>
              <Styles.MonthlyConsumptionTitle>
                <BdsTypo className="title" variant="fs-24" bold="bold" tag="h3">
                  {content.formatString(content.monthlyConsumption.title, {
                    month: content.monthlyConsumption.month[startDate.getMonth()],
                    year: startDate.getFullYear(),
                  })}
                </BdsTypo>
                {currentMonth && (
                  <BdsPaper className="partialUsage" elevation="static">
                    <BdsTypo variant="fs-12" bold="semi-bold">
                      {content.monthlyConsumption.partialUsage}
                    </BdsTypo>
                  </BdsPaper>
                )}
              </Styles.MonthlyConsumptionTitle>
              <BdsTypo className="subtitle" variant="fs-14">
                {isFirstDayOfMonth()
                  ? content.formatString(content.firstDayOfMonth, {
                      month: content.monthlyConsumption.month[startDate.getMonth()],
                    })
                  : content.chatbotsConsumption.subtitle}
              </BdsTypo>
            </div>
            <BdsSelect
              onBdsChange={updateMonthlyConsumption}
              value={startDate.toISOString()}
              label={content.monthlyConsumption.selectPlaceholder}
            >
              {generateMonthlyConsumptionOptions}
            </BdsSelect>
          </Styles.MonthlyConsumptionInfo>
          {!isFirstDayOfMonth() && (
            <Styles.ChatbotConsumption>
              <BlipTable
                idKey="name"
                model={chatbotTableModel}
                data={chatbotsConsumption}
                selectedItems={[]}
                onSortSet={sortChatbots}
                bodyHeight="100%"
                loading={isloading}
              />
            </Styles.ChatbotConsumption>
          )}
        </Styles.MonthlyConsumption>
      </BdsPaper>
    </Styles.RevenueContainer>
  );
};

export default ChatbotsConsumptionComponent;
