import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { localization } from './localization';
import { AgentContractComponent } from './AgentContractComponent';
import { showToast, showTenantModal } from 'api/CommonApi';
import { ApplicationApi } from 'api/ApplicationApi';
import { useTenant } from 'contexts/TenantContext';
import { getAllTenantAgents, setAgentContract, getTenantAgentBots } from 'api/AgentApi';
import { remove } from './customActions/remove';
import { getNameEmail } from './customActions/getNameEmail';
import { verifyAgentBillingAdditional, agentTriggered } from './customActions/utils';
import { actionAgentEnabled } from './customActions/actionAgentEnabled';
import { getBots } from './customActions/getBots';
import { ModalAgentBillingMessage } from './Modal';
import { isValidEmail } from 'utils/emailValid';
import Summary from './Summary';
import { createTrack } from 'api/SegmentApi';
import {
  ORGANIZATION_AGENTS_SEARCH,
  ORGANIZATION_AGENTS_SORT,
  ORGANIZATION_AGENTS_ADD,
  ORGANIZATION_AGENTS_FILTER_TRIGGERED,
} from 'shared/SegmentEvents';
import { AgentContainer } from './styles';

const AgentContractContainer = ({ canEdit = false }) => {
  const content = useContentLocalizer(localization);
  const {
    tenant: { agents, info, subscription },
    setTenantAgents,
  } = useTenant();
  const planName = info.plan;
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectEmail, setSelectEmail] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [disabledButtonConfirm, setDisabledButtonConfirm] = useState(true);
  const [showModalBilling, setShowModalBilling] = useState(false);
  const [filterTriggered, setFilterTriggered] = useState(false);
  const agentsSubscriptionNumber = subscription ? subscription.find((s) => s.metricId === 'Agent')?.quantity : 0;
  let countAgent = 0;

  useEffect(async () => {
    await getAgentsContract();
  }, []);

  useEffect(() => {
    isValidForm();
  }, [selectEmail, invalidEmail]);

  useEffect(() => {
    filterItems(search);
  }, [agents]);

  const getAgentsContract = async () => {
    setLoading(true);
    setFilterTriggered(false);
    const response = await getAllTenantAgents(info.id);
    const agentsTriggered = response.sort((a) => new Date(a.firstAttendance || new Date()).getTime());
    const { response: bots } = await ApplicationApi.sendApplicationRequest('getApplicationsByTenant', info.id);
    const agentsBots = await Promise.all(
      await agentsTriggered.map(async (agent) => {
        const applications = await getTenantAgentBots(info.id, agent.userIdentity);
        const botsName = applications.map((app) => {
          const [shortName] = app.split('@');
          return bots.find((bot) => {
            if (bot.shortName === shortName) return bot.name;
          });
        });
        const aplicationsName = [];
        botsName.forEach((app) => aplicationsName.push(app.name));
        return (agent = { ...agent, applications: aplicationsName });
      }),
    );
    setTenantAgents(
      agentsBots.sort(function (a, b) {
        if (a.isEnabled == true && a.creationDate > b.creationDate) {
          return -1;
        } else if (a.isEnabled == true && a.creationDate < b.creationDate) {
          return 1;
        } else {
          return 0;
        }
      }),
    );
    setLoading(false);
  };
 
  const filterItems = (termFiltered, AgentTriggeredFilterChecked) => {
    setSearch(termFiltered);
    const filterAgentTriggered =
      typeof AgentTriggeredFilterChecked !== 'undefined' ? AgentTriggeredFilterChecked : filterTriggered;
    const tempFilteredAgents = filterAgentTriggered
      ? agents.filter(
        (agent) =>
          (agent.fullName.toLowerCase().includes(termFiltered.toLowerCase()) && agent.triggered == true) ||
          (agent.email.includes(termFiltered.toLowerCase()) && agent.triggered == true),
      )
      : agents.filter(
        (agent) =>
          agent.fullName.toLowerCase().includes(termFiltered.toLowerCase()) ||
          agent.email.includes(termFiltered.toLowerCase()),
      );
    countAgent = tempFilteredAgents.length;
    setFilteredAgents(tempFilteredAgents);
  };

  const filterAgents = async (termFiltered) => {
    filterItems(termFiltered);
    if (termFiltered.length !== 0) {
      createTrack(info, ORGANIZATION_AGENTS_SEARCH, {
        status: 'success',
        qtdAgents: countAgent,
        text: termFiltered,
      });
    }
  };

  const filterAgentsTriggered = async (status) => {
    setFilterTriggered(!filterTriggered);
    filterItems(search, status);
    createTrack(info, ORGANIZATION_AGENTS_FILTER_TRIGGERED, {
      status: 'success',
      qtdAgents: countAgent,
      triggered: status,
    });
  };

  const sortAgents = async (sort) => {
    const tempFilteredAgents = filteredAgents.sort((a, b) => {
      if (a[sort.property] < b[sort.property]) {
        return sort.order === 'asc' ? -1 : 1;
      }
      if (a[sort.property] > b[sort.property]) {
        return sort.order === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setFilteredAgents([...tempFilteredAgents]);
    createTrack(info, ORGANIZATION_AGENTS_SORT, {
      status: 'success',
      qtdAgents: tempFilteredAgents.length,
      sortType: sort.order,
      sortProperty: sort.property,
    });
  };

  const confirmAddAgent = () => {
    if (verifyAgentBillingAdditional(agents, subscription)) {
      setShowModalBilling(true);
    } else {
      saveAgent();
    }
  };

  const isValidForm = () => {
    if (selectEmail.length === 0 || invalidEmail) {
      setDisabledButtonConfirm(true);
    } else setDisabledButtonConfirm(false);
  };

  const onChangeEmail = (e) => {
    const { data } = e.detail;
    setSelectEmail(data);
    var emailInvalid = false;
    data.forEach((input) => {
      emailInvalid = emailInvalid || !isValidEmail(input);
    });
    setInvalidEmail(emailInvalid);
  };

  const saveAgentPortal = async () => {
    const modal = await showTenantModal('InviteAgentModal');
    if (modal.response === true) {
      try {
        await getAgentsContract();
      } catch (e) {
        showToast({
          type: 'danger',
          message: content.error.setAgent,
        });
      }
    } 
  };

  const saveAgent = async () => {
    if (disabledButtonConfirm) {
      return;
    }
    if (invalidEmail) {
      showToast({
        type: 'danger',
        message: content.error.email,
      });
      return;
    }
    try {
      setLoading(true);
      await setAgentContract(info.id, selectEmail);
      createTrack(info, ORGANIZATION_AGENTS_ADD, {
        status: 'success',
        agents: selectEmail,
        buttonClick: 'confirm',
      });
    } catch (e) {
      showToast({
        type: 'danger',
        message: content.error.setAgent,
      });
      createTrack(info, ORGANIZATION_AGENTS_ADD, {
        status: 'failed',
        agents: selectEmail,
        buttonClick: 'confirm',
      });
    } finally {
      setShowModalBilling(false);
      await getAgentsContract();
      setLoading(false);
    }
  };

  const verifyAddAgents = () => {
    saveAgentPortal();
  };

  const tableModel = [
    {
      label: content.agent,
      action: getNameEmail,
      className: 'truncate items-center ',
      key: 'fullName',
    },
    { label: content.bot, key: 'applications', action: getBots, className: ' items-center ' },
    { label: content.createdAt, key: 'creationDate', className: 'truncate items-center ' },
    { label: content.triggered, key: 'triggered', className: 'truncate items-center' },
    ...(canEdit
      ? [{ label: content.isEnabled, key: 'isEnabled', action: actionAgentEnabled }]
      : []),    
    canEdit && { label: '', key: 'actions', columnWidth: 'w3', action: remove , spanClass: 'justify-end flex' },
  ];
  return (
    <AgentContainer>
      <ModalAgentBillingMessage
        open={showModalBilling}
        content={content}
        quantityAgents={agentTriggered(agents)}
        close={() => setShowModalBilling(false)}
        subscription={subscription}
        planName={planName}
        onClick={() => saveAgent()}
      />
      {<Summary
        quantityAgentsTriggered={agentTriggered(agents)}
        quantityAgents={agents.length}
        agentsSubscriptionNumber={agentsSubscriptionNumber}
        quantityAgentsEnabled={agents.filter((t) => t.isEnabled === true).length}
      />}
      <AgentContractComponent
        agents={filteredAgents}
        content={content}
        tableModel={tableModel}
        filter={filterAgents}
        search={search}
        filterTriggered={filterTriggered}
        filterEnabled={filterAgentsTriggered}
        sort={sortAgents}
        canEdit={canEdit}
        togleModalAdd={verifyAddAgents}
        loading={loading}
      />
    </AgentContainer>
  );
};

AgentContractContainer.propTypes = {
  agent: PropTypes.arrayOf(Object).isRequired,
  canEdit: PropTypes.bool,
};

export { AgentContractContainer };
