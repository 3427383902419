export const localization = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'Conversas Únicas',
      description:
        'Conversas únicas, antes chamadas de usuários ativos diários (DAUs), são conversas diárias contabilizadas a partir do envio ou recebimento de qualquer tipo de mensagem no Blip entre 0h e 23h59 do mesmo dia. Elas serão contabilizadas uma única vez por canal, mesmo se não houver resposta de quem as recebeu. Contam como conversas diárias únicas o atendimento humano (via Blip Desk) e as notificações ativas, além de mensagens por chatbots.',
      showMoreButton: 'Mostrar mais',
    },
    leftChart: {
      title: 'Consumo do plano contratado',
      labels: { counted: '{0} Conversas Únicas consumidas', available: '{0} Conversas Únicas disponíveis' },
      customLabels: { counted: '{0}%: Conversas Únicas consumidas', available: '{0}%: Conversas Únicas disponíveis' },
    },
    leftInfoPaper: {
      totalInfo: {
        description: 'Total de Conversas Únicas consumido nesse mês',
      },
      availableInfo: {
        description: 'Conversas Únicas restantes disponíveis no plano',
      },
      planInfo: {
        description: 'Total de Conversas Únicas do plano contratado',
        tooltip: 'Número de Conversas Únicas disponíveis por mês no plano contratado',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Consumo semanal de Conversas Únicas',
      customLabel: 'Conversas Únicas consumidas',
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'usuários que receberem uma notificação ativa, mesmo que não a respondam, serão contabilizados. Várias mensagens podem ser trocadas com um mesmo usuário ativo, entre o período mencionado, contabilizando uma única conversa.',
      },
    },
    rightInfoPaper: {
      weeklyAverage: {
        description: 'Média semanal de consumo de Conversas Únicas',
      },
      topWeekNumber: {
        label: '{0} semana',
        description: 'Foi a semana com maior consumo de Conversas Únicas do mês selecionado',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'Single Conversations',
      description:
        'Single conversations, formerly daily active users (DAUs), are daily conversations counted when any type of message is sent or received on Blip, between 00:00 and 23:59 on the same day. They will be counted only once per channel, even if there is no response from the person who received them. Human service (via Blip Desk), active notifications, and messages by chatbots all count as unique daily conversations.',
      showMoreButton: 'Show more',
    },
    leftChart: {
      title: 'Plan usage',
      labels: { counted: '{0} Single Conversations Consumption', available: '{0} Single Conversations Available' },
      customLabels: { counted: '{0}%: Single Conversations Consumption', available: '{0}%: Single Conversations Available' },
    },
    leftInfoPaper: {
      totalInfo: {
        description: 'Total of Single Conversations consumed this month',
      },
      availableInfo: {
        description: 'Remaining Single Conversations available in the plan',
      },
      planInfo: {
        description: 'Total of Single Conversations in your plan',
        tooltip: 'Number of Single Conversations available per month on your plan',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Weekly consumption of Single Conversations',
      customLabel: 'Single Conversations Consumed',
      warningLabel: {
        bold: 'Attention: ',
        regular:
          'every user that receives a proactive notification will be counted, even if they do not reply. Within the given period, multiple messages can be exchanged with the same active user, which counts as a single conversation.',
      },
    },
    rightInfoPaper: {
      weeklyAverage: {
        description: 'Average weekly consumption of Single Conversations',
      },
      topWeekNumber: {
        label: '{0} week',
        description: 'Week with the highest consumption of Single Conversations for the selected month',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'Conversaciones Únicas',
      description:
        'Las Conversaciones Únicas, antes denominadas usuarios activos diarios (DAU), son conversaciones diarias contadas desde el envío o la recepción de cualquier tipo de mensaje en Blip entre las 00:00 y las 23:59 del mismo día. Se contabilizarán una sola vez por canal, aunque no haya respuesta de la persona que los recibió. La atención humana (a través de Blip Desk), las notificaciones activas y los mensajes de los chatbots cuentan como conversaciones diarias únicas.',
      showMoreButton: 'Mostrar más',
    },
    leftChart: {
      title: 'Consumo del plan contratado',
      labels: { counted: '{0} Conversaciones Únicas consumidas', available: '{0} Conversaciones Únicas disponibles' },
      customLabels: { counted: '{0}%: Conversaciones Únicas consumidas', available: '{0}%: Conversaciones Únicas disponibles' },
    },
    leftInfoPaper: {
      totalInfo: {
        description: 'Total de Conversaciones Únicas consumidas este mes',
      },
      availableInfo: {
        description: 'Conversaciones Únicas restantes disponibles en el plan',
      },
      planInfo: {
        description: 'Total de Conversaciones Únicas en su plan',
        tooltip: 'Numero de Conversaciones Únicas disponibles por mes en el plan contratado',
      },
    },
    rightChart: {
      period: '{startDate} al {endDate}',
      title: 'Consumo semanal de Conversaciones Únicas',
      customLabel: 'Conversaciones Únicas consumidas',
      warningLabel: {
        bold: 'Importante: ',
        regular: 'los usuarios que reciban una notificación proactiva, aunque no la contesten, serán contabilizados. Se pueden intercambiar varios mensajes con el mismo usuario activo, entre el periodo mencionado, contando como una única conversación.',
      },
    },
    rightInfoPaper: {
      weeklyAverage: {
        description: 'Consumo promedio semanal de Conversaciones Únicas',
      },
      topWeekNumber: {
        label: '{0} semana',
        description: 'Semana con mayor consumo de Conversaciones Únicas para el mes seleccionado',
      },
    },
  },
};
