import styled from 'styled-components';
import colors from '_colors.module.scss';
import fonts from '_fonts.module.scss';

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.Surface3};
  margin-bottom: ${fonts.ParagraphMargin};
`;

export const Button = styled.button`
  border: 0;
  background: ${colors.Surface1};
`;

export const AgentContainer = styled.div`
  margin: 10px auto;
  width: 93%;
`;
