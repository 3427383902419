import React from 'react';
import PropTypes from 'prop-types';
import { GeneralChartComponent } from './GeneralChartComponent';

const GeneralChartContainer = ({ chartType, data, options, plugins, redraw }) => {
  return (
    <GeneralChartComponent
      chartType={chartType}
      data={data}
      options={options}
      plugins={plugins}
      redraw={redraw}
    ></GeneralChartComponent>
  );
};

GeneralChartContainer.propTypes = {
  chartType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.array,
  redraw: PropTypes.bool,
};

export { GeneralChartContainer };
