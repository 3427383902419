import React from 'react';
import PropTypes from 'prop-types';
import { BdsButton, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { PaperHeader, PaperHeaderTitle } from './styles';
import { Link } from 'react-router-dom';

const PaperHeaderComponent = ({ title, subtitle, path, buttonCaption, trackButton, isDetailedView, idTest }) => {
  return (
    <PaperHeader>
      <PaperHeaderTitle>
        {!isDetailedView && (
          <BdsTypo variant="fs-24" tag="h3" bold="bold" margin={false}>
            {title}
          </BdsTypo>
        )}
        {!isDetailedView && (
          <Link to={{ pathname: path }}>
            <BdsButton variant="tertiary" arrow={true} data-test={idTest} size="short" onClick={trackButton}>
              {buttonCaption}
            </BdsButton>
          </Link>
        )}
      </PaperHeaderTitle>
      <BdsTypo variant="fs-14" bold="regular" margin={true}> 
        {subtitle}
      </BdsTypo>
    </PaperHeader>
  );
};

PaperHeaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  path: PropTypes.string,
  buttonCaption: PropTypes.string,
  trackButton: PropTypes.func,
  isDetailedView: PropTypes.bool.isRequired,
};

export { PaperHeaderComponent };
