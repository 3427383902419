import { FC, useMemo } from 'react';

import { useTenant } from '../../contexts/TenantContext';
import { useCommon } from '../../contexts/CommonContext';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

import { accessPermission, canUserWrite } from '../../services/PermissionService';
import { sanitize } from '../../utils/sanitize';

import { Avatar } from '../../components/Avatar';
import LeaveContractLink from '../../components/LeaveContract';

import { BdsPaper, BdsTypo, BdsInputEditable, BdsTooltip, BdsIcon } from 'blip-ds/dist/blip-ds-react';

import { localization } from './localization';
import { setTenant } from '../../api/TenantApi';
import { showToast, startLoading, stopLoading } from '../../api/CommonApi';
import { createTrack } from '../../api/SegmentApi';

import { CONTRACT_NAME_CHANGED } from '../../shared/SegmentEvents';

import {
  Sidebar,
  SidebarPaperContent,
  SidebarPaperRow,
  SidebarPaperRowInline,
  SidebarTenantUrl,
  SidebarPaperContentHorizontal,
  SidebarHorizontal,
  SidebarPaperBottomTextHorizontal,
  SidebarHorizontalText,
  SidebarPaperHorizontalRow,
  SidebarPaperHorizontalRowInline,
} from './styles';
import React from 'react';

interface SidebarProps {
  horizontal: boolean;
}

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 15;

const SidebarComponent: FC<SidebarProps> = ({ horizontal }) => {
  const {
    common: { loggedUser },
  } = useCommon();
  const { tenant, setTenantName, setTenantPhotoUri } = useTenant();
  const [, copyText] = useCopyToClipboard();

  const content = useContentLocalizer(localization);

  const TENANT_URL = `${tenant.info.id}.blip.ai`;

  const hasWritePermission = canUserWrite(accessPermission.summary, loggedUser.roleId);

  const copyTextToClipboard = (text: string) => {
    copyText(text);
  };

  const onUpdateImage = (photoUri) => {
    setTenant({ ...tenant.info, photoUri });
    setTenantPhotoUri(photoUri);
  };

  const updateTenantName = async ({ detail: input }): Promise<void> => {
    if (input.value !== input.oldValue) {
      const name = sanitize(input.value.trim());
      try {
        if (name.length > MAX_NAME_LENGTH) return;

        if (name.length < MIN_NAME_LENGTH) {
          showToast({
            type: 'danger',
            message: content.nameTooShortError,
          });
          return;
        } else {
          startLoading();
          await setTenant({ ...tenant.info, name });
          setTenantName(input.value);
          showToast({
            type: 'success',
            message: content.nameUpdated,
          });
          createTrack(tenant, CONTRACT_NAME_CHANGED, {
            user: loggedUser.email,
          });
        }
      } catch (error) {
        showToast({
          type: 'danger',
          message: content.nameUpdateError,
        });
      } finally {
        stopLoading();
      }
    }
  };

  const formatDate: string = useMemo(() => {
    const date = new Date(tenant.info.creationDate);
    return date.toLocaleDateString().split('/').join('.');
  }, []);

  const renderAvatar = () => {
    return (
      <Avatar
        img={tenant.info.photoUri}
        canEdit={hasWritePermission}
        changePicLabel={content.changePic}
        onUpdateImage={onUpdateImage}
      />
    );
  };

  const renderInputEditable = () => {
    if (hasWritePermission) {
      return (
        <BdsInputEditable
          value={tenant.info.name}
          onBdsInputEditableSave={updateTenantName}
          minlength={MIN_NAME_LENGTH}
          maxlength={MAX_NAME_LENGTH}
          helperMessage={content.nameTooShortError}
        ></BdsInputEditable>
      );
    }
    return (
      <div>
        <BdsTypo variant="fs-24" tag="span">
          {tenant.info.name}
        </BdsTypo>
      </div>
    );
  };

  const renderHorizontalSidebar = () => {
    return (
      <SidebarHorizontal>
        <BdsPaper elevation="primary">
          <SidebarPaperContentHorizontal>
            {renderAvatar()}
            <SidebarHorizontalText>
              <SidebarPaperHorizontalRow>
                <BdsTypo data-test="contract-name"  variant="fs-14">{content.contractName}</BdsTypo>
                {renderInputEditable()}
                <SidebarTenantUrl>
                  <BdsTypo data-test="tenant-url" variant="fs-14" bold="bold">
                    {TENANT_URL}
                  </BdsTypo>
                  <BdsTooltip data-test="tenant-url-tooltip" position="bottom-center" tooltip-text={content.copy}>
                    <BdsIcon name="copy" onClick={() => copyTextToClipboard(TENANT_URL)}></BdsIcon>
                  </BdsTooltip>
                </SidebarTenantUrl>
              </SidebarPaperHorizontalRow>

              <SidebarPaperBottomTextHorizontal>
                <SidebarPaperHorizontalRow>
                  <BdsTypo data-test="contract-text-id" variant="fs-14">{content.id}</BdsTypo>
                  <SidebarPaperHorizontalRowInline>
                    <BdsTypo variant="fs-16" bold="bold" data-test="contract-id">
                      {tenant.info.id}
                    </BdsTypo>
                    <BdsTooltip position="bottom-center" tooltip-text={content.copy} data-test="copy-id-tenant">
                      <BdsIcon name="copy" onClick={() => copyTextToClipboard(tenant.info.id)}></BdsIcon>
                    </BdsTooltip>
                  </SidebarPaperHorizontalRowInline>
                </SidebarPaperHorizontalRow>
                <SidebarPaperHorizontalRow>
                  <BdsTypo data-test="creation-date-contract-text" variant="fs-14">{content.creationDate}</BdsTypo>
                  <BdsTypo data-test="creation-date-contract" variant="fs-16" bold="bold">
                    {formatDate}
                  </BdsTypo>
                </SidebarPaperHorizontalRow>
                {tenant.applications && tenant.applications.length > 0 && (
                  <SidebarPaperHorizontalRow>
                    <BdsTypo variant="fs-14" data-test="tenant-applications-length-text">{content.chatbots}</BdsTypo>
                    <BdsTypo variant="fs-16" data-test="tenant-applications-length" bold="bold">
                      {tenant.applications.length}
                    </BdsTypo>
                  </SidebarPaperHorizontalRow>
                )}
                {tenant.members && tenant.members.length > 0 && (
                  <SidebarPaperHorizontalRow>
                    <BdsTypo variant="fs-14" data-test="members-tenant-text">{content.members}</BdsTypo>
                    <BdsTypo variant="fs-16" bold="bold" data-test="members-tenant">
                      {tenant.members.length}
                    </BdsTypo>
                  </SidebarPaperHorizontalRow>
                )}
                <SidebarPaperHorizontalRow>
                  <LeaveContractLink />
                </SidebarPaperHorizontalRow>
              </SidebarPaperBottomTextHorizontal>
            </SidebarHorizontalText>
          </SidebarPaperContentHorizontal>
        </BdsPaper>
      </SidebarHorizontal>
    );
  };

  const renderDefaultSidebar = () => {
    return (
      <Sidebar>
        <BdsPaper elevation="primary">
          <SidebarPaperContent>
            {renderAvatar()}
            <SidebarPaperRow>
              <BdsTypo variant="fs-14">{content.contractName}</BdsTypo>
              {renderInputEditable()}
              <SidebarPaperRowInline>
                <SidebarTenantUrl>
                  <BdsTypo variant="fs-14" bold="bold">
                    {TENANT_URL}
                  </BdsTypo>
                  <BdsTooltip position="bottom-center" tooltip-text={content.copy}>
                    <BdsIcon name="copy" onClick={() => copyTextToClipboard(TENANT_URL)}></BdsIcon>
                  </BdsTooltip>
                </SidebarTenantUrl>
              </SidebarPaperRowInline>
            </SidebarPaperRow>
            <SidebarPaperRow>
              <BdsTypo variant="fs-14">{content.id}</BdsTypo>
              <SidebarPaperRowInline>
                <BdsTypo variant="fs-16" bold="bold">
                  {tenant.info.id}
                </BdsTypo>
                <BdsTooltip position="bottom-center" tooltip-text={content.copy}>
                  <BdsIcon name="copy" onClick={() => copyTextToClipboard(tenant.info.id)}></BdsIcon>
                </BdsTooltip>
              </SidebarPaperRowInline>
            </SidebarPaperRow>
            <SidebarPaperRow>
              <BdsTypo variant="fs-14">{content.creationDate}</BdsTypo>
              <BdsTypo variant="fs-16" bold="bold">
                {formatDate}
              </BdsTypo>
            </SidebarPaperRow>
            {tenant.applications && tenant.applications.length > 0 && (
              <SidebarPaperRow>
                <BdsTypo variant="fs-14">{content.chatbots}</BdsTypo>
                <BdsTypo variant="fs-16" bold="bold">
                  {tenant.applications.length}
                </BdsTypo>
              </SidebarPaperRow>
            )}
            {tenant.members && tenant.members.length > 0 && (
              <SidebarPaperRow>
                <BdsTypo variant="fs-14">{content.members}</BdsTypo>
                <BdsTypo variant="fs-16" bold="bold">
                  {tenant.members.length}
                </BdsTypo>
              </SidebarPaperRow>
            )}
            <SidebarPaperRow>
              <LeaveContractLink />
            </SidebarPaperRow>
          </SidebarPaperContent>
        </BdsPaper>
      </Sidebar>
    );
  };

  return horizontal ? renderHorizontalSidebar() : renderDefaultSidebar();
};

export default SidebarComponent;
