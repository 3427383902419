import { BdsButton, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import CopilotDeskImg from '../../../assets/images/copilot.png';
import CopilotPopup from '../../../assets/images/copilot-popup.svg';
import React, { useState } from 'react';

import {
  RoundedBdsIcon,
  Popup,
  PopupImg,
  CopilotImg,
  FeaturesList,
  FeatureTitle,
  FeatureDescription,
  CopilotAdContainer,
  CopilotAdTitle,
  CopilotAdContent,
} from './styles';
import { createTrack } from '../../../api/SegmentApi';
import { useTenant } from 'contexts/TenantContext';
import { useContentLocalizer } from '../../../hooks/useContentLocalizer';
import { localization } from './localization';
import { ORGANIZATION_COPILOT_LEAD, ORGANIZATION_COPILOT_FIRST_LEAD } from '../../../shared/SegmentEvents';
import CopilotModal from '../CopilotModal';
import PropTypes from 'prop-types';

const CopilotAd = ({ copilotEnableClick }) => {
  const {
    tenant: { info },
  } = useTenant();

  const [showModal, setShowModal] = useState(false);

  const translate = useContentLocalizer(localization);

  const handleClick = () => {
    createTrack(info, ORGANIZATION_COPILOT_FIRST_LEAD);
    setShowModal(true);
  };
  const contractClick = () => {
    createTrack(info, ORGANIZATION_COPILOT_LEAD);
    setShowModal(false);
    copilotEnableClick();
  };

  const features = [
    {
      title: translate.features.suggestion.title,
      description: translate.formatString(translate.features.suggestion.description, <span className="color-primary">{translate.features.suggestion.parameter}</span>),
    },
    {
      title: translate.features.transcription.title,
      description: translate.formatString(translate.features.transcription.description, <span className="color-primary">{translate.features.transcription.parameter}</span>),
    },
    {
      title: translate.features.summary.title,
      description: translate.formatString(translate.features.summary.description, <span className="color-primary">{translate.features.summary.parameter}</span>),
    },
  ];

  return (
    <>
      <CopilotAdContainer>
        <CopilotAdTitle>
          <BdsTypo variant="fs-40" tag="h1" bold="semi-bold">
            {translate.formatString(translate.title.text, <span className="color-primary">{translate.title.parameter}</span>)}
          </BdsTypo>
          <BdsTypo variant="fs-16" tag="p" bold="regular">
            {translate.formatString(translate.description.text, <strong>{translate.description.parameter}</strong>)}
          </BdsTypo>
          <BdsButton variant="primary" onClick={handleClick}>{translate.button}</BdsButton>
        </CopilotAdTitle>

        <CopilotAdContent>
          <Popup>
            <PopupImg src={CopilotPopup}></PopupImg>
            <BdsTypo variant="fs-14" bold="regular" tag="p" margin="false" className="color-surface-1">
              {translate.popup}
            </BdsTypo>
          </Popup>
          <CopilotImg src={CopilotDeskImg} alt={translate.description.imgAlt} />
        </CopilotAdContent>
      </CopilotAdContainer>
      <div className="bg-color-surface-1">
        <FeaturesList>
          {features.map((feature, index) => (
            <li key={index}>
              <FeatureTitle>
                <RoundedBdsIcon name="sparkle-ai" theme="outline" size="x-large"></RoundedBdsIcon>
                <BdsTypo variant="fs-24" bold="bold" tag="h2" margin="false">
                  {feature.title}
                </BdsTypo>
              </FeatureTitle>
              <FeatureDescription>
                <BdsTypo variant="fs-16" bold="regular" tag="p">
                  {feature.description}
                </BdsTypo>
              </FeatureDescription>
            </li>
          ))}
        </FeaturesList>
      </div>      
      <CopilotModal
          openModal={showModal}
          closeModal={() => setShowModal(false)}
          onClick={() => contractClick()}
        />
    </>
  );
};

CopilotAd.propTypes = {
  copilotEnableClick: PropTypes.func.isRequired,
};

export default CopilotAd;
