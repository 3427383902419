import React, { FC, useContext, useEffect } from 'react';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MiuConsumptionContext from '../../../../contexts/MiuConsumptionContext';
import { REPORT_PERIOD_SELECT } from '../../../../shared/SegmentEvents';
import { capitalizeFirstLetter } from '../../../../utils/strings';
import { ConsolidatedHeaderComponent } from '../../components/Metrics/ConsolidatedHeader/ConsolidatedHeaderComponent';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { MiuConsumptionPaper, MiuConsumptionConsolidatedRow, MiuConsumptionConsolidatedContent } from '../styles';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { BlipLoadingComponent } from '../../../../components/BlipLoading/BlipLoadingComponent';
import { BillingMetrics } from '../../../../shared/BillingMetrics';

const MiuConsumptionConsolidatedInformationsComponent: FC = () => {
  const {
    currentMonth,
    content,
    tenant,
    dateToSearch,
    periodOptions,
    trackingProps,
    setDateToSearch,
    loggedUser,
    getPlanStatus,
    hasEnabledMetric,
    compiledMiuConsumptionDataState,
    isMiuLoading,
    consolidatedMiuApiRequisition,
    miuMetricIds,
  } = useContext(MiuConsumptionContext);
  const consolidatedMiuConsumption = compiledMiuConsumptionDataState.consolidatedMiuConsumption;

  const consolidatedContainsDataOnSelectedMonth =
    compiledMiuConsumptionDataState.consolidatedContainsDataOnSelectedMonth;

  const consolidatedMetric = miuMetricIds.map((metricId) => {
    return {
      metricId: metricId,
      total: consolidatedMiuConsumption.find((item) => item.metric.metricId === metricId)?.total,
    };
  });

  const totalMiu = consolidatedMetric.find((item) => item.metricId !== BillingMetrics.exchangedMessage)?.total;
  const totalMsg = consolidatedMetric.find((item) => item.metricId === BillingMetrics.exchangedMessage)?.total;

  useEffect(() => {
    consolidatedMiuApiRequisition(dateToSearch, miuMetricIds, hasEnabledMetric);
  }, [dateToSearch]);

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  return (
    <>
      <PageHeaderComponent
        title={content.title}
        dateToSearch={dateToSearch}
        periodOptions={periodOptions}
        periodOptionChangeEvent={periodOptionChangeEvent}
      />
      <MiuConsumptionPaper>
        <div>
          <ConsolidatedHeaderComponent
            infoHeader={content.formatString(content.consolidated.plan, capitalizeFirstLetter(tenant.info.plan))}
            dateToSearch={dateToSearch}
            currentMonth={currentMonth}
            middleInfoContent={content.consolidated.middleInfo}
          />
          {isMiuLoading ? (
            <BlipLoadingComponent />
          ) : (
            <>
              <MiuConsumptionConsolidatedRow>
                {isMiuLoading ? (
                  <BlipLoadingComponent />
                ) : (
                  <>
                    {totalMiu >= 0 && (
                      <MiuConsumptionConsolidatedContent currentMonth={currentMonth} className="bg-color-surface-2">
                        <BdsTypo margin={true} bold="bold" variant="fs-16">
                          {content.consolidated.totalMiu}
                        </BdsTypo>
                        <BdsTypo
                          variant="fs-32"
                          bold="extra-bold"
                          margin={false}
                          data-totalMiu={totalMiu}
                          className={currentMonth ? 'color-content-default' : 'color-primary'}
                        >
                          {consolidatedContainsDataOnSelectedMonth ? totalMiu.toLocaleString(content.locale) : '--'}
                        </BdsTypo>
                      </MiuConsumptionConsolidatedContent>
                    )}
                    {totalMsg >= 0 && (
                      <MiuConsumptionConsolidatedContent currentMonth={currentMonth} className="bg-color-surface-2">
                        <BdsTypo margin={true} bold="bold" variant="fs-16">
                          {content.consolidated.totalMsg}
                        </BdsTypo>
                        <BdsTypo
                          variant="fs-32"
                          bold="extra-bold"
                          margin={false}
                          data-totalMsg={totalMsg}
                          className={currentMonth ? 'color-content-default' : 'color-primary'}
                        >
                          {consolidatedContainsDataOnSelectedMonth ? totalMsg.toLocaleString(content.locale) : '--'}
                        </BdsTypo>
                      </MiuConsumptionConsolidatedContent>
                    )}
                  </>
                )}
              </MiuConsumptionConsolidatedRow>
            </>
          )}
        </div>
      </MiuConsumptionPaper>
    </>
  );
};

export default MiuConsumptionConsolidatedInformationsComponent;
