import React from 'react';
import PropTypes from 'prop-types';
import { ConsolidatedPaperComponent } from './ConsolidatedPaperComponent';

const ConsolidatedPaperContainer = ({
  header,
  leftChart,
  leftInfoPapers,
  rightChart,
  rightInfoPapers,
  isLoading = false,
}) => {
  return (
    <ConsolidatedPaperComponent
      header={header}
      leftChart={leftChart}
      leftInfoPapers={leftInfoPapers}
      rightChart={rightChart}
      rightInfoPapers={rightInfoPapers}
      isLoading={isLoading}
    ></ConsolidatedPaperComponent>
  );
};

ConsolidatedPaperContainer.propTypes = {
  header: PropTypes.object.isRequired,
  leftChart: PropTypes.object.isRequired,
  leftInfoPapers: PropTypes.object.isRequired,
  rightChart: PropTypes.object.isRequired,
  rightInfoPapers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export { ConsolidatedPaperContainer };
