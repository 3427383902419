import { BdsSelectOption } from 'blip-ds/dist/blip-ds-react';
import React, { Reducer, useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCommon } from '../../contexts/CommonContext';
import DailyActiveUsersContext from '../../contexts/DailyActiveUsersContext';
import { useTenant } from '../../contexts/TenantContext';
import {
  generateMonthListBetweenDates,
  getFirstDayOfMonth,
  getFirstDayOfSpecifiedMonth,
  getLastDayOfSpecifiedMonth,
} from '../../utils/date';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { localization } from './localization';
import { startLoading, stopLoading } from '../../api/CommonApi';
import DailyActiveUsersSummary from './components/DailyActiveUsersSummary';
import MonthlyUsage from './components/Monthly/MonthlyUsage';
import DailyUsage from './components/Daily/DailyUsage';
import { DailyActiveUsersMonthlyUsage } from '../../models/dailyActiveUsers/DailyActiveUsersMonthlyUsage';
import { sortByProperty } from '../../utils/array';
import BillingDailyActiveUsersServiceApi from '../../api/BillingDailyActiveUsersApi';
import { CompiledDailyActiveUserConsumption } from '../../models/dailyActiveUsers/CompiledDailyActiveUserConsumption';
import { DailyActiveUserUsage } from '../../models/dailyActiveUsers/DailyActiveUserUsage';
import { billingRoutes, billingSubRoutes } from '../../routes/BillingRoutes';

interface CompiledDailyActiveUserDataState {
  compiledDailyActiveUserConsumption: CompiledDailyActiveUserConsumption;
  compiledContainsDataOnSelectedMonth: boolean;
  compiledMonthDate: string;
  compiledContainsOverspent: boolean;
  showPlanInformations: boolean;
}

const DailyActiveUsers: React.FC = () => {
  const {
    setHeaderContent,
    common: { config, loggedUser },
  } = useCommon();

  const { tenant } = useTenant();
  const content = useContentLocalizer(localization);
  const billingDailyActiveUserApi = new BillingDailyActiveUsersServiceApi();
  const initialDate = new Date(config.REVENUE_START_DATE);
  initialDate.setSeconds(0);
  const [dateToSearch, setDateToSearch] = useState(getFirstDayOfMonth());
  const [dailyActiveUserMonthlyUsage, setDailyActiveUserMonthlyUsage] = useState<DailyActiveUsersMonthlyUsage[]>([]);
  const [dailyActiveUserUsage, setDailyActiveUserUsage] = useState<DailyActiveUserUsage[]>([]);

  const [compiledDailyActiveUserDataState, setMonthlyActiveMessageUsageState] = useReducer<
    Reducer<CompiledDailyActiveUserDataState, Partial<CompiledDailyActiveUserDataState>>
  >(
    (compiledDailyActiveUserDataState, newCompiledDailyActiveUserDataState) => ({
      ...compiledDailyActiveUserDataState,
      ...newCompiledDailyActiveUserDataState,
    }),
    {
      compiledDailyActiveUserConsumption: {
        metric: null,
        total: 0,
        planUsageRate: 0,
        exceededPlan: 0,
        planUsage: 0,
        startDateReference: getFirstDayOfSpecifiedMonth(new Date()),
        endDateReference: getLastDayOfSpecifiedMonth(new Date()),
      },
      compiledContainsDataOnSelectedMonth: false,
      compiledContainsOverspent: false,
      compiledMonthDate: '',
      showPlanInformations: false,
    },
  );

  useEffect(() => {
    startLoading();

    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });
    stopLoading();

    return () => setHeaderContent({ redirect: null });
  }, []);

  const periodOptions = useMemo(() => {
    const monthList = generateMonthListBetweenDates(initialDate, new Date()).sort(
      (first, second) => second.getTime() - first.getTime(),
    );

    return monthList.map((monthDate) => {
      const month = monthDate.getMonth();
      const year = monthDate.getFullYear();

      return (
        <BdsSelectOption value={monthDate.toISOString()} key={`${month}-${year}`}>
          {content.compiledDailyActiveUserInformation.month[month]} {year}
        </BdsSelectOption>
      );
    });
  }, []);

  const sortDailyActiveUserMonthlyUsage = async (sort) => {
    const sortedChatbotsConsumption = sortByProperty(dailyActiveUserMonthlyUsage, sort.property, sort.order);
    setDailyActiveUserMonthlyUsage([...sortedChatbotsConsumption]);
  };

  const sortDailyActiveUserUsage = async (sort) => {
    const sortedChatbotsConsumption = sortByProperty(dailyActiveUserUsage, sort.property, sort.order);
    setDailyActiveUserUsage([...sortedChatbotsConsumption]);
  };

  const currentMonth = useMemo(() => {
    const month = dateToSearch.getMonth() + 1 === new Date().getMonth() + 1;

    const year = dateToSearch.getFullYear() === new Date().getFullYear();

    return month && year;
  }, [dateToSearch]);

  const contextProps = {
    currentMonth,
    dateToSearch,
    periodOptions,
    content,
    tenant,
    loggedUser,
    billingDailyActiveUserApi,
    dailyActiveUserMonthlyUsage,
    dailyActiveUserUsage,
    setDateToSearch,
    sortDailyActiveUserMonthlyUsage,
    setDailyActiveUserMonthlyUsage,
    sortDailyActiveUserUsage,
    setDailyActiveUserUsage,
    compiledDailyActiveUserDataState,
    setMonthlyActiveMessageUsageState,
  };

  return (
    <DailyActiveUsersContext.Provider value={contextProps}>
      <Switch>
        <Route exact path={billingRoutes.dailyActiveUsers} component={DailyActiveUsersSummary}></Route>
        <Route exact path={billingSubRoutes.dailyActiveUsersMonthly} component={MonthlyUsage}></Route>
        <Route exact path={billingSubRoutes.dailyActiveUsersDaily} component={DailyUsage}></Route>
      </Switch>
    </DailyActiveUsersContext.Provider>
  );
};

export default DailyActiveUsers;
