import { WhatsAppCallingIconVisibility } from '../enums/whatsapp-calling-icon-visibility.enum';
import { WhatsAppCallingStatus } from '../enums/whatsapp-calling-status.enum';
import { WhatsAppPhoneNumberSettings } from '../interfaces/whatsapp-phone-number-settings.interface';

export class WhatsAppApplication {
  public id: string;
  public identity: string;
  public name: string;
  public shortName: string;
  public tenantId: string;
  public hasPermission = false;
  public selected = false;
  public phoneNumber?: string;
  public phoneNumberSettings?: WhatsAppPhoneNumberSettings;
  public imageUri?: string;
  public emailOwner?: string;
  public created: Date;
  public updated?: Date;

  constructor(data?: any) {
    this.id = data?.id;
    this.identity = data?.identity;
    this.name = data?.name;
    this.shortName = data?.shortName;
    this.tenantId = data?.tenantId;
    this.hasPermission = data?.hasPermission ?? false;
    this.phoneNumber = data?.phoneNumber;
    this.phoneNumberSettings = data?.phoneNumberSettings;
    this.imageUri = data?.imageUri;
    this.emailOwner = data?.emailOwner;
    this.created = data?.created;
    this.updated = data?.updated;
    this.selected = data?.selected ?? false;
  }

  public get isCallingEnabled(): boolean {
    return this.phoneNumberSettings?.calling?.status === WhatsAppCallingStatus.ENABLED;
  }

  public set isCallingEnabled(enabled: boolean) {
    if (this.phoneNumberSettings?.calling) {
      this.phoneNumberSettings.calling.status = enabled
        ? WhatsAppCallingStatus.ENABLED
        : WhatsAppCallingStatus.DISABLED;
    }
  }

  public get isCallingIconVisible(): boolean {
    return [WhatsAppCallingIconVisibility.DEFAULT, WhatsAppCallingIconVisibility.NOT_SET].some(
      (x) => x === this.phoneNumberSettings?.calling?.callIconVisibility,
    );
  }

  public set isCallingIconVisible(enabled: boolean) {
    if (this.phoneNumberSettings?.calling) {
      this.phoneNumberSettings.calling.callIconVisibility = enabled
        ? WhatsAppCallingIconVisibility.DEFAULT
        : WhatsAppCallingIconVisibility.HIDE_IN_CHAT;
    }
  }
}
