export const localizationMauMsg = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo: MAUs e Mensagens',
    title: 'MAUs e Mensagens',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plano {0}: MAUs e Mensagens',
      middleInfo:
        '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
      planInformation: 'Plano {0} MAUs e Mensagens',
      totalMiu: 'Total de MAUs',
      totalMsg: 'Total de Mensagens',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de MAUs e Mensagens por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MAUs',
      columnTotalMsg: 'Mensagens',
    },
    daily: {
      title: 'Contabilização diária de MAUs e Mensagens',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MAUs',
      columnTotalMsg: 'Mensagens',
    },
  },
  en: {
    locale: 'en-US',
    header: 'Usage report: MAUs and Messages',
    title: 'MAUs and Messages',
    monthPlaceholder: 'Period',
    consolidated: {
      plan: '{0}: MAUs and Messages',
      middleInfo: '*Monthly statements will become available within 5 business days of month end.',
      planInformation: 'Plan {0} MAUs and Messages',
      totalMiu: 'Total number of MAUs',
      totalMsg: 'Total number of Messages',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Track consumption by MAUs and Messages via chatbot.',
      buttonShowAll: 'Show details',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MAUs',
      columnTotalMsg: 'Messages',
    },
    daily: {
      title: 'Daily accounting of MAUs and Messages',
      buttonShowAll: 'Show details',
      subtitle: 'Below are detailed more information on the contract.',
      columnDateReference: 'Date',
      columnTotalMiu: 'MAUs',
      columnTotalMsg: 'Messages',
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo: MAUs y Mensajes',
    title: 'MAUs y Mensajes',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plan {0}: MAUs y Mensajes',
      middleInfo:
        '*Los estados de cuenta mensuales estarán disponibles dentro de los 5 días hábiles posteriores al final del mes.',
      planInformation: 'Plan {0} MAUs y Mensajes',
      totalMiu: 'Total de MAUs',
      totalMsg: 'Total de Mensajes',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Seguimiento del consumo de MAUs y Mensajes por chatbot.',
      buttonShowAll: 'Mostrar detalles',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MAUs',
      columnTotalMsg: 'Mensajes',
    },
    daily: {
      title: 'Contabilidad diaria de MAUs y Mensajes',
      buttonShowAll: 'Mostrar detalles',
      subtitle: 'A continuación se detalla más información sobre el contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MAUs',
      columnTotalMsg: 'Mensajes',
    },
  },
};

export const localizationMeuMsg = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo: MEUs e Mensagens',
    title: 'MEUs e Mensagens',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plano {0}: MEUs e Mensagens',
      middleInfo:
        '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
      planInformation: 'Plano {0} MEUs e Mensagens',
      totalMiu: 'Total de MEUs',
      totalMsg: 'Total de Mensagens',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de MEUs e Mensagens por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MEUs',
      columnTotalMsg: 'Mensagens',
    },
    daily: {
      title: 'Contabilização diária de MEUs e Mensagens',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MEUs',
      columnTotalMsg: 'Mensagens',
    },
  },
  en: {
    locale: 'en-US',
    header: 'Usage report: MEUs and Messages',
    title: 'MEUs and Messages',
    monthPlaceholder: 'Period',
    consolidated: {
      plan: '{0}: MEUs and Messages',
      middleInfo: '*Monthly statements will become available within 5 business days of month end.',
      planInformation: 'Plan {0} MEUs and Messages',
      totalMiu: 'Total number of MEUs',
      totalMsg: 'Total number of Messages',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Track consumption by MEUs and Messages via chatbot.',
      buttonShowAll: 'Show details',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MEUs',
      columnTotalMsg: 'Messages',
    },
    daily: {
      title: 'Daily accounting of MEUs and Messages',
      buttonShowAll: 'Show details',
      subtitle: 'Below are detailed more information on the contract.',
      columnDateReference: 'Date',
      columnTotalMiu: 'MEUs',
      columnTotalMsg: 'Messages',
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo: MEUs y Mensajes',
    title: 'MEUs y Mensajes',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plan {0}: MEUs y Mensajes',
      middleInfo:
        '*Los estados de cuenta mensuales estarán disponibles dentro de los 5 días hábiles posteriores al final del mes.',
      planInformation: 'Plan {0} MEUs y Mensajes',
      totalMiu: 'Total de MEUs',
      totalMsg: 'Total de Mensajes',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de MEUs e Mensagens por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MEUs',
      columnTotalMsg: 'Mensagens',
    },
    daily: {
      title: 'Contabilização diária de MEUs e Mensagens',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MEUs',
      columnTotalMsg: 'Mensagens',
    },
  },
};

export const localizationMeu = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo: MEUs',
    title: 'MEUs',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plano {0}: MEUs',
      middleInfo:
        '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
      planInformation: 'Plano {0} MEUs',
      totalMiu: 'Total de MEUs',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de MEUs por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MEUs',
    },
    daily: {
      title: 'Contabilização diária de MEUs',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MEUs',
    },
  },
  en: {
    locale: 'en-US',
    header: 'Usage report: MEUs',
    title: 'MEUs',
    monthPlaceholder: 'Period',
    consolidated: {
      plan: '{0}: MEUs',
      middleInfo: '*Monthly statements will become available within 5 business days of month end.',
      planInformation: 'Plan {0} MEUs',
      totalMiu: 'Total number of MEUs',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de MEUs por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MEUs',
    },
    daily: {
      title: 'Contabilização diária de MEUs',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MEUs',
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo: MEUs',
    title: 'MEUs',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plan {0}: MEUs',
      middleInfo:
        '*Los estados de cuenta mensuales estarán disponibles dentro de los 5 días hábiles posteriores al final del mes.',
      planInformation: 'Plan {0} MEUs',
      totalMiu: 'Total de MEUs',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de MEUs por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MEUs',
    },
    daily: {
      title: 'Contabilização diária de MEUs',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MEUs',
    },
  },
};

export const localizationMau = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo: MAUs',
    title: 'MAUs',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plano {0}: MAUs',
      middleInfo:
        '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
      planInformation: 'Plano {0} MAUs',
      totalMiu: 'Total de MAUs',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de MAUs por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MAUs',
    },
    daily: {
      title: 'Contabilização diária de MAUs',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MAUs',
    },
  },
  en: {
    locale: 'en-US',
    header: 'Usage report: MAUs',
    title: 'MAUs',
    monthPlaceholder: 'Period',
    consolidated: {
      plan: '{0}: MAUs',
      middleInfo: '*Monthly statements will become available within 5 business days of month end.',
      planInformation: 'Plan {0} MAUs',
      totalMiu: 'Total number of MAUs',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Track consumption by MAUs via chatbot.',
      buttonShowAll: 'Show details',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MAUs',
    },
    daily: {
      title: 'Daily accounting of MAUs',
      buttonShowAll: 'Show details',
      subtitle: 'Below are detailed more information on the contract.',
      columnDateReference: 'Date',
      columnTotalMiu: 'MAUs',
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo: MAUs',
    title: 'MAUs',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plan {0}: MAUs',
      middleInfo:
        '*Los estados de cuenta mensuales estarán disponibles dentro de los 5 días hábiles posteriores al final del mes.',
      planInformation: 'Plan {0} MAUs',
      totalMiu: 'Total de MAUs',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Seguimiento del consumo de MAU por chatbot.',
      buttonShowAll: 'Mostrar detalles',
      columnChatBot: 'ID - Chatbot',
      columnTotalMiu: 'MAUs',
    },
    daily: {
      title: 'Contabilidad diaria de MAUs',
      buttonShowAll: 'Mostrar detalles',
      subtitle: 'A continuación se detalla más información sobre el contrato.',
      columnDateReference: 'Data',
      columnTotalMiu: 'MAUs',
    },
  },
};

export const localizationMsg = {
  pt: {
    locale: 'pt-BR',
    header: 'Relatório de consumo: Mensagens',
    title: 'Mensagens',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plano {0}: Mensagens',
      middleInfo:
        '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
      planInformation: 'Plano {0} Mensagens',
      totalMsg: 'Total de Mensagens',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Acompanhe o consumo de Mensagens por chatbot.',
      buttonShowAll: 'Mostrar detalhes',
      columnChatBot: 'ID - Chatbot',
      columnTotalMsg: 'Mensagens',
    },
    daily: {
      title: 'Contabilização diária de Mensagens',
      buttonShowAll: 'Mostrar detalhes',
      subtitle: 'Abaixo estão detalhadas mais informações do contrato.',
      columnDateReference: 'Data',
      columnTotalMsg: 'Mensagens',
    },
  },
  en: {
    locale: 'en-US',
    header: 'Usage report: Messages',
    title: 'Messages',
    monthPlaceholder: 'Period',
    consolidated: {
      plan: '{0}: Messages',
      middleInfo: '*Monthly statements will become available within 5 business days of month end.',
      planInformation: 'Plan {0} Messages',
      totalMsg: 'Total number of Messages',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Track consumption by Messages.',
      buttonShowAll: 'Show details',
      columnChatBot: 'ID - Chatbot',
      columnTotalMsg: 'Messages',
    },
    daily: {
      title: 'Daily accounting of Messages',
      buttonShowAll: 'Show details',
      subtitle: 'Below are detailed more information on the contract.',
      columnDateReference: 'Date',
      columnTotalMsg: 'Messages',
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Reporte de consumo: Mensajes',
    title: 'Mensajes',
    monthPlaceholder: 'Período',
    consolidated: {
      plan: 'Plan {0}: Mensajes',
      middleInfo:
        '*Los estados de cuenta mensuales estarán disponibles dentro de los 5 días hábiles posteriores al final del mes.',
      planInformation: 'Plan {0} Mensajes',
      totalMsg: 'Total de Mensajes',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    monthly: {
      title: 'Chatbots',
      subtitle: 'Seguimiento del consumo de Mensajes por chatbot.',
      buttonShowAll: 'Mostrar detalles',
      columnChatBot: 'ID - Chatbot',
      columnTotalMsg: 'Mensajes',
    },
    daily: {
      title: 'Contabilidad diaria de Mensajes',
      buttonShowAll: 'Mostrar detalles',
      subtitle: 'A continuación se detalla más información sobre el contrato.',
      columnDateReference: 'Data',
      columnTotalMsg: 'Mensajes',
    },
  },
};
