import styled from 'styled-components';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { ContainerFullWidth } from 'components/Global/components';
import { FadeIn } from 'components/Global/animations';

export const AgentConsumptionContainer = styled(ContainerFullWidth)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const AgentConsumptionPaper = styled(BdsPaper)`
  margin-bottom: 2rem;
  padding: 1.5rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;

  & .activeAgents {
    width: 250%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
  }

  & .bp-table tbody tr td bds-typo {
    &::part(bds-typo__text) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .bp-table thead th:first-of-type,
  .bp-table-chart table thead th:first-of-type {
    width: 250%;
  }

  & .activeAgents-thumbnail {
    float: left;
    margin-right: 10px;
  }

  & .bp-table thead tr > * {
    white-space: wrap;
  }
`;

export const AgentConsumptionConsolidatedRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const AgentConsumptionConsolidatedContent = styled.div`
  padding: 1rem;
  border-radius: 8px;
  margin-left: 20px;
  height: 112px;

  & > bds-typo:first-of-type {
    margin-bottom: 8px;
    display: inline-block;
  }
`;

export const AgentConsumptionProgressPaperContent = styled.div`
  padding: 2.2rem 1rem;
  border-radius: 8px;
  width: 100%;
  display: flex;

  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      text-align: center;
    }
  }

  & > bds-typo:last-of-type {
    &::part(bds-typo__text) {
      text-align: center;
    }
  }
`;

export const AgentConsumptionProgressContent = styled(AgentConsumptionProgressPaperContent)``;

export const AgentConsumptionConsolidatedContentAligned = styled.div`
  display: flex;
`;

export const AgentConsumptionProgressContentAligned = styled.div`
  display: flex;
  width: 100%;
  height: 112px;
`;

export const AgentConsumptionProgressBarAligned = styled.div`
  width: 100%;
  white-space: nowrap;

  & > bds-typo:first-of-type {
    position: relative;
    top: -5px;
  }
  & bds-tooltip {
    display: inline-block;
    margin-left: 10px;
  }

  & bds-progress-bar {
    margin-top: 12px;
  }
`;

export const AgentConsumptionProgressTotalAligned = styled.div`
  width: 25%;
  margin-top: 35px;
  text-align: center;

  & > bds-typo {
    margin: 0 4px;
  }
`;

export const AgentConsumptionWarningContentAligned = styled.div`
  margin-top: 20px;
`;

export const AgentConsumptionHeaderPartial = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.currentMonth == true ? 'space-between' : 'flex-end')};
`;
