import React from 'react';
import PropTypes from 'prop-types';
import { BlipTable } from 'components/BlipTable';
import { BlipSearch } from 'components/BlipSearch';
import { BlipDropdownButton } from 'components/BlipDropdownButton';
import { BlipSelect } from 'components/BlipSelect';
import { BlipIcon } from 'components/BlipIcon';

const TenantMembersComponent = ({
  members,
  selectedMembers,
  content,
  tableModel,
  filter,
  sort,
  selectedRole,
  setSelectedMembers,
  setSelectedMembersRole,
  deleteSelectedMembers,
  setSelectedRole,
  canEdit,
  inviteMembers,
}) => {
  const getActions = (edit, remove) => [
    <BlipDropdownButton
      key="edit"
      buttonTestId="edit-button"
      buttonClass="bp-btn--text-only bp-fs-4"
      dropdownClass="w6"
      arrowSide="right"
      label={<BlipIcon color="desk" name="edit" />}
      cancelLabel={content.cancel}
      footer={[
        <button key="apply" onClick={edit} disabled={selectedRole === undefined} className="bp-btn bp-btn--bot bp-btn--text-only">{content.apply}</button>,
        <button key="cancel" className="bp-btn bp-btn--silver bp-btn--text-only">{content.cancel}</button>,
      ]}
    >
      <p>{content.edit} <span className="bp-c-blip-dark">{selectedMembers.length}</span> {content.members}</p>
      <BlipSelect
        className="pt2"
        label={content.role}
        placeholder={content.chooseRole}
        options={[
          { label: content.guest, value: 'guest' },
          { label: content.member, value: 'member' },
          { label: content.admin, value: 'admin' },
        ]}
        inputValue={selectedRole !== undefined ? selectedRole.label : content.chooseRole}
        onSelectOption={({ $event }) => setSelectedRole($event.optionProps)}
      />
      <div className="mt3">
        {
          (() => {
            if (selectedRole !== undefined) {
              switch (selectedRole.value) {
                case 'guest':
                  return <p>{content.guestDescription}</p>;
                case 'member':
                  return <p>{content.memberDescription}</p>;
                case 'admin':
                  return <p>{content.adminDescription}</p>;
                default:
                  return <p></p>;
              }
            } else {
              return <br />;
            }
          })()
        }
      </div>
    </BlipDropdownButton>,
    <BlipDropdownButton
      key="delete"
      buttonTestId="delete-button"
      buttonClass="bp-btn--text-only bp-fs-4"
      dropdownClass="w6"
      arrowSide="right"
      label={<BlipIcon color="desk" name="trash" />}
      cancelLabel={content.cancel}
      footer={[
        <button key="delete" onClick={remove} className="bp-btn bp-btn--delete bp-btn--text-only">{content.delete}</button>,
        <button key="cancel" className="bp-btn bp-btn--silver bp-btn--text-only">{content.cancel}</button>,
      ]}
    >
      <p>{content.delete} <span className="bp-c-delete">{selectedMembers.length}</span> {content.members}</p>
      <p className="pt2 bp-fs-7">{content.deleteMessage}</p>
    </BlipDropdownButton>,
  ];

  return <div className="TenantMembers flex flex-column items-end">
    <BlipSearch onChange={filter} debounce={500} />
    <BlipTable
      idKey="userIdentity"
      model={tableModel}
      data={members.map(
        member => ({ ...member, roleId: content[member.roleId] })
      )}
      selectedItems={selectedMembers}
      onSortSet={sort}
      canSelect={canEdit}
      actions={getActions(setSelectedMembersRole, deleteSelectedMembers)} onItemSelect={setSelectedMembers}
      bodyHeight='400px'
    />
    {
      canEdit &&
      <button className="bp-btn bp-btn--blip-dark bp-btn--small mt20" onClick={inviteMembers}>{content.invite}</button>
    }
  </div>;
};

TenantMembersComponent.propTypes = {
  members: PropTypes.arrayOf(Object).isRequired,
  selectedMembers: PropTypes.arrayOf(Object).isRequired,
  content: PropTypes.object.isRequired,
  tableModel: PropTypes.array.isRequired,
  filter: PropTypes.func.isRequired,
  sort: PropTypes.func.isRequired,
  selectedRole: PropTypes.object,
  setSelectedMembers: PropTypes.func.isRequired,
  setSelectedMembersRole: PropTypes.func.isRequired,
  deleteSelectedMembers: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  inviteMembers: PropTypes.func.isRequired,
};

export { TenantMembersComponent };
