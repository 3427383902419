import styled from 'styled-components';
import { Container } from 'components/Global/components';
import { FadeIn } from 'components/Global/animations';

export const ContactHomeContainer = styled(Container)`
  display: grid;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  gap: 32px;
  padding-bottom: 80px;
  padding-top: 32px;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const ContactHomeSidebar = styled.div`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
`;

export const ContactHomeSidebarPaperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
`;

export const ContactHomeContent = styled.div`
  grid-area: content;
`;

export const ContactHomeContentRow = styled.div`
  display: flex;
  flex-grow: 0;
  padding: 8px 0px 16px 0px;
  margin-bottom: 24px;

  :not(:first-child) {
    margin-top: 48px;
  }

  & > bds-typo {
    margin-right: 8px;
  }

  & > bds-tooltip:active {
    transition: all 0.1s ease-in;
    bds-icon {
      transform: scale(1.1);
    }
  }

  .tooltip__tip {
    display: inline-flex;
    text-align: center;
  }
`;

export const ContactHomeOptionContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
