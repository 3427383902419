import styled from 'styled-components';
import blipColors from 'blip-tokens/build/json/colors.json';

export const RevenueContainer = styled.div`
  margin: 10px auto;
  width: 93%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 80px;
  padding-top: 32px;
`;

export const MonthlyConsumption = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
`;

export const MonthlyConsumptionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  & bds-typo:first-of-type {
    color: ${blipColors['color-neutral-dark-desk']};
  }
  & bds-typo:last-child {
    color: ${blipColors['color-neutral-dark-rooftop']};
  }

  .subtitle{
    margin: 5px 0;
    display: block;    
  }
`;

export const MonthlyConsumptionTitle = styled.div`
  display: flex;
  height: 30px;
  
  .title{
    display: block;
    margin: 4px 0;
  }

  .partialUsage{
    height: 30px;
    border-radius: 8px;
    background: ${blipColors['color-extend-browns-sand']};
    padding: 6px 8px;
    color: ${blipColors['color-primary-oranges-carrot']};
    margin-left: 20px;    
    & bds-typo:first-of-type {
      color: ${blipColors['color-primary-oranges-carrot']};
    }    
  }
`;

export const MonthlyConsumptionMetrics = styled.div`
  display: flex;
  gap: 32px;
`;

export const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #f3f6fa;
  flex: 1;
  padding: 16px 24px;
  justify-content: space-between;
  border-radius: 8px;
  & > bds-typo:first-of-type {
    color: ${blipColors['color-neutral-dark-desk']};
  }
  & > bds-typo:last-child {
    color: ${(props) =>
    props.currentMonth == true ? blipColors['color-primary-oranges-carrot'] : blipColors['color-primary-main']};
  }
`;

export const ChatbotConsumption = styled.div``;

export const ChatbotConsumptionInfo = styled.div`
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
  & bds-typo:first-of-type {
    color: ${blipColors['color-neutral-dark-desk']};
  }
  & bds-typo:last-child {
    color: ${blipColors['color-neutral-dark-rooftop']};
  }
`;

export const ContractRules = styled.div`
  padding: 24px 32px;
`;

export const ContractRulesUpper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  & > div > bds-typo:first-of-type {
    color: ${blipColors['color-neutral-dark-desk']};
  }
  & > div > bds-typo:last-child {
    color: ${blipColors['color-neutral-dark-rooftop']};
  }
`;
export const ContractDetails = styled.div`
  display: flex;
  gap: 24px;
  bds-typo {
    color: ${blipColors['color-neutral-dark-rooftop']};
  }
`;

export const ContractRulesBottom = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const ContractRulesCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #f3f6fa;
  flex: 1;
  padding: 24px 24px;
  justify-content: space-between;
  border-radius: 8px;

  & > div:first-child > bds-typo:first-child {
    color: ${blipColors['color-neutral-dark-desk']};
  }
  & bds-typo {
    color: ${blipColors['color-neutral-dark-rooftop']};
  }
`;

export const ContractRulesReferenceValues = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 16px;
  & bds-typo {
    color: ${blipColors['color-neutral-dark-rooftop']};
  }
`;

export const ReferenceTable = styled.div`
  & tr td:first-child {
    font-weight: bold;
  }
`;