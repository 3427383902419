import React from 'react';
import PropTypes from 'prop-types';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { PageHeader } from './styles';
import { DateDropdown } from '../../../../../components/DateDropdown';
import { useContentLocalizer } from '../../../../../hooks/useContentLocalizer';
import { localization } from './localizations';

const PageHeaderComponent = ({ title, dateToSearch, periodOptions, periodOptionChangeEvent }) => {
  const content = useContentLocalizer(localization);
  return (
    <PageHeader>
      <BdsTypo variant="fs-40" bold="bold" lineHeight="plus">
        {title}
      </BdsTypo>
      <DateDropdown
        value={dateToSearch.toISOString()}
        options={periodOptions}
        bdsChangeEvent={periodOptionChangeEvent}
        label={content.monthPlaceholder}
      ></DateDropdown>
    </PageHeader>
  );
};

PageHeaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
  dateToSearch: PropTypes.any.isRequired,
  periodOptions: PropTypes.array.isRequired,
  periodOptionChangeEvent: PropTypes.func.isRequired,
};

export { PageHeaderComponent };
