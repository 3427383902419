import blipFriendly from '../assets/images/blip-friendly.svg';
import blipWow from '../assets/images/blip-wow.svg';
import blipSatisfact from '../assets/images/blip-satisfact.svg';
import blipSmiley from '../assets/images/blip-smiley.svg';
import blipBored from '../assets/images/blip-bored.svg';
import blipWink from '../assets/images/blip-wink.svg';
import blipCurious from '../assets/images/blip-curious.svg';
import colors from '../_charts.module.scss';

export const UNLIMITED_PLAN_METRIC = -1;
export const METRIC_NOT_REGISTERED = null;

export enum BillingMetrics {
  monthlyActiveUser = 'MonthlyActiveUser',
  exchangedMessage = 'ExchangedMessage',
  monthlyEngagedUser = 'MonthlyEngagedUser',
  dailyActiveUser = 'DailyActiveUser',
  dailyEngagedUser = 'DailyEngagedUser',
  agent = 'Agent',
  session = 'Session',
}

export enum MetricStatus {
  error = 'error',
  empty = 'empty',
  unlimited = 'unlimited',
  normal = 'normal',
  nearLimit = 'near-limit',
  exceeded = 'exceeded',
}

export const getMetricStatus = (planUsageRate) => {
  if (planUsageRate === 0) {
    return MetricStatus.empty;
  }
  if (planUsageRate === -1) {
    return MetricStatus.unlimited;
  }
  if (planUsageRate > 0 && planUsageRate < 80) {
    return MetricStatus.normal;
  }
  if (planUsageRate >= 80 && planUsageRate < 100) {
    return MetricStatus.nearLimit;
  }
  if (planUsageRate >= 100) {
    return MetricStatus.exceeded;
  }

  return MetricStatus.error;
};

export const blipMetricStatus = [
  {
    status: MetricStatus.error,
    blipImg: blipBored,
    usageColor: colors.Surface3,
    availableColor: colors.Surface3,
  },
  {
    status: MetricStatus.empty,
    blipImg: blipBored,
    usageColor: colors.Surface3,
    availableColor: colors.Surface3,
  },
  {
    status: MetricStatus.unlimited,
    blipImg: blipFriendly,
    usageColor: colors.Surface3,
    availableColor: colors.Surface3,
  },
  {
    status: MetricStatus.normal,
    blipImg: blipSmiley,
    usageColor: colors.Surface3,
    availableColor: colors.Info,
  },
  {
    status: MetricStatus.nearLimit,
    blipImg: blipSatisfact,
    usageColor: colors.Orange,
    availableColor: colors.Surface3,
  },
  {
    status: MetricStatus.exceeded,
    blipImg: blipWow,
    usageColor: colors.Red,
    availableColor: colors.Surface3,
  },
];

export const blipBannerStatus = [
  {
    status: MetricStatus.error,
    blipImg: blipWow,
    variant: 'error',
  },
  {
    status: MetricStatus.empty,
    blipImg: blipWink,
    variant: 'system',
  },
  {
    status: MetricStatus.unlimited,
    blipImg: blipFriendly,
    variant: 'info',
  },
  {
    status: MetricStatus.normal,
    blipImg: blipFriendly,
    variant: 'info',
  },
  {
    status: MetricStatus.nearLimit,
    blipImg: blipWink,
    variant: 'system',
  },
  {
    status: MetricStatus.exceeded,
    blipImg: blipCurious,
    variant: 'warning',
  },
];
