import styled from 'styled-components';
import { BdsIcon } from 'blip-ds/dist/blip-ds-react';
import colors from '_colors.module.scss';

export const CopilotAdContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const CopilotAdTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 1.5rem;
    padding: 1.5rem 0 1.5rem 4rem;
`;

export const CopilotAdContent = styled.div`
    position: relative;
    width: 50%;
    padding: 1.5rem 0;
`;

export const RoundedBdsIcon = styled(BdsIcon)`
    display: flex;
    border-radius: 8px;
    background: ${colors.Surface2};
    padding: 0.5rem;
`;

export const CopilotImg = styled.img`
    position: relative; 
    width: 85%;
    left: 25%;
`;

export const Popup = styled.div`
    position: absolute;
    z-index: 1;
    background: ${colors.Brand};
    color: white;
    width: 230px;
    height: 80px;
    top: 50%;
    left: 15%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
`;

export const PopupImg = styled.img`
    position: absolute;
    right: calc(100% - 4px);
    bottom: calc(100% - 13px);
`;

export const FeaturesList = styled.ul`
    display: flex;
    list-style: none;
    padding: 4rem;
    gap: 1.5rem;
`;

export const FeatureTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const FeatureDescription = styled.div`
    padding-top: 1rem;
`;