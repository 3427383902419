import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { FadeIn } from 'components/Global/animations';
import styled from 'styled-components';
import { ContainerFullWidth } from 'components/Global/components';
import colors from '_colors.module.scss';

export const SessionConsumptionContainer = styled(ContainerFullWidth)`
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 3fr;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;
export const SessionConsumptionPaper = styled(BdsPaper)`
  padding: 1.5rem;
  margin-bottom: 2rem;
  animation: ${FadeIn} ease-in 0.5s;
  animation-iteration-count: 1;
`;

export const ConsolidatedSessionFilters = styled.div`
  position: relative;
`;

export const SessionConsumptionConsolidatedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .none {
    display: none;
  }
  .main-session-grid {
    > bds-grid {
      > bds-grid {
        width: 100%;
      }
    }
  }

  .b-1 {
    margin-bottom: 1rem;
  }

  .category-session-grid {
    > bds-grid {
      padding: 1rem;
      border-radius: 8px;
      bds-typo {
        margin-bottom: 0.5rem;
      }
      > bds-grid {
        & > bds-typo:nth-child(2) {
          margin-left: 0.5rem;
        }
      }
      bds-tooltip {
        margin-left: 0.5rem;
      }
    }
  }
`;

export const SessionConsumptionHeaderFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & bds-select {
    margin-left: 1.5rem;
  }
`;

export const SessionConsumptionRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: ${(props) => (props.isDetailedView ? '1.5rem' : '')};

  & bds-paper {
    margin-left: 20px;
    height: 30px;
    border-radius: 8px;
    padding: 6px 8px;
  }
`;

export const SessionConsumptionUsageStatementContent = styled.div`
  & > bds-typo:first-of-type {
    &::part(bds-typo__text) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  & > bds-paper > table > tbody > tr > td:first-of-type > span:first-of-type {
    font-weight: bold;
  }

  bds-banner {
    margin: 1.5rem 0;

    a {
      text-decoration: underline;
      color: ${colors.ContentDefault};
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
    }
  }

  & table {
    border: 0.125rem solid ${colors.Surface3};
    thead {
      border-top: none;
      border-bottom: 0.125rem solid ${colors.Surface3};
    }
  }
`;
