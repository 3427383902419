import { weekRangeOfSpecifiedMonth } from 'utils/date';
import { BillingWeekItems } from '../metrics/BillingWeekItems';

export class SummarySessionConsumption {
  freeTierLimit!: number;
  total!: number;
  totalBusinessInitiated!: number;
  totalFreeEntryPoint!: number;
  totalFreeTier!: number;
  totalRegular!: number;
  totalUserInitiated!: number;
  weekItems!: BillingWeekItems[];
  constructor(summarySessionConsumption: Partial<SummarySessionConsumption | null>) {
    this.freeTierLimit = 0;
    this.total = 0;
    this.totalBusinessInitiated = 0;
    this.totalFreeEntryPoint = 0;
    this.totalFreeTier = 0;
    this.totalRegular = 0;
    this.totalUserInitiated = 0;
    this.weekItems = weekRangeOfSpecifiedMonth(new Date().getFullYear(), new Date().getMonth());
    Object.assign(this, summarySessionConsumption);
  }
}
