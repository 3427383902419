import { IframeMessageProxy } from 'iframe-message-proxy';

export const sendApplicationRequest = (method, args) => {
  return IframeMessageProxy.sendMessage({
    action: 'sendApplicationRequest',
    content: {
      method,
      args,
    },
  });
};

export const ApplicationApi = {
  sendApplicationRequest,
};
