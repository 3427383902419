import React, { useEffect } from 'react';
import { HomeComponent } from './HomeComponent';
import { BlipTabs } from 'blip-toolkit';
import { useContentLocalizer } from 'hooks/useContentLocalizer';
import { localization } from './localization';
import { useTenant } from 'contexts/TenantContext';
import { useCommon } from 'contexts/CommonContext';
import { startLoading, stopLoading } from 'api/CommonApi';
import {HomeComponentContainer} from './styles';

const HomeContainer = () => {
  const content = useContentLocalizer(localization);
  const { tenant } = useTenant();
  const {
    setHeaderContent,
    common: { loggedUser },
  } = useCommon();

  useEffect(() => {
    setHeaderContent({ redirect: '/', text: `${content.formatString(content.members)} ${tenant.info.name}` });

    return () => setHeaderContent({ redirect: null });
  }, [tenant.name]);

  useEffect(() => {
    startLoading();
    new BlipTabs('tab-nav');
    stopLoading();
  }, []);
  return (
    <HomeComponentContainer>
      <HomeComponent
        content={content}
        tenant={tenant.info}
        members={
          tenant.members
            ? tenant.members.filter(
              (member) =>
                (member.userStatus === 'Accepted' || member.userStatus === 'PendingUser') &&
                member.userIdentity !== loggedUser.identity,
            )
            : []
        }
        pendingMembers={tenant.members ? tenant.members.filter((member) => member.userStatus === 'PendingTenant') : []}
        loggedUser={loggedUser}
      />
    </HomeComponentContainer>
  );
};

export { HomeContainer };
