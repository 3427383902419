import React from 'react';
import PropTypes from 'prop-types';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { getFirstDayOfSpecifiedMonth, getLastDayOfSpecifiedMonth, toUTCDateString } from '../../../../../utils/date';
import { useContentLocalizer } from '../../../../../hooks/useContentLocalizer';
import { localization } from './localizations';
import { ConsolidatedBorder, ConsolidatedHeader, ConsolidatedEndInfo } from './styles';

const ConsolidatedHeaderComponent = ({
  infoHeader,
  dateToSearch,
  currentMonth,
  middleInfoContent,
  endInfoContent,
  showConsolidatedBorder = true,
  filter1,
  filter2,
}) => {
  const componentContent = useContentLocalizer(localization);

  const dateContent = React.useMemo(() => {
    return componentContent.formatString(componentContent.period, {
      startDate: toUTCDateString(getFirstDayOfSpecifiedMonth(dateToSearch), componentContent.locale, true),
      endDate: toUTCDateString(
        currentMonth ? new Date() : getLastDayOfSpecifiedMonth(dateToSearch),
        componentContent.locale,
      ),
    });
  }, [dateToSearch]);

  return (
    <ConsolidatedHeader>
      <bds-grid direction="row" justify-content="space-between" margin="b-3">
        <bds-grid direction="column" justify-content="space-between">
          <bds-grid direction="row" margin="b-1">
            <BdsTypo margin={false} tag="h3" variant="fs-24" bold="bold">
              {infoHeader}
            </BdsTypo>
            {currentMonth && <bds-chip-tag color="warning">{componentContent.partialUsage}</bds-chip-tag>}
          </bds-grid>
          <BdsTypo variant="fs-14" lineHeight="plus">
            {dateContent}
          </BdsTypo>
        </bds-grid>
        <bds-grid direction="row" justify-content="space-between">
          {filter1}
          {filter2}
        </bds-grid>
      </bds-grid>
      {middleInfoContent && (
        <BdsTypo variant="fs-14" lineHeight="plus">
          {middleInfoContent}
        </BdsTypo>
      )}
      {showConsolidatedBorder && <ConsolidatedBorder className="color-surface-3" />}
      {endInfoContent && (
        <ConsolidatedEndInfo>
          <BdsTypo variant="fs-14" lineHeight="plus">
            {endInfoContent}
          </BdsTypo>
        </ConsolidatedEndInfo>
      )}
    </ConsolidatedHeader>
  );
};

ConsolidatedHeaderComponent.propTypes = {
  infoHeader: PropTypes.string.isRequired,
  dateToSearch: PropTypes.instanceOf(Date).isRequired,
  currentMonth: PropTypes.bool.isRequired,
  showConsolidatedBorder: PropTypes.bool,
  middleInfoContent: PropTypes.string,
  endInfoContent: PropTypes.string,
};

export { ConsolidatedHeaderComponent };
