import React, { useContext, useEffect } from 'react';
import AgentConsumptionContext from '../../../../contexts/AgentConsumptionContext';
import { AgentConsumptionContainer } from '../styles';
import AgentConsumptionConsolidatedInformations from './AgentConsumptionConsolidatedInformations';
import AgentConsumptionMonthlyUsage from './AgentConsumptionMonthlyUsage';

const AgentConsumptionSummaryComponent: React.FC = () => {
  const { tenant, content, setHeaderContent } = useContext(AgentConsumptionContext);

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: null });
  }, []);

  return (
    <AgentConsumptionContainer>
      <AgentConsumptionConsolidatedInformations></AgentConsumptionConsolidatedInformations>
      <AgentConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={5}></AgentConsumptionMonthlyUsage>
    </AgentConsumptionContainer>
  );
};

export default AgentConsumptionSummaryComponent;
