import React, { FC, useContext, useEffect } from 'react';
import agentConsumptionContext from '../../../../../contexts/AgentConsumptionContext';
import AgentConsumptionMonthlyUsage from '../../../AgentConsumption/components/AgentConsumptionMonthlyUsage';
import { AgentConsumptionContainer } from '../../styles';

const MonthlyUsageComponent: FC = () => {
  const { content, dateToSearch, tenant, setHeaderContent } = useContext(agentConsumptionContext);
  useEffect(() => {
    setHeaderContent({
      redirect: '/agent-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: '/' });
  }, [dateToSearch]);

  return (
    <AgentConsumptionContainer>
      <AgentConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={-1}></AgentConsumptionMonthlyUsage>
    </AgentConsumptionContainer>
  );
};

export default MonthlyUsageComponent;
