export const localization = {
  pt: {
    monthPlaceholder: 'Mês',
  },
  en: {
    monthPlaceholder: 'Month',
  },
  es: {
    monthPlaceholder: 'Mes',
  },
};
