export const localizationMauMsg = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'MAUs e Mensagens',
      description:
        'Seu contrato registra o volume total de MAUs e Mensagens, mas cobra o valor mais baixo entre os dois.',
      showMoreButton: 'Mostrar mais',
      tooltipText:
        'Usuários Ativos Mensalmente (MAUs) são aqueles que tiveram qualquer contato com o chatbot (por meio de um envio ativo ou não), ao menos uma vez no mês. Eles serão contabilizados uma vez. Mensagens (MSGs) correspondem ao total de mensagens trafegado no mês. Consideramos ativadores das métricas todo contato realizado via chatbot, o atendimento humano (via Blip desk) e as mensagens ativas.',
    },
    leftChart: {
      title: 'Consumo atual',
      labels: {
        miu: 'MAUs',
        msg: 'Mensagens',
      },
      customLabels: {
        miu: 'MAUs',
        msg: 'Mensagens',
      },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume de MAUs do mês atual',
      },
      totalMsg: {
        description: 'Volume de Mensagens do mês atual',
      },
      topWeekNumberMsg: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior número de Mensagens',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Consumo semanal de MAUs e Mensagens',
      customLabels: {
        miu: 'MAUs consumidos',
        msg: 'Mensagens consumidas',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Serão contabilizados os MAUs e MSGs do mês corrente. Após a aplicação de valores acordados em contrato, será cobrada a métrica de menor valor.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Média semanal de consumo de MAUs',
      },
      weeklyMsgAverage: {
        description: 'Média semanal de consumo de Mensagens',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior número de MAUs',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'MAUs and Messages',
      description:
        'Your contract records the total volume of MAUs and Messages, but only charges the lowest of the two.',
      showMoreButton: 'Show more',
      tooltipText:
        'Monthly Active Users (MAUs) are users who have had any contact with a chatbot (via active messages or not) at least once a month. They will be counted once. Messages (MSGs) correspond to the total number of messages exchanged in a month. Contacts made via chatbot, human service (Blip desk), and active messages are metrics activators.',
    },
    leftChart: {
      title: 'Current usage',
      labels: { miu: 'MAUs', msg: 'Messages' },
      customLabels: { miu: 'MAUs', msg: 'Messages' },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume of MAUs in the current month',
      },
      totalMsg: {
        description: 'Volume of messages in the current month',
      },
      topWeekNumberMsg: {
        label: '{0} week',
        description: 'Week of the selected month with the highest number of messages',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Weekly usage of MAUs and Messages',
      customLabels: { miu: 'Usage of MAUs', msg: 'Counted messages' },
      warningLabel: {
        bold: 'Important: ',
        regular:
          'MAUs and MSGs for the current month will be counted. After applying the agreed values, only the metric with the lowest value will be charged.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Weekly average usage of MAUs',
      },
      weeklyMsgAverage: {
        description: 'Weekly average usage of messages',
      },
      topWeekNumberMiu: {
        label: '{0} week',
        description: 'Week of the selected month with the highest number of MAUs',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'MAUs y Mensajes',
      description: 'Tu contrato registra el volumen total de MAU y Mensajes, pero solo cobra el menor de los dos.',
      showMoreButton: 'Mostrar más',
      tooltipText:
        'Los Usuarios Activos Mensuales (MAUs) son aquellos que han tenido algún contacto con el chatbot (a través de un mensaje activo o no) al menos una vez al mes. MAUs se contarán una vez. Mensajes (MSGs) corresponden al número total de mensajes intercambiadas en el mes. Son activadores de las métricas todos los contactos realizados a través de chatbot, servicio humano (vía Blip desk) y mensajes activos.',
    },
    leftChart: {
      title: 'Consumo actual',
      labels: { miu: 'MAUs', msg: 'Mensajes' },
      customLabels: { miu: 'MAUs', msg: 'Mensajes' },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volumen de MAUs del mes actual',
      },
      totalMsg: {
        description: 'Volumen de mensajes del mes actual',
      },
      topWeekNumberMsg: {
        label: '{0} semana',
        description: 'Semana del mes seleccionado con el mayor numero de Mensajes',
      },
    },
    rightChart: {
      period: '{startDate} al {endDate}',
      title: 'Consumo semanal de MAUs y Mensajes',
      customLabels: { miu: 'MAUs consumidos', msg: 'Mensajes consumidos' },
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Se contarán MAUs y MSGs del mes en curso. Luego de la aplicación de los valores pactados en el contrato, se cobrará la métrica de menor valor.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Promedio semanal de consumo de MAUs',
      },
      weeklyMsgAverage: {
        description: 'Promedio semanal de consumo de mensajes',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana del mes seleccionado con mayor número de MAUs',
      },
    },
  },
};

export const localizationMeuMsg = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'MEUs e Mensagens',
      description:
        'Seu contrato registra o volume total de MEUs e Mensagens, mas cobra o valor mais baixo entre os dois.',
      showMoreButton: 'Mostrar mais',
      tooltipText:
        'Usuários Ativos Mensalmente (MEUs) são aqueles que entraram em contato com o chatbot ao menos uma vez no mês. MSGs (Mensagens) correspondem ao total de Mensagens trafegado no mês.',
    },
    leftChart: {
      title: 'Consumo atual',
      tooltipText:
        'O volume de MSGs usualmente será maior do que o de MEUs. Para a fatura, é o valor final que determinará qual volumetria será usada para a cobrança. Assim, o valor na fatura do plano será a volumetria do mês vezes o valor negociado por unidade, sendo cobrado o menor valor, seja ele correspondente a MEUs ou a Mensagens.',
      labels: {
        miu: 'MEUs',
        msg: 'Mensagens',
      },
      customLabels: {
        miu: 'MEUs',
        msg: 'Mensagens',
      },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume de MEUs do mês atual',
      },
      totalMsg: {
        description: 'Volume de Mensagens do mês atual',
      },
      topWeekNumberMsg: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior número de Mensagens',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Consumo semanal de MEUs e Mensagens',
      customLabels: {
        miu: 'MEUs consumidos',
        msg: 'Mensagens consumidas',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Um usuário ativo mensal será contabilizado uma única vez, a partir do seu primeiro contato com o chatbot no mês.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Média semanal de consumo de MEUs',
      },
      weeklyMsgAverage: {
        description: 'Média semanal de consumo de Mensagens',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior número de MEUs',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'MEUs and Messages',
      description:
        'Your contract records the total volume of MEUs and Messages, but only charges the lowest of the two.',
      showMoreButton: 'Current usage',
      tooltipText:
        'Monthly Active Users (MEUs) are those who have contacted the chatbot at least once in a month. Messages (MSGs) are the total number of messages exchanged in a month.',
    },
    leftChart: {
      title: 'Current usage',
      tooltipText:
        'The volume of MSGs will usually be greater than the volume of MEUs. In the invoice, the final value will determine which volume will be considered for billing. The amount to be paid will be calculated as follows: the volume of the month times the amount negotiated per unit. The lower amount will be charged, be it related to MEUs or Messages.',
      labels: { miu: 'MEUs', msg: 'Messages' },
      customLabels: { miu: 'MEUs', msg: 'Messages' },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume of MEUs in the current month',
      },
      totalMsg: {
        description: 'Volume of messages in the current month',
      },
      topWeekNumberMsg: {
        label: '{0} week',
        description: 'Week of the selected month with the highest number of messages',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Weekly usage of MEUs and Messages',
      customLabels: { miu: 'Usage of MEUs', msg: 'Counted messages' },
      warningLabel: {
        bold: 'Important: ',
        regular:
          'A monthly active user will be counted only once, upon their first contact with the chatbot in a month.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Weekly average usage of MEUs',
      },
      weeklyMsgAverage: {
        description: 'Weekly average usage of messages',
      },
      topWeekNumberMiu: {
        label: '{0} week',
        description: 'Week of the selected month with the highest number of MEUs',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'MEUs y mensajes',
      description: 'Tu contrato registra el volumen total de MAU y Mensajes, pero solo cobra el menor de los dos.',
      showMoreButton: 'Mostrar más',
      tooltipText:
        'Usuarios activos mensuales (MEUs) son aquellos que se han puesto en contacto con el chatbot al menos una vez al mes. Mensajes (MSG) corresponden al número total de mensajes intercambiados en un mes.',
    },
    leftChart: {
      title: 'Consumo actual',
      labels: { miu: 'MEUs', msg: 'Mensajes' },
      customLabels: { miu: 'MEUs', msg: 'Mensajes' },
      tooltipText:
        'El volumen de MSG normalmente será mayor que el volumen de MEUs. En la factura, el valor final determinará qué volumen se considerará para la facturación. El monto a pagar se calculará de la siguiente manera: el volumen del mes por el monto negociado por unidad. Se cobrará el monto menor, ya sea relacionado con MAU o Mensajes.',
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volumen de MEUs del mes actual',
      },
      totalMsg: {
        description: 'Volumen de mensajes del mes actual',
      },
      topWeekNumberMsg: {
        label: '{0} semana',
        description: 'Semana del mes seleccionado con más mensajes',
      },
    },
    rightChart: {
      period: '{startDate} al {endDate}',
      title: 'Consumo semanal de MEUs y Mensajes',
      customLabels: { miu: 'MEUs consumidos', msg: 'Mensajes consumidos' },
      warningLabel: {
        bold: 'Importante: ',
        regular: 'Un usuario activo mensual se contará solo una vez, en su primer contacto con el chatbot en un mes.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Consumo medio semanal de MEUs',
      },
      weeklyMsgAverage: {
        description: 'Promedio semanal de consumo de mensajes',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana del mes seleccionado con mayor número de MEUs',
      },
    },
  },
};

export const localizationMau = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'MAUs',
      description: 'Seu contrato registra o volume total de MAUs',
      showMoreButton: 'Mostrar mais',
      tooltipText:
        'Usuários Ativos Mensalmente (MAUS) são aqueles que tiveram qualquer contato com o chatbot (por meio de um envio ativo ou não), ao menos uma vez no mês. Eles serão contabilizados uma vez.',
    },
    leftChart: {
      title: 'Consumo atual',
      labels: {
        miu: 'MAUs',
      },
      customLabels: {
        miu: 'MAUs',
      },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume de MAUs do mês atual',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Consumo semanal de MAUs',
      customLabels: {
        miu: 'MAUs consumidos',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Um usuário ativo mensal será contabilizado uma única vez, a partir da sua primeira conversa com o chatbot no mês, seja ela iniciada pelo usuário ou pelo chatbot (com o envio de mensagens ativas).',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Média semanal de consumo de MAUs',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior número de MAUs',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'MAUs',
      description: 'Your contract records the total volume of MAUs.',
      showMoreButton: 'Show more',
      tooltipText:
        'Monthly Active Users (MAUs) are users who have had any contact with a chatbot (via active messages or not) at least once a month. They will be counted once.',
    },
    leftChart: {
      title: 'Current usage',
      labels: {
        miu: 'MAUs',
      },
      customLabels: {
        miu: 'MAUs',
      },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume of MAUs in the current month',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Weekly usage of MAUs',
      customLabels: {
        miu: 'Usage of MAUs',
      },
      warningLabel: {
        bold: 'Important: ',
        regular:
          'A MAU will be counted only once, upon the first contact with a chatbot in a month, be it initiated by the user or the chatbot (via active messages).',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Weekly average usage of MAUs',
      },
      topWeekNumberMiu: {
        label: '{0} week',
        description: 'Week of the selected month with the highest number of MAUs',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'MAUs',
      description: 'Tu contrato registra el volumen total de MAUs.',
      showMoreButton: 'Mostrar más',
      tooltipText:
        'Los Usuarios Activos Mensuales (MAUs) son aquellos que han tenido algún contacto con el chatbot (a través de un mensaje activo o no) al menos una vez al mes. MAUs se contarán una vez.',
    },
    leftChart: {
      title: 'Consumo actual',
      labels: {
        miu: 'MAUs',
      },
      customLabels: {
        miu: 'MAUs',
      },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volumen de MAUs del mes actual',
      },
    },
    rightChart: {
      period: '{startDate} al {endDate}',
      title: 'Consumo semanal de MAUs',
      customLabels: {
        miu: 'MAUs consumidos',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Un MAU se contará solo una vez, en su primer contacto con el chatbot en un mes, ya sea iniciado por el usuario o por el chatbot (con el envío de mensajes activos).',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Promedio semanal de consumo de MAUs',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana del mes seleccionado con mayor número de MAUs',
      },
    },
  },
};

export const localizationMeu = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'MEUs',
      description: 'Seu contrato registra o volume total de MEUs',
      showMoreButton: 'Mostrar mais',
      tooltipText:
        'Usuários Engajados Mensalmente (MEUs) são aqueles que entraram em contato com o chatbot ao menos uma vez no mês.',
    },
    leftChart: {
      title: 'Consumo atual',
      tooltipText:
        'Para a fatura, é o valor final que determinará qual volumetria será usada para a cobrança. Assim, o valor na fatura do plano será a volumetria do mês vezes o valor negociado por unidade.',
      labels: {
        miu: 'MEUs',
      },
      customLabels: {
        miu: 'MEUs',
      },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume de MEUs do mês atual',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Consumo semanal de MEUs',
      customLabels: {
        miu: 'MEUs consumidos',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Um usuário ativo mensal será contabilizado uma única vez, a partir do seu primeiro contato com o chatbot no mês.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Média semanal de consumo de MEUs',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior número de MEUs',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'MEUs',
      description: 'Your contract records the total volume of MEUs.',
      showMoreButton: 'Current usage',
      tooltipText: 'Monthly Active Users (MEUs) are those who have contacted the chatbot at least once in a month.',
    },
    leftChart: {
      title: 'Current usage',
      tooltipText: 'In the invoice, the final value will determine which volume will be considered for billing.',
      labels: {
        miu: 'MEUs',
      },
      customLabels: {
        miu: 'MEUs',
      },
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volume of MEUs in the current month',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Weekly usage of MEUs',
      customLabels: {
        miu: 'Usage of MEUs',
      },
      warningLabel: {
        bold: 'Important: ',
        regular:
          'A monthly active user will be counted only once, upon their first contact with the chatbot in a month.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Weekly average usage of MEUs',
      },
      topWeekNumberMiu: {
        label: '{0} week',
        description: 'Week of the selected month with the highest number of MEUs',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'MEUs',
      description: 'Tu contrato registra el volumen total de MEUs.',
      showMoreButton: 'Mostrar más',
      tooltipText:
        'Usuarios activos mensuales (MEUs) son aquellos que se han puesto en contacto con el chatbot al menos una vez al mes.',
    },
    leftChart: {
      title: 'Consumo actual',
      labels: {
        miu: 'MEUs',
      },
      customLabels: {
        miu: 'MEUs',
      },
      tooltipText:
        'En la factura, el valor final determinará qué volumen se considerará para la facturación. El monto a pagar se calculará de la siguiente manera: el volumen del mes por el monto negociado por unidad.',
    },
    leftInfoPaper: {
      totalMiu: {
        description: 'Volumen de MEUs del mes actual',
      },
    },
    rightChart: {
      period: '{startDate} al {endDate}',
      title: 'Consumo semanal de MEUs',
      customLabels: {
        miu: 'MEUs consumidos',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular: 'Un usuario activo mensual se contará solo una vez, en su primer contacto con el chatbot en un mes.',
      },
    },
    rightInfoPaper: {
      weeklyMiuAverage: {
        description: 'Consumo medio semanal de MEUs',
      },
      topWeekNumberMiu: {
        label: '{0} semana',
        description: 'Semana del mes seleccionado con mayor número de MEUs',
      },
    },
  },
};

export const localizationMsg = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'Mensagens',
      description: 'Seu contrato registra o volume total de Mensagens',
      showMoreButton: 'Mostrar mais',
      tooltipText: 'Mensagens (MSGs) correspondem ao total de mensagens trafegado no mês.',
    },
    leftChart: {
      title: 'Consumo atual',
      labels: {
        msg: 'Mensagens',
      },
      customLabels: {
        msg: 'Mensagens',
      },
    },
    leftInfoPaper: {
      totalMsg: {
        description: 'Volume de Mensagens do mês atual',
      },
      topWeekNumberMsg: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior número de Mensagens',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Consumo semanal de Mensagens',
      customLabels: {
        msg: 'Mensagens consumidas',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Todas as MSGs trafegadas no mês corrente serão contabilizadas. ',
      },
    },
    rightInfoPaper: {
      weeklyMsgAverage: {
        description: 'Média semanal de consumo de Mensagens',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'Messages',
      description: 'Your contract records the total volume of Messages.',
      showMoreButton: 'Show more',
      tooltipText: 'Messages (MSGs) correspond to the total number of messages exchanged in a month.',
    },
    leftChart: {
      title: 'Current usage',
      labels: {
        msg: 'Messages',
      },
      customLabels: {
        msg: 'Messages',
      },
    },
    leftInfoPaper: {
      totalMsg: {
        description: 'Volume of messages in the current month',
      },
      topWeekNumberMsg: {
        label: '{0} week',
        description: 'Week of the selected month with the highest number of messages',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Weekly usage of Messages',
      customLabels: {
        msg: 'Counted messages',
      },
      warningLabel: {
        bold: 'Important: ',
        regular: 'All MSGs exchanged in the current month will be counted.',
      },
    },
    rightInfoPaper: {
      weeklyMsgAverage: {
        description: 'Weekly average usage of messages',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'Mensajes',
      description: 'Tu contrato registra el volumen total de Mensajes.',
      showMoreButton: 'Mostrar más',
      tooltipText: 'Los mensajes (MSGs) corresponden al número total de mensajes intercambiados en un mes.',
    },
    leftChart: {
      title: 'Consumo actual',
      labels: {
        msg: 'Mensajes',
      },
      customLabels: {
        msg: 'Mensajes',
      },
    },
    leftInfoPaper: {
      totalMsg: {
        description: 'Volumen de mensajes del mes actual',
      },
      topWeekNumberMsg: {
        label: '{0} semana',
        description: 'Semana del mes seleccionado con el mayor numero de Mensajes',
      },
    },
    rightChart: {
      period: '{startDate} al {endDate}',
      title: 'Consumo semanal de Mensajes',
      customLabels: {
        msg: 'Mensajes consumidos',
      },
      warningLabel: {
        bold: 'Importante: ',
        regular: 'Se contarán todos los MSGs transmitidos en el mes actual.',
      },
    },
    rightInfoPaper: {
      weeklyMsgAverage: {
        description: 'Promedio semanal de consumo de mensajes',
      },
    },
  },
};
