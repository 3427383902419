import React, { useCallback, useMemo, useState } from 'react';
import {
  BdsAvatar,
  BdsButton,
  BdsCheckbox,
  BdsGrid,
  BdsIcon,
  BdsSwitch,
  BdsTableCell,
  BdsTableRow,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { BdsCheckboxCustomEvent } from 'blip-ds/loader';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { WhatsAppApplication } from '../typing/models/whatsapp-application.interface';

interface ListItemProps {
  bot: WhatsAppApplication;
  translate: Record<string, string>;
  onEnableCalling: (application: WhatsAppApplication, enabled: boolean) => Promise<boolean>;
  onEnableIconVisibility: (application: WhatsAppApplication, enabled: boolean) => Promise<boolean>;
  onChangeSelectBot: (event: BdsCheckboxCustomEvent<any>) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  bot,
  translate,
  onEnableCalling,
  onEnableIconVisibility,
  onChangeSelectBot,
}) => {
  const [isLoading, setLoading] = useState(false);
  const isCheckboxDisabled = useMemo(
    () => !bot.hasPermission || !bot.isCallingEnabled || isLoading,
    [bot.hasPermission, bot.isCallingEnabled, isLoading, setLoading],
  );
  const isCallingEnabled = useMemo(() => bot.isCallingEnabled, [bot.isCallingEnabled]);
  const isIconEnabled = useMemo(() => bot.isCallingIconVisible, [bot.isCallingIconVisible]);

  const _onEnableCalling = useCallback(
    (enable: boolean) => {
      setLoading(true);
      return onEnableCalling(bot, enable).finally(() => setLoading(false));
    },
    [bot.identity],
  );

  const _onEnableIconVisibility = useCallback(
    (enabled: boolean) => {
      setLoading(true);
      return onEnableIconVisibility(bot, enabled).finally(() => setLoading(false));
    },
    [bot.identity],
  );

  return (
    <BdsTableRow className={bot.selected ? 'selected' : ''}>
      <BdsTableCell>
        <BdsCheckbox
          disabled={!bot.hasPermission}
          name={bot.id}
          checked={bot.selected}
          onBdsChange={onChangeSelectBot}
          label={''}
          refer={''}
        />
      </BdsTableCell>
      <BdsTableCell>
        <BdsGrid direction="row" alignItems="center" gap="2">
          {bot.imageUri ? (
            <BdsAvatar thumbnail={bot.imageUri} />
          ) : (
            <BdsIcon name="blip-chat" type="logo" size="xxx-large" className="round-avatar" />
          )}
          <BdsTypo bold="bold" variant="fs-16" margin={false} className={!bot.hasPermission ? 'label' : ''}>
            {bot.name}
          </BdsTypo>
        </BdsGrid>
      </BdsTableCell>
      <BdsTableCell>
        <BdsTypo variant="fs-14" bold="regular" margin={false}>
          {formatPhoneNumber(bot.phoneNumber)}
        </BdsTypo>
      </BdsTableCell>
      <BdsTableCell>
        <BdsSwitch
          name={bot.id}
          onBdsChange={(event) => {
            const value = event.detail.checked;
            if (value === bot.isCallingIconVisible) return;

            bot.isCallingIconVisible = value;
            _onEnableIconVisibility(value);
          }}
          checked={isIconEnabled}
          disabled={isCheckboxDisabled}
          refer={''}
        />
      </BdsTableCell>
      <BdsTableCell>
        <BdsTooltip tooltip-text={translate.hasNotPermission} disabled={bot.hasPermission}>
          {bot.hasPermission ? (
            isCallingEnabled ? (
              <BdsButton
                key={`bds-button-${bot.id}`}
                variant="ghost"
                bdsLoading={isLoading}
                disabled={isLoading}
                onClick={() => _onEnableCalling(false)}
              >
                {translate.disable}
              </BdsButton>
            ) : (
              <BdsButton
                key={`bds-button-2-${bot.id}`}
                bdsLoading={isLoading}
                disabled={isLoading}
                onClick={() => _onEnableCalling(true)}
              >
                {translate.enable}
              </BdsButton>
            )
          ) : (
            <BdsIcon name="lock" className="label" />
          )}
        </BdsTooltip>
      </BdsTableCell>
    </BdsTableRow>
  );
};

export default ListItem;
