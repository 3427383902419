export const localization =  {
  pt: {
    selected: 'selecionado(s)',
    emptyMessage: 'Não há dados',
    true: 'Sim',
    false: 'Não',
    locale: 'pt-BR',
  },
  en: {
    selected: 'selected',
    emptyMessage: 'No data',
    true: 'Yes',
    false: 'No',
    locale: 'en-US',
  },
  es: {
    selected: 'seleccionado(s)',
    emptyMessage: 'No hay datos',
    true: 'Sí',
    false: 'No',
    locale: 'es-ES',
  },
};
