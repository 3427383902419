import React, { Reducer, useEffect, useMemo, useReducer, useState } from 'react';
import { useCommon } from '../../../contexts/CommonContext';
import { useTenant } from '../../../contexts/TenantContext';
import { useContentLocalizer } from '../../../hooks/useContentLocalizer';
import { localization } from './localization';
import SessionConsumptionContext from '../../../contexts/SessionConsumptionContext';
import { Route } from 'react-router';
import SessionConsumptionSummary from './components/SessionConsumptionSummary';
import { BdsSelectOption } from 'blip-ds/dist/blip-ds-react';
import { Switch } from 'react-router-dom';
import BillingSessionConsumptionApi from '../../../api/Billing/BillingSessionConsumptionApi';
import { sortByProperty } from '../../../utils/array';
import MonthlyUsage from './components/Monthly/MonthlyUsage';
import DailyUsage from './components/Daily/DailyUsage';
import { AsideMenu } from '../components/AsideMenu';
import { useBillingConsumptionContext } from '../../../contexts/Billing/BillingConsumptionContext';
import { action, origin } from '../../../shared/SegmentEvents';
import { useBillingPlanUsageContext } from '../../../contexts/Billing/BillingPlanUsageContext';
import { billingRoutes, billingSubRoutes } from '../../../routes/BillingRoutes';
import { WabaConversationConsumption } from '../../../models/billing/sessionConsumption/WabaConversationConsumption';
import { OldConsolidatedSessionConsumption } from '../../../models/billing/sessionConsumption/OldConsolidatedSessionConsumption';
import { OldMonthlySessionConsumption } from '../../../models/billing/sessionConsumption/OldMonthlySessionConsumption';
import { OldDailySessionConsumption } from '../../../models/billing/sessionConsumption/OldDailySessionConsumption';
import { ConsolidatedSessionConsumption } from '../../../models/billing/sessionConsumption/ConsolidatedSessionConsumption';
import { MonthlySessionConsumption } from '../../../models/billing/sessionConsumption/MonthlySessionConsumption';
import { DailySessionConsumption } from '../../../models/billing/sessionConsumption/DailySessionConsumption';

interface OldCompiledSessionConsumptionDataState {
  oldConsolidatedSessionConsumption: OldConsolidatedSessionConsumption;
  oldConsolidatedContainsDataOnSelectedMonth: boolean;
}

interface CompiledSessionConsumptionDataState {
  consolidatedSessionConsumption: ConsolidatedSessionConsumption;
  consolidatedContainsDataOnSelectedMonth: boolean;
}

const SessionConsumption: React.FC = () => {
  const { dateToSearch, setDateToSearch, periodOptions, currentMonth } = useBillingConsumptionContext();
  const {
    setHeaderContent,
    common: { config, loggedUser },
  } = useCommon();
  const { tenant } = useTenant();
  const content = useContentLocalizer(localization);
  const [conversationTypeFilter, setConversationTypeFilter] = useState(content.defaultConversationTypeValue);
  const [conversationWabaFilter, setConversationWabaFilter] = useState<WabaConversationConsumption>();
  const billingSessionConsumptionApi = new BillingSessionConsumptionApi();
  const oldConsolidatedSessionConsumption = new OldConsolidatedSessionConsumption(null);
  const consolidatedSessionConsumption = new ConsolidatedSessionConsumption(null);
  const [conversationWabaFilterOptions, setConversationWabaFilterOptions] = useState<WabaConversationConsumption[]>([]);
  const [oldSessionConsumptionMonthlyUsage, setOldSessionConsumptionMonthlyUsage] = useState<
    OldMonthlySessionConsumption[]
  >([]);
  const [oldSessionConsumptionDailyUsage, setOldSessionConsumptionDailyUsage] = useState<OldDailySessionConsumption[]>(
    [],
  );

  const [sessionConsumptionMonthlyUsage, setSessionConsumptionMonthlyUsage] = useState<MonthlySessionConsumption[]>([]);
  const [sessionConsumptionDailyUsage, setSessionConsumptionDailyUsage] = useState<DailySessionConsumption[]>([]);

  const [isWabaloading, setWabaLoading] = useState(false);
  const { planStatus, getPlanStatus } = useBillingPlanUsageContext();
  const [oldCompiledSessionConsumptionDataState, setOldCompiledSessionConsumptionDataState] = useReducer<
    Reducer<OldCompiledSessionConsumptionDataState, Partial<OldCompiledSessionConsumptionDataState>>
  >(
    (oldCompiledSessionConsumptionDataState, newCompiledSessionConsumptionDataState) => ({
      ...oldCompiledSessionConsumptionDataState,
      ...newCompiledSessionConsumptionDataState,
    }),
    {
      oldConsolidatedSessionConsumption,
      oldConsolidatedContainsDataOnSelectedMonth: false,
    },
  );
  const [compiledSessionConsumptionDataState, setCompiledSessionConsumptionDataState] = useReducer<
    Reducer<CompiledSessionConsumptionDataState, Partial<CompiledSessionConsumptionDataState>>
  >(
    (compiledSessionConsumptionDataState, newCompiledSessionConsumptionDataState) => ({
      ...compiledSessionConsumptionDataState,
      ...newCompiledSessionConsumptionDataState,
    }),
    {
      consolidatedSessionConsumption,
      consolidatedContainsDataOnSelectedMonth: false,
    },
  );
  const [showSessionNewPricing, setShowSessionNewPricing] = useState(true);
  const [showConsolidatedSessionNewPricing, setConsolidatedSessionNewPricing] = useState(true);

  const WHATSAPP_BP_PRICING_CHANGES_URL = `${config.WHATSAPP_BP_PRICING_CHANGES_URL}?locale=${content.locale.replace(
    '-',
    '_',
  )}`;
  const WHATSAPP_BP_PRICING_CHANGES_CONSOLIDATED_START_DATE = new Date(
    config.WHATSAPP_BP_PRICING_CHANGES_CONSOLIDATED_START_DATE,
  );
  const WHATSAPP_BP_PRICING_CHANGES_START_DATE = new Date(config.WHATSAPP_BP_PRICING_CHANGES_START_DATE);

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });

    const getData = async () => {
      try {
        setWabaLoading(true);
        const wabaConversationConsumption = await billingSessionConsumptionApi.getWabaConversationConsumption(
          tenant.info.id,
        );

        const wabaOptions: any[] = [];
        wabaConversationConsumption?.map((wabaId, index) => {
          if (index == 0) {
            setConversationWabaFilter(wabaId);
          }

          wabaOptions.push(
            <BdsSelectOption value={wabaId} key={index}>
              {wabaId}
            </BdsSelectOption>,
          );
        });
        setConversationWabaFilterOptions(wabaOptions);
      } finally {
        setWabaLoading(false);
      }
    };

    getData();

    return () => setHeaderContent({ redirect: null });
  }, []);

  useEffect(() => {
    const getData = async () => {
      const showSessionNewPricing = dateToSearch >= WHATSAPP_BP_PRICING_CHANGES_START_DATE;
      const showConsolidatedSessionNewPricing = dateToSearch >= WHATSAPP_BP_PRICING_CHANGES_CONSOLIDATED_START_DATE;
      setShowSessionNewPricing(showSessionNewPricing);
      setConsolidatedSessionNewPricing(showConsolidatedSessionNewPricing);
    };

    getData();
  }, [dateToSearch]);

  const conversationTypeFilterOptions = useMemo(() => {
    return content.conversationType.map((conversationType) => {
      return (
        <BdsSelectOption value={conversationType.value} key={conversationType.value}>
          {conversationType.label}
        </BdsSelectOption>
      );
    });
  }, []);

  const sortOldSessionConsumptionMonthlyUsage = (sort) => {
    const sortedChatbotsConsumption = sortByProperty(oldSessionConsumptionMonthlyUsage, sort.property, sort.order);
    setOldSessionConsumptionMonthlyUsage([...sortedChatbotsConsumption]);
  };

  const sortOldSessionConsumptionDailyUsage = (sort) => {
    const sortedDailySessionUsage = sortByProperty(sessionConsumptionDailyUsage, sort.property, sort.order);
    setOldSessionConsumptionDailyUsage([...sortedDailySessionUsage]);
  };

  const trackingProps = {
    date: dateToSearch,
    planStatus: planStatus.current,
    whatsappConversationType: conversationTypeFilter,
    whatsappConversationWaba: conversationWabaFilter,
    origin: origin.SESSION,
    action: action.SELECT,
  };

  const contextProps = {
    dateToSearch,
    setDateToSearch,
    periodOptions,
    currentMonth,
    trackingProps,
    planStatus,
    getPlanStatus,
    conversationTypeFilter,
    conversationWabaFilter,
    oldCompiledSessionConsumptionDataState,
    compiledSessionConsumptionDataState,
    content,
    tenant,
    loggedUser,
    setHeaderContent,
    billingSessionConsumptionApi,
    sessionConsumptionMonthlyUsage,
    sessionConsumptionDailyUsage,
    oldSessionConsumptionMonthlyUsage,
    oldSessionConsumptionDailyUsage,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    sortOldSessionConsumptionMonthlyUsage,
    setOldSessionConsumptionMonthlyUsage,
    sortOldSessionConsumptionDailyUsage,
    setOldSessionConsumptionDailyUsage,
    setSessionConsumptionMonthlyUsage,
    setSessionConsumptionDailyUsage,
    setOldCompiledSessionConsumptionDataState,
    setCompiledSessionConsumptionDataState,
    setConversationWabaFilter,
    setConversationTypeFilter,
    isWabaloading,
    showSessionNewPricing,
    showConsolidatedSessionNewPricing,
    setShowSessionNewPricing,
    setConsolidatedSessionNewPricing,
    WHATSAPP_BP_PRICING_CHANGES_URL,
    WHATSAPP_BP_PRICING_CHANGES_START_DATE,
    WHATSAPP_BP_PRICING_CHANGES_CONSOLIDATED_START_DATE,
  };

  return (
    <SessionConsumptionContext.Provider value={contextProps}>
      <AsideMenu>
        <Switch>
          <Route exact path={billingRoutes.sessionConsumption} component={SessionConsumptionSummary}></Route>
          <Route exact path={billingSubRoutes.sessionConsumptionMonthly} component={MonthlyUsage}></Route>
          <Route exact path={billingSubRoutes.sessionConsumptionDaily} component={DailyUsage}></Route>
        </Switch>
      </AsideMenu>
    </SessionConsumptionContext.Provider>
  );
};

export default SessionConsumption;
