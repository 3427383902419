export const localization = {
  pt: {
    title: 'A contratação do Blip Copilot ainda não foi realizada.',
    description:
      'Ao habilitar esta funcionalidade, custos adicionais por agente e por sessão poderão ser aplicados à fatura do seu contrato. Ao continuar, você declara que está ciente e concorda com essas possíveis cobranças.',
    moreDescription: 'Em caso de dúvidas sobre a contratação do Blip Copilot, entre em contato com o time comercial. {0}',
    more: 'Saiba mais.',
    moreLink:'https://help.blip.ai/hc/pt-br/articles/21860799152279-Blip-Copilot',
    cancel: 'Cancelar',
    continue: 'Continuar',
  },
  en: {
    title: 'The acquisition of Blip Copilot has not yet been completed.',
    description:
      'By enabling this feature, additional costs per agent and per session may be applied to your contract\'s invoice. By continuing, you acknowledge and agree to these potential charges.',
    moreDescription: 'If you have any questions about the acquisition of Blip Copilot, please contact the sales team. {0}',
    more: 'Learn more.',
    moreLink:'https://help.blip.ai/hc/en-us/articles/21860799152279-Blip-Copilot',
    cancel: 'Cancel',
    continue: 'Continue',
  },
  es: {
    title: 'La adquisición de Blip Copilot aún no ha sido completada.',
    description:
      'Al habilitar esta función, se podrán aplicar costos adicionales por agente y por sesión a la factura de tu contrato. Al continuar, reconoces y aceptas estos posibles cargos.',
    moreDescription: 'Si tienes alguna duda sobre la adquisición de Blip Copilot, contacta al equipo de ventas. {0}',
    more: 'Saber más.',
    moreLink:'https://help.blip.ai/hc/es-mx/articles/21860799152279-Blip-Copilot',
    cancel: 'Cancelar',
    continue: 'Continuar',
  },
};
