import React, { useEffect } from 'react';
import { useTenant } from './../../contexts/TenantContext';
import { useContentLocalizer } from './../../hooks/useContentLocalizer';
import { localization } from './localizations';
import { AsideMenu } from './components/AsideMenu';
import { useCommon } from './../../contexts/CommonContext';
import DataConsumption from './DataConsumption';
import FeatureToggleService from '../../services/FeatureToggleService';
import { TRACKING_LOG } from '../../shared/FeatureToggleKeys';

const BillingConsumption: React.FC = () => {
  const content = useContentLocalizer(localization);
  const { tenant } = useTenant();
  const { setHeaderContent } = useCommon();
  useEffect(() => {
    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: null });
  }, []);

  useEffect(() => {
    const getData = async () => {
      const trackingLog = await FeatureToggleService.isFeatureToggleEnabled(TRACKING_LOG);
      (global as any).isTrackingLogEnabled = trackingLog;
    };
    getData();
  }, []);

  return (
    <AsideMenu>
      <DataConsumption />
    </AsideMenu>
  );
};

export default BillingConsumption;
