import React from 'react';
import PropTypes from 'prop-types';
import { ConsolidatedCard } from './styles';

const ConsolidatedCardComponent = ({
  title,
  value,
  tooltipText,
  currentMonth,
  consolidatedContainsDataOnSelectedMonth,
  content,
}) => {
  return (
    <ConsolidatedCard currentMonth={currentMonth} className="bg-color-surface-2">
      <bds-grid direction="column" justify-content="space-between">
        <bds-grid direction="row" justify-content="flex-start">
          <bds-typo margin={true} bold="bold" variant="fs-16">
            {title}
          </bds-typo>
          {tooltipText && (
            <bds-tooltip position="top-center" tooltip-text={tooltipText}>
              <bds-icon name="info" size="medium"></bds-icon>
            </bds-tooltip>
          )}
        </bds-grid>
        <bds-typo
          variant="fs-32"
          bold="extra-bold"
          margin={false}
          data-test={value}
          class={currentMonth ? 'color-content-default' : 'color-primary'}
        >
          {consolidatedContainsDataOnSelectedMonth ? value.toLocaleString(content.locale) : '--'}
        </bds-typo>
      </bds-grid>
    </ConsolidatedCard>
  );
};

ConsolidatedCardComponent.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  tooltipText: PropTypes.string,
  currentMonth: PropTypes.bool.isRequired,
  consolidatedContainsDataOnSelectedMonth: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
};

export { ConsolidatedCardComponent };
