import styled from 'styled-components';
import { BdsIllustration, BdsPaper } from 'blip-ds/dist/blip-ds-react';
import colors from '_colors.module.scss';
export const Container = styled.div`
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  .label { 
    color: ${colors.ContentGhost};
  }
  .round-avatar {
    border-radius: 50%;
    background: ${colors.System};
    padding: 8px;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
`;

export const Card = styled(BdsPaper)`
  background:  ${({selected, hasPermission}) => {
    if (hasPermission) {
      return '#f6f6f6';
    }
    return selected ? colors.Surface3 : colors.Surface1;
  }
  };
  width: 100%;
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
`;


export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  .icon {
    font-size: 4rem;
    color: ${colors.ContentGhost};
  }
  .message {
    color: ${colors.ContentGhost};
    margin-top: 1rem;
  }
`;

export const EmptyChartIllustration = styled(BdsIllustration)`
  width: 132px;
`;
