export const localization = {
  pt: {
    locale: 'pt-BR',
    header: {
      title: 'Atendentes',
      description:
        'São considerados atendentes consumidos aqueles que receberam pelo menos um ticket no período, realizando um ou mais atendimentos.',
      showMoreButton: 'Mostrar mais',
    },
    leftChart: {
      title: 'Consumo do plano contratado',
      labels: { counted: '{0} Atendentes consumidos', available: '{0} Atendentes disponíveis' },
      customLabels: { counted: '{0}%: Atendentes consumidos', available: '{0}%: Atendentes disponíveis' },
    },
    leftInfoPaper: {
      totalInfo: {
        description: 'Total de atendentes consumido nesse mês',
      },
      availableInfo: {
        description: 'Atendentes restantes disponíveis no plano',
      },
      planInfo: {
        description: 'Total de atendentes do plano contratado',
        tooltip:
          'Quantidade de atendentes disponíveis mensalmente no plano contratado.',
      },
    },
    rightChart: {
      period: '{startDate} a {endDate}',
      title: 'Consumo semanal de atendentes',
      customLabel: 'Atendentes consumidos',
      warningLabel: {
        bold: 'Importante: ',
        regular:
          'Um atendente será contabilizado uma única vez, no seu primeiro acionamento ou ticket recebido no mês.',
      },
    },
    rightInfoPaper: {
      weeklyAverage: {
        description: 'Média semanal de consumo de atendentes',
      },
      topWeekNumber: {
        label: '{0} semana',
        description: 'Semana do mês selecionado que teve o maior consumo de atendentes',
      },
    },
  },
  en: {
    locale: 'en-US',
    header: {
      title: 'Agents',
      description:
        'Counted agents are those who have received at least one ticket in the period, closing one or more of them.',
      showMoreButton: 'Show More',
    },
    leftChart: {
      title: 'Plan usage',
      labels: { counted: '{0} counted agents', available: '{0} available agents' },
      customLabels: { counted: '{0}%: counted agents', available: '{0}%: available agents' },
    },
    leftInfoPaper: {
      totalInfo: {
        description: 'Total of counted agents this month',
      },
      availableInfo: {
        description: 'Available agents on your plan',
      },
      planInfo: {
        description: 'Total number of agents on your plan',
        tooltip:
          'Number of agents available per month on your plan.',
      },
    },
    rightChart: {
      period: '{startDate} to {endDate}',
      title: 'Weekly usage of agents',
      customLabel: 'Counted agents',
      warningLabel: {
        bold: 'Important: ',
        regular: 'An agent will be counted only once, upon receiving a first call or ticket in a month.',
      },
    },
    rightInfoPaper: {
      weeklyAverage: {
        description: 'Weekly average usage of agents',
      },
      topWeekNumber: {
        label: '{0} week',
        description: 'Week of the selected month with the highest count of agents',
      },
    },
  },
  es: {
    locale: 'es-ES',
    header: {
      title: 'Asistentes',
      description:
        'Los asistentes consumidos son aquellos que recibieron al menos un ticket en el período, realizando una o más llamadas.',
      showMoreButton: 'Mostrar más',
    },
    leftChart: {
      title: 'Consumo del plan contratado',
      labels: { counted: '{0} Asistentes consumidos', available: '{0} Asistentes disponibles' },
      customLabels: { counted: '{0}%: Asistentes consumidos', available: '{0}%: Asistentes disponibles' },
    },
    leftInfoPaper: {
      totalInfo: {
        description: 'Total de asistentes utilizados este mes',
      },
      availableInfo: {
        description: 'Asistentes restantes disponibles en el plan',
      },
      planInfo: {
        description: 'Total de asistentes del plan contratado',
        tooltip:
          'Numero de asistentes disponibles por mes en el plan contratado.',
      },
    },
    rightChart: {
      period: '{startDate} al {endDate}',
      title: 'Consumo semanal de Asistentes',
      customLabel: 'Asistentes consumidos',
      warningLabel: {
        bold: 'Importante: ',
        regular: 'Un asistente se contará solo una vez, al recibir una primera llamada o ticket en un mes.',
      },
    },
    rightInfoPaper: {
      weeklyAverage: {
        description: 'Consumo promedio semanal de asistentes',
      },
      topWeekNumber: {
        label: '{0} semana',
        description: 'Semana del mes seleccionada con mayor consumo de asistentes',
      },
    },
  },
};
