import styled from 'styled-components';

export const ConsolidatedCard = styled.div`
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  min-height: 112px;
  display: flex;

  & > bds-grid {
    bds-tooltip {
      margin-left: 0.5rem;
    }
  }
`;
