import React from 'react';
import PropTypes from 'prop-types';
import { BarChartComponent } from './BarChartComponent';

const BarChartContainer = ({ data, options, plugins, redraw }) => {
  return <BarChartComponent data={data} options={options} plugins={plugins} redraw={redraw}></BarChartComponent>;
};

BarChartContainer.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.object,
  redraw: PropTypes.bool,
};

export { BarChartContainer };
