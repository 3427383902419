import styled from 'styled-components';

export const GeneralChartContainer = styled.div`
@media (min-width: 1024px) {
    height: 12em;
  }

  @media (min-width: 1280px) {
    height: 14em;
  }

  @media (min-width: 1440px) {
    height: 16em;
  }

  @media (min-width: 1600px) {
    height: 18em;
  }

  @media (min-width: 1920px) {
    height: 22em;
  }
`;