import React, { createContext, Reducer, useReducer, useState } from 'react';
import BillingAgentConsumptionServiceApi from '../../api/Billing/BillingAgentConsumptionApi';
import { ConsolidatedAgentConsumption } from '../../models/billing/agentConsumption/ConsolidatedAgentConsumption';
import { getMonthTwoDigits } from '../../utils/date';
import { RequestStatus } from '../../shared/Requests';
import {
  BillingMetrics,
  getMetricStatus,
  MetricStatus,
  METRIC_NOT_REGISTERED,
  UNLIMITED_PLAN_METRIC,
} from '../../shared/BillingMetrics';

interface CompiledAgentConsumptionDataState {
  consolidatedAgentConsumption: ConsolidatedAgentConsumption;
  consolidatedContainsDataOnSelectedMonth: boolean;
}

interface AgentConsumptionContextData {
  compiledAgentConsumptionDataState: CompiledAgentConsumptionDataState;
  consolidatedAgentApiRequisition: (
    dateToSearch: Date,
    isMetricAllowed: (value: BillingMetrics) => boolean,
  ) => Promise<void>;
  isAgentLoading: boolean;
  agentStatus: MetricStatus;
}

interface props {
  tenant: any;
}

export const AgentConsumptionContext = createContext<AgentConsumptionContextData>({} as AgentConsumptionContextData);

export const AgentConsumptionContextProvider: React.FC<props> = ({ children, tenant }) => {
  const [isAgentLoading, setAgentLoading] = useState(false);
  const [agentStatus, setAgentStatus] = useState(MetricStatus.error);
  const billingAgentConsumptionApi = new BillingAgentConsumptionServiceApi();
  const consolidatedAgentConsumption = new ConsolidatedAgentConsumption(null);
  const [compiledAgentConsumptionDataState, setCompiledAgentConsumptionDataState] = useReducer<
    Reducer<CompiledAgentConsumptionDataState, Partial<CompiledAgentConsumptionDataState>>
  >(
    (compiledAgentConsumptionDataState, newCompiledAgentConsumptionDataState) => ({
      ...compiledAgentConsumptionDataState,
      ...newCompiledAgentConsumptionDataState,
    }),
    {
      consolidatedAgentConsumption,
      consolidatedContainsDataOnSelectedMonth: false,
    },
  );

  const consolidatedAgentApiRequisition = async (
    dateToSearch: Date,
    isMetricAllowed: (value: BillingMetrics) => boolean,
  ): Promise<void> => {
    try {
      setAgentLoading(true);
      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const consolidatedAgent = isMetricAllowed(BillingMetrics.agent)
        ? await billingAgentConsumptionApi.getConsolidatedAgentConsumption(tenant.info.id, monthString)
        : consolidatedAgentConsumption;
      setCompiledAgentConsumptionDataState({
        consolidatedAgentConsumption: consolidatedAgent,
        consolidatedContainsDataOnSelectedMonth: billingAgentConsumptionApi.getStatus() === RequestStatus.SUCCESS,
      });
      if (billingAgentConsumptionApi.getStatus() === RequestStatus.SUCCESS) {
        setAgentStatus(
          getMetricStatus(
            consolidatedAgent?.metric.quantity === UNLIMITED_PLAN_METRIC
              ? UNLIMITED_PLAN_METRIC
              : consolidatedAgent.planUsageRate,
          ),
        );
      } else {
        setAgentStatus(getMetricStatus(METRIC_NOT_REGISTERED));
      }
    } finally {
      setAgentLoading(false);
    }
  };

  return (
    <AgentConsumptionContext.Provider
      value={{
        compiledAgentConsumptionDataState,
        consolidatedAgentApiRequisition,
        isAgentLoading,
        agentStatus,
      }}
    >
      {children}
    </AgentConsumptionContext.Provider>
  );
};

export function useAgentConsumptionContext(): AgentConsumptionContextData {
  const context = React.useContext(AgentConsumptionContext);

  if (!context) {
    throw new Error('use agentConsumptionContext context must be used within an AgentConsumptionContextProvider');
  }

  return context;
}
