import React from 'react';
import PropTypes from 'prop-types';
import { PageTemplate } from 'components/PageTemplate';
import { TenantMembers } from 'components/TenantMembers';
import { TenantPendingMembers } from 'components/TenantPendingMembers';
import { BlipBadge } from 'components/BlipBadge';
import { canUserRead, canUserWrite } from '../../services/PermissionService';

const HomeComponent = ({
  content,
  members,
  pendingMembers,
  loggedUser,
}) => {
  return (
    <PageTemplate>
      <div id="tab-nav">
        <ul className="bp-tab-nav">
          {canUserRead('tenant-members', loggedUser.roleId) && (
            <li>
              <a href="#" data-ref="members">
                {content.members}
              </a>
            </li>
          )}
          {canUserRead('tenant-members', loggedUser.roleId) && (
            <li>
              <a href="#" data-ref="pending">
                {content.pending}{' '}
                {pendingMembers.length > 0 && (
                  <BlipBadge data-ref="pending" value={pendingMembers.length} />
                )}
              </a>
            </li>
          )}
        </ul>
        {canUserRead('tenant-members', loggedUser.roleId) && (
          <div className="bp-tab-content fl w-100" data-ref="members">
            <TenantMembers
              members={members}
              canEdit={canUserWrite('tenant-members', loggedUser.roleId)}
            />
          </div>
        )}
        {canUserRead('tenant-members', loggedUser.roleId) && (
          <div className="bp-tab-content fl w-100" data-ref="pending">
            <TenantPendingMembers members={pendingMembers} />
          </div>
        )}
      </div>
    </PageTemplate>
  );
};

HomeComponent.propTypes = {
  content: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  pendingMembers: PropTypes.array.isRequired,
  loggedUser: PropTypes.object.isRequired,
};

export { HomeComponent };
