import { BdsButton, BdsPaper, BdsSelect, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { FC, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { BlipTable } from '../../../components/BlipTable';
import SessionConsumptionContext from '../../../contexts/SessionConsumptionContext';
import { getMonthTwoDigits, toUTCDateString } from '../../../utils/date';
import { DateDropdown } from '../../../components/DateDropdown';
import {
  SessionConsumptionPaper,
  SessionConsumptionRow,
  SessionConsumptionSumUsagePaperRow,
  SessionConsumptionUsageStatementContent,
  SessionConsumptionHeaderPartial,
  SessionConsumptionHeaderFilter,
} from '../styles';
import {
  SESSION_CONSUMPTION_DAILY_BALANCE_STATEMENT_DETAIL_CLICK,
  SESSION_CONSUMPTION_PERIOD_SELECT_DAILY_OPENED,
  SESSION_CONSUMPTION_TYPE_SELECT_DAILY_OPENED,
  SESSION_CONSUMPTION_WABA_SELECT_DAILY_OPENED,
} from '../../../shared/SegmentEvents';
import { createTrack } from '../../../api/SegmentApi';
import React from 'react';

export interface SessionConsumptionDailyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const SessionConsumptionDailyUsageComponent: FC<SessionConsumptionDailyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    currentMonth,
    dateToSearch,
    content,
    tenant,
    loggedUser,
    sortSessionConsumptionDailyUsage,
    billingSessionConsumptionApi,
    sessionConsumptionDailyUsage,
    setSessionConsumptionDailyUsage,
    periodOptions,
    setDateToSearch,
    conversationTypeFilter,
    conversationWabaFilter,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    setConversationTypeFilter,
    setConversationWabaFilter,
  } = useContext(SessionConsumptionContext);

  const tableDailyUsageModel = [
    {
      label: content.sessionDailyConsumption.columnDateReference,
      key: 'dateReference',
    },
    {
      label: content.sessionDailyConsumption.columnUserSession,
      key: 'sessionByUser',
    },
    {
      label: content.sessionDailyConsumption.columnBusinessSession,
      key: 'sessionByCompany',
    },
    {
      label: content.sessionDailyConsumption.columnTotal,
      key: 'sessionTotal',
    },
  ];

  useEffect(() => {
    const getData = async () => {
      startLoading();

      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const sessionConsumptionDailyUsage = await billingSessionConsumptionApi.getDailySessionConsumption(
        tenant.info.id,
        monthString,
        registersToTake,
        conversationTypeFilter,
        conversationWabaFilter,
      );

      const sessionConsumptionDailyUsageParse = sessionConsumptionDailyUsage.map((obj) => ({
        ...obj,
        dateReference: toUTCDateString(obj.dateReference, content.locale),
      }));

      setSessionConsumptionDailyUsage(sessionConsumptionDailyUsageParse);
      stopLoading();
    };

    getData();
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  const trackSessionConsumptionDailyBalanceStatementDetail = async () => {
    createTrack(tenant.info, SESSION_CONSUMPTION_DAILY_BALANCE_STATEMENT_DETAIL_CLICK, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, SESSION_CONSUMPTION_PERIOD_SELECT_DAILY_OPENED, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const wabaOptionChangeEvent = async (selectedOption) => {
    const conversationWabaValue = selectedOption.detail.value;
    setConversationWabaFilter(conversationWabaValue);
    createTrack(tenant.info, SESSION_CONSUMPTION_WABA_SELECT_DAILY_OPENED, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaValue,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const typeOptionChangeEvent = async (selectedOption) => {
    const conversationTypeValue = selectedOption.detail.value;
    setConversationTypeFilter(conversationTypeValue);
    createTrack(tenant.info, SESSION_CONSUMPTION_TYPE_SELECT_DAILY_OPENED, {
      sessionType: conversationTypeValue,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  return (
    <SessionConsumptionPaper>
      <SessionConsumptionRow>
        <BdsTypo className="title" no-wrap="true" tag="h3" variant="fs-24" bold="bold" margin={false}>
          {content.sessionDailyConsumption.title}
          <BdsTypo className="subtitle" variant="fs-14">
            {content.sessionDailyConsumption.about}
          </BdsTypo>
        </BdsTypo>
        {registersToTake > 0 ? (
          <Link to={{ pathname: '/old-session-consumption/daily', state: { SessionConsumptionssDaily: true } }}>
            <BdsButton
              variant="ghost"
              arrow={true}
              size="short"
              onClick={trackSessionConsumptionDailyBalanceStatementDetail}
            >
              {content.sessionDailyConsumption.buttonAllChatbots}
            </BdsButton>
          </Link>
        ) : (
          <SessionConsumptionHeaderPartial currentMonth={currentMonth}>
            {currentMonth && (
              <BdsPaper className="partialUsage" elevation="static">
                <BdsTypo variant="fs-12" bold="semi-bold">
                  {content.consolidated.partialUsage}
                </BdsTypo>
              </BdsPaper>
            )}
            <SessionConsumptionHeaderFilter>
              {conversationWabaFilterOptions.length > 0 && (
                <BdsSelect
                  disabled={conversationWabaFilterOptions.length === 1}
                  value={conversationWabaFilter}
                  label={content.conversationWabaLabel}
                  onBdsChange={wabaOptionChangeEvent}
                >
                  {conversationWabaFilterOptions}
                </BdsSelect>
              )}
              <BdsSelect
                value={conversationTypeFilter}
                label={content.conversationTypeLabel}
                onBdsChange={typeOptionChangeEvent}
              >
                {conversationTypeFilterOptions}
              </BdsSelect>
              <DateDropdown
                value={dateToSearch.toISOString()}
                bdsChangeEvent={periodOptionChangeEvent}
                options={periodOptions}
                label={content.monthPlaceholder}
              ></DateDropdown>
            </SessionConsumptionHeaderFilter>
          </SessionConsumptionHeaderPartial>
        )}
      </SessionConsumptionRow>
      <SessionConsumptionSumUsagePaperRow></SessionConsumptionSumUsagePaperRow>
      <SessionConsumptionUsageStatementContent>
        <BdsPaper>
          <BlipTable
            idKey="sessionDailyConsumptions"
            model={tableDailyUsageModel}
            data={sessionConsumptionDailyUsage}
            selectedItems={[]}
            onSortSet={sortSessionConsumptionDailyUsage}
            canSelect={false}
            bodyHeight={tableBodyHeight}
          ></BlipTable>
        </BdsPaper>
      </SessionConsumptionUsageStatementContent>
    </SessionConsumptionPaper>
  );
};

export default SessionConsumptionDailyUsageComponent;
