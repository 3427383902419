import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext } from 'react';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersLessWidthRow } from '../styles';

const CompiledOverspentInformationsComponent: React.FC = () => {
  const { content, compiledDailyActiveUserDataState } = useContext(DailyActiveUsersContext);

  return (
    <>
      {compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth && (
        <CompiledDailyActiveUsersLessWidthRow>
          <BdsTypo tag="h4">
            {content.compiledDailyActiveUserInformation.baseValueLabel}
            <BdsTypo variant="fs-24" bold="bold">
              {compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.plan.planValue.toLocaleString(
                content.locale,
                {
                  style: 'currency',
                  currency: compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.plan.currencyCode,
                },
              )}
            </BdsTypo>
          </BdsTypo>
          <BdsTypo tag="h4">
            {content.compiledDailyActiveUserInformation.totalMonthlyLabel}
            <BdsTypo variant="fs-24" bold="bold">
              {content.formatString(
                content.compiledDailyActiveUserInformation.totalMonthlyValue,
                compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.plan.dailyActiveUserQuantity.toLocaleString(),
              )}
            </BdsTypo>
          </BdsTypo>
          <BdsTypo tag="h4">
            {content.compiledDailyActiveUserInformation.overspentCostLabel}
            <BdsTypo variant="fs-24" bold="bold">
              {content.formatString(
                content.compiledDailyActiveUserInformation.overspentCostValue,
                compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.plan.overspentDailyActiveUserValue.toLocaleString(
                  content.locale,
                  {
                    style: 'currency',
                    currency: compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.plan.currencyCode,
                  },
                ),
              )}
            </BdsTypo>
          </BdsTypo>
        </CompiledDailyActiveUsersLessWidthRow>
      )}
    </>
  );
};

export default CompiledOverspentInformationsComponent;
