import styled from 'styled-components';

const MAX_SCREEN_WIDTH = 1440;

export const Container = styled.div`
  max-width: ${MAX_SCREEN_WIDTH}px;
  margin: 0 auto;
`;

export const ContainerFullWidth = styled.div`
  width: 100%;
  margin: 0 auto;
`;
