import { BdsTypo, BdsPaper, BdsSelect } from 'blip-ds/dist/blip-ds-react';
import { FC, useContext, useEffect, useMemo } from 'react';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { DateDropdown } from '../../../components/DateDropdown';
import { useCommon } from '../../../contexts/CommonContext';
import SessionConsumptionContext from '../../../contexts/SessionConsumptionContext';
import {
  SESSION_CONSUMPTION_PERIOD_SELECT,
  SESSION_CONSUMPTION_TYPE_SELECT,
  SESSION_CONSUMPTION_WABA_SELECT,
} from '../../../shared/SegmentEvents';
import {
  getFirstDayOfSpecifiedMonth,
  getLastDayOfSpecifiedMonth,
  getMonthTwoDigits,
  toUTCDateString,
} from '../../../utils/date';
import { createTrack } from '../../../api/SegmentApi';
import {
  SessionConsumptionConsolidatedHeaderPartial,
  SessionConsumptionConsolidatedContent,
  SessionConsumptionConsolidatedHeaderRow,
  SessionConsumptionConsolidatedPeriodRow,
  SessionConsumptionConsolidatedRow,
  SessionConsumptionPaper,
  SessionConsumptionConsolidatedFilterText,
} from '../styles';
import React from 'react';

const SessionConsumptionConsolidatedInformationsComponent: FC = () => {
  const {
    currentMonth,
    dateToSearch,
    compiledSessionConsumptionDataState,
    setCompiledSessionConsumptionDataState,
    content,
    tenant,
    loggedUser,
    billingSessionConsumptionApi,
    setDateToSearch,
    periodOptions,
    conversationTypeFilter,
    conversationWabaFilter,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    setConversationTypeFilter,
    setConversationWabaFilter,
  } = useContext(SessionConsumptionContext);
  const { setHeaderContent } = useCommon();

  useEffect(() => {
    const getData = async () => {
      startLoading();
      setHeaderContent({
        redirect: '/',
        text: content.formatString(content.header, tenant.info.name),
      });
      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;

      const consolidatedSessionConsumption = await billingSessionConsumptionApi.getConsolidatedSessionConsumption(
        tenant.info.id,
        monthString,
        conversationTypeFilter,
        conversationWabaFilter,
      );
      setCompiledSessionConsumptionDataState({ consolidatedSessionConsumption: consolidatedSessionConsumption });
      stopLoading();
    };

    getData();
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, SESSION_CONSUMPTION_PERIOD_SELECT, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };
  const wabaOptionChangeEvent = async (selectedOption) => {
    const conversationWabaValue = selectedOption.detail.value;
    setConversationWabaFilter(conversationWabaValue);
    createTrack(tenant.info, SESSION_CONSUMPTION_WABA_SELECT, {
      sessionType: conversationTypeFilter,
      sessionWaba: conversationWabaValue,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const typeOptionChangeEvent = async (selectedOption) => {
    const conversationTypeValue = selectedOption.detail.value;
    setConversationTypeFilter(conversationTypeValue);
    createTrack(tenant.info, SESSION_CONSUMPTION_TYPE_SELECT, {
      sessionType: conversationTypeValue,
      sessionWaba: conversationWabaFilter,
      sessionDate: dateToSearch.getFullYear() + '-' + ('0' + (dateToSearch.getMonth() + 1)).slice(-2),
      sessionUser: loggedUser.email,
    });
  };

  const dateContent = useMemo(() => {
    return content.formatString(content.consolidated.period, {
      startDate: toUTCDateString(getFirstDayOfSpecifiedMonth(dateToSearch), content.locale, true),
      endDate: toUTCDateString(
        currentMonth ? new Date() : getLastDayOfSpecifiedMonth(dateToSearch),
        content.locale,
      ),
    });
  }, [compiledSessionConsumptionDataState]);

  return (
    <SessionConsumptionPaper>
      <div>
        <SessionConsumptionConsolidatedHeaderRow>
          <div>
            <SessionConsumptionConsolidatedHeaderPartial>
              <BdsTypo className="title" tag="h3" variant="fs-24" bold="bold">
                {content.formatString(content.consolidated.header, content.consolidated.month[dateToSearch.getMonth()])}
              </BdsTypo>
              {currentMonth && (
                <BdsPaper className="partialUsage" elevation="static">
                  <BdsTypo variant="fs-12" bold="semi-bold">
                    {content.consolidated.partialUsage}
                  </BdsTypo>
                </BdsPaper>
              )}
            </SessionConsumptionConsolidatedHeaderPartial>
            <BdsTypo className="subtitle" variant="fs-14">
              {dateContent}
            </BdsTypo>
          </div>
          {conversationWabaFilterOptions.length > 0 && (
            <BdsSelect
              disabled={conversationWabaFilterOptions.length === 1}
              value={conversationWabaFilter}
              label={content.conversationWabaLabel}
              onBdsChange={wabaOptionChangeEvent}
            >
              {conversationWabaFilterOptions}
            </BdsSelect>
          )}
          <BdsSelect
            value={conversationTypeFilter}
            label={content.conversationTypeLabel}
            onBdsChange={typeOptionChangeEvent}
          >
            {conversationTypeFilterOptions}
          </BdsSelect>
          <DateDropdown
            value={dateToSearch.toISOString()}
            bdsChangeEvent={periodOptionChangeEvent}
            options={periodOptions}
            label={content.monthPlaceholder}
          ></DateDropdown>
        </SessionConsumptionConsolidatedHeaderRow>
        <SessionConsumptionConsolidatedPeriodRow>
          <BdsTypo variant="fs-14"> {content.consolidated.endInfo}</BdsTypo>
        </SessionConsumptionConsolidatedPeriodRow>
        <SessionConsumptionConsolidatedFilterText>
          <BdsTypo variant="fs-14"> {content.consolidated.filter}</BdsTypo>
        </SessionConsumptionConsolidatedFilterText>
        <SessionConsumptionConsolidatedRow>
          <SessionConsumptionConsolidatedContent currentMonth={currentMonth}>
            <BdsTypo margin={true} bold="bold" variant="fs-16">
              {content.consolidated.sessionsByUser}
            </BdsTypo>
            <BdsTypo
              variant="fs-32"
              bold="extra-bold"
              margin={false}
              data-sessionByUser={compiledSessionConsumptionDataState.consolidatedSessionConsumption.sessionByUser}
            >
              {compiledSessionConsumptionDataState.consolidatedSessionConsumption.sessionByUser.toLocaleString(
                content.locale,
              )}
            </BdsTypo>
          </SessionConsumptionConsolidatedContent>
          <SessionConsumptionConsolidatedContent currentMonth={currentMonth}>
            <BdsTypo margin={true} bold="bold" variant="fs-16">
              {content.consolidated.sessionsByCompany}
            </BdsTypo>
            <BdsTypo
              variant="fs-32"
              bold="extra-bold"
              margin={false}
              data-sessionByCompany={
                compiledSessionConsumptionDataState.consolidatedSessionConsumption.sessionByCompany
              }
            >
              {compiledSessionConsumptionDataState.consolidatedSessionConsumption.sessionByCompany.toLocaleString(
                content.locale,
              )}
            </BdsTypo>
          </SessionConsumptionConsolidatedContent>
          <SessionConsumptionConsolidatedContent currentMonth={currentMonth}>
            <BdsTypo margin={true} bold="bold" variant="fs-16">
              {content.consolidated.sessionsTotal}
            </BdsTypo>
            <BdsTypo
              variant="fs-32"
              bold="extra-bold"
              margin={false}
              data-total={compiledSessionConsumptionDataState.consolidatedSessionConsumption.sessionTotal}
            >
              {compiledSessionConsumptionDataState.consolidatedSessionConsumption.sessionTotal.toLocaleString(
                content.locale,
              )}
            </BdsTypo>
          </SessionConsumptionConsolidatedContent>
        </SessionConsumptionConsolidatedRow>
      </div>
    </SessionConsumptionPaper>
  );
};

export default SessionConsumptionConsolidatedInformationsComponent;
