import React, { useContext, useEffect } from 'react';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersPaper, CompiledDailyActiveUsersRow } from '../styles';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { getMonthTwoDigits } from '../../../utils/date';
import CompiledOverspentInformations from './CompiledOverspentInformations';
import CompiledDailyActiveUserTitle from './CompiledDailyActiveUserTitle';
import CompiledPlanInformations from './CompiledPlanInformations';
import CompiledDailyActiveUserProgressContent from './CompiledDailyActiveUserProgressContent';
import CompiledDailyActiveUserOverspentContent from './CompiledDailyActiveUserOverspentContent';
import CompiledDailyActiveUserTotalContent from './CompiledDailyActiveUserTotalContent';
import CompiledDailyActiveUserOverspentWarning from './CompiledDailyActiveUserOverspentWarning';
import FeatureToggleService from '../../../services/FeatureToggleService';
import { DAILY_ACTIVE_USERS_PLAN_INFORMATIONS } from '../../../shared/FeatureToggleKeys';
import { useCommon } from '../../../contexts/CommonContext';

const CompiledDailyActiveUsersComponent: React.FC = () => {
  const {
    content,
    dateToSearch,
    tenant,
    billingDailyActiveUserApi,
    compiledDailyActiveUserDataState,
    setMonthlyActiveMessageUsageState,
  } = useContext(DailyActiveUsersContext);
  const { setHeaderContent } = useCommon();

  useEffect(() => {
    const getData = async () => {
      startLoading();
      setHeaderContent({
        redirect: '/',
        text: content.formatString(content.header, tenant.info.name),
      });

      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const monthDate = content.compiledDailyActiveUserInformation.month[dateToSearch.getMonth()];
      const compiledDailyUserConsumption = await billingDailyActiveUserApi.getCompiledDailyActiveUserConsumption(
        tenant.info.id,
        monthString,
      );

      const containsOverspent =
        compiledDailyUserConsumption != null &&
        !!compiledDailyUserConsumption.exceededPlan &&
        compiledDailyUserConsumption.exceededPlan > 0;

      const containsDataOnSelectedMonth =
        compiledDailyUserConsumption != null &&
        !!compiledDailyUserConsumption.metric.quantity &&
        compiledDailyUserConsumption.metric.quantity > 0;

      const containsPlanData =
        compiledDailyUserConsumption != null &&
        !!compiledDailyUserConsumption.metric &&
        !!compiledDailyUserConsumption.metric.quantity;

      const showDailyActiveUserPlanInformations = await FeatureToggleService.isFeatureToggleEnabled(
        DAILY_ACTIVE_USERS_PLAN_INFORMATIONS,
      );

      setMonthlyActiveMessageUsageState({
        compiledDailyActiveUserConsumption: compiledDailyUserConsumption,
        compiledContainsDataOnSelectedMonth: containsDataOnSelectedMonth,
        showPlanInformations: showDailyActiveUserPlanInformations && containsPlanData,
        compiledMonthDate: monthDate,
        compiledContainsOverspent: containsOverspent,
      });
      stopLoading();
    };

    getData();
  }, [dateToSearch]);

  return (
    <CompiledDailyActiveUsersPaper>
      <div>
        <CompiledDailyActiveUserTitle></CompiledDailyActiveUserTitle>
        <CompiledPlanInformations></CompiledPlanInformations>
        <CompiledDailyActiveUsersRow>
          <CompiledDailyActiveUserProgressContent></CompiledDailyActiveUserProgressContent>
          {compiledDailyActiveUserDataState.compiledContainsOverspent && (
            <CompiledDailyActiveUserOverspentContent></CompiledDailyActiveUserOverspentContent>
          )}
          <CompiledDailyActiveUserTotalContent></CompiledDailyActiveUserTotalContent>
        </CompiledDailyActiveUsersRow>
        <CompiledDailyActiveUserOverspentWarning></CompiledDailyActiveUserOverspentWarning>
        {compiledDailyActiveUserDataState.showPlanInformations && (
          <CompiledOverspentInformations></CompiledOverspentInformations>
        )}
      </div>
    </CompiledDailyActiveUsersPaper>
  );
};

export default CompiledDailyActiveUsersComponent;
