import { BdsButton } from 'blip-ds/dist/blip-ds-react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BlipTableFooter } from './styles';

export const BlipTableFooterComponent = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  return (
    <BlipTableFooter>
      {range.map((item, index) => (
        <BdsButton
          size="short"
          key={index}
          variant={`${page === item ? 'primary' : 'ghost'}`}
          className="button"
          onClick={() => setPage(item)}
        >
          {item}
        </BdsButton>
      ))}
    </BlipTableFooter>
  );
};

BlipTableFooterComponent.propTypes = {
  range: PropTypes.array,
  setPage: PropTypes.func,
  page: PropTypes.number,
  slice: PropTypes.array,
};
