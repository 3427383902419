import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { useCommon } from '../../contexts/CommonContext';
import { BdsTypo, BdsIcon, BdsButton } from 'blip-ds/dist/blip-ds-react';
import {
  SubheaderContainer,
  SubheaderContainerLink,
  SubheaderContent,
  SubheadContentGeneral,
  SubheaderLeft,
  SubheaderRight,
} from './styles';
import { localization } from './localization';
import { createTrack } from '../../api/SegmentApi';

import { useTenant } from '../../contexts/TenantContext';
import {
  action,
  AGENT_CONSUMPTION_CLICK,
  ORGANIZATION_AGENTS,
  origin,
  REPORT_AGENT_CONSUMPTION_OPEN,
} from '../../shared/SegmentEvents';
import { AGENT_CONSUMPTION, DATA_CONSUMPTION } from '../../shared/FeatureToggleKeys';
import { accessPermission, hasPermissions } from '../../services/PermissionService';
import { BillingMetrics } from '../../shared/BillingMetrics';
import FeatureToggleService from '../../services/FeatureToggleService';

const Subheader: React.FC = () => {
  const content = useContentLocalizer(localization);
  const location = useLocation();
  const {
    common: { headerContent, loggedUser },
  } = useCommon();
  const {
    tenant: { info: tenant, subscription },
  } = useTenant();

  const agentConsumptionRoute =
    location.pathname === '/old-agent-consumption' || location.pathname === '/agent-consumption';
  const agentConsumptionMonthlyRoute =
    location.pathname === '/old-agent-consumption/monthly' || location.pathname === '/agent-consumption/monthly';
  const agentRoute = location.pathname === '/agent';
  const hasMaxWidth =
    location.pathname === '/old-agent-consumption' || location.pathname === '/old-agent-consumption/monthly';
  const [agentConsumptionVisibility, setAgentConsumptionVisibility] = useState(false);
  const [oldAgentConsumptionVisibility, setOldAgentConsumptionVisibility] = useState(false);

  useEffect(() => {
    const getAgentConsumptionVisibility = async () => {
      const isAgentConsumptionPageVisible = await hasPermissions(
        subscription,
        loggedUser,
        accessPermission.billing,
        [BillingMetrics.agent],
        AGENT_CONSUMPTION,
      );
      const isDataConsumptionEnabled = await FeatureToggleService.isFeatureToggleEnabled(DATA_CONSUMPTION);
      setAgentConsumptionVisibility(isAgentConsumptionPageVisible && agentRoute && isDataConsumptionEnabled);
      setOldAgentConsumptionVisibility(isAgentConsumptionPageVisible && agentRoute && !isDataConsumptionEnabled);
    };
    getAgentConsumptionVisibility();
  }, [loggedUser, location.pathname]);

  const trackPage = (trackingKey, origin: origin) => {
    createTrack(tenant, trackingKey, {
      user: loggedUser.email,
      action: action.CLICK,
      origin: origin,
    });
  };

  return (
    <SubheaderContainer className="bg-color-surface-1">
      <SubheaderContent hasMaxWidth={hasMaxWidth}>
        <SubheadContentGeneral>
          <SubheaderLeft>
            {headerContent.redirect && (
              <SubheaderContainerLink to={headerContent.redirect}>
                <BdsIcon name="arrow-ball-left" size="large"></BdsIcon>
              </SubheaderContainerLink>
            )}
            <BdsTypo tag="span" bold="bold" variant="fs-20">
              {headerContent.text ?? content.contractPanel}
            </BdsTypo>
          </SubheaderLeft>
          <SubheaderRight>
            {(agentConsumptionRoute || agentConsumptionMonthlyRoute) && (
              <Link to={{ pathname: '/agent' }}>
                <BdsButton
                  onClick={() => trackPage(ORGANIZATION_AGENTS, origin.AGENTCONSUMPTION)}
                  variant="tertiary"
                  size="standard"
                >
                  {content.agents}
                </BdsButton>
              </Link>
            )}
            {oldAgentConsumptionVisibility && (
              <Link to={{ pathname: '/old-agent-consumption' }}>
                <BdsButton
                  onClick={() => trackPage(AGENT_CONSUMPTION_CLICK, origin.AGENT)}
                  variant="tertiary"
                  size="standard"
                >
                  {content.agentConsumption}
                </BdsButton>
              </Link>
            )}
            {agentConsumptionVisibility && (
              <Link to={{ pathname: '/agent-consumption' }}>
                <BdsButton
                  onClick={() => trackPage(REPORT_AGENT_CONSUMPTION_OPEN, origin.AGENT)}
                  variant="tertiary"
                  size="standard"
                >
                  {content.agentConsumption}
                </BdsButton>
              </Link>
            )}
          </SubheaderRight>
        </SubheadContentGeneral>
      </SubheaderContent>
    </SubheaderContainer>
  );
};

export default Subheader;
