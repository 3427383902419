import React, { Reducer, useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentConsumptionContext from '../../contexts/AgentConsumptionContext';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import AgentConsumptionSummaryComponent from './components/AgentConsumptionSummary';
import { ConsolidatedAgentConsumption } from '../../models/agentConsumption/ConsolidatedAgentConsumption';
import { MonthlyAgentConsumption } from '../../models/agentConsumption/MonthlyAgentConsumption';
import BillingAgentConsumptionApi from '../../api/BillingAgentConsumptionApi';
import { localization } from './localizations';
import { useTenant } from '../../contexts/TenantContext';
import {
  generateMonthListBetweenDates,
  getFirstDayOfMonth,
  getFirstDayOfSpecifiedMonth,
  getLastDayOfSpecifiedMonth,
} from '../../utils/date';
import { BdsSelectOption } from 'blip-ds/dist/blip-ds-react';
import { startLoading, stopLoading } from '../../api/CommonApi';
import { useCommon } from '../../contexts/CommonContext';
import MonthlyUsageComponent from './components/Monthly/MonthlyUsage';
import { sortByProperty } from '../../utils/array';
import { billingRoutes, billingSubRoutes } from '../../routes/BillingRoutes';

interface CompiledAgentConsumptionDataState {
  consolidatedAgentConsumption: ConsolidatedAgentConsumption;
  consolidatedContainsDataOnSelectedMonth: boolean;
}

const AgentConsumption: React.FC = () => {
  const [dateToSearch, setDateToSearch] = useState(getFirstDayOfMonth());
  const content = useContentLocalizer(localization);
  const billingAgentConsumptionApi = new BillingAgentConsumptionApi();
  const { tenant } = useTenant();
  const {
    setHeaderContent,
    common: { config, loggedUser },
  } = useCommon();
  const [monthlyAgentConsumptionDataState, setMonthlyAgentConsumptionDataState] = useState<MonthlyAgentConsumption[]>(
    [],
  );
  const [compiledAgentConsumptionDataState, setCompiledAgentConsumptionDataState] = useReducer<
    Reducer<CompiledAgentConsumptionDataState, Partial<CompiledAgentConsumptionDataState>>
  >(
    (compiledAgentConsumptionDataState, newCompiledAgentConsumptionDataState) => ({
      ...compiledAgentConsumptionDataState,
      ...newCompiledAgentConsumptionDataState,
    }),
    {
      consolidatedAgentConsumption: {
        metric: null,
        total: 0,
        planUsageRate: 0,
        exceededPlan: 0,
        planUsage: 0,
        startDateReference: getFirstDayOfSpecifiedMonth(new Date()),
        endDateReference: getLastDayOfSpecifiedMonth(new Date()),
      },
      consolidatedContainsDataOnSelectedMonth: false,
    },
  );

  const initialDate = new Date(config.REVENUE_START_DATE);
  initialDate.setSeconds(0);

  useEffect(() => {
    startLoading();

    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });

    stopLoading();

    return () => setHeaderContent({ redirect: null });
  }, []);

  const currentMonth = useMemo(() => {
    const month = dateToSearch.getMonth() + 1 === new Date().getMonth() + 1;
    const year = dateToSearch.getFullYear() === new Date().getFullYear();
    return month && year;
  }, [dateToSearch]);

  const periodOptions = useMemo(() => {
    const monthList = generateMonthListBetweenDates(initialDate, new Date()).sort(
      (first, second) => second.getTime() - first.getTime(),
    );

    return monthList.map((monthDate) => {
      const month = monthDate.getMonth();
      const year = monthDate.getFullYear();

      return (
        <BdsSelectOption value={monthDate.toISOString()} key={`${month}-${year}`}>
          {content.consolidated.month[month]} {year}
        </BdsSelectOption>
      );
    });
  }, []);

  const sortAgentConsumptionMonthlyUsage = async (sort) => {
    const sortedChatbotsConsumption = sortByProperty(monthlyAgentConsumptionDataState, sort.property, sort.order);
    setMonthlyAgentConsumptionDataState([...sortedChatbotsConsumption]);
  };

  const contextProps = {
    content,
    tenant,
    loggedUser,
    dateToSearch,
    currentMonth,
    periodOptions,
    billingAgentConsumptionApi,
    setDateToSearch,
    compiledAgentConsumptionDataState,
    setCompiledAgentConsumptionDataState,
    monthlyAgentConsumptionDataState,
    setMonthlyAgentConsumptionDataState,
    sortAgentConsumptionMonthlyUsage,
  };

  return (
    <AgentConsumptionContext.Provider value={contextProps}>
      <Switch>
        <Route exact path={billingRoutes.oldAgentConsumption} component={AgentConsumptionSummaryComponent}></Route>
        <Route exact path={billingSubRoutes.oldAgentConsumptionMonthly} component={MonthlyUsageComponent}></Route>
      </Switch>
    </AgentConsumptionContext.Provider>
  );
};

export default AgentConsumption;
