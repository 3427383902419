import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentConsumptionContext from '../../../contexts/AgentConsumptionContext';
import { useContentLocalizer } from '../../../hooks/useContentLocalizer';
import AgentConsumptionSummaryComponent from './components/AgentConsumptionSummary';
import { localization } from './localizations';
import { useTenant } from '../../../contexts/TenantContext';
import { useCommon } from '../../../contexts/CommonContext';
import MonthlyUsageComponent from './components/Monthly/MonthlyUsage';
import { AsideMenu } from '../components/AsideMenu';
import { useBillingConsumptionContext } from '../../../contexts/Billing/BillingConsumptionContext';
import { action, origin } from '../../../shared/SegmentEvents';
import { useBillingPlanUsageContext } from '../../../contexts/Billing/BillingPlanUsageContext';
import { useBillingMetricsContext } from '../../../contexts/Billing/BillingMetricsContext';
import { MonthlyAgentConsumption } from '../../../models/billing/agentConsumption/MonthlyAgentConsumption';
import { sortByProperty } from '../../../utils/array';
import BillingAgentConsumptionServiceApi from '../../../api/Billing/BillingAgentConsumptionApi';
import { billingRoutes, billingSubRoutes } from '../../../routes/BillingRoutes';

const AgentConsumption: React.FC = () => {
  const { dateToSearch, setDateToSearch, periodOptions, currentMonth } = useBillingConsumptionContext();

  const { planStatus, getPlanStatus } = useBillingPlanUsageContext();
  const content = useContentLocalizer(localization);
  const billingAgentConsumptionApi = new BillingAgentConsumptionServiceApi();
  const { isMetricAllowed } = useBillingMetricsContext();
  const { tenant } = useTenant();
  const [monthlyAgentConsumptionDataState, setMonthlyAgentConsumptionDataState] = useState<MonthlyAgentConsumption[]>(
    [],
  );
  const {
    setHeaderContent,
    common: { loggedUser },
  } = useCommon();

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: '/' });
  }, []);

  const sortAgentConsumptionMonthlyUsage = (sort) => {
    const sortedChatbotsConsumption = sortByProperty(monthlyAgentConsumptionDataState, sort.property, sort.order);
    setMonthlyAgentConsumptionDataState([...sortedChatbotsConsumption]);
  };

  const trackingProps = {
    date: dateToSearch,
    planStatus: planStatus.current,
    origin: origin.AGENTCONSUMPTION,
    action: action.SELECT,
  };

  const contextProps = {
    content,
    tenant,
    loggedUser,
    setHeaderContent,
    dateToSearch,
    setDateToSearch,
    periodOptions,
    currentMonth,
    trackingProps,
    planStatus,
    getPlanStatus,
    isMetricAllowed,
    sortAgentConsumptionMonthlyUsage,
    monthlyAgentConsumptionDataState,
    setMonthlyAgentConsumptionDataState,
    billingAgentConsumptionApi,
  };

  return (
    <AgentConsumptionContext.Provider value={contextProps}>
      <AsideMenu>
        <Switch>
          <Route exact path={billingRoutes.agentConsumption} component={AgentConsumptionSummaryComponent}></Route>
          <Route exact path={billingSubRoutes.agentConsumptionMonthly} component={MonthlyUsageComponent}></Route>
        </Switch>
      </AsideMenu>
    </AgentConsumptionContext.Provider>
  );
};

export default AgentConsumption;
