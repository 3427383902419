import { useState, useEffect, useCallback } from 'react';
import { showToast } from 'api/CommonApi';
import { useContentLocalizer } from 'hooks/useContentLocalizer';

import { localization } from 'shared/localization';

export const useCopyToClipboard = (text?: string): [boolean, (string) => void] => {
  const content = useContentLocalizer(localization);

  const copyToClipboard = (str: string): boolean => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    const selection = document.getSelection();

    if (selection) {
      const selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
      el.select();
      const success = document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection()?.removeAllRanges();
        document.getSelection()?.addRange(selected);
        showToast({
          type: 'success',
          message: content.copySuccess,
        });
        return success;
      }
    }

    return false;
  };

  const [copied, setCopied] = useState(false);

  const copy = useCallback(
    (text: string) => {
      if (!copied) setCopied(copyToClipboard(text));
    },
    [text],
  );
  useEffect(() => () => setCopied(false), [text]);

  return [copied, copy];
};
