import React, { useCallback } from 'react';
import { TenantReducer } from 'reducers/TenantReducer';

const TenantContext = React.createContext();

const useTenant = () => {
  const context = React.useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  const [tenant, dispatch] = context;

  //defining actions to be used based on the reducer
  const setTenant = useCallback(
    (tenantValue) => dispatch({ type: 'setTenant', tenant: tenantValue }),
    [dispatch]
  );
  const setTenantName = (name) => dispatch({ type: 'setTenantName', name });
  const setTenantPhotoUri = (photoUri) => dispatch({ type: 'setTenantPhotoUri', photoUri });
  const setTenantMembers = (members) => dispatch({ type: 'setTenantMembers', members });
  const setTenantAgents = (agents) => dispatch({ type: 'setTenantAgents', agents });
  
  const setMemberToAccepted = (member) => {
    const updatedMembers = [...tenant.members];
    const memberIndex = updatedMembers.findIndex((oldMember) => oldMember.userIdentity === member.userIdentity);
    updatedMembers[memberIndex].userStatus = 'Accepted';
    dispatch({ type: 'setTenantMembers', members: updatedMembers });
  };

  const setMemberToRejected = (member) => {
    const updatedMembers = [...tenant.members];
    const memberIndex = updatedMembers.findIndex((oldMember) => oldMember.userIdentity === member.userIdentity);
    updatedMembers[memberIndex].userStatus = 'Rejected';
    dispatch({ type: 'setTenantMembers', members: updatedMembers });
  };

  return {
    tenant,
    setTenant,
    setTenantName,
    setTenantPhotoUri,
    setTenantMembers,
    setTenantAgents,
    setMemberToAccepted,
    setMemberToRejected,
  };
};

const TenantProvider = (props) => {
  const [tenant, dispatch] = React.useReducer(TenantReducer, { info: {}, members: [] });
  const value = React.useMemo(() => [tenant, dispatch], [tenant]);
  return <TenantContext.Provider value={value} {...props} />;
};

export { TenantProvider, useTenant };
