import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import styled from 'styled-components';
import colors from '_colors.module.scss';

export const SummaryPaper = styled(BdsPaper)`
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: ${colors.Surface1};
  color: ${colors.ContentDefault};
  & > div {
    margin-left: 0.5rem;
  }
`;

export const SummaryBody = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > bds-typo {
    &::part(bds-typo__text) {
      color: ${colors.ContentDefault};
    }
  }
`;

export const SummaryContent = styled.div`
  background: ${colors.Surface2};
  padding: 1rem;
  border-radius: 8px;
  position: block;
  width: 31%;
  & > bds-typo:first-of-type {
    color: ${colors.Brand};
    margin-bottom: 8px;
  }
  & > bds-typo:last-child {
    color: ${(props) =>
    props.currentMonth == true ? colors.Focus : colors.Brand};
  }  
`;
