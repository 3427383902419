import React from 'react';
import PropTypes from 'prop-types';
import { BarChart } from '../../../../../components/Charts/BarChart';
import { DoughnutChart } from '../../../../../components/Charts/DoughnutChart';
import { LineChart } from '../../../../../components/Charts/LineChart';
import { GeneralChartContainer } from './styles';

const GeneralChartComponent = ({ chartType, data, options, plugins, redraw }) => {
  return (
    <GeneralChartContainer>
      {chartType === 'DoughnutChart' && (
        <DoughnutChart data={data} options={options} plugins={plugins} redraw={redraw}></DoughnutChart>
      )}
      {chartType === 'BarChart' && (
        <BarChart data={data} options={options} plugins={plugins} redraw={redraw}></BarChart>
      )}
      {chartType === 'LineChart' && (
        <LineChart data={data} options={options} plugins={plugins} redraw={redraw}></LineChart>
      )}
    </GeneralChartContainer>
  );
};

GeneralChartComponent.propTypes = {
  chartType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  plugins: PropTypes.array,
  redraw: PropTypes.bool,
};

export { GeneralChartComponent };
