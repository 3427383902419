export const localization = {
  pt: {
    leaveContract: 'Deixar contrato',
    leaveUniqueContractConfirmAlert: {
      title: 'Quer mesmo deixar esse contrato?',
      body: 'Esse é o seu único contrato ativo. Caso queira utilizar o Blip novamente, você deverá criar um novo contrato ou ser convidado para algum já existente.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Deixar contrato',
      },
    },
    leaveContractConfirmAlert: {
      title: 'Quer mesmo deixar esse contrato?',
      body: 'Ao sair, você perderá o acesso a todos os chatbots e especificações. Caso queira voltar, deverá reenviar uma solicitação ou receber um novo convite.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Deixar contrato',
      },
    },
    leaveContractChangePermissionsAlert: {
      title: 'Ops… você não pode deixar esse contrato',
      body: 'Antes de deixar o contrato, você precisa transferir a permissão administrativa de todos os chatbots em sua propriedade.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Ir para chatbots',
      },
    },
  },
  en: {
    leaveContract: 'Leave contract',
    leaveUniqueContractConfirmAlert: {
      title: 'Do you really want to leave this contract?',
      body: 'This is your only current contract. If you wish to return to Blip, you must create a new contract or be invited to one other.',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Leave contract',
      },
    },
    leaveContractConfirmAlert: {
      title: 'Do you really want to leave this contract?',
      body: 'By leaving this contract, you lose access to all chatbots and specifications. In case you wish to return, you must resend a request or receive a new invitation.',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Leave contract',
      },
    },
    leaveContractChangePermissionsAlert: {
      title: 'Oops… you cannot leave this contract',
      body: 'Before leaving this contract, you must transfer the current chatbots in your administration to someone else.',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Go to chatbots',
      },
    },
  },
  es: {
    leaveContract: 'Dejar contrato',
    leaveUniqueContractConfirmAlert: {
      title: '¿De verdad quiere dejar este contrato?',
      body: 'Este es su único contrato activo. Si desea utilizar Blip nuevamente, debe crear un nuevo contrato o ser invitado a uno existente.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Dejar contrato',
      },
    },
    leaveContractConfirmAlert: {
      title: '¿De verdad quiere dejar este contrato?',
      body: 'Cuando salga, perderá el acceso a todos los chatbots y especificaciones. Si desea regresar, debe reenviar una solicitud o recibir una nueva invitación.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Dejar contrato',
      },
    },
    leaveContractChangePermissionsAlert: {
      title: 'Vaya... no puede dejar este contrato',
      body: 'Antes de dejar el contrato, debe transferir el permiso administrativo para todos los chatbots en su propiedad.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Ir a chatbots',
      },
    },
  },
};
