import React from 'react';
import PropTypes from 'prop-types';
import { BdsButton, BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Header, Title, Body } from './styles';
import { Link } from 'react-router-dom';

const HeaderComponent = ({
  title,
  subtitle,
  buttonTitle,
  pathname,
  buttonTrackEvent,
  tooltipIcon,
  tooltipText,
  tooltipTrackEvent,
  idTest,
}) => {
  return (
    <>
      <Header>
        <Title>
          <BdsTypo tag="span" variant="fs-24" bold="bold" lineHeight="double">
            {title}
          </BdsTypo>
          {tooltipIcon && tooltipText && (
            <BdsTooltip position="top-center" tooltip-text={tooltipText} onMouseOver={tooltipTrackEvent}>
              <BdsIcon name={tooltipIcon} size="small"></BdsIcon>
            </BdsTooltip>
          )}
        </Title>
        <Link to={{ pathname: pathname }}>
          <BdsButton variant="tertiary" arrow={true} data-test={idTest} size="short" onClick={buttonTrackEvent}>
            {buttonTitle}
          </BdsButton>
        </Link>
      </Header>
      <Body>
        <BdsTypo variant="fs-14" bold="regular" lineHeight="plus" margin={true}>
          {subtitle}
        </BdsTypo>
      </Body>
    </>
  );
};

HeaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonTrackEvent: PropTypes.func,
  pathname: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipText: PropTypes.string,
};

export { HeaderComponent };
