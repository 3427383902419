export const localization = {
  pt: {
    locale: 'pt-BR',
    header: 'Consumo de conversa do contrato {0}',
    monthPlaceholder: 'Período',
    conversationTypeLabel: 'Conversas',
    conversationWabaLabel: 'WABA',
    consolidated: {
      partialUsage: 'Consumo parcial',      
      noContent: '',
      header: 'Conversas em {0}',
      period: 'De {startDate} a {endDate}',
      endInfo: '*O fechamento de um mês estará disponível em até 5 dias úteis contados do último dia desse mesmo mês.',
      filter: 'Os valores deste relatório são recebidos direto do WhatsApp e podem ser diferentes dos mostrados na fatura. Use o relatório apenas como estimativa do seu consumo.',
      sessionsByUser: 'Conversas iniciadas pelo usuário',
      sessionsByCompany: 'Conversas iniciadas pela empresa',
      sessionsTotal: 'Total de conversas',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],      
    },
    defaultConversationTypeValue: 'All', 
    conversationType: [
      {value: 'All', label: 'Todas as conversas'},
      {value: 'FreeTier', label: 'Conversas grátis'},
      {value: 'Regular', label: 'Conversas cobradas'},
    ],           
    chatbotSessionConsumption: {
      title: 'Chatbots',
      about: 'Acompanhe o consumo de conversas por chatbot.',
      columnPhoneNumber: 'Telefone',
      columnUserSession: 'Conversa iniciada pelo usuário',
      columnEnterpriseSession: 'Conversa iniciada pela empresa',
      buttonAllChatbots: 'Mostrar detalhes',
    },
    sessionDailyConsumption: {
      title: 'Contagem diária de conversas',
      about: 'Acompanhe o consumo diário de conversas.',
      columnDateReference: 'Data',
      columnUserSession: 'Conversa iniciada pelo usuário',
      columnBusinessSession: 'Conversa iniciada pela empresa',
      columnTotal: 'Total',
      buttonAllChatbots: 'Mostrar detalhes',      
    },
  },
  en: {
    locale: 'en-US',
    header: 'Contract\'s consumption by conversation {0}',
    monthPlaceholder: 'Time period',
    conversationTypeLabel: 'Conversation',
    conversationWabaLabel: 'WABA',
    consolidated: {
      partialUsage: 'Partial consumption',
      noContent: '',
      header: 'Conversations in {0}',
      period: 'From {startDate} to {endDate}',
      endInfo: '*Monthly statements will become available within 5 business days of month end.',
      filter: 'The numbers in this report come directly from WhatsApp and may differ from the numbers shown on the invoice. It is best to use the report only as an estimate of your consumption.',
      sessionsByUser: 'User-initiated conversations',
      sessionsByCompany: 'Company-initiated conversations',
      sessionsTotal: 'Total of conversations',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],          
    },
    defaultConversationTypeValue: 'All', 
    conversationType: [
      {value: 'All', label: 'See all conversations'},
      {value: 'FreeTier', label: 'Free-of-charge conversations'},
      {value: 'Regular', label: 'Billed conversations'},
    ],      
    chatbotSessionConsumption: {
      title: 'Chatbots',
      about: 'Track conversation consumption by chatbot.',
      columnPhoneNumber: 'Phone number',
      columnUserSession: 'User-initiated conversation',
      columnEnterpriseSession: 'Company-initiated conversation',
      buttonAllChatbots: 'Show details',
    },
    sessionDailyConsumption: {
      title: 'Daily conversation count',
      about: 'Track daily conversation consumption.',
      columnDateReference: 'Date',
      columnUserSession: 'User-initiated conversation',
      columnBusinessSession: 'Company-initiated conversation',
      columnTotal: 'Total',
      buttonAllChatbots: 'Show details',      
    },
  },
  es: {
    locale: 'es-ES',
    header: 'Consumo de conversación del contrato {0}',
    monthPlaceholder: 'Período de tiempo',
    conversationTypeLabel: 'Conversaciones',
    conversationWabaLabel: 'WABA',
    consolidated: {
      partialUsage: 'Consumo parcial',
      noContent: '',
      header: 'Conversaciones en {0}',
      period: 'Del {startDate} al {endDate}',
      endInfo: '*Los estados de cuenta mensuales estarán disponibles dentro de los 5 días hábiles posteriores al final del mes.',
      filter: 'Los importes de este informe se reciben directamente de WhatsApp y pueden diferir de los que se muestran en la factura. Utilice este informe solo como una estimación de su consumo.',
      sessionsByUser: 'Conversaciones iniciadas por el usuario',
      sessionsByCompany: 'Conversaciones iniciadas por la empresa',
      sessionsTotal: 'Total de conversaciones',
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],           
    },
    defaultConversationTypeValue: 'All', 
    conversationType: [
      {value: 'All', label: 'Todas las conversaciones'},
      {value: 'FreeTier', label: 'Conversaciones gratis'},
      {value: 'Regular', label: 'Conversaciones cobradas'},
    ],      
    chatbotSessionConsumption: {
      title: 'Chatbots',
      about: 'Seguimiento del consumo de conversaciones por chatbot.',
      columnPhoneNumber: 'Número de teléfono',
      columnUserSession: 'Conversación iniciada por el usuario',
      columnEnterpriseSession: 'Conversación iniciada por la empresa',
      buttonAllChatbots: 'Mostrar detalles',
    },
    sessionDailyConsumption: {
      title: 'Recuento de conversaciones diarias',
      about: 'Rastree el consumo diario de conversaciones.',
      columnDateReference: 'Fecha',
      columnUserSession: 'Conversación iniciada por el usuario',
      columnBusinessSession: 'Conversación iniciada por la empresa',
      columnTotal: 'Total',
      buttonAllChatbots: 'Mostrar detalles',
    },
  },
};
