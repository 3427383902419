import styled from 'styled-components';
import blipColors from 'blip-tokens/build/json/colors.json';

export const LeaveContractLink = styled.a`
  color: ${blipColors['color-extend-reds-delete']};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${blipColors['color-extend-reds-lipstick']};
  }
`;