import React, { createContext, MutableRefObject, useEffect, useRef, useState } from 'react';
import { MetricStatus } from '../../shared/BillingMetrics';

interface BillingPlanUsageContextData {
  planStatus: MutableRefObject<MetricStatus>;
  planStatusLoading: boolean;
  getPlanStatus: () => Promise<MetricStatus>;
}

interface props {
  planStatusArray: MetricStatus[];
}

export const BillingPlanUsageContext = createContext<BillingPlanUsageContextData>({} as BillingPlanUsageContextData);

export const BillingPlanUsageContextProvider: React.FC<props> = ({ children, planStatusArray }) => {
  const planStatus = useRef(MetricStatus.error);
  const [planStatusLoading, setplanStatusLoading] = useState(false);

  const getPlanStatus = () => {
    return new Promise<MetricStatus>((resolve) => {
      setTimeout(() => {
        resolve(planStatus.current);
      }, 3000);
    });
  };
  useEffect(() => {
    setplanStatusLoading(true);
    //Priority
    // > 0  - Usage is within the limits of your plan.
    // -1   - Space to monitor your plan's usage.
    // 0    - No data to display for the chosen period.
    // null - Problems loading the data. Please try refreshing the page.
    const priorityPlanStatus = [
      { status: MetricStatus.exceeded, priority: 5 },
      { status: MetricStatus.nearLimit, priority: 4 },
      { status: MetricStatus.normal, priority: 3 },
      { status: MetricStatus.unlimited, priority: 2 },
      { status: MetricStatus.empty, priority: 1 },
      { status: MetricStatus.error, priority: 0 },
    ];

    const filteredMetrics: any[] = [];
    priorityPlanStatus.filter(function (metric) {
      if (planStatusArray.some((metricStatus) => metricStatus === metric.status)) {
        filteredMetrics.push({ status: metric.status, priority: metric.priority });
      }
    });

    const filteredPriorities: any[] = [];
    filteredMetrics.map((item) => {
      filteredPriorities.push(item.priority);
    });
    const priority = Math.max.apply(null, filteredPriorities);
    const status = priorityPlanStatus.filter((metric) => metric.priority === priority)[0].status;

    const timeout = setTimeout(() => {
      planStatus.current = status;
      setplanStatusLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [planStatusArray]);

  return (
    <BillingPlanUsageContext.Provider
      value={{
        planStatus,
        planStatusLoading,
        getPlanStatus,
      }}
    >
      {children}
    </BillingPlanUsageContext.Provider>
  );
};

export function useBillingPlanUsageContext(): BillingPlanUsageContextData {
  const context = React.useContext(BillingPlanUsageContext);

  if (!context) {
    throw new Error('use billingplanusagecontext context must be used within an BillingPlanUsageContextProvider');
  }

  return context;
}
