import React, { FC, useContext, useEffect, useMemo } from 'react';
import {
  action,
  REPORT_DAU_CONSUMPTION_OPEN,
  REPORT_DAU_PLAN_CONSUMPTION_CHART_HOVER,
  REPORT_DAU_TOTAL_PLAN_TOOLTIP_HOVER,
  REPORT_DAU_WEEKLY_CONSUMPTION_CHART_HOVER,
} from '../../../../../shared/SegmentEvents';
import { getMetricStatus, blipMetricStatus } from '../../../../../shared/BillingMetrics';
import { InfoPaper } from '../../../components/ConsumptionData/InfoPaper';
import { ConsolidatedPaper } from '../../../components/ConsumptionData/ConsolidatedPaper';
import { LeftInfoPaperArea, RightInfoPaperArea } from '../../styles';
import { useContentLocalizer } from '../../../../../hooks/useContentLocalizer';
import { localization } from './localizations';
import DataConsumptionContext from '../../../../../contexts/Billing/DataConsumptionContext';
import DataConsumptionMetricContext from '../../../../../contexts/Billing/DataConsumptionMetricContext';
import { getOrdinal } from '../../../../../utils/strings';
import { toUTCDateString } from '../../../../../utils/date';
import { trackEvent } from '../../../../../api/Billing/Tracking';
import { useBillingPlanUsageContext } from '../../../../../contexts/Billing/BillingPlanUsageContext';
import colors from '../../../../../_charts.module.scss';

const DauConsumptionComponent: FC = () => {
  const { dateToSearch, isMetricAllowed, tenant, loggedUser, trackingProps } = useContext(DataConsumptionContext);
  const { compiledDailyActiveUserDataState, isDauLoading, consolidatedDauApiRequisition } =
    useContext(DataConsumptionMetricContext);
  const { planStatus } = useBillingPlanUsageContext();
  const content = useContentLocalizer(localization);
  const compiledContainsDataOnSelectedMonth = compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth;
  const planUsageRate = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsageRate;
  const planUsage = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.planUsage;
  const total = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.total;
  const availablePlan = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.availablePlan;
  const weekItems = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.weekItems;
  const weeklyAverage = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.weeklyAverage;
  const topWeekNumber = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.topWeekNumber;
  const quantity = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.metric.quantity;

  useEffect(() => {
    consolidatedDauApiRequisition(dateToSearch, isMetricAllowed);
  }, [dateToSearch]);

  const metricStatus = useMemo(() => {
    let metrics;
    blipMetricStatus.map((metric) => {
      if (metric.status === getMetricStatus(planUsageRate)) {
        metrics = metric;
      }
    });
    return metrics;
  }, [planUsageRate]);

  const leftChartData = {
    chartType: 'DoughnutChart',
    title: content.leftChart.title,

    labels: [
      content.formatString(
        content.leftChart.labels.counted,
        compiledContainsDataOnSelectedMonth ? planUsage.toLocaleString(content.locale) : '--',
      ),
      content.formatString(
        content.leftChart.labels.available,
        compiledContainsDataOnSelectedMonth ? availablePlan.toLocaleString(content.locale) : '--',
      ),
    ],

    customLabels: [
      {
        text: content.formatString(
          content.leftChart.customLabels.counted,
          compiledContainsDataOnSelectedMonth ? planUsageRate.toLocaleString(content.locale) : '--',
        ),
        backgroundColor: metricStatus.usageColor,
      },
      {
        text: content.formatString(
          content.leftChart.customLabels.available,
          compiledContainsDataOnSelectedMonth ? (100 - planUsageRate).toLocaleString(content.locale) : '--',
        ),
        backgroundColor: metricStatus.availableColor,
      },
    ],
    datasets: [
      {
        data: [planUsage, compiledContainsDataOnSelectedMonth ? availablePlan : 100],
        backgroundColor: [metricStatus.usageColor, metricStatus.availableColor],
        borderColor: [metricStatus.usageColor, metricStatus.availableColor],
        borderWidth: 1,
      },
    ],
  };

  let leftCount = 0;

  const leftChartOptions = {
    maintainAspectRatio: false,
    cutout: '65%',
    locale: content.locale,
    onHover: function (evt) {
      if (evt.type === 'mousemove' && leftCount < 1) {
        trackEvent(REPORT_DAU_PLAN_CONSUMPTION_CHART_HOVER, tenant, loggedUser, trackingProps);
        leftCount++;
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label1 = context.label.split(' ').slice(0, 3).join(' ');
            const label2 = context.label.split(' ').slice(3, 6).join(' ');
            return compiledContainsDataOnSelectedMonth ? [label1, label2] : '--';
          },
        },
      },
    },
  };
  const leftChartPlugins = [
    {
      beforeDraw: function (chart) {
        const chartWidth = chart.width,
          chartHeight = chart.height,
          ctx = chart.ctx;
        const image = new Image();
        const imageSize = chartWidth / 6;
        image.src = metricStatus.blipImg;
        ctx.drawImage(image, chartWidth / 2 - imageSize / 2, chartHeight / 2 - imageSize / 2, imageSize, imageSize);
      },
    },
  ];

  const weekItemsLabel = useMemo(() => {
    return weekItems.map((item) => {
      return content.formatString(content.rightChart.period, {
        startDate: toUTCDateString(item.startDateReference, content.locale, false),
        endDate: toUTCDateString(item.endDateReference, content.locale, false),
      });
    });
  }, [weekItems]);

  const labels = weekItemsLabel;

  const rightChartData = {
    chartType: 'BarChart',
    title: content.rightChart.title,
    labels,
    customLabels: [
      {
        text: content.rightChart.customLabel,
        backgroundColor: colors.Brand,
      },
    ],

    datasets: [
      {
        label: content.rightChart.customLabel,
        data: weekItems.map((item) => item.total),
        backgroundColor: colors.Brand,
      },
    ],

    warningLabelBold: content.rightChart.warningLabel.bold,
    warningLabelRegular: content.rightChart.warningLabel.regular,
  };

  let rightCount = 0;

  const rightChartOptions = {
    maintainAspectRatio: false,
    barThickness: 15,
    locale: content.locale,
    onHover: function (evt) {
      if (evt.type === 'mousemove' && rightCount < 1) {
        trackEvent(REPORT_DAU_WEEKLY_CONSUMPTION_CHART_HOVER, tenant, loggedUser, trackingProps);
        rightCount++;
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y:
        compiledContainsDataOnSelectedMonth && quantity >= 0 && planUsageRate < 100
          ? { min: 0, max: quantity }
          : { min: 0 },
    },
  };
  const redraw = false;
  const leftChart = { leftChartData, leftChartOptions, leftChartPlugins, redraw };
  const rightChart = { rightChartData, rightChartOptions, redraw };

  const header = {
    title: content.header.title,
    subtitle: content.header.description,
    buttonTitle: content.header.showMoreButton,
    pathname: '/dau-consumption',
    idTest: 'show-more-report-dau-consumption',
    buttonTrackEvent: () =>
      trackEvent(REPORT_DAU_CONSUMPTION_OPEN, tenant, loggedUser, { ...trackingProps, action: action.CLICK }),
  };

  let tooltipCount = 0;
  const leftInfoPaper = useMemo(() => {
    return (
      <LeftInfoPaperArea>
        <InfoPaper
          justifyContent={'flex-start'}
          classNameTitle={'flex-start-margin'}
          icon={'user-active'}
          title={compiledContainsDataOnSelectedMonth ? total.toLocaleString(content.locale) : '--'}
          text={content.leftInfoPaper.totalInfo.description}
        ></InfoPaper>
        <InfoPaper
          justifyContent={'flex-start'}
          classNameTitle={'flex-start-margin'}
          icon={'user-default'}
          title={compiledContainsDataOnSelectedMonth ? availablePlan.toLocaleString(content.locale) : '--'}
          text={content.leftInfoPaper.availableInfo.description}
        ></InfoPaper>
        <InfoPaper
          justifyContent={'space-between'}
          classNameTitle={'space-between'}
          title={quantity >= 0 && compiledContainsDataOnSelectedMonth ? quantity.toLocaleString(content.locale) : '--'}
          text={content.leftInfoPaper.planInfo.description}
          tooltipIcon={'info'}
          tooltipText={content.leftInfoPaper.planInfo.tooltip}
          tooltipTrackEvent={() => {
            if (tooltipCount < 1) {
              trackEvent(REPORT_DAU_TOTAL_PLAN_TOOLTIP_HOVER, tenant, loggedUser, {
                ...trackingProps,
                action: action.HOVER,
              });
              tooltipCount++;
            }
          }}
        ></InfoPaper>
      </LeftInfoPaperArea>
    );
  }, [planStatus.current, compiledDailyActiveUserDataState]);

  const rightInfoPapers = useMemo(() => {
    return (
      <RightInfoPaperArea justifyContent={'flex-start'}>
        <InfoPaper
          justifyContent={'flex-start'}
          classNameTitle={'flex-start-margin'}
          icon={'user-active'}
          title={compiledContainsDataOnSelectedMonth ? weeklyAverage.toLocaleString(content.locale) : '--'}
          text={content.rightInfoPaper.weeklyAverage.description}
          bdsPaper={false}
        ></InfoPaper>
        <InfoPaper
          justifyContent={'flex-start'}
          classNameTitle={'flex-start-margin'}
          icon={'calendar'}
          title={
            compiledContainsDataOnSelectedMonth
              ? content.formatString(
                  content.rightInfoPaper.topWeekNumber.label,
                  getOrdinal(topWeekNumber, content.locale, 'f'),
                )
              : '--'
          }
          text={content.rightInfoPaper.topWeekNumber.description}
          infoPaperType={'weekly'}
          bdsPaper={false}
        ></InfoPaper>
      </RightInfoPaperArea>
    );
  }, [compiledDailyActiveUserDataState]);

  return (
    <ConsolidatedPaper
      header={header}
      leftChart={leftChart}
      leftInfoPapers={leftInfoPaper}
      rightChart={rightChart}
      rightInfoPapers={rightInfoPapers}
      isLoading={isDauLoading}
    ></ConsolidatedPaper>
  );
};

export default DauConsumptionComponent;
