import { IframeMessageProxy } from 'iframe-message-proxy';

const messageAction = 'segment';
const tenantUrlAction = 'Tenant:url';
const MIXPANEL_MAX_LIMIT_CHARACTERS_ALLOWED = 65;

const sendSegmentMessage = (content) =>
  IframeMessageProxy.sendMessage({
    action: messageAction,
    content,
  });

export const resetAccount = () =>
  sendSegmentMessage({
    method: 'resetAccount',
  });

export const createTrack = async (tenant, trackEvent, payload = {}) => {
  if (trackEvent.length >= MIXPANEL_MAX_LIMIT_CHARACTERS_ALLOWED) {
    console.warn(
      `${trackEvent}: track event key length should not be greater equal than ${MIXPANEL_MAX_LIMIT_CHARACTERS_ALLOWED} characters.`,
    );
  }

  if (tenant) {
    const { response: organizationUrl } = await IframeMessageProxy.sendMessage({
      action: tenantUrlAction,
      content: tenant.id,
    });

    payload = {
      organizationName: tenant.name,
      plan: tenant.plan,
      fireAndForget: true,
      organizationUrl,
      ...payload,
    };

    if (global.isTrackingLogEnabled) {
      console.log(trackEvent);
      console.log(payload);
    }    
  }

  return sendSegmentMessage({
    method: 'createTrack',
    parameters: {
      trackEvent,
      payload,
    },
  });
};
