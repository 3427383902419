import React, { useContext } from 'react';
import { BdsButton, BdsGrid, BdsIllustration, BdsPaper, BdsTypo, BdsModal } from 'blip-ds/dist/blip-ds-react';
import '../../styles.scss';
import SubmitCertificate from '../SubmitCertificate';
import MtlsContext from '../../../../contexts/MtlsContext';

const CreateCertificate: React.FC = () => {
  const { submitModal, openSubmitModal } = useContext(MtlsContext);
  return (
    <BdsPaper width="100%">
      <BdsGrid gap="8" margin="5">
        <BdsGrid>
          <BdsIllustration className="mtls-lock-illustration" type="spots" name="lock-2"></BdsIllustration>
        </BdsGrid>
        <BdsGrid direction="column" justifyContent="space-between">
          <BdsGrid direction="column" margin="b-2">
            <BdsTypo variant="fs-24" bold="bold" margin={false}>
              Mutual TLS (End to End Encryption)
            </BdsTypo>
            <BdsTypo variant="fs-16">
              Mutual TLS, or mTLS for short, is a method for mutual authentication. mTLS ensures that the parties at
              each end of a network connection are who they claim to be by verifying that they both have the correct
              private key. The information within their respective TLS certificates provides additional verification.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid>
            <BdsButton icon="add" onClick={openSubmitModal}>
              Cadastrar novo certificado
            </BdsButton>
            <BdsModal open={submitModal} size="dynamic">
              <SubmitCertificate />
            </BdsModal>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};

export default CreateCertificate;
