import React, { FC, useContext, useEffect, useState } from 'react';
import { BdsPaper } from 'blip-ds/dist/blip-ds-react';
import { BlipTable } from '../../../../components/BlipTable';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { getMonthTwoDigits, toUTCDateString } from '../../../../utils/date';
import { DailyActiveUserPaper, DailyActiveUserUsageStatementContent } from '../styles';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { PaperHeaderComponent } from '../../components/Metrics/PaperHeader/PaperHeaderComponent';
import { trackEvent } from '../../../../api/Billing/Tracking';
import {
  action,
  origin,
  REPORT_DAU_CONSUMPTION_PER_DATE_OPEN,
  REPORT_PERIOD_SELECT,
} from '../../../../shared/SegmentEvents';

export interface DailyActiveUserUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const dailyActiveUserUsageComponent: FC<DailyActiveUserUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    dateToSearch,
    content,
    tenant,
    loggedUser,
    trackingProps,
    sortDailyActiveUserUsage,
    setDailyActiveUserUsage,
    dailyActiveUserUsage,
    billingDailyActiveUserApi,
    periodOptions,
    setDateToSearch,
    getPlanStatus,
  } = useContext(DailyActiveUsersContext);
  const isDetailedView = registersToTake === -1;
  const [isLoading, setLoading] = useState(false);

  const tableDailyUsageModel = [
    { label: content.dailyUsage.byDaily.date, key: 'dateReference' },
    { label: content.dailyUsage.byDaily.dailyActiveUsers, key: 'total' },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
        const dailyActiveUserUsage = await billingDailyActiveUserApi.getDailyActiveUserUsage(
          tenant.info.id,
          monthString,
          registersToTake,
        );

        const dailyActiveUserMonthlyUsageParse = dailyActiveUserUsage.map((obj) => ({
          ...obj,
          dateReference: toUTCDateString(obj.dateReference, content.locale),
        }));

        setDailyActiveUserUsage(dailyActiveUserMonthlyUsageParse);
      } finally {
        setLoading(false);
      }
    };
    getData();

  }, [dateToSearch]);

  const trackDailyButton = () => {
    trackEvent(REPORT_DAU_CONSUMPTION_PER_DATE_OPEN, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.DAU,
      action: action.CLICK,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.DAUPERDATE,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  return (
    <>
      {isDetailedView && (
        <PageHeaderComponent
          title={content.dailyUsage.title}
          dateToSearch={dateToSearch}
          periodOptions={periodOptions}
          periodOptionChangeEvent={periodOptionChangeEvent}
        />
      )}
      <DailyActiveUserPaper>
        <PaperHeaderComponent
          title={content.dailyUsage.title}
          subtitle={content.dailyUsage.tableInformation}
          path={'/dau-consumption/daily'}
          buttonCaption={content.dailyUsage.buttonTitle}
          trackButton={trackDailyButton}
          isDetailedView={isDetailedView}
          idTest={'show-all-dash-dau-daily-consumption'}
        />
        <DailyActiveUserUsageStatementContent>
          <BdsPaper>
            <BlipTable
              idKey="date"
              model={tableDailyUsageModel}
              data={dailyActiveUserUsage}
              selectedItems={[]}
              onSortSet={sortDailyActiveUserUsage}
              canSelect={false}
              bodyHeight={tableBodyHeight}
              loading={isLoading}
            ></BlipTable>
          </BdsPaper>
        </DailyActiveUserUsageStatementContent>
      </DailyActiveUserPaper>
    </>
  );
};

export default dailyActiveUserUsageComponent;
