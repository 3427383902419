import { BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext } from 'react';
import { BlipProgressBar } from '../../../../components/BlipProgressBar';
import AgentConsumptionContext from '../../../../contexts/AgentConsumptionContext';
import {
  AgentConsumptionProgressBarAligned,
  AgentConsumptionProgressContent,
  AgentConsumptionProgressTotalAligned,
} from '../styles';
import { useAgentConsumptionContext } from '../../../../contexts/Billing/AgentConsumptionContext';

const AgentConsumptionProgressComponent: React.FC = () => {
  const { content, currentMonth } = useContext(AgentConsumptionContext);
  const { compiledAgentConsumptionDataState } = useAgentConsumptionContext();
  const consolidatedContainsDataOnSelectedMonth =
    compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth;
  const planUsageRate = compiledAgentConsumptionDataState.consolidatedAgentConsumption.planUsageRate;
  const planUsage = compiledAgentConsumptionDataState.consolidatedAgentConsumption.planUsage;
  const quantity = compiledAgentConsumptionDataState.consolidatedAgentConsumption.metric.quantity;

  return (
    <AgentConsumptionProgressContent
      currentMonth={currentMonth}
      containsOverspent={true}
      className="bg-color-surface-2"
    >
      <AgentConsumptionProgressBarAligned>
        <BdsTypo data-planUsageRate={planUsageRate} tag="span" variant="fs-16" bold="bold" margin={false}>
          {content.consolidated.progressContentTitle}
        </BdsTypo>
        <BdsTooltip position="top-center" tooltip-text={content.consolidated.progressContentHoverIcon}>
          <BdsIcon name="info"></BdsIcon>
        </BdsTooltip>
        <BlipProgressBar
          completed={planUsageRate}
          color={currentMonth ? 'information' : 'default'}
        ></BlipProgressBar>
      </AgentConsumptionProgressBarAligned>
      <AgentConsumptionProgressTotalAligned currentMonth={currentMonth}>
        <BdsTypo
          data-planUsage={planUsage}
          className={currentMonth ? 'color-content-default' : 'color-primary'}
          variant="fs-32"
          tag="span"
          bold="extra-bold"
          margin={false}
        >
          {consolidatedContainsDataOnSelectedMonth ? planUsage.toLocaleString(content.locale) : '--'}
        </BdsTypo>
        <BdsTypo className={'color-content-disable'} variant="fs-32" tag="span" bold="extra-bold" margin={true}>
          /
        </BdsTypo>
        <BdsTypo
          className={'color-content-disable'}
          data-quantity={quantity}
          variant="fs-24"
          tag="span"
          bold="extra-bold"
          margin={false}
        >
          {quantity >= 0 && consolidatedContainsDataOnSelectedMonth ? quantity.toLocaleString(content.locale) : '--'}
        </BdsTypo>
      </AgentConsumptionProgressTotalAligned>
    </AgentConsumptionProgressContent>
  );
};

export default AgentConsumptionProgressComponent;
