import styled from 'styled-components';
export const LeftInfoPaperArea = styled.div`
  display: flex;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-between;
`;

export const RightInfoPaperArea = styled.div`
  display: flex;
  align-content: space-around;
  flex-direction: column;
  ${(props) =>
    props.justifyContent === 'flex-start' ? 'justify-content: flex-start; & > div {    margin-bottom: 4rem;  }' : 'justify-content: space-between;'};
  
`;
