import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext } from 'react';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersTotalConsumedPaperContent } from '../styles';

const CompiledDailyActiveUserTotalContentComponent: React.FC = () => {
  const { content, compiledDailyActiveUserDataState } = useContext(DailyActiveUsersContext);

  const total = (toLocaleString: boolean) => {
    if (compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth) {
      return toLocaleString
        ? compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.total.toLocaleString(content.locale)
        : compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.total;
    } else {
      return '--';
    }
  };

  return (
    <CompiledDailyActiveUsersTotalConsumedPaperContent>
      <BdsTypo tag="h4" variant="fs-16" bold="bold" margin={true}>
        {content.compiledDailyActiveUserInformation.totalUsed}
      </BdsTypo>
      <BdsTypo className='totalUsed' tag="h4" variant="fs-32" bold="bold" margin={true} data-total={total(false)}>
        {total(true)}
      </BdsTypo>
    </CompiledDailyActiveUsersTotalConsumedPaperContent>
  );
};

export default CompiledDailyActiveUserTotalContentComponent;
