export const localization = {
  pt: {
    title: 'Painel do Contrato',
    overview: 'Resumo',
    members: 'Membros do contrato',
    pending: 'Pendentes',
  },
  en: {
    title: 'Contract Panel',
    overview: 'Overview',
    members: 'Members of',
    pending: 'Pending',
  },
  es: {
    title: 'Panel de contrato',
    overview: 'Resumen',
    members: 'Miembros del contrato',
    pending: 'Pendientes',
  },
};
