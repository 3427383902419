import { MonthlyConsumption } from './MonthlyConsumption';

export class MRRRevenue {
  idContract!: number;
  MAUs!: number;
  MEUs!: number;
  MSGs!: number;
  activeReport!: boolean;

  constructor(contractRules: Partial<MRRRevenue>) { 
    Object.assign(this, contractRules);
  }

  public toMonthlyConsumption(miu: string): MonthlyConsumption {
    return new MonthlyConsumption(
      miu.includes('MAU') ? this.MAUs : this.MEUs,
      this.MSGs
    );
  }
}