export const localization = {
  pt: {
    header: {
      titleRedirect: 'Blip Copilot: Permissões de bots',
      title: 'Blip Copilot',
      subTitle: 'Gerencie os bots terão permissão para usar o Desk Copilot.',
    },
    summaryBots: {
      enabled: 'Bots habilitados',
      disabled: 'Bots desabilitados',
    },
    detailsBots: {
      title: 'Nome do bot',
      enable: 'Habilitar',
      disable: 'Desabilitar',
      enabled: 'habilitado',
      disabled: 'desabilitado',
      hasNotPermission: 'Você não tem acesso a esse contato inteligente. Solicite acesso ao (à) administrador(a).',
    },
    enabledCopilot: {
      success: 'Bot {0} com sucesso',
      error: 'Falha durante a ação, tente novamente',
    },
    selectionBots: {
      selectAll: 'Selecionar todos',
      enable: 'Habilitar Selecionados',
      disable: 'Desabilitar Selecionados',
      selected: '{0} Selecionados:',
    },
    page: {
      result: 'Exibindo {0}-{1} de {2} resultados',
    },
    listEmpty: {
      titleEnabled: 'Nenhum bot habilitado ainda',
      subTitleEnabled: 'Que tal checar os bots desabilitados para ativar o Blip Copilot?',
      buttonDisabled: 'Ver bots desabilitados',
      titleDisabled: 'Nenhum bot desabilitado',
      subTitleDisabled: 'Que tal checar os bots em quais bots o Blip Copilot está habilitado?',
      buttonEnabled: 'Ver bots habilitados',
    },
    filter: {
      allBots: 'Todos os bots',
      onlyEnabled: 'Somente habilitados',
      onlyDisabled: 'Somente desabilitados',
    },
  },
  en: {
    header: {
      titleRedirect: 'Blip Copilot: Bot permissions',
      title: 'Blip Copilot',
      subTitle: 'Manage the bots that will have permission to use Desk Copilot.',
    },
    summaryBots: {
      enabled: 'Enabled bots',
      disabled: 'Disabled bots',
    },
    detailsBots: {
      title: 'Name of the bot',
      enable: 'Enable',
      disable: 'Disable',
      enabled: 'enabled',
      disabled: 'disabled',
      hasNotPermission: 'You do not have access to this smart contact. Request access to the administrator.',
    },
    enabledCopilot: {
      success: 'Bot {0} successfully',
      error: 'Failure during the action, try again',
    },  
    selectionBots: {
      selectAll: 'Select all',
      enable: 'Enable Selected',
      disable: 'Disable Selected',
      selected: '{0} Selected:',
    },
    listEmpty: {
      titleEnabled: 'No bot enabled yet',
      subTitleEnabled: 'How about checking the disabled bots to enable Blip Copilot?',
      buttonDisabled: 'View disabled bots',
      titleDisabled: 'No bot disabled',
      subTitleDisabled: 'How about checking the bots in which Blip Copilot is enabled?',
      buttonEnabled: 'View enabled bots',
    },
    filter: {
      allBots: 'All bots',
      onlyEnabled: 'Only enabled',
      onlyDisabled: 'Only disabled',
    },
  },
  es: {
    header: {
      titleRedirect: 'Blip Copilot: Permisos de bots',
      title: 'Blip Copilot',
      subTitle: 'Administre los bots que tendrán permiso para usar Desk Copilot.',
    },
    summaryBots: {
      enabled: 'Bots habilitados',
      disabled: 'Bots deshabilitados',
    },
    detailsBots: {
      title: 'Nombre del bot',
      enable: 'Habilitar',
      disable: 'Deshabilitar',
      enabled: 'habilitado',  
      disabled: 'deshabilitado',
      hasNotPermission: 'No tienes acceso a este contacto inteligente. Solicita acceso al administrador.',
    },
    enabledCopilot: {
      success: 'Bot {0} con éxito',
      error: 'Error durante la acción, inténtalo de nuevo',
    },
    selectionBots: {
      selectAll: 'Seleccionar todo',
      enable: 'Habilitar seleccionados',
      disable: 'Deshabilitar seleccionados',
      selected: '{0} Seleccionados:',
    },
    listEmpty: {
      titleEnabled: 'Ningún bot habilitado aún',
      subTitleEnabled: '¿Qué tal verificar los bots deshabilitados para habilitar Blip Copilot?',
      buttonDisabled: 'Ver bots deshabilitados',
      titleDisabled: 'Ningún bot deshabilitado',
      subTitleDisabled: '¿Qué tal verificar los bots en los que Blip Copilot está habilitado?',
      buttonEnabled: 'Ver bots habilitados',
    },
    filter: {
      allBots: 'Todos los bots',
      onlyEnabled: 'Solo habilitados',
      onlyDisabled: 'Solo deshabilitados',
    },
  },
};
