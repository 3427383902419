import React, { createContext, Reducer, useReducer, useState } from 'react';
import BillingMiuConsumptionServiceApi from '../../api/Billing/BillingMiuConsumptionApi';
import { ConsolidatedMiuConsumption } from '../../models/billing/miuConsumption/ConsolidatedMiuConsumption';
import { RequestStatus } from '../../shared/Requests';
import { getMonthTwoDigits } from '../../utils/date';
import {
  BillingMetrics,
  getMetricStatus,
  MetricStatus,
  METRIC_NOT_REGISTERED,
  UNLIMITED_PLAN_METRIC,
} from '../../shared/BillingMetrics';
import { equalsIgnoreOrder } from '../../utils/array';
import {
  localizationMau as pageLocalizationMau,
  localizationMeu as pageLocalizationMeu,
  localizationMsg as pageLocalizationMsg,
  localizationMauMsg as pageLocalizationMauMsg,
  localizationMeuMsg as pageLocalizationMeuMsg,
} from '../../views/Billing/MiuConsumption/localizations';
import {
  localizationMau as dashLocalizationMau,
  localizationMeu as dashLocalizationMeu,
  localizationMsg as dashLocalizationMsg,
  localizationMauMsg as dashLocalizationMauMsg,
  localizationMeuMsg as dashLocalizationMeuMsg,
} from '../../views/Billing/DataConsumption/components/MiuConsumption/localizations';

interface CompiledMiuConsumptionDataState {
  consolidatedMiuConsumption: Array<ConsolidatedMiuConsumption>;
  consolidatedContainsDataOnSelectedMonth: boolean;
}

interface MiuConsumptionContextData {
  compiledMiuConsumptionDataState: CompiledMiuConsumptionDataState;
  consolidatedMiuApiRequisition: (
    dateToSearch: Date,
    metricIds: Array<BillingMetrics>,
    hasEnabledMetric: (value: Array<BillingMetrics>) => boolean,
  ) => Promise<void>;
  isMiuLoading: boolean;
  miuStatus: MetricStatus;
  miuMetricIds: Array<BillingMetrics>;
  localization: any;
}

interface props {
  tenant: any;
}

export const MiuConsumptionContext = createContext<MiuConsumptionContextData>({} as MiuConsumptionContextData);

export const MiuConsumptionContextProvider: React.FC<props> = ({ children, tenant }) => {
  const [isMiuLoading, setIsMiuLoading] = useState(false);
  const [miuStatus, setMiuStatus] = useState(MetricStatus.error);
  const billingMiuConsumptionApi = new BillingMiuConsumptionServiceApi();
  const consolidatedMiuConsumption = new Array<ConsolidatedMiuConsumption>(
    new ConsolidatedMiuConsumption(null, BillingMetrics.monthlyActiveUser),
    new ConsolidatedMiuConsumption(null, BillingMetrics.exchangedMessage),
  );
  const [compiledMiuConsumptionDataState, setCompiledMiuConsumptionDataState] = useReducer<
    Reducer<CompiledMiuConsumptionDataState, Partial<CompiledMiuConsumptionDataState>>
  >(
    (compiledMiuConsumptionDataState, newCompiledMiuConsumptionDataState) => ({
      ...compiledMiuConsumptionDataState,
      ...newCompiledMiuConsumptionDataState,
    }),
    {
      consolidatedMiuConsumption,
      consolidatedContainsDataOnSelectedMonth: false,
    },
  );

  const contentLocalization = [
    {
      metricId: [BillingMetrics.monthlyActiveUser],
      localizationMetricId: [BillingMetrics.monthlyActiveUser],
      localization: {
        pageLocalization: pageLocalizationMau,
        dashLocalization: dashLocalizationMau,
        asideMenuLocalization: 'mauConsumption',
      },
    },
    {
      metricId: [BillingMetrics.monthlyEngagedUser],
      localizationMetricId: [BillingMetrics.monthlyEngagedUser],
      localization: {
        pageLocalization: pageLocalizationMeu,
        dashLocalization: dashLocalizationMeu,
        asideMenuLocalization: 'meuConsumption',
      },
    },
    {
      metricId: [BillingMetrics.exchangedMessage],
      localizationMetricId: [BillingMetrics.exchangedMessage],
      localization: {
        pageLocalization: pageLocalizationMsg,
        dashLocalization: dashLocalizationMsg,
        asideMenuLocalization: 'msgConsumption',
      },
    },
    {
      metricId: [BillingMetrics.monthlyActiveUser, BillingMetrics.exchangedMessage],
      localizationMetricId: [BillingMetrics.monthlyActiveUser, BillingMetrics.exchangedMessage],
      localization: {
        pageLocalization: pageLocalizationMauMsg,
        dashLocalization: dashLocalizationMauMsg,
        asideMenuLocalization: 'mauMsgConsumption',
      },
    },
    {
      metricId: [BillingMetrics.monthlyEngagedUser, BillingMetrics.exchangedMessage],
      localizationMetricId: [BillingMetrics.monthlyEngagedUser, BillingMetrics.exchangedMessage],
      localization: {
        pageLocalization: pageLocalizationMeuMsg,
        dashLocalization: dashLocalizationMeuMsg,
        asideMenuLocalization: 'meuMsgConsumption',
      },
    },
    {
      metricId: [BillingMetrics.monthlyActiveUser, BillingMetrics.monthlyEngagedUser, BillingMetrics.exchangedMessage],
      localizationMetricId: [BillingMetrics.monthlyActiveUser, BillingMetrics.exchangedMessage],
      localization: {
        pageLocalization: pageLocalizationMauMsg,
        dashLocalization: dashLocalizationMauMsg,
        asideMenuLocalization: 'mauMsgConsumption',
      },
    },
    {
      metricId: [BillingMetrics.monthlyActiveUser, BillingMetrics.monthlyEngagedUser],
      localizationMetricId: [BillingMetrics.monthlyActiveUser],
      localization: {
        pageLocalization: pageLocalizationMau,
        dashLocalization: dashLocalizationMau,
        asideMenuLocalization: 'mauConsumption',
      },
    },
  ];

  const miuMetrics = tenant.subscription.filter(
    (item) =>
      item.metricId === BillingMetrics.monthlyActiveUser ||
      item.metricId === BillingMetrics.monthlyEngagedUser ||
      item.metricId === BillingMetrics.exchangedMessage,
  );

  const metricIds = miuMetrics.map((item) => item.metricId);

  const miuMetricIds = contentLocalization.find((item) => equalsIgnoreOrder(item.metricId, metricIds))
    ?.localizationMetricId || [BillingMetrics.monthlyActiveUser, BillingMetrics.exchangedMessage];

  const localization = contentLocalization.find((item) => equalsIgnoreOrder(item.metricId, miuMetricIds))?.localization;

  const consolidatedMiuApiRequisition = async (
    dateToSearch: Date,
    metricIds: Array<BillingMetrics>,
    hasEnabledMetric: (value: Array<BillingMetrics>) => boolean,
  ): Promise<void> => {
    try {
      setIsMiuLoading(true);
      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
      const metricIdsString = metricIds.join(';');

      const consolidatedMiu = hasEnabledMetric([
        BillingMetrics.monthlyActiveUser,
        BillingMetrics.monthlyEngagedUser,
        BillingMetrics.exchangedMessage,
      ])
        ? await billingMiuConsumptionApi.getConsolidatedMiuConsumption(tenant.info.id, metricIdsString, monthString)
        : consolidatedMiuConsumption;

      setCompiledMiuConsumptionDataState({
        consolidatedMiuConsumption: consolidatedMiu,
        consolidatedContainsDataOnSelectedMonth: billingMiuConsumptionApi.getStatus() === RequestStatus.SUCCESS,
      });

      if (billingMiuConsumptionApi.getStatus() === RequestStatus.SUCCESS) {
        setMiuStatus(getMetricStatus(UNLIMITED_PLAN_METRIC));
      } else {
        setMiuStatus(getMetricStatus(METRIC_NOT_REGISTERED));
      }
    } finally {
      setIsMiuLoading(false);
    }
  };

  return (
    <MiuConsumptionContext.Provider
      value={{
        compiledMiuConsumptionDataState,
        consolidatedMiuApiRequisition,
        isMiuLoading,
        miuStatus,
        miuMetricIds,
        localization,
      }}
    >
      {children}
    </MiuConsumptionContext.Provider>
  );
};

export function useMiuConsumptionContext(): MiuConsumptionContextData {
  const context = React.useContext(MiuConsumptionContext);

  if (!context) {
    throw new Error('use miuConsumptionContext context must be used within an MiuConsumptionContextProvider');
  }

  return context;
}
