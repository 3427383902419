import React from 'react';

export const getNameEmail = (agent) => {
  
  const { fullName, email } = agent;
  return (
    <div className='flex flex-column '>
      <span className='truncate bold '>{fullName}</span>
      <span className='truncate bp-fs-7'>{email}</span>
    </div>
  );
};
