import React from 'react';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import PropTypes from 'prop-types';

const NotFound = ({title, subTitle}) => {
  return (
    <div className="flex flex-column justify-center items-center">
      <bds-illustration type="blip-outline" name="sad" class="ma4 w-10"></bds-illustration>
      <BdsTypo className='ma2 ' tag='h1' variant='fs-20' bold='bold'>{title}</BdsTypo>
      <BdsTypo variant='fs-16' tag='span'>{subTitle}</BdsTypo>
    </div>
  );
};


NotFound.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string ,
};


export { NotFound };
