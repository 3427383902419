export const resetMomentTime = (instance) => instance.hours(0).minutes(0).seconds(0).milliseconds(0);

export const getMonthTwoDigits = (instance) => `0${instance.getMonth() + 1}`.slice(-2);

export const generateMonthListBetweenDates = (startDate: Date, endDate: Date): Date[] => {
  const options: Date[] = [];
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  for (let year = startYear; year <= endYear; year++) {
    const endMonth = year != endYear ? 11 : endDate.getMonth();
    const startMonth = year === startYear ? startDate.getMonth() : 0;
    for (let month = startMonth; month <= endMonth; month = month > 12 ? month % 12 || 11 : month + 1) {
      options.push(new Date(year, month));
    }
  }
  return options;
};

export const getDayBeforeToday = (): Date => new Date(today().getFullYear(), today().getMonth(), today().getDate() - 1);

export const getFirstDayOfMonth = (): Date => new Date(today().getFullYear(), today().getMonth(), 1);

const today = (): Date => new Date();

export const getFirstDayOfSpecifiedMonth = (dateToCheck: Date): Date =>
  new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), 1);

export const getLastDayOfSpecifiedMonth = (dateToCheck: Date): Date =>
  new Date(dateToCheck.getFullYear(), dateToCheck.getMonth() + 1, 0);

export const formatTimestampToLocaleDate = (timestamp: string, locale = 'pt-br'): string => {
  return new Date(timestamp).toLocaleString(locale).slice(0, -3).replace(' ', ' - ');
};

export const IsEndOfTheWeek = (date: Date) => {
  const weekday = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 };
  return date.getDay() === weekday.Saturday;
};

export const AddDays = (referenceDate: Date, days: number) => {
  const date = new Date(referenceDate);
  date.setDate(date.getDate() + days);
  return date;
};

export const weekRangeOfSpecifiedMonth = (year: number, month: number) => {
  let start = new Date(year, month, 1);
  const end = new Date(year, month, new Date(year, month, 0).getDate());
  const WeekRangeArray: any[] = [];
  let i = 0;
  for (let date = start; date <= end; date = AddDays(date, 1)) {
    if (!IsEndOfTheWeek(date) && date < end) {
      continue;
    }

    WeekRangeArray.push({
      startDateReference: start,
      endDateReference: date,
      total: 0,
      weekNumber: i,
    });
    i++;
    start = AddDays(date, 1);
  }

  return WeekRangeArray;
};

export const toUTCDateString = (date: Date, locale: string, showYear = true) => {
  const dataDisplay = locale == 'en-US' ? 'numeric' : '2-digit';
  const defaultOptions: any = {
    day: dataDisplay,
    month: dataDisplay,
    timeZone: 'UTC',
  };
  const yearOptions: any = {
    year: 'numeric',
  };
  const options = showYear ? Object.assign(defaultOptions, yearOptions) : defaultOptions;

  return new Date(date).toLocaleDateString(locale, options);
};

export const formatDate = (
  date: Date,
  locale: string,
  separator = '/',
  options: any = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' },
  reverse = false,
) => {
  const formatedDate = new Date(date).toLocaleDateString(locale, options).replace(/\//g, separator);

  return reverse ? formatedDate.split(separator).reverse().join(separator) : formatedDate;
};
