import styled from 'styled-components';
import colors from '../../../../../_colors.module.scss';

export const InfoPaperContainer = styled.div`
  padding: 1em;
  margin-bottom: 1em;

  @media (min-width: 1024px) {
    width: 9em;
  }

  @media (min-width: 1280) {
    width: 10em;
  }

  @media (min-width: 1440px) {
    width: 11em;
  }

  @media (min-width: 1600px) {
    width: 13em;
  }

  @media (min-width: 1920px) {
    width: 15em;
  }

  ${(props) =>
    props.bdsPaper
      ? `box-shadow: rgb(7 71 166 / 15%) 0px 2px 8px;  display: block;  background: ${colors.Surface1};  border-radius: 7px;`
      : `border: 1px solid ${colors.Surface3}; border-radius: 8px;`};
`;

export const InfoPaperHeader = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justifyContent !== '' ? props.justifyContent : 'space-between')};
  align-items: center;
  height: auto;

  .flex-start-margin {
    ${(props) => (props.showIcon ? 'margin-left: 10px;' : 'margin-left: 0px;')};
  }

  & > bds-tooltip {
    height: 16px;
  }
`;

export const InfoPaperTitle = styled.div`
  white-space: nowrap;
  & > bds-typo.total-limit {
    margin: 0 5px;
  }
`;

export const InfoPaperBody = styled.div`
  display: flex;
`;
