import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import { IframeMessageProxy } from 'iframe-message-proxy';
import './index.scss';
import './register-web-components';
import './typings/blip-ds.d';

IframeMessageProxy.listen();
ReactDOM.render(<App />, document.getElementById('root'));


