import { weekRangeOfSpecifiedMonth } from 'utils/date';
import { BillingMetric } from './BillingMetric';
import { BillingWeekItems } from './BillingWeekItems';

export class BillingPlanMetric {
  metric!: BillingMetric;
  weekItems!: BillingWeekItems[];
  total!: number;
  planUsageRate!: number;
  exceededPlan!: number;
  planUsage!: number;
  availablePlan!: number;
  topWeekNumber!: number;
  weeklyAverage!: number;
  startDateReference!: string;
  endDateReference!: string;

  constructor(billingPlanMetric: Partial<BillingPlanMetric | null>) {
    this.metric = {
      metricId: '',
      quantity: 0,
      allowAdditionalItem: false,
      additionalItemValue: 0,
    };
    this.weekItems = weekRangeOfSpecifiedMonth(new Date().getFullYear(), new Date().getMonth());
    this.total = 0;
    this.planUsageRate = 0;
    this.exceededPlan = 0;
    this.planUsage = 0;
    this.availablePlan = 0;
    this.topWeekNumber = 0;
    this.weeklyAverage = 0;
    this.startDateReference = '';
    this.endDateReference = '';
    Object.assign(this, billingPlanMetric);
  }
}
