import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext } from 'react';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersOverspentMessagesPaperContent } from '../styles';

const CompiledDailyActiveUserOverspentContentComponent: React.FC = () => {
  const { content, compiledDailyActiveUserDataState } = useContext(DailyActiveUsersContext);

  const exceededPlan = (toLocaleString: boolean) => {
    if (compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth) {
      return toLocaleString
        ? compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.exceededPlan.toLocaleString(
          content.locale,
        )
        : compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.exceededPlan;
    } else {
      return '--';
    }
  };

  return (
    <CompiledDailyActiveUsersOverspentMessagesPaperContent>
      <BdsTypo tag="h4" variant="fs-16" bold="bold" margin={false}>
        {content.compiledDailyActiveUserInformation.overspentMessages}
      </BdsTypo>
      <BdsTypo tag="h4" variant="fs-32" bold="bold" margin={false} data-exceededPlan={exceededPlan(false)}>
        {exceededPlan(true)}
      </BdsTypo>
    </CompiledDailyActiveUsersOverspentMessagesPaperContent>
  );
};

export default CompiledDailyActiveUserOverspentContentComponent;
