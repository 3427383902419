export const localization = {
  pt: {
    contractPanel: 'Painel do contrato',
    agents: 'Gestão de atendentes',
    agentConsumption: 'Dados de consumo',
  },
  en: {
    contractPanel: 'Contract panel',
    agents: 'Manage agents',
    agentConsumption: 'Consumption data',
  },
  es: {
    contractPanel: 'Panel de contrato',
    agents: 'Gestión de asistentes',
    agentConsumption: 'Datos de consumo',
  },
};
