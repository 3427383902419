import { DailyActiveUsersMonthlyUsage } from '../../models/billing/dailyActiveUsers/DailyActiveUsersMonthlyUsage';
import { DailyActiveUserUsage } from '../../models/billing/dailyActiveUsers/DailyActiveUserUsage';
import { CompiledDailyActiveUserConsumption } from '../../models/billing/dailyActiveUsers/CompiledDailyActiveUserConsumption';
import { BlipApi } from '../BlipApi';
import { METHOD, RequestStatus } from 'shared/Requests';
import { useSubscriptionReferenceDate } from './BillingApi';

export default class BillingDailyActiveUsersServiceApi {
  private readonly BILLING_COMMAND_DESTINATION = 'postmaster@billing.blip.ai';
  private code = '';
  private status = '';
  private useSubscriptionReferenceDate: boolean | undefined = undefined;

  public async getUseSubscriptionReferenceDate(): Promise<void> {
    if (this.useSubscriptionReferenceDate === undefined) {
      this.useSubscriptionReferenceDate = await useSubscriptionReferenceDate();
    }    
  }

  public getStatus() {
    return this.status;
  }

  public getCode() {
    return this.code;
  }

  public setSuccess() {
    this.status = RequestStatus.SUCCESS;
  }

  public setErrorLog(e) {
    const error = JSON.parse(<string>e);
    const {
      status,
      reason: { code },
    } = JSON.parse(error.message);
    this.code = code;
    this.status = status;
    console.log(e);
  }

  public async getCompiledDailyActiveUserConsumption(
    tenantId: string,
    month: string,
  ): Promise<CompiledDailyActiveUserConsumption> {
    await this.getUseSubscriptionReferenceDate();
    const uri = `/tenants/${tenantId}/daily-active-users/summary?month=${month}&useSubscriptionReferenceDate=${this.useSubscriptionReferenceDate}`;
    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const { response } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return new CompiledDailyActiveUserConsumption(response);
    } catch (e) {
      this.setErrorLog(e);
      return new CompiledDailyActiveUserConsumption(null);
    }
  }

  public async getDailyActiveUserMonthlyUsage(
    tenantId: string,
    month: string,
    registersToTake: number,
  ): Promise<DailyActiveUsersMonthlyUsage[] | null> {
    await this.getUseSubscriptionReferenceDate();
    let uri = `/tenants/${tenantId}/daily-active-users/monthly/by-bot?month=${month}&useSubscriptionReferenceDate=${this.useSubscriptionReferenceDate}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }
    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new DailyActiveUsersMonthlyUsage(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<DailyActiveUsersMonthlyUsage>();
    }
  }

  public async getDailyActiveUserUsage(
    tenantId: string,
    month: string,
    registersToTake: number,
  ): Promise<DailyActiveUserUsage[] | null> {
    await this.getUseSubscriptionReferenceDate();
    let uri = `/tenants/${tenantId}/daily-active-users/daily?month=${month}&useSubscriptionReferenceDate=${this.useSubscriptionReferenceDate}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }
    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new DailyActiveUserUsage(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<DailyActiveUserUsage>();
    }
  }
}
