export const localization = {
  pt: {
    locale: 'pt-BR',
    header: 'Certificados MTLS de {0}',
  },
  en: {
    locale: 'en-US',
    header: '{0} MTLS Certificates',
  },
  es: {
    locale: 'es-ES',
    header: 'Certificados MTLS de {0}',
  },
};
