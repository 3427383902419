import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCommon } from '../../../contexts/CommonContext';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { useTenant } from '../../../contexts/TenantContext';
import { useContentLocalizer } from '../../../hooks/useContentLocalizer';
import { localization } from './localization';
import DailyActiveUsersSummary from './components/DailyActiveUsersSummary';
import MonthlyUsage from './components/Monthly/MonthlyUsage';
import DailyUsage from './components/Daily/DailyUsage';
import { DailyActiveUsersMonthlyUsage } from '../../../models/Billing/dailyActiveUsers/DailyActiveUsersMonthlyUsage';
import { sortByProperty } from '../../../utils/array';
import BillingDailyActiveUsersServiceApi from '../../../api/Billing/BillingDailyActiveUsersApi';
import { DailyActiveUserUsage } from '../../../models/Billing/dailyActiveUsers/DailyActiveUserUsage';
import { AsideMenu } from '../components/AsideMenu';
import { useBillingConsumptionContext } from '../../../contexts/Billing/BillingConsumptionContext';
import { action, origin } from '../../../shared/SegmentEvents';
import { useBillingPlanUsageContext } from '../../../contexts/Billing/BillingPlanUsageContext';
import { useBillingMetricsContext } from '../../../contexts/Billing/BillingMetricsContext';
import { billingRoutes, billingSubRoutes } from '../../../routes/BillingRoutes';

const DailyActiveUsers: React.FC = () => {
  const { dateToSearch, setDateToSearch, periodOptions, currentMonth } = useBillingConsumptionContext();
  const { isMetricAllowed } = useBillingMetricsContext();
  const {
    setHeaderContent,
    common: { loggedUser },
  } = useCommon();

  const { tenant } = useTenant();
  const content = useContentLocalizer(localization);
  const billingDailyActiveUserApi = new BillingDailyActiveUsersServiceApi();
  const [dailyActiveUserMonthlyUsage, setDailyActiveUserMonthlyUsage] = useState<DailyActiveUsersMonthlyUsage[]>([]);
  const [dailyActiveUserUsage, setDailyActiveUserUsage] = useState<DailyActiveUserUsage[]>([]);
  const { planStatus, getPlanStatus } = useBillingPlanUsageContext();

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: null });
  }, []);

  const sortDailyActiveUserMonthlyUsage = (sort) => {
    const sortedChatbotsConsumption = sortByProperty(dailyActiveUserMonthlyUsage, sort.property, sort.order);
    setDailyActiveUserMonthlyUsage([...sortedChatbotsConsumption]);
  };

  const sortDailyActiveUserUsage = (sort) => {
    const sortedChatbotsConsumption = sortByProperty(dailyActiveUserUsage, sort.property, sort.order);
    setDailyActiveUserUsage([...sortedChatbotsConsumption]);
  };

  const trackingProps = {
    date: dateToSearch,
    planStatus: planStatus.current,
    origin: origin.DAU,
    action: action.SELECT,
  };

  const contextProps = {
    dateToSearch,
    setDateToSearch,
    periodOptions,
    currentMonth,
    content,
    tenant,
    loggedUser,
    setHeaderContent,
    billingDailyActiveUserApi,
    dailyActiveUserMonthlyUsage,
    dailyActiveUserUsage,
    sortDailyActiveUserMonthlyUsage,
    setDailyActiveUserMonthlyUsage,
    sortDailyActiveUserUsage,
    setDailyActiveUserUsage,
    trackingProps,
    getPlanStatus,
    isMetricAllowed,
  };

  return (
    <DailyActiveUsersContext.Provider value={contextProps}>
      <AsideMenu>
        <Switch>
          <Route exact path={billingRoutes.dauConsumption} component={DailyActiveUsersSummary}></Route>
          <Route exact path={billingSubRoutes.dauConsumptionMonthly} component={MonthlyUsage}></Route>
          <Route exact path={billingSubRoutes.dauConsumptionDaily} component={DailyUsage}></Route>
        </Switch>
      </AsideMenu>
    </DailyActiveUsersContext.Provider>
  );
};

export default DailyActiveUsers;
