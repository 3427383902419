import styled from 'styled-components';
import blipColors from 'blip-tokens/build/json/colors.json';

export const ContentPaper = styled.div` 
display:flex;

.paper {

    overflow:auto;
    max-height:150px;
    ::-webkit-scrollbar {
        width: 0.375rem ;
        height: 0.375rem;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${blipColors['color-neutral-medium-silver']};
        border-radius: 1000px;
    }

    ::-webkit-scrollbar-track {
        background: none;
    }
}
.p1 {
    padding: 4px 8px 4px 8px;
}

.bg-secundary {
    background: ${blipColors['color-neutral-light-whisper']};
}  
`;

export const ContentModal = styled.div`

.cheetos{
    color:${blipColors['color-extend-browns-cheetos']};
}
.disabled-text{
    color:#607B99;
}

.rooftop{
    color:${blipColors['color-neutral-dark-rooftop']};
}

.cloud{
    color:${blipColors['color-neutral-medium-cloud']};
}





`;
