import styled from 'styled-components';

export const BlipCardContainer = styled.div`
  width: 312px;
  cursor: pointer;

  & > bds-paper {
    padding: 16px;
    display: flex;
    height: 100%;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const BlipCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
`;

export const BlipCardIconContainer = styled.div`
  min-width: 48px;
  height: 48px;
  border-radius: 8px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
