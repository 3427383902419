import { BdsButton, BdsTypo, BdsModal, BdsModalAction } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import { useContentLocalizer } from '../../../hooks/useContentLocalizer';
import { localization } from './localization';
import PropTypes from 'prop-types';

const CopilotModal = ({ openModal, closeModal, onClick }) => {
  const translate = useContentLocalizer(localization);

  const moreLink = translate.moreLink;
  return (
    <>
      <BdsModal open={openModal} onBdsModalChanged={() => closeModal()}>
          <div className="flex justify-center items-center">
              <bds-illustration
                type="spots"
                name="bill-1"
                role="img"
                class="bds-illustration hydrated"
              ></bds-illustration>
            <div className="flex flex-column justify-center items-center">
              <BdsTypo tag="h1" variant="fs-20" bold="bold">
                {translate.title}
              </BdsTypo>
              <BdsTypo variant="fs-16" tag="span">
                {translate.description}
              </BdsTypo>
              <BdsTypo variant="fs-16" tag="span" className="mt3">
                {translate.formatString(translate.moreDescription, <a href={moreLink} target="_blank" rel='noreferrer'>{translate.more}</a>)}
              </BdsTypo>
            </div>
          </div>
        <BdsModalAction className="flex flex-row justify-end mt3">
          <BdsButton className="mr4" variant="secondary" onClick={() => closeModal()}>
            {translate.cancel}
          </BdsButton>
          <BdsButton bds-loading-variant="primary" variant="primary" onClick={() => onClick()}>
            {translate.continue}
          </BdsButton>
        </BdsModalAction>
      </BdsModal>
    </>
  );
};

CopilotModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CopilotModal;
