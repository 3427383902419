import React, { FC, useState, useEffect, useContext } from 'react';
import { BdsTypo, BdsPaper, BdsSelect, BdsButton } from 'blip-ds/dist/blip-ds-react';
import NumberFormat from 'react-number-format';

import * as Styles from '../styles';
import { BlipTable } from '../../../components/BlipTable';
import { MonthlyConsumption } from '../../../models/Revenue/MonthlyConsumption';
import { ReferenceValue } from '../../../models/Revenue/ReferenceValue';
import { TenantApi } from '../../../api/TenantApi';
import { useCommon } from '../../../contexts/CommonContext';
import { createTrack } from '../../../api/SegmentApi';
import { CHATBOTS_REVENUE_CHATBOTS_CLICK } from '../../../shared/SegmentEvents';
import { getMonthTwoDigits } from '../../../utils/date';
import { Link } from 'react-router-dom';
import RevenueContext from '../../../contexts/RevenueContext';
import { BlipLoadingComponent } from '../../../components/BlipLoading/BlipLoadingComponent';

const ContractConsumptionComponent: FC = () => {
  const MONTHLY_ACTIVE_USERS_UNIT = 'MAU';
  const MONTHLY_ENGAGED_USERS_UNIT = 'MEU';
  const MESSAGES_UNIT = 'MSG';
  const MIUS_UNIT = 'MIU';

  const {
    currentMonth,
    startDate,
    endDate,
    chatbotsConsumption,
    setChatbotsConsumption,
    chatbotTableModel,
    content,
    tenant,
    contractRules,
    setContractRules,
    revenueApi,
    generateMonthlyConsumptionOptions,
    updateMonthlyConsumption,
    sortChatbots,
    isFirstDayOfMonth,
  } = useContext(RevenueContext);

  const [monthlyConsumption, setMonthlyConsumption] = useState<MonthlyConsumption | null>(null);
  const [referenceValues, setReferenceValues] = useState<ReferenceValue[]>([]);
  const [referenceTableModel, setReferenceTableModel] = useState([]);
  const [tenantPlan, setTenantPlan] = useState([]);
  const [isloading, setLoading] = useState(false);
  const showContractRules = false;

  const {
    setHeaderContent,
    common: { loggedUser },
  } = useCommon();

  useEffect(() => {
    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.title, { tenant: tenant.info.name }),
    });

    const getData = async () => {
      try {
        setLoading(true);
        const DEFAULT_CHATBOTS_CONSUMPTION_QUANTITY = 3;
        const monthString = `${startDate.getFullYear()}-${getMonthTwoDigits(startDate)}`;
        const apiContractRules = await revenueApi.getContractRules(tenant.info.id, monthString);
        const monthlyConsumption = await revenueApi.getMonthlyConsumption(
          tenant.info.id,
          monthString,
          apiContractRules.miu,
        );
        const chatbotsConsumption = await revenueApi.getChatbotsConsumption(
          tenant.info.id,
          DEFAULT_CHATBOTS_CONSUMPTION_QUANTITY,
          monthString,
          apiContractRules.miu,
        );
        const tenantPlan = await TenantApi.getTenantPlan(tenant.info.id);

        setContractRules(apiContractRules);
        setReferenceValues(apiContractRules.referenceValues);
        setMonthlyConsumption(monthlyConsumption);
        setChatbotsConsumption(chatbotsConsumption);
        setTenantPlan(tenantPlan.tenantPlan.charAt(0).toUpperCase() + tenantPlan.tenantPlan.slice(1));
      } finally {
        setLoading(false);
      }
    };
    getData();

    setReferenceTableModel([
      { label: content.contractRules.referenceTable.type, key: 'type', notSortable: true },
      { label: content.contractRules.referenceTable.range, key: 'range', notSortable: true },
      { label: content.contractRules.referenceTable.calculation, key: 'calculation', notSortable: true },
    ]);

    return () => setHeaderContent({ redirect: null });
  }, [startDate, endDate]);

  const chatbotsClick = () => {
    createTrack(tenant.info, CHATBOTS_REVENUE_CHATBOTS_CLICK, {
      user: loggedUser.email,
    });
  };

  return (
    <Styles.RevenueContainer>
      <BdsPaper elevation="static">
        <Styles.MonthlyConsumption>
          <Styles.MonthlyConsumptionInfo>
            <div>
              <Styles.MonthlyConsumptionTitle>
                <BdsTypo className="title" variant="fs-24" bold="bold" tag="h3">
                  {content.formatString(content.monthlyConsumption.title, {
                    month: content.monthlyConsumption.month[startDate.getMonth()],
                  })}
                </BdsTypo>
                {currentMonth && (
                  <BdsPaper className="partialUsage" elevation="static">
                    <BdsTypo variant="fs-12" bold="semi-bold">
                      {content.monthlyConsumption.partialUsage}
                    </BdsTypo>
                  </BdsPaper>
                )}
              </Styles.MonthlyConsumptionTitle>
              <BdsTypo className="subtitle" variant="fs-14">
                {isFirstDayOfMonth()
                  ? content.formatString(content.firstDayOfMonth, {
                      month: content.monthlyConsumption.month[startDate.getMonth()],
                    })
                  : content.formatString(content.monthlyConsumption.period, {
                      startDate: startDate.toLocaleDateString(content.locale),
                      endDate: endDate.toLocaleDateString(content.locale),
                    })}
              </BdsTypo>
              <BdsTypo margin={true} variant="fs-14">
                {!isFirstDayOfMonth() ? content.monthlyConsumption.endInfo : ''}
              </BdsTypo>
            </div>
            <BdsSelect
              onBdsChange={updateMonthlyConsumption}
              value={startDate.toISOString()}
              label={content.monthlyConsumption.selectPlaceholder}
            >
              {generateMonthlyConsumptionOptions}
            </BdsSelect>
          </Styles.MonthlyConsumptionInfo>
          {isloading ? (
            <BlipLoadingComponent />
          ) : (
            <>
              {monthlyConsumption && (
                <Styles.MonthlyConsumptionMetrics>
                  {contractRules?.mainRuleName.includes(MIUS_UNIT) &&
                    (contractRules?.miu.includes(MONTHLY_ACTIVE_USERS_UNIT) ||
                      contractRules?.miu.includes(MONTHLY_ENGAGED_USERS_UNIT)) && (
                      <Styles.MetricCard currentMonth={currentMonth}>
                        <BdsTypo variant="fs-16" bold="bold">
                          {content.formatString(content.monthlyConsumption.metricTitle, { metric: contractRules?.miu })}
                        </BdsTypo>
                        <BdsTypo variant="fs-32" bold="extra-bold" tag="span">
                          {!isFirstDayOfMonth() && (
                            <NumberFormat
                              value={monthlyConsumption.consumption}
                              displayType={'text'}
                              thousandSeparator={content.thousandSeparator}
                              decimalSeparator={content.decimalSeparator}
                            />
                          )}
                          {isFirstDayOfMonth() && '--'} {contractRules?.miu}
                        </BdsTypo>
                      </Styles.MetricCard>
                    )}
                  {contractRules?.mainRuleName.includes(MESSAGES_UNIT) && (
                    <Styles.MetricCard currentMonth={currentMonth}>
                      <BdsTypo variant="fs-16" bold="bold">
                        {content.formatString(content.monthlyConsumption.metricTitle, {
                          metric: content.monthlyConsumption.messages,
                        })}
                      </BdsTypo>
                      <BdsTypo variant="fs-32" bold="extra-bold" tag="span">
                        {!isFirstDayOfMonth() && (
                          <NumberFormat
                            value={monthlyConsumption.messages}
                            displayType={'text'}
                            thousandSeparator={content.thousandSeparator}
                            decimalSeparator={content.decimalSeparator}
                          />
                        )}
                        {isFirstDayOfMonth() && '--'} MSGs
                      </BdsTypo>
                    </Styles.MetricCard>
                  )}
                </Styles.MonthlyConsumptionMetrics>
              )}
            </>
          )}
        </Styles.MonthlyConsumption>
      </BdsPaper>

      {!isFirstDayOfMonth() && (
        <BdsPaper elevation="static">
          <Styles.ChatbotConsumption>
            <Styles.ChatbotConsumptionInfo>
              <div>
                <BdsTypo variant="fs-24" bold="bold" tag="h3">
                  {content.chatbotsConsumption.title}
                </BdsTypo>
                <BdsTypo variant="fs-14">
                  {content.formatString(content.chatbotsConsumption.info, { metric: contractRules.miu })}
                </BdsTypo>
              </div>
              <Link to={{ pathname: '/revenue/chatbots', state: { fromContractConsumption: true } }}>
                <BdsButton variant="ghost" arrow={true} size="short" onClick={chatbotsClick}>
                  {content.chatbotsConsumption.button}
                </BdsButton>
              </Link>
            </Styles.ChatbotConsumptionInfo>

            <BlipTable
              idKey="name"
              model={chatbotTableModel}
              data={chatbotsConsumption}
              selectedItems={[]}
              onSortSet={sortChatbots}
              bodyHeight="240px"
              loading={isloading}
            />
          </Styles.ChatbotConsumption>
        </BdsPaper>
      )}

      {showContractRules && !isFirstDayOfMonth() && (
        <BdsPaper elevation="static">
          <Styles.ContractRules>
            <Styles.ContractRulesUpper>
              <div>
                <BdsTypo variant="fs-24" bold="bold" tag="h3">
                  {content.contractRules.title}
                </BdsTypo>
                <BdsTypo variant="fs-14">{content.contractRules.info}</BdsTypo>
              </div>
              <Styles.ContractDetails>
                <div>
                  <BdsTypo variant="fs-16" bold="bold">
                    {content.contractRules.consideredMetric}
                    <br />
                  </BdsTypo>
                  <BdsTypo variant="fs-12">{contractRules.miu}</BdsTypo>
                </div>
                <div>
                  <BdsTypo variant="fs-16" bold="bold">
                    {content.formatString(content.contractRules.plan, { plan: tenantPlan })}
                    <br />
                  </BdsTypo>
                  <BdsTypo variant="fs-12">
                    {content.formatString(content.contractRules.baseValue, {
                      value: (
                        <NumberFormat
                          value={contractRules.planBase}
                          displayType={'text'}
                          thousandSeparator={content.thousandSeparator}
                          decimalSeparator={content.decimalSeparator}
                          prefix={'R$ '}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      ),
                    })}
                  </BdsTypo>
                </div>
                <div>
                  <BdsTypo variant="fs-16" bold="bold">
                    {content.contractRules.startDateTitle}
                    <br />
                  </BdsTypo>
                  <BdsTypo variant="fs-12">{new Date(tenant.info.creationDate).toLocaleDateString()}</BdsTypo>
                </div>
              </Styles.ContractDetails>
            </Styles.ContractRulesUpper>

            <Styles.ContractRulesBottom>
              <Styles.ContractRulesCard>
                <div>
                  <BdsTypo variant="fs-16" bold="bold">
                    {content.contractRules.usageMetricsTitle}
                    <br />
                  </BdsTypo>
                  <BdsTypo variant="fs-12">{content.contractRules.usageMetricsInfo}</BdsTypo>
                  <br />
                </div>
                <div>
                  <BdsTypo variant="fs-12" bold="bold">
                    {content.contractRules.advantageTitle}
                    <br />
                  </BdsTypo>
                  <BdsTypo variant="fs-12">{content.contractRules.advantageInfo}</BdsTypo>
                  <br />
                </div>
                <div>
                  <BdsTypo variant="fs-12" bold="bold">
                    {content.contractRules.consideredTitle}
                    <br />
                  </BdsTypo>
                  <BdsTypo variant="fs-12">
                    {content.contractRules.consideredMIU}
                    <br />
                    {content.contractRules.consideredMAU}
                    <br />
                    {content.contractRules.consideredMEU}
                    <br />
                    {content.contractRules.consideredMSG}
                  </BdsTypo>
                </div>
              </Styles.ContractRulesCard>

              <Styles.ContractRulesReferenceValues>
                <div>
                  <BdsTypo variant="fs-16" bold="bold">
                    {content.contractRules.referenceTable.title}
                    <br />
                  </BdsTypo>
                  <BdsTypo variant="fs-14">{content.contractRules.referenceTable.info}</BdsTypo>
                </div>
                <Styles.ReferenceTable>
                  <BlipTable
                    idKey="calculation"
                    model={referenceTableModel}
                    data={referenceValues}
                    selectedItems={[]}
                    bodyHeight="200px"
                    loading={isloading}
                  />
                </Styles.ReferenceTable>
              </Styles.ContractRulesReferenceValues>
            </Styles.ContractRulesBottom>
          </Styles.ContractRules>
        </BdsPaper>
      )}
    </Styles.RevenueContainer>
  );
};

export default ContractConsumptionComponent;
