import React, { useMemo } from 'react';
import { EmptyChartIllustration, EmptyState } from '../styles';
import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';

function ListEmpty({ translate, status, onChange }) {
  const { titleEnabled, subTitleEnabled, buttonDisabled, titleDisabled, subTitleDisabled, buttonEnabled } = translate;
  const listEmptyText = useMemo(() => {
    if (status === 'true') {
      return { title: titleEnabled, subTitle: subTitleEnabled, button: buttonDisabled };
    }
    return { title: titleDisabled, subTitle: subTitleDisabled, button: buttonEnabled };
  }, [status]);
  return (
    <EmptyState>
      <BdsGrid direction="column" align-items="center">
        <EmptyChartIllustration name="robot-2" type="default" />
        <BdsTypo className="flex mb2 mt3" variant="fs-24" bold="bold">
          {listEmptyText.title}
        </BdsTypo>
        <BdsTypo className="flex mb2 label" variant="fs-20">
          {listEmptyText.subTitle}
        </BdsTypo>
        <BdsButton onClick={() => onChange()}>{listEmptyText.button}</BdsButton>
      </BdsGrid>
    </EmptyState>
  );
}

export default ListEmpty;
