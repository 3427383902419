import React from 'react';
import PropTypes from 'prop-types';
import { BdsTypo, BdsButton, BdsPaper} from 'blip-ds/dist/blip-ds-react';
import { BlipTable } from '../BlipTable';
import { BlipSearch, NotFound } from '../BlipSearchDs';
import { BlipCheckBox } from '../BlipCheckBox';

const AgentContractComponent = ({
  agents,
  content,
  tableModel,
  filter,
  search,
  sort,
  canEdit,
  togleModalAdd,
  filterTriggered,
  filterEnabled,
  loading,
}) => {
  return (
    <>
      <div className="AgentContract flex flex-row mb4 items-center" >
        <span className="w-50 flex items-center justify-start ">
          <BdsTypo variant="fs-24" tag="span" bold="semi-bold" className="mr4">
            {content.title}
          </BdsTypo>
          <BlipCheckBox checked={filterTriggered} toggleCheck={(e) => filterEnabled(e.target.checked)} />
          <span>{content.checkEnabled}</span>
        </span>
        <span className="w-50 flex items-center justify-end">
          <BlipSearch onChange={filter} search={search} debounce={500} />
          {canEdit && search.length === 0 && (
            <BdsButton icon="plus" className="ml2" onClick={togleModalAdd} size="standard">
              {content.add}
            </BdsButton>
          )}
        </span>
      </div>
      <div className='pb4'>
        {agents.length < 1 && search.length > 0 ? (
          <NotFound title={content.searchNotFound.title} subTitle={content.searchNotFound.subTitle} />
        ) : (
          <BdsPaper elevation="secondary">
            <BlipTable 
              idKey="userIdentity"
              model={tableModel}
              data={agents.map((agent) => ({ ...agent, roleId: content[agent.roleId] }))}
              onSortSet={sort}
              canSelect={false}
              bodyHeight="440px"
              loading={loading}
              selectedItems={[]}
            />
          </BdsPaper>
        )}
      </div>
    </>
  );
};

AgentContractComponent.propTypes = {
  agents: PropTypes.arrayOf(Object).isRequired,
  content: PropTypes.object.isRequired,
  tableModel: PropTypes.array.isRequired,
  filter: PropTypes.func.isRequired,
  sort: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  remove: PropTypes.func,
};

export { AgentContractComponent };
