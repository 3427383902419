import { BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import { FC, useContext, useEffect, useMemo } from 'react';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { createTrack } from '../../../api/SegmentApi';
import { DateDropdown } from '../../../components/DateDropdown';
import AgentConsumptionContext from '../../../contexts/AgentConsumptionContext';
import { useCommon } from '../../../contexts/CommonContext';
import { AGENT_CONSUMPTION_PERIOD_SELECT } from '../../../shared/SegmentEvents';
import {
  getFirstDayOfSpecifiedMonth,
  getLastDayOfSpecifiedMonth,
  getMonthTwoDigits,
  toUTCDateString,
} from '../../../utils/date';
import AgentConsumptionProgressContent from '../components/AgentConsumptionProgressContent';
import {
  AgentConsumptionPaper,
  AgentConsumptionConsolidatedHeaderRow,
  AgentConsumptionConsolidatedHeaderPartial,
  AgentConsumptionConsolidatedPeriodRow,
  AgentConsumptionConsolidatedFilterText,
  AgentConsumptionConsolidatedRow,
  AgentConsumptionConsolidatedContent,
  AgentConsumptionProgressContentAligned,
  AgentConsumptionConsolidatedContentAligned,
  AgentConsumptionWarningContentAligned,
} from '../styles';

const AgentConsumptionConsolidatedInformationsComponent: FC = () => {
  const {
    content,
    tenant,
    loggedUser,
    dateToSearch,
    currentMonth,
    periodOptions,
    billingAgentConsumptionApi,
    setDateToSearch,
    compiledAgentConsumptionDataState,
    setCompiledAgentConsumptionDataState,
  } = useContext(AgentConsumptionContext);
  const { setHeaderContent } = useCommon();

  useEffect(() => {
    const getData = async () => {
      startLoading();
      setHeaderContent({
        redirect: '/',
        text: content.formatString(content.header, tenant.info.name),
      });
      const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;

      const consolidatedAgentConsumption = await billingAgentConsumptionApi.getConsolidatedAgentConsumption(
        tenant.info.id,
        monthString,
      );

      const consolidatedContainsDataOnSelectedMonth = consolidatedAgentConsumption.metric.quantity > 0;
      setCompiledAgentConsumptionDataState({
        consolidatedAgentConsumption: consolidatedAgentConsumption,
        consolidatedContainsDataOnSelectedMonth: consolidatedContainsDataOnSelectedMonth,
      });
      stopLoading();
    };

    getData();
  }, [dateToSearch]);

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    createTrack(tenant.info, AGENT_CONSUMPTION_PERIOD_SELECT, {
      agentConsumptionDate:
        dateToRetrieveData.getFullYear() + '-' + ('0' + (dateToRetrieveData.getMonth() + 1)).slice(-2),
      agentConsumptionUser: loggedUser.email,
    });
  };

  const dateContent = useMemo(() => {
    return content.formatString(content.consolidated.period, {
      startDate: toUTCDateString(getFirstDayOfSpecifiedMonth(dateToSearch), content.locale, true),
      endDate: toUTCDateString(
        currentMonth ? new Date() : getLastDayOfSpecifiedMonth(dateToSearch),
        content.locale,
      ),
    });
  }, [compiledAgentConsumptionDataState]);

  const planContent = useMemo(() => {
    if (compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth) {
      return content.formatString(
        content.consolidated.planInformation,
        compiledAgentConsumptionDataState.consolidatedAgentConsumption.metric.quantity.toLocaleString(),
      );
    } else {
      return content.formatString(content.consolidated.planInformation, '--');
    }
  }, [compiledAgentConsumptionDataState]);

  return (
    <AgentConsumptionPaper>
      <div>
        <AgentConsumptionConsolidatedHeaderRow>
          <div>
            <AgentConsumptionConsolidatedHeaderPartial>
              <BdsTypo className="title" tag="h3" variant="fs-24" bold="bold">
                {content.formatString(content.consolidated.header, content.consolidated.month[dateToSearch.getMonth()])}
              </BdsTypo>
              {currentMonth && (
                <BdsPaper className="partialUsage" elevation="static">
                  <BdsTypo variant="fs-12" bold="semi-bold">
                    {content.consolidated.partialUsage}
                  </BdsTypo>
                </BdsPaper>
              )}
            </AgentConsumptionConsolidatedHeaderPartial>
            <BdsTypo className="subtitle" variant="fs-14">
              {dateContent}
            </BdsTypo>
          </div>
          <DateDropdown
            value={dateToSearch.toISOString()}
            bdsChangeEvent={periodOptionChangeEvent}
            options={periodOptions}
            label={content.monthPlaceholder}
          ></DateDropdown>
        </AgentConsumptionConsolidatedHeaderRow>
        <AgentConsumptionConsolidatedPeriodRow>
          <BdsTypo variant="fs-14"> {content.consolidated.endInfo}</BdsTypo>
        </AgentConsumptionConsolidatedPeriodRow>
        <AgentConsumptionConsolidatedFilterText>
          <BdsTypo tag="h4" variant="fs-20" bold="bold" margin={false}>
            {planContent}
          </BdsTypo>
          <BdsTypo variant="fs-14"> {content.consolidated.filter}</BdsTypo>
        </AgentConsumptionConsolidatedFilterText>
        <AgentConsumptionConsolidatedRow>
          <AgentConsumptionProgressContentAligned>
            <AgentConsumptionProgressContent />
          </AgentConsumptionProgressContentAligned>
          <AgentConsumptionConsolidatedContentAligned>
            <AgentConsumptionConsolidatedContent currentMonth={currentMonth}>
              <BdsTypo margin={true} bold="bold" variant="fs-16">
                {content.consolidated.exceededAgent}
              </BdsTypo>
              <BdsTypo
                data-exceededPlan={compiledAgentConsumptionDataState.consolidatedAgentConsumption.exceededPlan}
                variant="fs-32"
                bold="extra-bold"
                margin={false}
              >
                {compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth
                  ? compiledAgentConsumptionDataState.consolidatedAgentConsumption.exceededPlan.toLocaleString(
                    content.locale,
                  )
                  : '--'}
              </BdsTypo>
            </AgentConsumptionConsolidatedContent>
            <AgentConsumptionConsolidatedContent currentMonth={currentMonth}>
              <BdsTypo margin={true} bold="bold" variant="fs-16">
                {content.consolidated.totalAgent}
              </BdsTypo>
              <BdsTypo
                data-total={compiledAgentConsumptionDataState.consolidatedAgentConsumption.total}
                variant="fs-32"
                bold="extra-bold"
                margin={false}
              >
                {compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth
                  ? compiledAgentConsumptionDataState.consolidatedAgentConsumption.total.toLocaleString(content.locale)
                  : '--'}
              </BdsTypo>
            </AgentConsumptionConsolidatedContent>
          </AgentConsumptionConsolidatedContentAligned>
        </AgentConsumptionConsolidatedRow>
        {compiledAgentConsumptionDataState.consolidatedAgentConsumption.exceededPlan > 0 && (
          <AgentConsumptionWarningContentAligned currentMonth={currentMonth}>
            <BdsTypo bold="bold" variant="fs-14" className="highlighted">
              {content.formatString(
                content.consolidated.warningExceededAgentTitle,
                compiledAgentConsumptionDataState.consolidatedContainsDataOnSelectedMonth
                  ? compiledAgentConsumptionDataState.consolidatedAgentConsumption.metric.quantity.toLocaleString(
                    content.locale,
                  )
                  : '--',
              )}
            </BdsTypo>
            <BdsTypo variant="fs-14">{content.consolidated.warningExceededAgentSubtitle}</BdsTypo>
          </AgentConsumptionWarningContentAligned>
        )}
      </div>
    </AgentConsumptionPaper>
  );
};

export default AgentConsumptionConsolidatedInformationsComponent;
