const agentTriggered = (agents) => {
  return agents.filter((a) => a.triggered === true).length;
};

const verifyAgentBillingAdditional = (agents = [], subscription = []) => {
  const agentsSubscriptionNumber = subscription ? subscription.find((s) => s.metricId === 'Agent')?.quantity : 0;
  return agentsSubscriptionNumber <= agentTriggered(agents);
};

export { verifyAgentBillingAdditional, agentTriggered };
