import React, { useContext, useEffect } from 'react';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import { useCommon } from '../../../contexts/CommonContext';
import DailyActiveUsersContext from '../../../contexts/DailyActiveUsersContext';
import { DailyActiveUserContainer } from '../styles';
import CompiledDailyActiveUsers from './CompiledDailyActiveUsers';
import DailyActiveUserMonthlyUsage from './DailyActiveUserMonthlyUsage';
import DailyActiveUserUsage from './DailyActiveUserUsage';

const DailyActiveUsersSummaryComponent: React.FC = () => {
  const { tenant, content } = useContext(DailyActiveUsersContext);
  const { setHeaderContent } = useCommon();

  useEffect(() => {
    startLoading();
    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });

    stopLoading();

    return () => setHeaderContent({ redirect: null });
  }, []);

  return (
    <DailyActiveUserContainer>
      <CompiledDailyActiveUsers></CompiledDailyActiveUsers>
      <DailyActiveUserMonthlyUsage tableBodyHeight="1000px" registersToTake={5}></DailyActiveUserMonthlyUsage>
      <DailyActiveUserUsage tableBodyHeight="1000px" registersToTake={5}></DailyActiveUserUsage>
    </DailyActiveUserContainer>
  );
};

export default DailyActiveUsersSummaryComponent;
