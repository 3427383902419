import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useContext } from 'react';
import { useDauConsumptionContext } from '../../../../contexts/Billing/DauConsumptionContext';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { CompiledDailyActiveUsersTotalConsumedPaperContent } from '../styles';

const CompiledDailyActiveUserOverspentContentComponent: React.FC = () => {
  const { content, currentMonth } = useContext(DailyActiveUsersContext);
  const { compiledDailyActiveUserDataState } = useDauConsumptionContext();
  const compiledContainsDataOnSelectedMonth = compiledDailyActiveUserDataState.compiledContainsDataOnSelectedMonth;
  const exceededPlan = compiledDailyActiveUserDataState.compiledDailyActiveUserConsumption.exceededPlan;

  return (
    <CompiledDailyActiveUsersTotalConsumedPaperContent className="bg-color-surface-2">
      <BdsTypo tag="h4" variant="fs-16" bold="bold" margin={false}>
        {content.compiledDailyActiveUserInformation.overspentMessages}
      </BdsTypo>
      <BdsTypo
        tag="h4"
        variant="fs-32"
        bold="bold"
        margin={false}
        data-exceededPlan={exceededPlan}
        className={currentMonth ? 'color-content-default' : 'color-primary'}
      >
        {exceededPlan >= 0 && compiledContainsDataOnSelectedMonth ? exceededPlan.toLocaleString(content.locale) : '--'}
      </BdsTypo>
    </CompiledDailyActiveUsersTotalConsumedPaperContent>
  );
};

export default CompiledDailyActiveUserOverspentContentComponent;
