import React, { FC, useContext, useEffect } from 'react';
import { useCommon } from '../../../../../contexts/CommonContext';
import DailyActiveUsersContext from '../../../../../contexts/DailyActiveUsersContext';
import { useContentLocalizer } from '../../../../../hooks/useContentLocalizer';
import DailyActiveUserMonthlyUsage from '../../../DailyActiveUsers/components/DailyActiveUserMonthlyUsage';
import { localization } from '../../localization';
import { DailyActiveUserContainer } from '../../styles';

const MonthlyUsageComponent: FC = () => {
  const { dateToSearch, tenant } = useContext(DailyActiveUsersContext);
  const { setHeaderContent } = useCommon();
  const content = useContentLocalizer(localization);

  useEffect(() => {
    setHeaderContent({
      redirect: '/dau-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: null });
  }, [dateToSearch]);

  return (
    <DailyActiveUserContainer>
      <DailyActiveUserMonthlyUsage tableBodyHeight="1000px" registersToTake={-1}></DailyActiveUserMonthlyUsage>
    </DailyActiveUserContainer>
  );
};

export default MonthlyUsageComponent;
