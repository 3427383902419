import React, { FC, useContext, useEffect, useMemo } from 'react';
import DataConsumptionContext from '../../../../../contexts/Billing/DataConsumptionContext';
import DataConsumptionMetricContext from '../../../../../contexts/Billing/DataConsumptionMetricContext';
import { InfoPaper } from '../../../components/ConsumptionData/InfoPaper';
import { ConsolidatedPaper } from '../../../components/ConsumptionData/ConsolidatedPaper';
import { LeftInfoPaperArea, RightInfoPaperArea } from '../../styles';
import { useContentLocalizer } from '../../../../../hooks/useContentLocalizer';
import { getOrdinal } from '../../../../../utils/strings';
import { roundNextHundredth } from '../../../../../utils/numbers';
import { toUTCDateString } from '../../../../../utils/date';
import { BillingMetrics } from '../../../../../shared/BillingMetrics';
import { trackEvent } from '../../../../../api/Billing/Tracking';
import { useBillingPlanUsageContext } from '../../../../../contexts/Billing/BillingPlanUsageContext';
import { billingRoutes } from '../../../../../routes/BillingRoutes';
import {
  action,
  REPORT_REVENUE_CONSUMPTION_OPEN,
  REPORT_REVENUE_PLAN_CONSUMPTION_CHART_HOVER,
  REPORT_REVENUE_PLAN_CONSUMPTION_TOOLTIP_HOVER,
  REPORT_REVENUE_TITLE_TOOLTIP_HOVER,
  REPORT_REVENUE_WEEKLY_CONSUMPTION_CHART_HOVER,
} from '../../../../../shared/SegmentEvents';
import colors from '../../../../../_charts.module.scss';


const MiuConsumptionComponent: FC = () => {
  const ROUND_UP_PERCENT = 1.25;

  const { dateToSearch, hasEnabledMetric, tenant, loggedUser, trackingProps } = useContext(DataConsumptionContext);
  const { compiledMiuConsumptionDataState, isMiuLoading, consolidatedMiuApiRequisition, miuMetricIds, localization } =
    useContext(DataConsumptionMetricContext);
  const { planStatus } = useBillingPlanUsageContext();
  const consolidatedMiuConsumption = compiledMiuConsumptionDataState.consolidatedMiuConsumption;

  const consolidatedContainsDataOnSelectedMonth =
    compiledMiuConsumptionDataState.consolidatedContainsDataOnSelectedMonth;

  useEffect(() => {
    consolidatedMiuApiRequisition(dateToSearch, miuMetricIds, hasEnabledMetric);
  }, [dateToSearch]);

  const content = useContentLocalizer(localization.dashLocalization);

  const getLeftChartLabel = (metricId: BillingMetrics) => {
    return metricId === BillingMetrics.exchangedMessage
      ? content.formatString(content.leftChart.labels.msg)
      : content.formatString(content.leftChart.labels.miu);
  };

  const getLeftChartCustomLabel = (metricId: BillingMetrics) => {
    return metricId === BillingMetrics.exchangedMessage
      ? content.formatString(content.leftChart.customLabels.msg)
      : content.formatString(content.leftChart.customLabels.miu);
  };

  const getCustomColor = (metricId: BillingMetrics) => {
    return metricId === BillingMetrics.exchangedMessage
      ? colors.Brand
      : colors.System;
  };

  const arrWeekItems = consolidatedMiuConsumption.map((item) => item.weekItems);
  const arrLeftChartTotal = consolidatedMiuConsumption.map((item) => item.total);
  const arrLeftChartCustomLabel = consolidatedMiuConsumption.map((item) => {
    return {
      backgroundColor: getCustomColor(item.metric.metricId),
      text: getLeftChartCustomLabel(item.metric.metricId),
    };
  });

  const arrLeftChartLabel = consolidatedMiuConsumption.map((item) => getLeftChartLabel(item.metric.metricId));
  const arrLeftChartColor = arrLeftChartCustomLabel.map((item) => item.backgroundColor);

  let chartTooltipCount = 0;
  const leftChartData = {
    chartType: 'BarChart',
    title: content.leftChart.title,
    tooltipIcon: 'info',
    tooltipText: content.leftChart.tooltipText,
    tooltipTrackEvent: () => {
      if (chartTooltipCount < 1) {
        trackEvent(REPORT_REVENUE_PLAN_CONSUMPTION_TOOLTIP_HOVER, tenant, loggedUser, {
          ...trackingProps,
          action: action.HOVER,
        });
        chartTooltipCount++;
      }
    },
    labels: arrLeftChartLabel,
    customLabels: arrLeftChartCustomLabel,
    datasets: [
      {
        data: arrLeftChartTotal,
        backgroundColor: arrLeftChartColor,
      },
    ],
  };

  let leftCount = 0;
  const arrTotal = arrLeftChartTotal;
  const maxArrTotal = Math.max(...arrTotal) > 0 ? Math.max(...arrTotal) : 1;
  const leftChartOptions = {
    maintainAspectRatio: false,
    barThickness: 15,
    locale: content.locale,
    onHover: function (evt) {
      if (evt.type === 'mousemove' && leftCount < 1) {
        trackEvent(REPORT_REVENUE_PLAN_CONSUMPTION_CHART_HOVER, tenant, loggedUser, trackingProps);
        leftCount++;
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: consolidatedContainsDataOnSelectedMonth
        ? { min: 0, max: roundNextHundredth(maxArrTotal * ROUND_UP_PERCENT) }
        : { min: 0 },
    },
  };

  const getRightChartCustomLabel = (metricId: BillingMetrics) => {
    return metricId === BillingMetrics.exchangedMessage
      ? content.formatString(content.rightChart.customLabels.msg)
      : content.formatString(content.rightChart.customLabels.miu);
  };

  const weekItemsLabel = useMemo(() => {
    return arrWeekItems[0].map((item) => {
      return content.formatString(content.rightChart.period, {
        startDate: toUTCDateString(item.startDateReference, content.locale, false),
        endDate: toUTCDateString(item.endDateReference, content.locale, false),
      });
    });
  }, [arrWeekItems]);

  const labels = weekItemsLabel;

  const arrRightChartCustomLabel = consolidatedMiuConsumption.map((item) => {
    return {
      backgroundColor: getCustomColor(item.metric.metricId),
      text: getLeftChartCustomLabel(item.metric.metricId),
    };
  });

  const arrRightChartDataset = consolidatedMiuConsumption.map((item) => {
    return {
      label: getRightChartCustomLabel(item.metric.metricId),
      data: item.weekItems.map((item) => item.total),
      backgroundColor: getCustomColor(item.metric.metricId),
    };
  });

  const rightChartData = {
    chartType: 'BarChart',
    title: content.rightChart.title,
    labels,
    customLabels: arrRightChartCustomLabel,

    datasets: arrRightChartDataset,

    warningLabelBold: content.rightChart.warningLabel.bold,
    warningLabelRegular: content.rightChart.warningLabel.regular,
  };

  let rightCount = 0;
  const arrWeekTotal = arrWeekItems.map((arrItem) => arrItem.map((item) => item.total)).flat(1);
  const maxArrWeekTotal = Math.max(...arrWeekTotal) > 0 ? Math.max(...arrWeekTotal) : 1;
  const rightChartOptions = {
    maintainAspectRatio: false,
    barThickness: 8,
    locale: content.locale,
    onHover: function (evt) {
      if (evt.type === 'mousemove' && rightCount < 1) {
        trackEvent(REPORT_REVENUE_WEEKLY_CONSUMPTION_CHART_HOVER, tenant, loggedUser, trackingProps);
        rightCount++;
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: consolidatedContainsDataOnSelectedMonth
        ? { min: 0, max: roundNextHundredth(maxArrWeekTotal * ROUND_UP_PERCENT) }
        : { min: 0 },
    },
  };

  const redraw = false;
  const leftChart = { leftChartData, leftChartOptions, redraw };
  const rightChart = { rightChartData, rightChartOptions, redraw };

  let titleTooltipCount = 0;

  const header = {
    title: content.header.title,
    subtitle: content.header.description,
    buttonTitle: content.header.showMoreButton,
    tooltipIcon: 'info',
    tooltipText: content.header.tooltipText,
    pathname: billingRoutes.mauConsumption,
    idTest: 'show-more-report-miu-consumption',
    buttonTrackEvent: () =>
      trackEvent(REPORT_REVENUE_CONSUMPTION_OPEN, tenant, loggedUser, { ...trackingProps, action: action.CLICK }),
    tooltipTrackEvent: () => {
      if (titleTooltipCount < 1) {
        trackEvent(REPORT_REVENUE_TITLE_TOOLTIP_HOVER, tenant, loggedUser, { ...trackingProps, action: action.HOVER });
        titleTooltipCount++;
      }
    },
  };

  const consolidatedMetric = miuMetricIds.map((metricId) => {
    return {
      metricId: metricId,
      total: consolidatedMiuConsumption.find((item) => item.metric.metricId === metricId)?.total || 0,
      topWeekNumber: consolidatedMiuConsumption.find((item) => item.metric.metricId === metricId)?.topWeekNumber || 0,
      weeklyAverage: consolidatedMiuConsumption.find((item) => item.metric.metricId === metricId)?.weeklyAverage || 0,
    };
  });

  const totalMiu = consolidatedMetric.find((item) => item.metricId !== BillingMetrics.exchangedMessage)?.total;
  const totalMsg = consolidatedMetric.find((item) => item.metricId === BillingMetrics.exchangedMessage)?.total;

  const topWeekNumberMiu = consolidatedMetric.find(
    (item) => item.metricId !== BillingMetrics.exchangedMessage,
  )?.topWeekNumber;
  const topWeekNumberMsg = consolidatedMetric.find(
    (item) => item.metricId === BillingMetrics.exchangedMessage,
  )?.topWeekNumber;

  const weeklyAverageMiu = consolidatedMetric.find(
    (item) => item.metricId !== BillingMetrics.exchangedMessage,
  )?.weeklyAverage;
  const weeklyAverageMsg = consolidatedMetric.find(
    (item) => item.metricId === BillingMetrics.exchangedMessage,
  )?.weeklyAverage;

  const leftInfoPaper = useMemo(() => {
    return (
      <LeftInfoPaperArea>
        {totalMiu >= 0 && (
          <InfoPaper
            justifyContent={'flex-start'}
            classNameTitle={'flex-start-margin'}
            icon={'user-engaged'}
            title={consolidatedContainsDataOnSelectedMonth ? totalMiu.toLocaleString(content.locale) : '--'}
            text={content.leftInfoPaper.totalMiu.description}
          ></InfoPaper>
        )}
        {totalMsg >= 0 && (
          <InfoPaper
            justifyContent={'flex-start'}
            classNameTitle={'flex-start-margin'}
            icon={'message-talk'}
            title={consolidatedContainsDataOnSelectedMonth ? totalMsg.toLocaleString(content.locale) : '--'}
            text={content.leftInfoPaper.totalMsg.description}
          ></InfoPaper>
        )}
        {topWeekNumberMsg >= 0 && (
          <InfoPaper
            justifyContent={'flex-start'}
            classNameTitle={'flex-start-margin'}
            icon={'calendar'}
            title={
              consolidatedContainsDataOnSelectedMonth
                ? content.formatString(
                    content.leftInfoPaper.topWeekNumberMsg.label,
                    getOrdinal(topWeekNumberMsg, content.locale, 'f'),
                  )
                : '--'
            }
            text={content.leftInfoPaper.topWeekNumberMsg.description}
            infoPaperType={'weekly'}
            bdsPaper={false}
          ></InfoPaper>
        )}
      </LeftInfoPaperArea>
    );
  }, [planStatus.current, compiledMiuConsumptionDataState]);

  const rightInfoPapers = useMemo(() => {
    return (
      <RightInfoPaperArea>
        {weeklyAverageMiu >= 0 && (
          <InfoPaper
            justifyContent={'flex-start'}
            classNameTitle={'flex-start-margin'}
            icon={'user-engaged'}
            title={consolidatedContainsDataOnSelectedMonth ? weeklyAverageMiu.toLocaleString(content.locale) : '--'}
            text={content.rightInfoPaper.weeklyMiuAverage.description}
            bdsPaper={false}
          ></InfoPaper>
        )}
        {weeklyAverageMsg >= 0 && (
          <InfoPaper
            justifyContent={'flex-start'}
            classNameTitle={'flex-start-margin'}
            icon={'message-talk'}
            title={consolidatedContainsDataOnSelectedMonth ? weeklyAverageMsg.toLocaleString(content.locale) : '--'}
            text={content.rightInfoPaper.weeklyMsgAverage.description}
            bdsPaper={false}
          ></InfoPaper>
        )}
        {topWeekNumberMiu >= 0 && (
          <InfoPaper
            justifyContent={'flex-start'}
            classNameTitle={'flex-start-margin'}
            icon={'calendar'}
            title={
              consolidatedContainsDataOnSelectedMonth
                ? content.formatString(
                    content.rightInfoPaper.topWeekNumberMiu.label,
                    getOrdinal(topWeekNumberMiu, content.locale, 'f'),
                  )
                : '--'
            }
            text={content.rightInfoPaper.topWeekNumberMiu.description}
            infoPaperType={'weekly'}
            bdsPaper={false}
          ></InfoPaper>
        )}
      </RightInfoPaperArea>
    );
  }, [compiledMiuConsumptionDataState]);

  return (
    <ConsolidatedPaper
      header={header}
      leftChart={leftChart}
      leftInfoPapers={leftInfoPaper}
      rightChart={rightChart}
      rightInfoPapers={rightInfoPapers}
      isLoading={isMiuLoading}
    ></ConsolidatedPaper>
  );
};

export default MiuConsumptionComponent;
