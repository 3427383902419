import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { BusinessInitiatedSession } from './BusinessInitiatedSession';
import { UserInitiatedSession } from './UserInitiatedSession';

export class MonthlySessionConsumption {
  phoneNumber!: string;
  businessInitiated!: BusinessInitiatedSession;
  userInitiated!: UserInitiatedSession;

  constructor(monthlySessionConsumption: Partial<MonthlySessionConsumption>) {
    this.phoneNumber = '';
    this.businessInitiated = {
      utility: 0,
      authentication: 0,
      marketing: 0,
      total: 0,
    };
    this.userInitiated = {
      service: 0,
      free: 0,
      total: 0,
    };

    Object.assign(this, monthlySessionConsumption);

    if (!this.phoneNumber.startsWith('+')) {
      this.phoneNumber = '+' + this.phoneNumber;
    }

    this.phoneNumber = formatPhoneNumberIntl(this.phoneNumber);
  }
}
