import React from 'react';
import { BdsLoadingSpinner } from 'blip-ds/dist/blip-ds-react';
import { LoadingStateWrapper } from '../styles';

const LoadingState: React.FC = () => {
  return (
    <LoadingStateWrapper justifyContent="center" alignItems="center">
      <BdsLoadingSpinner />
    </LoadingStateWrapper>
  );
};

export default LoadingState;
