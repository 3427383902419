import React, { useState } from 'react';
import { BdsPaper, BdsIcon, BdsTypo, BdsChipTag } from 'blip-ds/dist/blip-ds-react';
import { PaperElevation } from 'blip-ds/dist/types/components/paper/paper-interface';
import { BlipCardContainer, BlipCardTextContainer, BlipCardIconContainer } from './styles';

interface BlipCardProps {
  title: string;
  icon: string;
  description: string;
  tagNewFeature?: string;
  onClick: () => void;
}

const BlipCard: React.FC<BlipCardProps> = ({ title, icon, description, tagNewFeature, onClick }) => {
  const [elevation, setElevation] = useState<PaperElevation>('static');

  return (
    <BlipCardContainer
      onMouseEnter={() => setElevation('secondary')}
      onMouseLeave={() => setElevation('static')}
      onClick={onClick}
    >
      <BdsPaper elevation={elevation}>
        <BlipCardIconContainer className="bg-color-system">
          <BdsIcon name={icon} size="xx-large" className="color-primary"></BdsIcon>
        </BlipCardIconContainer>
        <BlipCardTextContainer>
          <div className='flex row justify-between'>
            <BdsTypo variant="fs-16" tag="p" bold="bold">
              {title}
            </BdsTypo>
            { tagNewFeature &&  <BdsChipTag color="success" size="small">
              {tagNewFeature}
            </BdsChipTag> }
          </div>
          <BdsTypo variant="fs-14" tag="p" bold="regular">
            {description}
          </BdsTypo>
        </BlipCardTextContainer>
      </BdsPaper>
    </BlipCardContainer>
  );
};

export default BlipCard;
