export const localization = {
  pt: {
    locale: 'pt-BR',
    header: 'Dados de consumo do contrato {0} por atendente',
    monthPlaceholder: 'Período',
    consolidated: {
      partialUsage: 'Consumo parcial',
      header: 'Atendentes acionados em {0}',
      period: 'De {startDate} a {endDate}',
      endInfo: '*O fechamento de um mês estará disponível em até 3 dias úteis contados do último dia desse mesmo mês.',
      filter: 'Use o filtro para ver o total de atendentes acionados em cada mês.',
      planInformation: 'Plano {0} atendentes',
      exceededAgent: 'Atendentes excedentes',
      totalAgent: 'Total de atendentes',
      progressContentTitle: 'Atendentes acionados incluídos no plano',
      progressContentHoverIcon: 'Atendente acionado é aquele que realizou um ou mais atendimentos neste período.',
      warningExceededAgentTitle: 'O limite de {0} atendentes acionados do plano foi atingido.',
      warningExceededAgentSubtitle: 'Não se preocupe! Você pode seguir usando o seu plano como de costume. O número de atendentes excedentes será exibido a seguir.',
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    monthly:{
      title: 'Lista de atendentes acionados',
      subtitle: 'Acompanhe o consumo mensal',
      buttonShowAll: 'Mostrar detalhes',
      columnAgentId: 'Atendente',
      columnExtraCharge: 'Cobrança extra',
      columnNumTickets: 'N. de tickets',
      columnFirstRepliedTicket: 'Data do primeiro ticket atendido',
      columnLastRepliedTicket: 'Data do último ticket atendido',
      extraCharge:['Sim', 'Não'],
    },
  },
  en: {
    locale: 'en-US',
    header: '{0} contract consumption data per agent',
    monthPlaceholder: 'Time',
    consolidated: {
      partialUsage: 'Partial consumption',
      header: 'Activated agents in {0}',
      period: 'From {startDate} to {endDate}',
      endInfo: '*Monthly statements will become available within 3 business days of month end.',
      filter: 'Use the filter to view the total number of activated agents each month.',
      planInformation: 'Plan {0} agents',
      exceededAgent: 'Agent limit exceeded',
      totalAgent: 'Total number of agents',
      progressContentTitle: 'Activated agents included in the plan',
      progressContentHoverIcon: 'Activated agent is the one who replied to one or more tickets.',
      warningExceededAgentTitle: 'The limit of {0} activated agents in the plan was reached.',
      warningExceededAgentSubtitle: 'Don\'t worry! You can go on and use your plan as usual. The number of agents which exceed the limit will be displayed.',
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    monthly:{
      title: 'List of activated agents',
      subtitle: 'Keep track of monthly consumption',
      buttonShowAll: 'Show details',
      columnAgentId: 'Agent',
      columnExtraCharge: 'Extra charge',
      columnNumTickets: 'No. of tickets',
      columnFirstRepliedTicket: 'Date of the first replied ticket',
      columnLastRepliedTicket: 'Date of the last replied ticket', 
      extraCharge:['Yes', 'No'],
    },    
  },
  es: {
    locale: 'es-ES',
    header: 'Datos de consumo de contrato de {0} por asistente',
    monthPlaceholder: 'Intervalo',
    consolidated: {
      partialUsage: 'Consumo parcial',
      header: 'Asistentes activados en {0}',
      period: 'Del {startDate} al {endDate}',
      endInfo:
        '*Los estados de cuenta mensuales estarán disponibles dentro de los 3 días hábiles posteriores al final del mes.',
      filter: 'Utilice el filtro para ver el número total de agentes activados cada mes.',
      exceededAgent: 'Asistentes excedentes',
      planInformation: 'Plan {0} asistentes',
      totalAgent: 'Total de asistentes',
      progressContentTitle: 'Asistentes activados incluidos en el plan',
      progressContentHoverIcon: 'Asistente activado es aquel que respondió a uno o más tickets.',
      warningExceededAgentTitle: 'Se ha alcanzado el límite del plan de {0} agentes activados.',
      warningExceededAgentSubtitle: '¡No te preocupes! Puede usar su plan como de costumbre. Se mostrará el número de agentes que superan el límite.',      
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
    monthly:{
      title: 'Lista de asistentes activados',
      subtitle: 'Ver seguimiento de consumo mensual',
      buttonShowAll: 'Mostrar detalles',
      columnAgentId: 'Asistente',
      columnExtraCharge: 'Carga extra',
      columnNumTickets: 'N. de tickets',
      columnFirstRepliedTicket: 'Fecha del primer ticket respondido',
      columnLastRepliedTicket: 'Fecha del último ticket respondido',
      extraCharge:['Sí', 'No'],       
    },    
  },
};
