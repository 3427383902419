import React, { FC, useContext, useEffect, useState } from 'react';
import { BdsSelect } from 'blip-ds/dist/blip-ds-react';
import { useCommon } from '../../../../contexts/CommonContext';
import { capitalizeFirstLetter } from '../../../../utils/strings';
import { PageHeader } from '../../components/Metrics/PageHeader';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { RequestStatus } from '../../../../shared/Requests';
import { ConsolidatedCard } from '../../components/Metrics/ConsolidatedCard';
import { BlipLoadingComponent } from '../../../../components/BlipLoading/BlipLoadingComponent';
import SessionConsumptionContext from '../../../../contexts/SessionConsumptionContext';
import { ConsolidatedHeader } from '../../components/Metrics/ConsolidatedHeader';
import '../../../../typings/blip-ds.d';
import {
  REPORT_PERIOD_SELECT,
  REPORT_SESSION_CONSUMPTION_CONVERSATION_SELECT,
  REPORT_SESSION_CONSUMPTION_WABAID_SELECT,
} from '../../../../shared/SegmentEvents';
import { getMonthTwoDigits } from '../../../../utils/date';
import { SessionConsumptionConsolidatedRow, SessionConsumptionPaper } from '../styles';

const SessionConsumptionConsolidatedInformationsComponent: FC = () => {
  const {
    currentMonth,
    dateToSearch,
    oldCompiledSessionConsumptionDataState,
    setOldCompiledSessionConsumptionDataState,
    compiledSessionConsumptionDataState,
    setCompiledSessionConsumptionDataState,
    content,
    tenant,
    loggedUser,
    trackingProps,
    billingSessionConsumptionApi,
    conversationTypeFilter,
    conversationWabaFilter,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    setConversationTypeFilter,
    setConversationWabaFilter,
    periodOptions,
    setDateToSearch,
    getPlanStatus,
    isWabaloading,
    WHATSAPP_BP_PRICING_CHANGES_CONSOLIDATED_START_DATE,
    showConsolidatedSessionNewPricing,
    setConsolidatedSessionNewPricing,
  } = useContext(SessionConsumptionContext);
  const { setHeaderContent } = useCommon();
  const [isLoading, setLoading] = useState(false);
  const sessionByUser = oldCompiledSessionConsumptionDataState.oldConsolidatedSessionConsumption.sessionByUser;
  const sessionByCompany = oldCompiledSessionConsumptionDataState.oldConsolidatedSessionConsumption.sessionByCompany;
  const sessionTotal = oldCompiledSessionConsumptionDataState.oldConsolidatedSessionConsumption.sessionTotal;
  const oldConsolidatedContainsDataOnSelectedMonth =
    oldCompiledSessionConsumptionDataState.oldConsolidatedContainsDataOnSelectedMonth;

  const allUtility = compiledSessionConsumptionDataState.consolidatedSessionConsumption.allConversations.utility;
  const allAuthentication =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.allConversations.authentication;
  const allMarketing = compiledSessionConsumptionDataState.consolidatedSessionConsumption.allConversations.marketing;
  const allService = compiledSessionConsumptionDataState.consolidatedSessionConsumption.allConversations.service;
  const allTotal = compiledSessionConsumptionDataState.consolidatedSessionConsumption.allConversations.total;
  const allSessionByCompany =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.allConversations.oldWhatsappPricing
      ?.sessionByCompany;
  const allSessionByUser =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.allConversations.oldWhatsappPricing
      ?.sessionByUser;

  const billedUtility = compiledSessionConsumptionDataState.consolidatedSessionConsumption.billedConversations.utility;
  const billedAuthentication =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.billedConversations.authentication;
  const billedMarketing =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.billedConversations.marketing;
  const billedService = compiledSessionConsumptionDataState.consolidatedSessionConsumption.billedConversations.service;
  const billedTotal = compiledSessionConsumptionDataState.consolidatedSessionConsumption.billedConversations.total;
  const billedSessionByCompany =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.billedConversations.oldWhatsappPricing
      ?.sessionByCompany;
  const billedSessionByUser =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.billedConversations.oldWhatsappPricing
      ?.sessionByUser;

  const freeTier =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.freeOfChargeConversations.freeTier;
  const freeEntryPoint =
    compiledSessionConsumptionDataState.consolidatedSessionConsumption.freeOfChargeConversations.freeEntryPoint;
  const freeTotal = compiledSessionConsumptionDataState.consolidatedSessionConsumption.freeOfChargeConversations.total;

  const consolidatedContainsDataOnSelectedMonth =
    compiledSessionConsumptionDataState.consolidatedContainsDataOnSelectedMonth;

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        setHeaderContent({
          redirect: '/data-consumption',
          text: content.formatString(content.header, tenant.info.name),
        });

        const showConsolidatedSessionNewPricing = dateToSearch >= WHATSAPP_BP_PRICING_CHANGES_CONSOLIDATED_START_DATE;
        setConsolidatedSessionNewPricing(showConsolidatedSessionNewPricing);

        const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;

        if (showConsolidatedSessionNewPricing) {
          const consolidatedSessionConsumption = await billingSessionConsumptionApi.getConsolidatedSessionConsumption(
            tenant.info.id,
            monthString,
            conversationWabaFilter,
          );
          setCompiledSessionConsumptionDataState({
            consolidatedSessionConsumption: consolidatedSessionConsumption,
            consolidatedContainsDataOnSelectedMonth: billingSessionConsumptionApi.getStatus() === RequestStatus.SUCCESS,
          });
        } else {
          const oldConsolidatedSessionConsumption =
            await billingSessionConsumptionApi.getOldConsolidatedSessionConsumption(
              tenant.info.id,
              monthString,
              conversationTypeFilter,
              conversationWabaFilter,
            );
          setOldCompiledSessionConsumptionDataState({
            oldConsolidatedSessionConsumption: oldConsolidatedSessionConsumption,
            oldConsolidatedContainsDataOnSelectedMonth:
              billingSessionConsumptionApi.getStatus() === RequestStatus.SUCCESS,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToRetrieveData,
      planStatus: planStatus,
    });
  };

  const wabaOptionChangeEvent = (selectedOption) => {
    const conversationWabaValue = selectedOption.detail.value;
    setConversationWabaFilter(conversationWabaValue);
    trackEvent(REPORT_SESSION_CONSUMPTION_WABAID_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToSearch,
      whatsappConversationWaba: conversationWabaValue,
    });
  };

  const typeOptionChangeEvent = (selectedOption) => {
    const conversationTypeValue = selectedOption.detail.value;
    setConversationTypeFilter(conversationTypeValue);
    trackEvent(REPORT_SESSION_CONSUMPTION_CONVERSATION_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToSearch,
      whatsappConversationType: conversationTypeValue,
    });
  };

  return (
    <>
      <PageHeader
        title={content.title}
        dateToSearch={dateToSearch}
        periodOptions={periodOptions}
        periodOptionChangeEvent={periodOptionChangeEvent}
      />
      <SessionConsumptionPaper>
        <div>
          <ConsolidatedHeader
            infoHeader={content.formatString(content.plan, capitalizeFirstLetter(tenant.info.plan))}
            dateToSearch={dateToSearch}
            currentMonth={currentMonth}
            middleInfoContent={content.middleInfo}
            endInfoContent={content.endInfo}
            filter1={
              conversationWabaFilterOptions.length > 0 && (
                <BdsSelect
                  disabled={conversationWabaFilterOptions.length === 1}
                  value={conversationWabaFilter}
                  label={content.conversationWabaLabel}
                  onBdsChange={wabaOptionChangeEvent}
                >
                  {conversationWabaFilterOptions}
                </BdsSelect>
              )
            }
            filter2={
              !showConsolidatedSessionNewPricing && (
                <BdsSelect
                  value={conversationTypeFilter}
                  label={content.conversationTypeLabel}
                  onBdsChange={typeOptionChangeEvent}
                >
                  {conversationTypeFilterOptions}
                </BdsSelect>
              )
            }
          />
          {isWabaloading || isLoading ? (
            <BlipLoadingComponent />
          ) : (
            <SessionConsumptionConsolidatedRow>
              <bds-grid
                class="main-session-grid"
                xs="12"
                gap="3"
                direction="column"
                flexWrap="wrap"
                justify-content="center"
              >
                <bds-grid
                  class={`${!showConsolidatedSessionNewPricing ? 'old-session-grid' : 'none'}`}
                  xs="12"
                  direction="row"
                  justify-content="space-between"
                >
                  <bds-grid margin="r-3">
                    <ConsolidatedCard
                      title={content.consolidated.sessionsByUser}
                      value={sessionByUser}
                      currentMonth={currentMonth}
                      consolidatedContainsDataOnSelectedMonth={oldConsolidatedContainsDataOnSelectedMonth}
                      content={content}
                    />
                  </bds-grid>

                  <bds-grid>
                    <ConsolidatedCard
                      title={content.consolidated.sessionsByCompany}
                      value={sessionByCompany}
                      currentMonth={currentMonth}
                      consolidatedContainsDataOnSelectedMonth={oldConsolidatedContainsDataOnSelectedMonth}
                      content={content}
                    />
                  </bds-grid>

                  <bds-grid margin="l-3">
                    <ConsolidatedCard
                      title={content.consolidated.sessionsTotal}
                      value={sessionTotal}
                      currentMonth={currentMonth}
                      consolidatedContainsDataOnSelectedMonth={oldConsolidatedContainsDataOnSelectedMonth}
                      content={content}
                    />
                  </bds-grid>
                </bds-grid>

                <bds-grid
                  class={`${showConsolidatedSessionNewPricing ? 'new-session-grid' : 'none'}`}
                  xs="12"
                  direction="row"
                  justify-content="space-between"
                >
                  <bds-grid margin="r-3">
                    <ConsolidatedCard
                      title={content.consolidated.conversationTypeCategory.newPricing.all.title}
                      value={allTotal}
                      currentMonth={currentMonth}
                      consolidatedContainsDataOnSelectedMonth={consolidatedContainsDataOnSelectedMonth}
                      content={content}
                      tooltipText={content.consolidated.tooltip.allConversations}
                    />
                  </bds-grid>

                  <bds-grid>
                    <ConsolidatedCard
                      title={content.consolidated.conversationTypeCategory.newPricing.regular.title}
                      value={billedTotal}
                      currentMonth={currentMonth}
                      consolidatedContainsDataOnSelectedMonth={consolidatedContainsDataOnSelectedMonth}
                      content={content}
                      tooltipText={content.consolidated.tooltip.billedConversations}
                    />
                  </bds-grid>

                  <bds-grid margin="l-3">
                    <ConsolidatedCard
                      title={content.consolidated.conversationTypeCategory.newPricing.free.title}
                      value={freeTotal}
                      currentMonth={currentMonth}
                      consolidatedContainsDataOnSelectedMonth={consolidatedContainsDataOnSelectedMonth}
                      content={content}
                      tooltipText={content.consolidated.tooltip.freeOfChargeConversations}
                    />
                  </bds-grid>
                </bds-grid>

                <bds-grid
                  class={`${showConsolidatedSessionNewPricing ? 'category-session-grid' : 'category-session-grid none'}`}
                  xxs="12"
                  direction="row"
                  justify-content="space-between"
                >
                  <bds-grid class="bg-color-surface-2" direction="column" justify-content="space-between" margin="r-3">
                    <bds-typo variant="fs-16" bold="bold" margin={true} class="b-1">
                      {content.consolidated.conversationTypeCategory.newPricing.all.title}
                    </bds-typo>
                    <bds-typo class="color-content-disable" variant="fs-14">
                      {content.consolidated.conversationTypeCategory.newPricing.subTitle}
                    </bds-typo>

                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.marketing}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth ? allMarketing.toLocaleString(content.locale) : '--'}
                      </bds-typo>
                    </bds-grid>
                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.utility}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth ? allUtility.toLocaleString(content.locale) : '--'}
                      </bds-typo>
                    </bds-grid>
                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.authentication}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth
                          ? allAuthentication.toLocaleString(content.locale)
                          : '--'}
                      </bds-typo>
                    </bds-grid>
                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.service}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth ? allService.toLocaleString(content.locale) : '--'}
                      </bds-typo>
                    </bds-grid>

                    <bds-typo
                      variant="fs-14"
                      class={`${allSessionByCompany || allSessionByUser ? 'color-content-disable' : 'none'}`}
                    >
                      {content.consolidated.conversationTypeCategory.oldPricing.title}
                    </bds-typo>
                    <bds-grid
                      direction="row"
                      justify-content="space-between"
                      class={`${allSessionByCompany ? '' : 'none'}`}
                    >
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.oldPricing.sessionsByCompany}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth && allSessionByCompany
                          ? allSessionByCompany.toLocaleString(content.locale)
                          : '--'}
                      </bds-typo>
                    </bds-grid>

                    <bds-grid
                      direction="row"
                      justify-content="space-between"
                      class={`${allSessionByUser ? '' : 'none'}`}
                    >
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.oldPricing.sessionsByUser}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth && allSessionByUser
                          ? allSessionByUser.toLocaleString(content.locale)
                          : '--'}
                      </bds-typo>
                    </bds-grid>
                  </bds-grid>

                  <bds-grid class="bg-color-surface-2" direction="column" justify-content="space-between">
                    <bds-typo variant="fs-16" bold="bold" margin={true} class="b-1">
                      {content.consolidated.conversationTypeCategory.newPricing.regular.title}
                    </bds-typo>
                    <bds-typo class="color-content-disable" variant="fs-14">
                      {content.consolidated.conversationTypeCategory.newPricing.subTitle}
                    </bds-typo>

                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.marketing}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth
                          ? billedMarketing.toLocaleString(content.locale)
                          : '--'}
                      </bds-typo>
                    </bds-grid>
                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.utility}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth ? billedUtility.toLocaleString(content.locale) : '--'}
                      </bds-typo>
                    </bds-grid>
                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.authentication}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth
                          ? billedAuthentication.toLocaleString(content.locale)
                          : '--'}
                      </bds-typo>
                    </bds-grid>
                    <bds-grid direction="row" justify-content="space-between">
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.newPricing.service}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth ? billedService.toLocaleString(content.locale) : '--'}
                      </bds-typo>
                    </bds-grid>

                    <bds-typo
                      variant="fs-14"
                      class={`${billedSessionByCompany || billedSessionByUser ? 'color-content-disable' : 'none'}`}
                    >
                      {content.consolidated.conversationTypeCategory.oldPricing.title}
                    </bds-typo>

                    <bds-grid
                      direction="row"
                      justify-content="space-between"
                      class={`${billedSessionByCompany ? '' : 'none'}`}
                    >
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.oldPricing.sessionsByCompany}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth && billedSessionByCompany
                          ? billedSessionByCompany.toLocaleString(content.locale)
                          : '--'}
                      </bds-typo>
                    </bds-grid>

                    <bds-grid
                      direction="row"
                      justify-content="space-between"
                      class={`${billedSessionByUser ? '' : 'none'}`}
                    >
                      <bds-typo variant="fs-14">
                        {content.consolidated.conversationTypeCategory.oldPricing.sessionsByUser}
                      </bds-typo>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth && billedSessionByUser
                          ? billedSessionByUser.toLocaleString(content.locale)
                          : '--'}
                      </bds-typo>
                    </bds-grid>
                  </bds-grid>

                  <bds-grid margin="l-3" class="bg-color-surface-2" direction="column" justify-content="flex-start">
                    <bds-typo variant="fs-16" bold="bold" margin={true} class="b-1">
                      {content.consolidated.conversationTypeCategory.newPricing.free.title}
                    </bds-typo>

                    <bds-grid direction="row" justify-content="space-between">
                      <bds-grid direction="row" justify-content="flex-start">
                        <bds-typo variant="fs-14">
                          {content.consolidated.conversationTypeCategory.newPricing.free.freeTier}
                        </bds-typo>
                        <bds-tooltip
                          position="top-center"
                          tooltip-text={content.consolidated.conversationTypeCategory.newPricing.free.tooltip.freeTier}
                        >
                          <bds-icon name="info" size="small"></bds-icon>
                        </bds-tooltip>
                      </bds-grid>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth ? freeTier.toLocaleString(content.locale) : '--'}
                      </bds-typo>
                    </bds-grid>
                    <bds-grid direction="row" justify-content="space-between">
                      <bds-grid direction="row" justify-content="flex-start">
                        <bds-typo variant="fs-14">
                          {content.consolidated.conversationTypeCategory.newPricing.free.freeEntryPoint}
                        </bds-typo>
                        <bds-tooltip
                          position="top-center"
                          tooltip-text={
                            content.consolidated.conversationTypeCategory.newPricing.free.tooltip.freeEntryPoint
                          }
                        >
                          <bds-icon name="info" size="small"></bds-icon>
                        </bds-tooltip>
                      </bds-grid>
                      <bds-typo variant="fs-14">
                        {consolidatedContainsDataOnSelectedMonth ? freeEntryPoint.toLocaleString(content.locale) : '--'}
                      </bds-typo>
                    </bds-grid>
                  </bds-grid>
                </bds-grid>
              </bds-grid>
            </SessionConsumptionConsolidatedRow>
          )}
        </div>
      </SessionConsumptionPaper>
    </>
  );
};

export default SessionConsumptionConsolidatedInformationsComponent;
