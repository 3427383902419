import { useMemo, useReducer, useContext, createContext, useCallback } from 'react';
import { CommonReducer } from 'reducers/CommonReducer';
import moment from 'moment';
import 'moment/locale/pt-br';

const CommonContext = createContext();

const useCommon = () => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error('useCommon must be used within a CommonProvider');
  }
  const [common, dispatch] = context;

  //defining actions to be used based on the reducer
  const setLanguage = useCallback((language) => dispatch({ type: 'setLanguage', language }), [dispatch]);
  const setConfig = useCallback((config) => dispatch({ type: 'setConfig', config }), [dispatch]);
  const setLoading = useCallback((loading) => dispatch({ type: 'setLoading', loading }), [dispatch]);
  const setLoggedUser = useCallback((loggedUser) => dispatch({ type: 'setLoggedUser', loggedUser }), [dispatch]);
  const setHeaderContent = useCallback((headerContent) => dispatch({ type: 'setHeaderContent', headerContent }), [
    dispatch,
  ]);

  moment.locale(common.language);

  return {
    common,
    moment,
    setLanguage,
    setConfig,
    setLoading,
    setLoggedUser,
    setHeaderContent,
  };
};

const CommonProvider = (props) => {
  const [common, dispatch] = useReducer(CommonReducer, {
    language: 'pt',
    config: {},
    loading: false,
    headerContent: { redirect: undefined, text: undefined },
  });
  const value = useMemo(() => [common, dispatch], [common]);
  return <CommonContext.Provider value={value} {...props} />;
};

export { CommonProvider, useCommon };
