import { BillingMetrics } from 'shared/BillingMetrics';
import { BillingPlanMetric } from '../metrics/BillingPlanMetric';

export class CompiledDailyActiveUserConsumption extends BillingPlanMetric {
  constructor(compiledDailyActiveUserConsumption: Partial<CompiledDailyActiveUserConsumption | null>) {
    super(compiledDailyActiveUserConsumption);
    this.metric.metricId = BillingMetrics.dailyActiveUser;
    Object.assign(this, compiledDailyActiveUserConsumption);
  }
}
