import { useContext, useEffect, useMemo, useState } from 'react';
import { useCommon } from '../../../contexts/CommonContext';
import { startLoading, stopLoading } from '../../../api/CommonApi';
import SessionConsumptionContext from '../../../contexts/SessionConsumptionContext';
import SessionConsumptionConsolidatedInformations from './SessionConsumptionConsolidatedInformations';
import SessionConsumptionMonthlyUsage from './SessionConsumptionMonthlyUsage';
import SessionConsumptionDailyUsage from './SessionConsumptionDailyUsage';
import { SessionConsumptionContainer } from '../styles';
import FeatureToggleService from '../../../services/FeatureToggleService';
import { SESSION_CONSUMPTION_BY_BOT_INFORMATIONS } from '../../../shared/FeatureToggleKeys';

const SessionConsumptionSummaryComponent: React.FC = () => {
  const [showSessionConsumptionMonthlyUsage, setShowSessionConsumptionMonthlyUsage] = useState(false);
  const { tenant, content } = useContext(SessionConsumptionContext);
  const { setHeaderContent } = useCommon();

  useEffect(() => {
    startLoading();
    setHeaderContent({
      redirect: '/',
      text: content.formatString(content.header, tenant.info.name),
    });

    stopLoading();

    return () => setHeaderContent({ redirect: null });
  }, []);

  useMemo(() => {
    const getData = async () => {
      const showSessionConsumptionMonthlyUsage = await FeatureToggleService.isFeatureToggleEnabled(
        SESSION_CONSUMPTION_BY_BOT_INFORMATIONS,
      );
      setShowSessionConsumptionMonthlyUsage(showSessionConsumptionMonthlyUsage);
    };
    getData();
  }, []);

  return (
    <SessionConsumptionContainer>
      <SessionConsumptionConsolidatedInformations></SessionConsumptionConsolidatedInformations>
      {showSessionConsumptionMonthlyUsage && (
        <SessionConsumptionMonthlyUsage tableBodyHeight="1000px" registersToTake={5}></SessionConsumptionMonthlyUsage>
      )}

      <SessionConsumptionDailyUsage tableBodyHeight="1000px" registersToTake={5}></SessionConsumptionDailyUsage>
    </SessionConsumptionContainer>
  );
};

export default SessionConsumptionSummaryComponent;
