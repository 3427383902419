import React, { useContext } from 'react';
import ConsolidatedDataConsumption from './components/ConsolidatedDataConsumption';
import DauConsumption from './components/DauConsumption';
import AgentConsumption from './components/AgentConsumption';
import SessionConsumption from './components/SessionConsumption';
import MiuConsumption from './components/MiuConsumption';
import { BillingMetrics } from './../../../shared/BillingMetrics';
import { useDauConsumptionContext } from './../../../contexts/Billing/DauConsumptionContext';
import { useAgentConsumptionContext } from './../../../contexts/Billing/AgentConsumptionContext';
import { useSessionConsumptionContext } from './../../../contexts/Billing/SessionConsumptionContext';
import { useMiuConsumptionContext } from './../../../contexts/Billing/MiuConsumptionContext';
import DataConsumptionMetricContext from './../../../contexts/Billing/DataConsumptionMetricContext';
import DataConsumptionContext from '../../../contexts/Billing/DataConsumptionContext';

const DataConsumptionComponent: React.FC = () => {
  const { isMetricAllowed, hasEnabledMetric } = useContext(DataConsumptionContext);
  const { compiledDailyActiveUserDataState, consolidatedDauApiRequisition, isDauLoading } = useDauConsumptionContext();
  const { compiledAgentConsumptionDataState, consolidatedAgentApiRequisition, isAgentLoading } =
    useAgentConsumptionContext();
  const { compiledSessionConsumptionDataState, consolidatedSessionApiRequisition, isSessionLoading } =
    useSessionConsumptionContext();
  const { compiledMiuConsumptionDataState, consolidatedMiuApiRequisition, isMiuLoading, miuMetricIds, localization } =
    useMiuConsumptionContext();

  const contextProps = {
    consolidatedDauApiRequisition,
    compiledDailyActiveUserDataState,
    isDauLoading,
    consolidatedAgentApiRequisition,
    compiledAgentConsumptionDataState,
    isAgentLoading,
    consolidatedSessionApiRequisition,
    compiledSessionConsumptionDataState,
    isSessionLoading,
    consolidatedMiuApiRequisition,
    compiledMiuConsumptionDataState,
    isMiuLoading,
    miuMetricIds,
    localization,
  };
  return (
    <DataConsumptionMetricContext.Provider value={contextProps}>
      <ConsolidatedDataConsumption />
      {isMetricAllowed(BillingMetrics.dailyActiveUser) && <DauConsumption />}
      {isMetricAllowed(BillingMetrics.agent) && <AgentConsumption />}
      {isMetricAllowed(BillingMetrics.session) && <SessionConsumption />}
      {hasEnabledMetric([
        BillingMetrics.monthlyActiveUser,
        BillingMetrics.monthlyEngagedUser,
        BillingMetrics.exchangedMessage,
      ]) && <MiuConsumption />}
    </DataConsumptionMetricContext.Provider>
  );
};

export default DataConsumptionComponent;
