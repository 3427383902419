import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import MiuConsumptionContext from '../../../contexts/MiuConsumptionContext';
import { useContentLocalizer } from '../../../hooks/useContentLocalizer';
import MiuConsumptionSummaryComponent from './components/MiuConsumptionSummary';
import { useTenant } from '../../../contexts/TenantContext';
import { useCommon } from '../../../contexts/CommonContext';
import MonthlyUsageComponent from './components/Monthly/MonthlyUsage';
import DailyUsageComponent from './components/Daily/DailyUsage';
import { AsideMenu } from '../components/AsideMenu';
import { useBillingConsumptionContext } from '../../../contexts/Billing/BillingConsumptionContext';
import { action, origin } from '../../../shared/SegmentEvents';
import { useBillingPlanUsageContext } from '../../../contexts/Billing/BillingPlanUsageContext';
import { useBillingMetricsContext } from '../../../contexts/Billing/BillingMetricsContext';
import { MonthlyMiuConsumption } from '../../../models/billing/miuConsumption/MonthlyMiuConsumption';
import { sortByProperty } from '../../../utils/array';
import BillingMiuConsumptionServiceApi from '../../../api/Billing/BillingMiuConsumptionApi';
import { billingRoutes, billingSubRoutes } from '../../../routes/BillingRoutes';
import { useMiuConsumptionContext } from '../../../contexts/Billing/MiuConsumptionContext';

const MiuConsumption: React.FC = () => {
  const { dateToSearch, setDateToSearch, periodOptions, currentMonth } = useBillingConsumptionContext();
  const { compiledMiuConsumptionDataState, isMiuLoading, consolidatedMiuApiRequisition, miuMetricIds, localization } =
    useMiuConsumptionContext();
  const { planStatus, getPlanStatus } = useBillingPlanUsageContext();
  const billingMiuConsumptionApi = new BillingMiuConsumptionServiceApi();
  const { hasEnabledMetric } = useBillingMetricsContext();
  const { tenant } = useTenant();
  const [monthlyMiuConsumptionDataState, setMonthlyMiuConsumptionDataState] = useState<MonthlyMiuConsumption[]>([]);
  const [dailyMiuConsumptionDataState, setDailyMiuConsumptionDataState] = useState<MonthlyMiuConsumption[]>([]);

  const {
    setHeaderContent,
    common: { loggedUser },
  } = useCommon();

  const content = useContentLocalizer(localization.pageLocalization);

  useEffect(() => {
    setHeaderContent({
      redirect: '/data-consumption',
      text: content.formatString(content.header, tenant.info.name),
    });
    return () => setHeaderContent({ redirect: '/' });
  }, []);

  const sortMiuConsumptionMonthlyUsage = (sort) => {
    const sortedChatbotsConsumption = sortByProperty(monthlyMiuConsumptionDataState, sort.property, sort.order);
    setMonthlyMiuConsumptionDataState([...sortedChatbotsConsumption]);
  };

  const sortMiuConsumptionDailyUsage = (sort) => {
    const sortedDailyUsageConsumption = sortByProperty(dailyMiuConsumptionDataState, sort.property, sort.order);
    setDailyMiuConsumptionDataState([...sortedDailyUsageConsumption]);
  };

  const trackingProps = {
    date: dateToSearch,
    planStatus: planStatus.current,
    origin: origin.MIUCONSUMPTION,
    action: action.SELECT,
  };

  const contextProps = {
    content,
    tenant,
    loggedUser,
    setHeaderContent,
    dateToSearch,
    setDateToSearch,
    periodOptions,
    currentMonth,
    trackingProps,
    planStatus,
    getPlanStatus,
    hasEnabledMetric,
    compiledMiuConsumptionDataState,
    isMiuLoading,
    consolidatedMiuApiRequisition,
    sortMiuConsumptionMonthlyUsage,
    sortMiuConsumptionDailyUsage,
    monthlyMiuConsumptionDataState,
    setMonthlyMiuConsumptionDataState,
    dailyMiuConsumptionDataState,
    setDailyMiuConsumptionDataState,
    billingMiuConsumptionApi,
    miuMetricIds,
  };

  return (
    <MiuConsumptionContext.Provider value={contextProps}>
      <AsideMenu>
        <Switch>
          <Route exact path={billingRoutes.mauConsumption} component={MiuConsumptionSummaryComponent}></Route>
          <Route exact path={billingSubRoutes.mauConsumptionChatbots} component={MonthlyUsageComponent}></Route>
          <Route exact path={billingSubRoutes.mauConsumptionDaily} component={DailyUsageComponent}></Route>
        </Switch>
      </AsideMenu>
    </MiuConsumptionContext.Provider>
  );
};

export default MiuConsumption;
