import React, { FC, useContext, useEffect } from 'react';
import { useCommon } from '../../../../contexts/CommonContext';
import DailyActiveUsersContext from '../../../../contexts/DailyActiveUsersContext';
import { useContentLocalizer } from '../../../../hooks/useContentLocalizer';
import DailyActiveUserMonthlyUsage from '../../../DailyActiveUsers/components/DailyActiveUserMonthlyUsage';
import { localization } from '../../localization';

const MonthlyUsageComponent: FC = () => {
  const { dateToSearch, tenant } = useContext(DailyActiveUsersContext);
  const { setHeaderContent } = useCommon();
  const content = useContentLocalizer(localization);

  useEffect(() => {
    setHeaderContent({
      redirect: '/daily-active-users',
      text: content.formatString(content.header, tenant.info.name),
    });

    return () => setHeaderContent({ redirect: null });
  }, [dateToSearch]);

  return <DailyActiveUserMonthlyUsage tableBodyHeight="1000px" registersToTake={-1}></DailyActiveUserMonthlyUsage>;
};

export default MonthlyUsageComponent;
