import React from 'react';
import { DataConsumptionContainer } from './components/ConsolidatedDataConsumption/styles';
import DataConsumptionContext from '../../../contexts/Billing/DataConsumptionContext';
import { useBillingConsumptionContext } from '../../../contexts/Billing/BillingConsumptionContext';
import { useTenant } from '../../../contexts/TenantContext';
import { useCommon } from '../../../contexts/CommonContext';
import { useBillingMetricsContext } from '../../../contexts/Billing/BillingMetricsContext';
import DataConsumptionComponent from './DataConsumption';
import { useBillingPlanUsageContext } from '../../../contexts/Billing/BillingPlanUsageContext';
import { PageHeaderComponent } from '../components/Metrics/PageHeader/PageHeaderComponent';
import { useContentLocalizer } from '../../../hooks/useContentLocalizer';
import { localization } from './localizations';
import { action, origin, REPORT_PERIOD_SELECT } from '../../../shared/SegmentEvents';
import { trackEvent } from '../../../api/Billing/Tracking';

const DataConsumption: React.FC = () => {
  const { tenant } = useTenant();
  const {
    common: { loggedUser },
  } = useCommon();
  const { dateToSearch, setDateToSearch, periodOptions, currentMonth } = useBillingConsumptionContext();
  const { planStatus, planStatusLoading, getPlanStatus } = useBillingPlanUsageContext();
  const { isMetricAllowed, hasEnabledMetric } = useBillingMetricsContext();
  const content = useContentLocalizer(localization);

  const trackingProps = {
    date: dateToSearch,
    planStatus: planStatus.current,
    origin: origin.PLANOVERVIEW,
    action: action.HOVER,
  };

  const contextProps = {
    tenant,
    loggedUser,
    dateToSearch,
    periodOptions,
    currentMonth,
    setDateToSearch,
    isMetricAllowed,
    hasEnabledMetric,
    planStatus,
    planStatusLoading,
    trackingProps,
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToRetrieveData,
      planStatus: planStatus,
      action: action.SELECT,
    });
  };

  return (
    <DataConsumptionContext.Provider value={contextProps}>
      <DataConsumptionContainer>
        <PageHeaderComponent
          title={content.title}
          dateToSearch={dateToSearch}
          periodOptions={periodOptions}
          periodOptionChangeEvent={periodOptionChangeEvent}
        />
        <DataConsumptionComponent />
      </DataConsumptionContainer>
    </DataConsumptionContext.Provider>
  );
};

export default DataConsumption;
