import React from 'react';
import PropTypes from 'prop-types';
import { ConsolidatedCardComponent } from './ConsolidatedCardComponent';

const ConsolidatedCardContainer = ({
  title,
  value,
  tooltipText,
  currentMonth,
  consolidatedContainsDataOnSelectedMonth,
  content,
}) => {
  return (
    <ConsolidatedCardComponent
      title={title}
      value={value}
      tooltipText={tooltipText}
      currentMonth={currentMonth}
      consolidatedContainsDataOnSelectedMonth={consolidatedContainsDataOnSelectedMonth}
      content={content}
    ></ConsolidatedCardComponent>
  );
};

ConsolidatedCardContainer.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  tooltipText: PropTypes.string,
  currentMonth: PropTypes.bool.isRequired,
  consolidatedContainsDataOnSelectedMonth: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
};

export { ConsolidatedCardContainer };
