import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Header } from '../Header';
import { GeneralChart } from '../GeneralChart';

import {
  ConsolidatedPaperContainer,
  ConsolidatedPaperHeader,
  ConsolidatedPaperBody,
  LeftChartArea,
  MiddleBorder,
  RightChartArea,
  LabelChartAligment,
  WarningLabel,
} from './styles';
import { BlipLoadingComponent } from '../../../../../components/BlipLoading/BlipLoadingComponent';
const ConsolidatedPaperComponent = ({ header, leftChart, leftInfoPapers, rightChart, rightInfoPapers, isLoading }) => {
  const leftLabelChart = useMemo(() => {
    return leftChart.leftChartData.customLabels.map((label, index) => {
      return (
        <LabelChartAligment key={index} backgroundColor={label.backgroundColor}>
          <BdsTypo key={label} variant={'fs-14'} bold={'regular'} lineHeight="plus">
            {label.text}
          </BdsTypo>
        </LabelChartAligment>
      );
    });
  }, [leftChart]);

  const rightLabelChart = useMemo(() => {
    return rightChart.rightChartData.customLabels.map((label, index) => {
      return (
        <LabelChartAligment key={index} backgroundColor={label.backgroundColor}>
          <BdsTypo key={label} variant={'fs-14'} bold={'regular'} lineHeight="plus">
            {label.text}
          </BdsTypo>
        </LabelChartAligment>
      );
    });
  }, [rightChart]);

  return (
    <ConsolidatedPaperContainer>
      <ConsolidatedPaperHeader>
        <Header
          title={header.title}
          subtitle={header.subtitle}
          tooltipIcon={header.tooltipIcon}
          tooltipText={header.tooltipText}
          buttonTitle={header.buttonTitle}
          pathname={header.pathname}
          buttonTrackEvent={header.buttonTrackEvent}
          tooltipTrackEvent={header.tooltipTrackEvent}
          idTest={header.idTest}
        ></Header>
      </ConsolidatedPaperHeader>
      <ConsolidatedPaperBody>
        {isLoading ? (
          <BlipLoadingComponent />
        ) : (
          <>
            <LeftChartArea>
              <BdsTypo variant={'fs-20'} bold={'bold'} lineHeight="plus">
                {leftChart.leftChartData.title}
                {leftChart.leftChartData.tooltipIcon && leftChart.leftChartData.tooltipText && (
                  <BdsTooltip position="top-center" tooltip-text={leftChart.leftChartData.tooltipText} onMouseOver={leftChart.leftChartData.tooltipTrackEvent}>
                    <BdsIcon name={leftChart.leftChartData.tooltipIcon} size="small"></BdsIcon>
                  </BdsTooltip>
                )}
              </BdsTypo>
              <GeneralChart
                chartType={leftChart.leftChartData.chartType}
                data={leftChart.leftChartData}
                options={leftChart.leftChartOptions}
                plugins={leftChart.leftChartPlugins}
                redraw={leftChart.redraw}
              ></GeneralChart>
              <BdsTypo variant={'fs-14'} bold={'regular'} lineHeight="simple">
                {leftLabelChart}
              </BdsTypo>
            </LeftChartArea>
            {leftInfoPapers}
            <MiddleBorder></MiddleBorder>
            <RightChartArea>
              <BdsTypo variant={'fs-20'} bold={'bold'} lineHeight="plus">
                {rightChart.rightChartData.title}
              </BdsTypo>
              <GeneralChart
                chartType={rightChart.rightChartData.chartType}
                data={rightChart.rightChartData}
                options={rightChart.rightChartOptions}
                redraw={leftChart.redraw}
              ></GeneralChart>
              <BdsTypo variant={'fs-14'} bold={'regular'} lineHeight="simple">
                {rightLabelChart}
              </BdsTypo>
              {rightChart.rightChartData.warningLabelBold && rightChart.rightChartData.warningLabelRegular && (
                <WarningLabel>
                  <BdsTypo variant={'fs-14'} bold={'regular'} lineHeight="plus">
                    <span>{rightChart.rightChartData.warningLabelBold}</span>
                    <span>{rightChart.rightChartData.warningLabelRegular}</span>
                  </BdsTypo>
                </WarningLabel>
              )}
            </RightChartArea>
            {rightInfoPapers}
          </>
        )}
      </ConsolidatedPaperBody>
    </ConsolidatedPaperContainer>
  );
};

ConsolidatedPaperComponent.propTypes = {
  header: PropTypes.object.isRequired,
  leftChart: PropTypes.object.isRequired,
  leftInfoPapers: PropTypes.object.isRequired,
  rightChart: PropTypes.object.isRequired,
  rightInfoPapers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export { ConsolidatedPaperComponent };
