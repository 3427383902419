import { MtlsCertificate } from '../models/MtlsCertificate';
import { BlipApi } from './BlipApi';
import { METHOD, RequestStatus } from 'shared/Requests';

export default class MtlsCertificateApi {
  private readonly POSTMASTER = 'postmaster@blip.ai';
  private code = '';
  private status = '';

  public getStatus() {
    return this.status;
  }

  public getCode() {
    return this.code;
  }

  public setSuccess() {
    this.status = RequestStatus.SUCCESS;
  }

  public setErrorLog(e) {
    const error = JSON.parse(<string>e);
    const {
      status,
      reason: { code },
    } = JSON.parse(error.message);
    this.code = code;
    this.status = status;
    console.log(e);
  }

  public async getMtlsCertificates(tenantId: string): Promise<MtlsCertificate[]> {
    const uri = `/mtls-certificates/${tenantId}`;

    const command = {
      to: this.POSTMASTER,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);

      this.setSuccess();
      return items.map((item) => new MtlsCertificate(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<MtlsCertificate>();
    }
  }
}
